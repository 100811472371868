
export const BANK_ACCOUNT_FOR_PAYMENT_VIA_AUKRO_NAME: string = 'Výchozí pro Platbu přes Aukro ';
export const BANK_ACCOUNT_NAME: string = 'Bankovní účet';
export const BANK_ACCOUNT_NAME_MAX_LENGTH: number = 50;

export const ENVIRONMENT_PRODUCTION: string = 'prod';

// TODO(PDEV-10263) Change this to be served and decided by BE
// PERSONAL_TAKEOVER, PERSONAL_TAKEOVER_ADVANCED_PAYMENT
export const PERSONAL_TAKEOVER_SHIPPING_METHOD_IDS: number[] = [12, 15];
export const PERSONAL_TAKEOVER_SHIPPING_METHOD_CODES: string[] = ['PERSONAL_TAKEOVER', 'PERSONAL_TAKEOVER_ADVANCED_PAYMENT'];

export const TAG_ATTRIBUTE_ID = 130546;

export const APP_NAME = 'Aukro';

export const DELIVERY_TIME_ATTRIBUTE_ID = 8820;
