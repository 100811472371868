/**
 * Unit maps for key from API to Italian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_IT = {
  PAIRS: {
    '=0': 'coppie',
    '=1': 'coppia',
    '=2': 'coppie',
    '=3': 'coppie',
    '=4': 'coppie',
    other: 'coppie',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'coppie',
    '=1': 'coppia',
    other: 'coppie',
  },
  PIECES: {
    '=0': 'pezzi',
    '=1': 'pezzo',
    '=2': 'pezzi',
    '=3': 'pezzi',
    '=4': 'pezzi',
    other: 'pezzi',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'pezzi',
    '=1': 'pezzo',
    other: 'pezzi',
  },
  PIECE_SHORT: {
    other: 'pz.',
  },
  SET: {
    '=0': 'set',
    '=1': 'set',
    '=2': 'set',
    '=3': 'set',
    '=4': 'set',
    other: 'set',
  },
  SET_ACCUSATIVE: {
    '=0': 'set',
    '=1': 'set',
    other: 'set',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'scommette',
    '=2': 'scommettono',
    '=3': 'scommettono',
    '=4': 'scommettono',
    other: 'scommette',
  },
  BET_PAST: {
    '=1': 'ha scommesso',
    '=2': 'hanno scommesso',
    '=3': 'hanno scommesso',
    '=4': 'hanno scommesso',
    other: 'ha scommesso',
  },
  ITEM: {
    '=0': 'articoli',
    '=1': 'articolo',
    '=2': 'articoli',
    '=3': 'articoli',
    '=4': 'articoli',
    other: 'articoli',
  },
  DAY: {
    '=0': 'giorni',
    '=1': 'giorno',
    '=2': 'giorni',
    '=3': 'giorni',
    '=4': 'giorni',
    other: 'giorni',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'giorno',
    other: 'giorni',
  },
  HOUR: {
    '=0': 'ore',
    '=1': 'ora',
    '=2': 'ore',
    '=3': 'ore',
    '=4': 'ore',
    other: 'ore',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'ora',
    other: 'ore',
  },
  MINUTE: {
    '=0': 'minuti',
    '=1': 'minuto',
    '=2': 'minuti',
    '=3': 'minuti',
    '=4': 'minuti',
    other: 'minuti',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minuto',
    other: 'minuti',
  },
  OTHER: {
    '=0': 'altri',
    '=1': 'altro',
    '=2': 'altri',
    '=3': 'altri',
    '=4': 'altri',
    other: 'altri',
  },
  FILE: {
    '=0': 'file',
    '=1': 'file',
    '=2': 'file',
    '=3': 'file',
    '=4': 'file',
    other: 'file',
  },
  IMAGE: {
    '=0': 'immagini',
    '=1': 'immagine',
    '=2': 'immagini',
    '=3': 'immagini',
    '=4': 'immagini',
    other: 'immagini',
  },
  OFFER: {
    '=0': 'offerte',
    '=1': 'offerta',
    '=2': 'offerte',
    '=3': 'offerte',
    '=4': 'offerte',
    other: 'offerte',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'offerte',
    '=1': 'offerta',
    '=2': 'offerte',
    '=3': 'offerte',
    '=4': 'offerte',
    other: 'offerte',
  },
  POSITIVE_COMMENT: {
    '=0': 'commenti positivi',
    '=1': 'commento positivo',
    '=2': 'commenti positivi',
    '=3': 'commenti positivi',
    '=4': 'commenti positivi',
    other: 'commenti positivi',
  },
  USER_GENITIV: {
    '=0': 'utenti',
    '=1': 'utente',
    '=2': 'utenti',
    '=3': 'utenti',
    '=4': 'utenti',
    other: 'utenti',
  },
  USER_INSTRUMENTAL: {
    '=0': 'utenti',
    '=1': 'utente',
    other: 'utenti',
  },
  ADDED_PASSIVE: {
    '=0': 'aggiunti',
    '=1': 'aggiunto',
    '=2': 'aggiunti',
    '=3': 'aggiunti',
    '=4': 'aggiunti',
    other: 'aggiunti',
  },
};
