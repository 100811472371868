import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { HttpRequestCloneConfig } from './model/http-request-clone-config';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { DomainCurrencyService } from '@shared/currency/service/domain-currency.service';

@Injectable()
export class CurrencyPreferenceInterceptor implements HttpInterceptor {

  private readonly CURRENCY_HEADER_NAME: string = 'X-Accept-Currency';

  constructor(
    private readonly domainCurrencyService: DomainCurrencyService,
  ) {
  }

  public intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.domainCurrencyService.currentDomainCurrency$
      .pipe(
        mergeMap((currency) =>
          this.interceptWithCurrency(
            request,
            next,
            currency,
          ),
        ),
      );
  }

  private interceptWithCurrency(
    request: HttpRequest<string>,
    next: HttpHandler,
    currency: CurrencyCodeType,
  ): Observable<HttpEvent<unknown>> {

    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    requestCloneConfig.headers = request.headers.set(
      this.CURRENCY_HEADER_NAME, currency,
    );

    return next.handle(request.clone(requestCloneConfig));
  }

}
