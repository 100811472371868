import { Inject, Injectable, Optional } from '@angular/core';
// TODO: Move appropriate logic into this service
// eslint-disable-next-line import/no-restricted-paths
import { PlatformService } from '@shared/platform/service/platform.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { MediaBreakpointsType } from '@common/responsiveness/type/media-breakpoints.type';
import { Nil } from '@util/helper-types/nil';
import { MEDIA_BREAKPOINTS } from '@common/responsiveness/const/media-breakpoints.const';
import { DOCUMENT } from '@angular/common';
import isNil from 'lodash-es/isNil';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { NumberUtils } from '@util/util/number.utils';
import { RangeBreakpointConfigModel } from '@common/responsiveness/model/range-breakpoint-config.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ExpressRequest } from '@common/platform/model/request';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { REQUEST } from '../../../server/const/request';
import { ViewportWidthType } from '@common/responsiveness/model/viewport-width.type';
import { ViewportWidthTypeUtil } from '@common/responsiveness/util/viewport-width-type.util';
import { MediaBreakpointsWithMinType } from '@common/responsiveness/type/media-breakpoints-with-min.type';
import { ArrayUtils } from '@util/util/array.utils';
import { LoggerService } from '@common/logger/service/logger.service';

type ClientHintMobileValue = typeof CLIENT_HINT_MOBILE_VALUES[number];

const CLIENT_HINT_MOBILE_VALUES = ['?0', '?1'] as const;

/**
 * Should be used everytime, if there's need to execute some TypeScript logic only on specific media breakpoint
 *
 * All public methods are working in both browser and server
 */
@Injectable({
  providedIn: 'root',
})
export class ResponsivenessService extends NgUnsubscribe {

  /**
   * Holds info about breakpoints defined in app and their values (in px)
   * @private
   */
  private readonly breakpointsValuesMap: ReadonlyMap<MediaBreakpointsWithMinType, number>;
  /**
   * Current window width
   * @private
   */
  private readonly _windowWidth$: BehaviorSubject<number | Nil> = new BehaviorSubject<number | Nil>(null);
  /**
   * Current active breakpoint
   * @private
   */
  private readonly _activeBreakpoint$: BehaviorSubject<MediaBreakpointsWithMinType | Nil> =
    new BehaviorSubject<MediaBreakpointsWithMinType | Nil>(null);
  /**
   * Current active viewport width type
   *
   * NOTE: If for example active viewport width type is tablet it doesn't mean that this device is tablet,
   *   but it has only the same width (like tablet). So you should always prefer using {@link _activeBreakpoint$}
   * @private
   */
  private readonly _viewportWidthType$: BehaviorSubject<ViewportWidthType | Nil> = new BehaviorSubject<ViewportWidthType | Nil>(null);

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() @Inject(REQUEST) private readonly request: ExpressRequest,
    private readonly platformService: PlatformService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly loggerService: LoggerService,
  ) {
    super();

    this.breakpointsValuesMap = this.createBreakpointValuesMap();

    this.initializeWindowWidthAndResizeListener();
    this.initializeActiveBreakpointAndWidthType();
  }

  /**
   * @returns current active viewport width type in uppercase ('MOBILE', 'TABLET' or 'DESKTOP')
   */
  public get viewportWidthTypeUppercase(): Uppercase<ViewportWidthType> {
    return this._viewportWidthType$.getValue().toUpperCase() as Uppercase<ViewportWidthType>;
  }

  /**
   * @returns current active viewport width type
   * @see _viewportWidthType$
   * @deprecated use `viewportWidthTypeUppercase` getter instead
   */
  public get viewportWidthType(): ViewportWidthType {
    return this._viewportWidthType$.getValue();
  }

  /**
   * Same like {@link viewportWidthType} but additionally returns Observable, which emits everytime {@link viewportWidthType} changes
   */
  public get viewportWidthType$(): Observable<ViewportWidthType> {
    return this._viewportWidthType$.asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  /**
   * @returns current active breakpoint
   * @see _activeBreakpoint$
   */
  public get activeBreakpoint(): MediaBreakpointsWithMinType | Nil {
    return this._activeBreakpoint$.getValue();
  }

  /**
   * Same like {@link activeBreakpoint} but additionally returns Observable, which emits everytime {@link activeBreakpoint} changes
   */
  public get activeBreakpoint$(): Observable<MediaBreakpointsWithMinType> {
    return this._activeBreakpoint$.asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  /**
   * Same like {@link isActiveBreakpointInRange} but additionally returns Observable,
   * which emits everytime active breakpoint becomes in range or ceases from range
   */
  public isActiveBreakpointInRange$(config: RangeBreakpointConfigModel): Observable<boolean> {
    return this.activeBreakpoint$
      .pipe(
        map((activeBreakpoint) =>
          this.isBreakpointInRange(activeBreakpoint, config.min, config.max),
        ),
        distinctUntilChanged(),
      );
  }

  /**
   * @returns whether current active breakpoint is in give n breakpoint range
   */
  public isActiveBreakpointInRange(config: RangeBreakpointConfigModel): boolean {
    return this.isBreakpointInRange(this.activeBreakpoint, config.min, config.max);
  }

  /**
   * @returns observable emitting whether current active breakpoint is lower than LG (0–1023 px)
   */
  public get isLgAndLower$(): Observable<boolean> {
    return this.isActiveBreakpointInRange$({ max: 'LG' });
  }

  /**
   * @returns whether current active breakpoint is lower than LG (0–1023 px)
   */
  public get isLgAndLower(): boolean {
    return this.isActiveBreakpointInRange({ max: 'LG' });
  }

  /**
   * @returns observable emitting whether current active breakpoint is min LG (1024 px and more)
   */
  public get isLgAndHigher$(): Observable<boolean> {
    return this.isActiveBreakpointInRange$({ min: 'LG' });
  }

  /**
   * @returns whether current active breakpoint is min LG (1024 px and more)
   */
  public get isLgAndHigher(): boolean {
    return this.isActiveBreakpointInRange({ min: 'LG' });
  }

  /**
   * @returns observable emitting whether current active breakpoint is lower than MD (0–764 px)
   */
  public get isMdAndLower$(): Observable<boolean> {
    return this.isActiveBreakpointInRange$({ max: 'MD' });
  }

  /**
   * @returns whether current active breakpoint is lower than MD (0–764 px)
   */
  public get isMdAndLower(): boolean {
    return this.isActiveBreakpointInRange({ max: 'MD' });
  }

  /**
   * @returns whether current active breakpoint is lower than MD (0–764 px) and lower than LG (0–1023 px)
   */
  public get isMdLgCombination$(): Observable<[boolean, boolean]> {
    return combineLatest([
      this.isMdAndLower$,
      this.isLgAndLower$,
    ]);
  }

  /**
   * @returns whether current {@link viewportWidthType } is visible (included in the {@link widthType} param
   * NOTE: If {@link widthType} is nil or empty, it will return true
   */
  public isActiveWidthType(
    widthType: ViewportWidthType[],
  ): boolean {
    if (ArrayUtils.isEmpty(widthType)) {
      return true;
    }

    return widthType.includes(this.viewportWidthType);
  }

  /**
   * @returns whether given breakpoint is in given breakpoint range
   */
  private isBreakpointInRange(
    breakpoint: MediaBreakpointsWithMinType | Nil,
    minRangeBreakpoint?: MediaBreakpointsType,
    maxRangeBreakpoint?: MediaBreakpointsType,
  ): boolean {
    // if there is no min and max breakpoint range, return true
    if (isNil(minRangeBreakpoint) && isNil(maxRangeBreakpoint)) {
      return true;
    }

    const breakpointValue = this.getBreakpointValue(breakpoint) ?? 0;
    const minRangeBreakpointValue = this.getBreakpointValue(minRangeBreakpoint);
    const maxRangeBreakpointValue = this.getBreakpointValue(maxRangeBreakpoint);

    if (isNil(minRangeBreakpointValue)) {

      if (maxRangeBreakpointValue === breakpointValue) {
        return false;
      }

      return maxRangeBreakpointValue > breakpointValue;
    }

    if (isNil(maxRangeBreakpointValue)) {

      if (minRangeBreakpointValue === breakpointValue) {
        return true;
      }

      return minRangeBreakpointValue < breakpointValue;
    }

    // remaining case is where !isNil(minRangeBreakpointValue) && !isNil(maxRangeBreakpointValue)

    // handle wrong values
    // we don't allow having showOn value higher than hideOn value
    if (minRangeBreakpointValue >= maxRangeBreakpointValue) {
      this.loggerService.logException(
        new Error('ResponsivenessService#isBreakpointInRangeWrong :: wrong params values'),
        {
          extra: {
            breakpoint,
            minRangeBreakpoint,
            maxRangeBreakpoint,
            breakpointValue,
            minRangeBreakpointValue,
            maxRangeBreakpointValue,
          },
        },
      );

      // as fallback, return false
      return false;
    }

    if (breakpointValue === minRangeBreakpointValue) {
      return true;
    }

    if (breakpointValue === maxRangeBreakpointValue) {
      return false;
    }

    return NumberUtils.isNumberInRange(breakpointValue, minRangeBreakpointValue, maxRangeBreakpointValue);
  }

  /**
   * Same like {@link windowWidth} but additionally return Observable, which emits everytime {@link windowWidth} changes
   *
   * NOTE: Always prefer (if possible) using {@link isActiveBreakpointInRange$} instead of this
   */
  public get windowWidth$(): Observable<number> {
    return this._windowWidth$.asObservable()
      .pipe(
        distinctUntilChanged(),
      );
  }

  /**
   * NOTE: On server it is statically set based on requests user agent
   * NOTE: Always prefer (if possible) using {@link isActiveBreakpointInRange} instead of this
   * @returns current window width
   */
  public get windowWidth(): number {
    return this._windowWidth$.value;
  }

  /**
   * @returns value (in px) of the given breakpoint
   */
  private getBreakpointValue(breakpoint: MediaBreakpointsWithMinType): number | Nil {
    return this.breakpointsValuesMap?.get(breakpoint);
  }

  private setWindowWidth(windowWidth: number): void {
    this._windowWidth$.next(windowWidth);
  }

  private setActiveBreakpoint(breakpoint: MediaBreakpointsWithMinType): void {
    this._activeBreakpoint$.next(breakpoint);
  }

  private setViewportWidthType(widthType: ViewportWidthType): void {
    this._viewportWidthType$.next(widthType);
  }

  private createBreakpointValuesMap(): typeof this.breakpointsValuesMap {
    // getComputedStyle is not available on SSR
    const documentComputedStyles: CSSStyleDeclaration | Nil = !this.platformCommonService.isServer ? this.getWindowComputedStyle() : null;

    return new Map(
      MEDIA_BREAKPOINTS
        .reduce((acc, breakpointName) => ([
          ...acc,
          [breakpointName, this.calculateBreakpointValue(breakpointName, documentComputedStyles)],
        ]), [
          ['MIN', 0],
        ] as [MediaBreakpointsWithMinType, number][]),
    );
  }

  private getWindowComputedStyle(): CSSStyleDeclaration {
    return this.window.getComputedStyle?.(this.document.documentElement);
  }

  private calculateBreakpointValue(
    breakpointName: MediaBreakpointsType,
    documentComputedStyles: CSSStyleDeclaration | Nil,
  ): number {
    // on server, we don't have ability to read css vars from the document, so we have it statically set
    if (isNil(documentComputedStyles)) {
      return this.getBreakpointValueForSSR(breakpointName);
    }

    const cssBreakpointVar = parseInt(
      documentComputedStyles.getPropertyValue(`--auk-bp-${ breakpointName.toLowerCase() }`),
      10,
    );

    return cssBreakpointVar;
  }

  private get clientMobileHintValue(): ClientHintMobileValue {
    return this.request.headers['sec-ch-ua-mobile'] as ClientHintMobileValue;
  }

  private get hasClientHintMobileTrue(): boolean {
    return this.clientMobileHintValue === '?1';
  }

  private get hasValidMobileClientHint(): boolean {
    return CLIENT_HINT_MOBILE_VALUES.includes(this.clientMobileHintValue);
  }

  private initializeWindowWidthAndResizeListener(): void {
    if (this.platformCommonService.isServer) {
      // if is tablet we return maximal tablet width (based on our css breakpoints)
      if (this.platformService.isTablet) {
        return this.setWindowWidth(this.getBreakpointValueForSSR('LG') - 1);
      }

      // if is mobile we return maximal mobile width (based on our css breakpoints)
      if (this.platformService.isMobile) {
        return this.setWindowWidth(this.getBreakpointValueForSSR('MD') - 1);
      }

      // if is desktop we return max LG width (based on our css breakpoints)
      return this.setWindowWidth(this.getBreakpointValueForSSR('XL') - 1);
    }

    this.ngZoneUtilService.fromEventOut$(this.window, 'resize')
      .pipe(
        debounceTime(100),
        startWith(void 0),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        // in browser, we set real window width
        this.setWindowWidth(this.window.innerWidth);
      });
  }

  private initializeActiveBreakpointAndWidthType(): void {
    this.windowWidth$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((windowWidth) => {
        const activeBreakpoint: MediaBreakpointsWithMinType = Array.from(this.breakpointsValuesMap)
          .reverse()
          .find(([_, breakpointValue]) => windowWidth >= breakpointValue)?.[0] ?? 'MIN';
        const viewportWidthType = ViewportWidthTypeUtil.getViewportWidthType(activeBreakpoint);

        this.setActiveBreakpoint(activeBreakpoint);
        this.setViewportWidthType(viewportWidthType);
      });
  }

  /**
   * NOTE: It should never be used in browser (only on SSR)
   * @returns breakpointValue in px
   */
  private getBreakpointValueForSSR(breakpointName: MediaBreakpointsType): number {
    switch (breakpointName) {
      case 'SM':
        return 640;
      case 'MD':
        return 765;
      case 'LG':
        return 1024;
      case 'XL':
        return 1280;
      case '2XL':
        return 1400;
    }
  }

}
