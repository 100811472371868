import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CookieService } from '@common/cookie/service/cookie.service';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { filter, map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { DisclaimerComponent } from '@shared/legacy/component/disclaimer/disclaimer.component';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { Nil } from '@util/helper-types/nil';
import { DisclaimerResult } from '@shared/legacy/component/disclaimer/disclaimer-result';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

@Injectable({
  providedIn: 'root',
})
export class AdultContentService extends BaseDestroy {

  // Adult content is currently opened
  public opened: boolean = false;
  public _isAdultConfirmDialogOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isUserLoggedIn: boolean = false;

  private readonly name: string = 'restrictAdultContent';

  constructor(
    private readonly cookieService: CookieService,
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly platformService: PlatformService,
    private readonly authenticationService: AuthenticationService,
  ) {
    super();

    this.authenticationService.getLoginStatusChange()
      .pipe(
        startWith(this.authenticationService.isLoggedIn()),
        takeUntil(this.destroy$),
      )
      .subscribe((loggedIn: boolean) => {
        this.isUserLoggedIn = loggedIn;
      });
  }

  /**
   * Emits when adult confirm dialog has been closed
   */
  public get afterAdultConfirmDialogClosed$(): Observable<void> {
    return this.isAdultConfirmDialogOpened$
      .pipe(
        // skip initial value from behaviour subject
        skip(1),
        // emit only after dialog is closed
        filter((isOpened) => !isOpened),
        map(() => void 0),
      );
  }

  public get isAdultConfirmDialogOpened$(): Observable<boolean> {
    return this._isAdultConfirmDialogOpened$.asObservable();
  }

  public get isAdultConfirmDialogOpened(): boolean {
    return this._isAdultConfirmDialogOpened$.getValue();
  }

  // The system distinguishes between consents of individual / anonymous users
  //  Hence, if three different users on given computer give their consent, the cookie / local storage token will be
  //  stored 3x times
  public getKey(userId?: number): string {
    if (!userId) {
      return this.name;
    } else {
      return userId.toString() + this.name;
    }
  }

  // cookie is used when the information should be remembered only untill browser is closed
  public setAdultConsentStateCookie(payload: object, userId?: number): void {
    this.cookieService.put(this.getKey(userId), JSON.stringify(payload));
  }

  // local storage is used when the information should be held permanently
  public setAdultConsentStateLocalStorage(payload: object, userId?: number): void {
    localStorage.setItem(this.getKey(userId), JSON.stringify(payload));
  }

  public saveAdultConsent(gaveConsent: boolean, rememberConsent: boolean, userId?: number): void {
    if (rememberConsent) {
      this.setAdultConsentStateLocalStorage({ gaveConsent }, userId);
    } else {
      this.setAdultConsentStateCookie({ gaveConsent }, userId);
    }
  }

  // Check if a disclaimer needs to be shown
  public canShowAdultConfirmDialog(userId: number | null): boolean {
    return this.isAdultContentAllowed(userId) === null;
  }

  // Redirect the user to the disclaimer page
  public openAdultConfirmDialog(): void {
    // Prevent displaying more modals when user navigate back and forward
    // No disclaimer when SSR
    if (this.isAdultConfirmDialogOpened || this.platformService.isServer) {
      return;
    }

    this._isAdultConfirmDialogOpened$.next(true);
    this.aukMatDialogService.openSimple$(
      DisclaimerComponent,
      {
        disableClose: true,
        closeOnNavigation: true,
      },
    )
      .pipe(
        switchMap((dialogRef: MatDialogRef<DisclaimerComponent, DisclaimerResult>) => dialogRef.afterClosed()),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        if (result) {
          this.saveAdultConsent(result.consent, result.remember, result.id);
        }
        this._isAdultConfirmDialogOpened$.next(false);
      });
  }

  public getLocalStorageConsent(userId: number): { gaveConsent: boolean } | Nil {
    // treats possibility of user manually changing value
    try {
      return JSON.parse(localStorage.getItem(this.getKey(userId))) as { gaveConsent: boolean };
    } catch (e) {
      return null;
    }
  }

  public getSessionConsent(userId: number): { gaveConsent: boolean } | Nil {
    const cookieStorageConsent = this.cookieService.get(this.getKey(userId));
    if (cookieStorageConsent != null) {
      // treats possibility of user manually changing value
      try {
        return JSON.parse(cookieStorageConsent) as { gaveConsent: boolean };
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  public shouldBlurItemImage(isAdultContentImage: boolean): boolean {
    return (isAdultContentImage === true && this.isAdultContentAllowed() === null && !this.isUserLoggedIn);
  }

  public isAdultContentAllowed(userId?: number): boolean {
    const localStorageConsent = this.getLocalStorageConsent(userId);
    const cookieConsent = this.getSessionConsent(userId);

    if (localStorageConsent !== null) {
      return localStorageConsent.gaveConsent;

    } else if (cookieConsent !== null) {
      return cookieConsent.gaveConsent;

    } else {
      return null;
    }
  }

}
