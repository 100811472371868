import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FavouriteItemsModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-items.model';
import { FavouriteItemModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-item.model';
import { ProductUtil } from '@shared/util/product/product.util';
import { FavouriteEmptyModel } from '@shared/favourite/model/favourite-empty.model';
import { FavouriteClickModel } from '@shared/favourite/model/favourite-click.model';
import { MouseUtils } from '@util/util/mouse.utils';

@Component({
  selector: 'auk-favourite-items-popover',
  templateUrl: './favourite-items-popover.component.html',
  styleUrls: ['./favourite-items-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePopoverItemsComponent {

  @Input() public favouriteItems: FavouriteItemsModel;

  @Output() public buyItem: EventEmitter<FavouriteItemModel> = new EventEmitter<FavouriteItemModel>();
  @Output() public measuredClick: EventEmitter<FavouriteClickModel> = new EventEmitter<FavouriteClickModel>();

  protected emptyPopoverContent: FavouriteEmptyModel = {
    title: { key: 'FAVOURITES_POPOVER_ITEMS_NO_DATA_TITLE' },
    label: { key: 'FAVOURITES_POPOVER_ITEMS_NO_DATA_LABEL' },
  };

  protected getRouterLink(item: FavouriteItemModel): string[] {
    return ProductUtil.getOfferUrl({ seoUrl: item.seoUrl, itemId: item.itemId });
  }

  protected onItemClick(event: MouseEvent, item: FavouriteItemModel): void {
    this.measuredClick.emit({
      itemId: item.itemId,
      properties: { action: 'showItemDetail' },
      tab: 'ITEMS',
      canDoAppNavigation: MouseUtils.canDoAppNavigation(event),
    });
  }

  protected onBidButtonClick(event: MouseEvent, item: FavouriteItemModel): void {
    this.measuredClick.emit({
      itemId: item.itemId,
      properties: { action: 'bidOnItem' },
      tab: 'ITEMS',
      canDoAppNavigation: MouseUtils.canDoAppNavigation(event),
    });
  }

  protected onBuyButtonClick(event: MouseEvent, item: FavouriteItemModel): void {
    event.stopPropagation();
    // Prevent default as this action should not trigger navigation to item
    event.preventDefault();
    this.buyItem.emit(item);
    this.measuredClick.emit({
      itemId: item.itemId,
      properties: { action: 'addToCart' },
      tab: 'ITEMS',
    });
  }

}
