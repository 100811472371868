import { Injectable } from '@angular/core';
import { BaseDomainConfig } from '@shared/domain/module/domain-config/domain/base-domain-config';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { CountryCodeEnum } from '@shared/domain/model/country-code.enum';
import { ZipUtil } from '@shared/zip/util/zip.util';

@Injectable({
  providedIn: 'root',
})
export class DomainConfigAtService extends BaseDomainConfig {

  public override getConfig(): DomainConfigModel {
    return {
      countryCode: CountryCodeEnum.AUSTRIA,
      phonePrefix: '43',
      currencyConfig: {
        currencyCode: 'EUR',
        currencyNumberType: 'FLOAT',
        selectableCurrencyCodes: ['EUR'],
        minOnlinePayment: 1,
        currencySuffix: '€',
      },
      canSelectCountry: false,
      subbrandLogosMap: {
        BAZAAR: 'logo',
        ANTIQUE: 'logo-antique-de',
        NOT_SPECIFIED: 'logo',
      },
      canFixZipCode: false,
      showDialogForAddressChange: true,
      isShippingTemplatesPageAllowed: false,
      showMpLink: false,
      billBalanceDomesticPaymentAllowed: false,
      vatConfig: {
        requiredTaxNumber: false,
      },
      allowedToChangeCurrency: false,
      canShowVerifyForBuyAction: false,
      canShowVerifyBankAccountAction: false,
      showContinueButtonOnCartShippingAndPaymentPage: false,
      explicitLangInItemDetailDescription: true,
      isYuspAllowed: false,
      metaTagRobotIndexFollowAlways: false,
      differentiateBetweenCompanyNamesInCompanyForm: false,
      zipPattern: ZipUtil.ZIP_FOREIGN_PATTERN,
      zipMask: ZipUtil.createXDigitZipCodeTextMask(4),
      exposeConfig: {
        fastExposeAllowed: false,
        simpleExposeAllowed: false,
        extendedExposeAllowed: false,
      },
      countryIconId: 'czech-republic',
      countryCircleIconId: 'austria-circle',
      countryName: 'COUNTRY_NAME_AT',
      vatPattern: /^(([A-Z\d-]{5,20}))$/,
      userAccountVerificationWay: 'BANK_TRANSFER',
      bankButtonsAllowed: false,
      urlParamCity: 'stadt',
    };
  }

}
