import { ColorCombination } from '../class/color-combination.class';
import { COLOR_VARIANTS } from './color-variants';
import { explicitObjectLiteralValueType } from '@util/helper-functions/explicit-object-value-type';

/**
 * List of color combinations available for every page which is extended {@link ColorCombinationInputComponent}
 */
export const COLOR_COMBINATIONS = explicitObjectLiteralValueType({
  PRIMARY: new ColorCombination(COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY),
  PRIMARY_PERSISTENT: new ColorCombination(COLOR_VARIANTS.PRIMARY_PERSISTENT, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_PERSISTENT),
  PRIMARY_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.PRIMARY),
  // eslint-disable-next-line max-len
  PRIMARY_CONTRAST_PERSISTENT: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_PERSISTENT, COLOR_VARIANTS.PRIMARY_PERSISTENT),
  PRIMARY_CONTRAST_BORDER: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.CONTRAST),
  PRIMARY_CONTRAST_OPPOSITE: new ColorCombination(COLOR_VARIANTS.PRIMARY_CONTRAST_OPPOSITE, COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.PRIMARY),
  PRIMARY_CONTRAST_INVERT: new ColorCombination(COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY),
  PRIMARY_CONTRAST_FILLED: new ColorCombination(COLOR_VARIANTS.PRIMARY_CONTRAST, COLOR_VARIANTS.PRIMARY_OPPOSITE, COLOR_VARIANTS.PRIMARY),
  PRIMARY_50: new ColorCombination(COLOR_VARIANTS.PRIMARY_50, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_50),
  PRIMARY_50_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_50, COLOR_VARIANTS.PRIMARY_50),
  PRIMARY_400: new ColorCombination(COLOR_VARIANTS.PRIMARY_400, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_400),
  PRIMARY_400_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_400, COLOR_VARIANTS.PRIMARY_400),
  PRIMARY_700: new ColorCombination(COLOR_VARIANTS.PRIMARY_700, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_700),
  PRIMARY_700_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY_700, COLOR_VARIANTS.PRIMARY_700),
  PRIMARY_CONTRAST_PRIMUS_300: new ColorCombination(COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMUS_300),
  SECONDARY: new ColorCombination(COLOR_VARIANTS.SECONDARY, COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.PRIMARY),
  TERTIARY: new ColorCombination(COLOR_VARIANTS.TERTIARY, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY),
  TERTIARY_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY, COLOR_VARIANTS.TERTIARY),
  TERTIARY_300: new ColorCombination(COLOR_VARIANTS.TERTIARY_300, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_300),
  TERTIARY_300_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_300, COLOR_VARIANTS.TERTIARY_300),
  TERTIARY_400_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_400, COLOR_VARIANTS.TERTIARY_400),
  TERTIARY_500_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_500, COLOR_VARIANTS.TERTIARY_500),
  TERTIARY_600_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_600, COLOR_VARIANTS.TERTIARY_600),
  TERTIARY_600: new ColorCombination(COLOR_VARIANTS.TERTIARY_600, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_600),
  TERTIARY_700: new ColorCombination(COLOR_VARIANTS.TERTIARY_700, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_700),
  TERTIARY_700_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.TERTIARY_700, COLOR_VARIANTS.TERTIARY_700),
  SUCCESS: new ColorCombination(COLOR_VARIANTS.SUCCESS, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS, COLOR_VARIANTS.CONTRAST_OPPOSITE),
  SUCCESS_CONTRAST_300: new ColorCombination(COLOR_VARIANTS.SUCCESS, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_300),
  SUCCESS_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS, COLOR_VARIANTS.SUCCESS),
  SUCCESS_50: new ColorCombination(COLOR_VARIANTS.SUCCESS_50, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_50),
  SUCCESS_50_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_50, COLOR_VARIANTS.SUCCESS_50),
  SUCCESS_50_CONTRAST_OPPOSITE_400: new ColorCombination(
    COLOR_VARIANTS.SUCCESS_50,
    COLOR_VARIANTS.CONTRAST_OPPOSITE,
    COLOR_VARIANTS.SUCCESS_400,
  ),
  SUCCESS_100: new ColorCombination(COLOR_VARIANTS.SUCCESS_100, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_100),
  SUCCESS_100_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_100, COLOR_VARIANTS.SUCCESS_100),
  SUCCESS_200: new ColorCombination(COLOR_VARIANTS.SUCCESS_200, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_200),
  SUCCESS_200_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_200, COLOR_VARIANTS.SUCCESS_200),
  SUCCESS_300: new ColorCombination(COLOR_VARIANTS.SUCCESS_300, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_300),
  SUCCESS_300_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_300, COLOR_VARIANTS.SUCCESS_300),
  SUCCESS_400: new ColorCombination(COLOR_VARIANTS.SUCCESS_400, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_400),
  SUCCESS_400_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_400, COLOR_VARIANTS.SUCCESS_400),
  SUCCESS_600: new ColorCombination(COLOR_VARIANTS.SUCCESS_600, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_600),
  SUCCESS_600_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_600, COLOR_VARIANTS.SUCCESS_600),
  SUCCESS_700: new ColorCombination(COLOR_VARIANTS.SUCCESS_700, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_700),
  SUCCESS_700_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SUCCESS_700, COLOR_VARIANTS.SUCCESS_700),
  SUCCESS_100_600: new ColorCombination(COLOR_VARIANTS.SUCCESS_100, COLOR_VARIANTS.SUCCESS_600, COLOR_VARIANTS.SUCCESS_600),
  DANGER: new ColorCombination(COLOR_VARIANTS.DANGER, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.DANGER, COLOR_VARIANTS.INFO),
  DANGER_CONTRAST_300: new ColorCombination(COLOR_VARIANTS.DANGER, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.DANGER_300),
  DANGER_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.DANGER, COLOR_VARIANTS.DANGER),
  DANGER_50: new ColorCombination(COLOR_VARIANTS.DANGER_50, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.DANGER_50),
  DANGER_50_OPPOSITE_400: new ColorCombination(COLOR_VARIANTS.DANGER_50, COLOR_VARIANTS.CONTRAST_OPPOSITE, COLOR_VARIANTS.DANGER_50),
  DANGER_50_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.DANGER_50, COLOR_VARIANTS.DANGER_50),
  DANGER_100_600: new ColorCombination(COLOR_VARIANTS.DANGER_100, COLOR_VARIANTS.DANGER_600, COLOR_VARIANTS.DANGER_600),
  DANGER_50_CONTRAST_OPPOSITE_300: new ColorCombination(
    COLOR_VARIANTS.DANGER_50,
    COLOR_VARIANTS.CONTRAST_OPPOSITE,
    COLOR_VARIANTS.DANGER_300,
  ),
  WARNING_50: new ColorCombination(COLOR_VARIANTS.WARNING_50, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.WARNING_50),
  WARNING_50_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.WARNING_50, COLOR_VARIANTS.WARNING_50),
  WARNING: new ColorCombination(COLOR_VARIANTS.WARNING, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.WARNING),
  WARNING_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.WARNING, COLOR_VARIANTS.WARNING),
  VIP: new ColorCombination(COLOR_VARIANTS.VIP, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.VIP),
  INFO: new ColorCombination(COLOR_VARIANTS.INFO, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.INFO),
  INFO_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.INFO, COLOR_VARIANTS.INFO),
  CONTRAST: new ColorCombination(COLOR_VARIANTS.PRIMARY, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.PRIMARY),
  CONTRAST_OPPOSITE: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.CONTRAST_OPPOSITE, COLOR_VARIANTS.PRIMARY),
  FADED: new ColorCombination(COLOR_VARIANTS.TERTIARY_200, COLOR_VARIANTS.TERTIARY_50, COLOR_VARIANTS.TERTIARY_50),
  FADED_INVERSED: new ColorCombination(COLOR_VARIANTS.TERTIARY_50, COLOR_VARIANTS.TERTIARY_200, COLOR_VARIANTS.TERTIARY_200),
  SILVERSTEIN_50: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_50, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_50),
  SILVERSTEIN_50_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_50, COLOR_VARIANTS.SILVERSTEIN_50),
  SILVERSTEIN_100: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_100, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_100),
  SILVERSTEIN_100_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_100, COLOR_VARIANTS.SILVERSTEIN_100),
  SILVERSTEIN_200: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_200, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_200),
  SILVERSTEIN_200_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_200, COLOR_VARIANTS.SILVERSTEIN_200),
  SILVERSTEIN_200_CONTRAST_OPPOSITE:
    new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_200, COLOR_VARIANTS.CONTRAST_OPPOSITE, COLOR_VARIANTS.SILVERSTEIN_200),
  SILVERSTEIN_300: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_300, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_300),
  SILVERSTEIN_300_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_300, COLOR_VARIANTS.SILVERSTEIN_300),
  SILVERSTEIN_400: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_400, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_400),
  SILVERSTEIN_400_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_400, COLOR_VARIANTS.SILVERSTEIN_400),
  SILVERSTEIN_500: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_500, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_500),
  SILVERSTEIN_500_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_500, COLOR_VARIANTS.SILVERSTEIN_500),
  SILVERSTEIN_600: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_600, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_600),
  SILVERSTEIN_600_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_600, COLOR_VARIANTS.SILVERSTEIN_600),
  SILVERSTEIN_700: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_700, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_700),
  SILVERSTEIN_700_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_700, COLOR_VARIANTS.SILVERSTEIN_700),
  SILVERSTEIN_800: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_800, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_800),
  SILVERSTEIN_800_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_800, COLOR_VARIANTS.SILVERSTEIN_800),
  SILVERSTEIN_900: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_900, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_900),
  SILVERSTEIN_900_CONTRAST: new ColorCombination(COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_900, COLOR_VARIANTS.SILVERSTEIN_900),
  SILVERSTEIN_600_500: new ColorCombination(
    COLOR_VARIANTS.SILVERSTEIN_600_500, COLOR_VARIANTS.CONTRAST, COLOR_VARIANTS.SILVERSTEIN_600_500),
  SILVERSTEIN_100_PRIMARY: new ColorCombination(
    COLOR_VARIANTS.SILVERSTEIN_100_PRIMARY,
    COLOR_VARIANTS.SILVERSTEIN_900_CONTRAST,
    COLOR_VARIANTS.SILVERSTEIN_100_PRIMARY,
  ),
  SILVERSTEIN_200_900: new ColorCombination(COLOR_VARIANTS.SILVERSTEIN_200, COLOR_VARIANTS.SILVERSTEIN_900, COLOR_VARIANTS.SILVERSTEIN_900),
  SILVERSTEIN_CONTRAST_200_600: new ColorCombination(
    COLOR_VARIANTS.CONTRAST_SILVERSTEIN_200,
    COLOR_VARIANTS.SILVERSTEIN_600,
    COLOR_VARIANTS.SILVERSTEIN_600,
  ),
  JUNGLE_GREEN_500: new ColorCombination(
    COLOR_VARIANTS.JUNGLE_GREEN_500,
    COLOR_VARIANTS.JUNGLE_GREEN_500_OPPOSITE,
    COLOR_VARIANTS.JUNGLE_GREEN_500,
  ),
  JUNGLE_GREEN_500_CONTRAST: new ColorCombination(
    COLOR_VARIANTS.JUNGLE_GREEN_500_OPPOSITE,
    COLOR_VARIANTS.JUNGLE_GREEN_500,
    COLOR_VARIANTS.JUNGLE_GREEN_500,
  ),
  JUNGLE_GREEN_600_CONTRAST: new ColorCombination(
    COLOR_VARIANTS.CONTRAST,
    COLOR_VARIANTS.JUNGLE_GREEN_600,
    COLOR_VARIANTS.JUNGLE_GREEN_600,
  ),
  MATTER_BLUE: new ColorCombination(
    COLOR_VARIANTS.CONTRAST,
    COLOR_VARIANTS.ROYAL_BLUE_500,
    COLOR_VARIANTS.CONTRAST),
  MATTER_LIGHT_BLUE: new ColorCombination(
    COLOR_VARIANTS.ROYAL_BLUE_50,
    COLOR_VARIANTS.ROYAL_BLUE_50,
    COLOR_VARIANTS.ROYAL_BLUE_300),
  ROYAL_BLUE_500: new ColorCombination(
    COLOR_VARIANTS.ROYAL_BLUE_500,
    COLOR_VARIANTS.ROYAL_BLUE_500,
    COLOR_VARIANTS.ROYAL_BLUE_500),
  ANTIQUE: new ColorCombination(
    COLOR_VARIANTS.ANTIQUE,
    COLOR_VARIANTS.CONTRAST,
    COLOR_VARIANTS.ANTIQUE),
  ANTIQUE_FULL: new ColorCombination(
    COLOR_VARIANTS.ANTIQUE,
    COLOR_VARIANTS.ANTIQUE,
    COLOR_VARIANTS.ANTIQUE),
  ANTIQUE_FULL_INVERT: new ColorCombination(
    COLOR_VARIANTS.ANTIQUE,
    COLOR_VARIANTS.CONTRAST,
    COLOR_VARIANTS.ANTIQUE),
  ANTIQUE_LIGHT: new ColorCombination(
    COLOR_VARIANTS.ANTIQUE_LIGHT,
    COLOR_VARIANTS.ANTIQUE_LIGHT,
    COLOR_VARIANTS.ANTIQUE_LIGHT),
  BAZAAR_FULL: new ColorCombination(
    COLOR_VARIANTS.BAZAAR,
    COLOR_VARIANTS.BAZAAR,
    COLOR_VARIANTS.BAZAAR),
  BAZAAR_LIGHT: new ColorCombination(
    COLOR_VARIANTS.BAZAAR_LIGHT,
    COLOR_VARIANTS.BAZAAR_LIGHT,
    COLOR_VARIANTS.BAZAAR_LIGHT),
  TRANSPARENT: new ColorCombination(
    COLOR_VARIANTS.TRANSPARENT,
    COLOR_VARIANTS.SILVERSTEIN_600,
    COLOR_VARIANTS.TRANSPARENT,
  ),
  TRANSPARENT_PRIMARY_NO_CONTRAST: new ColorCombination(
    COLOR_VARIANTS.TRANSPARENT,
    COLOR_VARIANTS.PRIMARY,
    COLOR_VARIANTS.TRANSPARENT,
  ),
  TRANSPARENT_PRIMARY_CONTRAST: new ColorCombination(
    COLOR_VARIANTS.TRANSPARENT,
    COLOR_VARIANTS.PRIMARY,
    COLOR_VARIANTS.TERTIARY_300,
  ),
  TRANSPARENT_PRIMARY: new ColorCombination(
    COLOR_VARIANTS.TRANSPARENT_PRIMARY,
    COLOR_VARIANTS.SILVERSTEIN_900_CONTRAST,
    COLOR_VARIANTS.TRANSPARENT_PRIMARY,
  ),
  SURFACE_3: new ColorCombination(
    COLOR_VARIANTS.SURFACE_3,
    COLOR_VARIANTS.CONTRAST_OPPOSITE,
    COLOR_VARIANTS.SURFACE_3,
  ),
  // Mainly used as color combination for SECONDARY button
  SECONDARY_BUTTON: new ColorCombination(
    COLOR_VARIANTS.SILVERSTEIN_200_300_400_100,
    COLOR_VARIANTS.CONTRAST_OPPOSITE,
    COLOR_VARIANTS.SILVERSTEIN_200_300_400_100,
  ),
  // Mainly used as color combination for DARK SECONDARY button
  SECONDARY_BUTTON_CONTRAST: new ColorCombination(
    COLOR_VARIANTS.SILVERSTEIN_600_500_400_800,
    COLOR_VARIANTS.CONTRAST,
    COLOR_VARIANTS.SILVERSTEIN_600_500_400_800,
  ),
  // Intended for buttons which should not be outstanding
  QUIET_BUTTON: new ColorCombination(
    COLOR_VARIANTS.SILVERSTEIN_50_200,
    COLOR_VARIANTS.SILVERSTEIN_500,
    COLOR_VARIANTS.SILVERSTEIN_50_200,
  ),
}, ColorCombination);
