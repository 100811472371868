import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeAll, take } from 'rxjs/operators';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { ArrayUtils } from '@util/util/array.utils';
import { SystemParamType } from './model/system-param.type';
import { Nil } from '@util/helper-types/nil';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';
import { AukroHeadquartersDto } from './model/aukro-headquarters.dto';
import { ConfigElementButton } from '@shared/config-element-button/config-element-button';
import { SubbrandType, SubbrandTypeArr } from '@shared/subbrand/model/subbrand.type';
import { WithSubbrandData } from '@shared/subbrand/util/with-subbrand.data';
import isEmpty from 'lodash-es/isEmpty';
import { SystemParamFeType } from '@shared/configurator/model/system-param-fe.type';
import { FrontendConfigElementsTypeEnum } from '@shared/services/configurator-cache/model/frontend-config-elements-type.enum';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ConfiguratorApiService } from '@api/aukro-api/api/configurator-api.service';
import { ConfigElementValueDto } from '@api/aukro-api/model/config-element-value-dto';
import { ConfigElementFrontendPackResponse } from '@api/aukro-api/model/config-element-frontend-pack-response';
import { ConfigElementValuesDto } from '@api/aukro-api/model/config-element-values-dto';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { ConfigElementEnum } from '@api/generated/defs/ConfigElementEnum';
import { LoggerService } from '@common/logger/service/logger.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorCacheService extends NgUnsubscribe implements CacheAware {

  constructor(
    private readonly configuratorApiService: ConfiguratorApiService,
    public readonly cacheService: CacheService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  /**
   * Get single specific system parameter for FE by key
   * @param key Keys to fetch from system parameters
   * @param paramType Type of param
   * @returns Observable<ConfigElementValueDto> | Nil
   */
  public getFeSystemParam<T extends string | boolean | number | unknown[] | object>(
    key: SystemParamFeType,
    paramType: T extends string ? 'STRING' : T extends boolean ? 'BOOLEAN' : T extends number ? 'NUMBER' : 'JSON',
  ): Observable<T | Nil> {
    return this.systemParametersFE([key])
      .pipe(
        map((configElements: ConfigElementValueDto[]) => {
          const foundParam = configElements.find((configElement) => configElement.key === key);

          if (isNil(foundParam)) {
            this.loggerService.logException(new Error(`FE system param not found - ${ key }, Expected: ${ paramType }`));
            return null;
          }

          return this.parseFeSystemParam(key, foundParam.value, paramType) as T | Nil;
        }),
        take(1),
      );
  }

  public buttons(): Observable<ConfigElementButton> {
    return this.getAllConfigElementsForFE().pipe(
      filter((data: ConfigElementFrontendPackResponse) => data !== null),
      map((data: ConfigElementFrontendPackResponse) => this.getConfigElementsByType(data, FrontendConfigElementsTypeEnum.BUTTON)),
      map((buttons: ConfigElementValueDto[]) => buttons.map(
        data => this.parseWithExceptionHandling<ConfigElementButton>(data.value, 'Error parsing BUTTON config element')),
      ),
      mergeAll());
  }

  /**
   * Get specific menu layouts by keys
   * @param keys
   */
  public menuLayouts(keys: string[]): Observable<ConfigElementValueDto[]> {
    return this.getConfigElementsByKeysAndType(keys, FrontendConfigElementsTypeEnum.MENUS_LAYOUTS);
  }

  public menuLayoutsWithSubbrand(keys: string[]): Observable<WithSubbrandData<ConfigElementValueDto>[]> {
    return this.getConfigElementsByKeysAndTypeWithSubbrand(keys, FrontendConfigElementsTypeEnum.MENUS_LAYOUTS);
  }

  private getConfigElementsByKeysAndTypeWithSubbrand(
    keys: string[],
    configElementType: FrontendConfigElementsTypeEnum): Observable<WithSubbrandData<ConfigElementValueDto>[]> {
    return this.getConfigElementsByKeysAndType(this.withSubbrandsSuffix(keys), configElementType)
      .pipe(map((a) => this.mergeSubbrands(a, keys)));
  }

  /**
   * Appends subbrand suffixes to the provided keys and returns the modified array.
   * e.g. [HORIZONTAL_MENU] -> [HORIZONTAL_MENU_ANTIQUE, HORIZONTAL_MENU_BAZAAR, HORIZONTAL_MENU_NOT_SPECIFIED]
   * without base key
   * @param keys - An array of string keys.
   */
  private withSubbrandsSuffix(keys: string[]): string[] {
    return keys.reduce((res: string[], current: string) => [...res, ...SubbrandTypeArr.map(s => `${ current }_${ s }`)], []);
  }

  /**
   * Merges configuration elements according to units grouped by key with subbrand variants
   * @param elements
   * @param keys
   */
  private mergeSubbrands(elements: ConfigElementValueDto[], keys: string[]): WithSubbrandData<ConfigElementValueDto>[] {

    if (isEmpty(elements)) {
      return [];
    }

    const mapWithSubbbrand: Record<SubbrandType, ConfigElementValueDto[]> = {
      ANTIQUE: [],
      BAZAAR: [],
      NOT_SPECIFIED: [],
    };

    elements.forEach((e) => {
      const subb = this.getSubbrandFromKey(e);
      if (isNotNil(subb)) {
        mapWithSubbbrand[subb].push(e);
      }
    });

    function getByKeyAndSubbrand(key: string, s: SubbrandType): ConfigElementValueDto {
      return mapWithSubbbrand[s]?.find((subbrandVariant) => subbrandVariant.key.includes(key));
    }

    return keys.map((b) => ({
      dataBySubbrand: {
        ANTIQUE: getByKeyAndSubbrand(b, 'ANTIQUE'),
        BAZAAR: getByKeyAndSubbrand(b, 'BAZAAR'),
        NOT_SPECIFIED: getByKeyAndSubbrand(b, 'NOT_SPECIFIED'),
      },
    }));
  }

  /**
   * Extracts the subbrand type from a configuration element's key.
   * @param configElementValue
   */
  private getSubbrandFromKey(configElementValue: ConfigElementValueDto): SubbrandType | Nil {
    return configElementValue.key.match(new RegExp(SubbrandTypeArr.join('|'), 'i'))?.[0] as SubbrandType;
  }

  /**
   * Get specific system parameters for FE by keys
   * @param keys Keys to fetch from system parameters
   * @returns Observable<ConfigElementValueDto[]>
   * @deprecated Use getFeSystemParam instead.
   */
  public systemParametersFE(keys: string[]): Observable<ConfigElementValueDto[]> {
    return this.getConfigElementsByKeysAndType(keys, FrontendConfigElementsTypeEnum.SYSTEM_PARAMETER_FE);
  }

  /**
   * @returns POPUP_FLOW config elements
   */
  public getPopupFlows(): Observable<ConfigElementValueDto[]> {
    return this.getAllConfigElementsForFE()
      .pipe(
        filter((valuesPack: ConfigElementFrontendPackResponse) => ArrayUtils.isNotEmpty(valuesPack?.configElementValuesDtos)),
        map((valuesPack: ConfigElementFrontendPackResponse) => valuesPack.configElementValuesDtos),
        map((values: ConfigElementValuesDto[]) =>
          values.find((v: ConfigElementValuesDto) => v?.configElementType === FrontendConfigElementsTypeEnum.POPUP_FLOW)),
        map((v: ConfigElementValuesDto) => v?.values),
        filter((configElementValues: ConfigElementValueDto[]) => ArrayUtils.isNotEmpty(configElementValues)),
      );
  }

  /**
   * Get web html blocks for FE by keys
   * @param keys keys of web html block config elements to fetch
   * @returns Observable<ConfigElementValueDto[]>
   */
  public webHtmlBlocks(keys: string[]): Observable<ConfigElementValueDto[]> {
    return this.configuratorApiService.getConfigElement$({
      configElementTypeEnum: FrontendConfigElementsTypeEnum.WEB_HTML_BLOCK,
      keys,
    }).pipe(
      filter((data: ConfigElementValueDto[]) => data !== null),
    );
  }

  /**
   * @param keys
   * @deprecated use ConfiguratorCacheService.enums instead
   */
  public enumsFE(keys: string[]): Observable<ConfigElementValueDto[]> {
    return this.configuratorApiService.getConfigElement$({
      configElementTypeEnum: FrontendConfigElementsTypeEnum.ENUM,
      keys,
    }).pipe(
      filter((data: ConfigElementValueDto[]) => !isNil(data)),
    );
  }

  // TODO(PDEV-10862) implement caching
  // TODO(PDEV-18067) delete implements CacheAware && delete cacheService: CacheService,
  //  in SCO services and implement in MyCommunicationCommonActionsService
  public enumsContent<CONTENT>(key: string): Observable<CONTENT[]> {
    return this.enums<CONTENT>(key)
      .pipe(
        map((configElementEnum: ConfigElementEnum<CONTENT>) =>
          configElementEnum?.content),
      );
  }

  // TODO(PDEV-10862) implement caching
  /**
   *
   * @param key key of CE enum we want to retrieve
   * @returns method returns config element enum mapped into desired object via generics
   */
  public enums<CONTENT>(key: string): Observable<ConfigElementEnum<CONTENT>> {
    return this.configuratorApiService.getConfigElement$({
      configElementTypeEnum: FrontendConfigElementsTypeEnum.ENUM,
      keys: [key],
    }).pipe(
      filter((data: ConfigElementValueDto[]) => !isNil(data)),
      map((data: ConfigElementValueDto[]) => {
        let result: ConfigElementEnum<CONTENT>;
        try {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          result = JSON.parse(data[0]?.value);
        } catch (error) {
          this.loggerService.logException(
            new Error(`ONSITE CONFIGURATOR error in ${ key }`),
            {
              extra: {
                error,
              },
            },
          );
        }
        return result;
      }),
    );
  }

  /**
   * Parse json value for aukro headquarters
   * @returns Observable<AukroHeadquartersDto>
   */
  public parseJsonValueAukroHeadquarters(): Observable<AukroHeadquartersDto> {
    return this.systemParametersFE(['AUKRO_HEADQUARTERS'])
      .pipe(
        take(1),
        map((configElement: ConfigElementValueDto[]) =>
          this.parseWithExceptionHandling(configElement[0].value, 'ONSITE CONFIGURATOR error in AUKRO_HEADQUARTERS')),
      );
  }

  /**
   * Get SYSTEM_PARAMETER_FE, MENUS_LAYOUTS, POPUP_FLOW
   * @returns Observable<ConfigElementValuesDto>
   */
  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(5),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(15),
    key: 'ConfiguratorCacheService#getAllConfigElementsForFE',
    localeDependent: true,
  })
  private getAllConfigElementsForFE(): Observable<ConfigElementFrontendPackResponse> {
    return this.configuratorApiService.getConfigElementForFrontend$()
      .pipe(
        catchError((err: unknown) => {
          const title: string = 'Configurator: Cannot load FRONTEND config elements from endpoint';

          this.loggerService.logException(
            new Error(title),
            {
              fingerprint: [title],
              extra: {
                HttpError: err,
              },
            },
          );

          return of(null);
        }));
  }

  private getConfigElementsByKeysAndType(
    keys: string[],
    configElementType: FrontendConfigElementsTypeEnum,
  ): Observable<ConfigElementValueDto[]> {
    return this.getAllConfigElementsForFE()
      .pipe(
        filter((data: ConfigElementFrontendPackResponse) => data !== null),
        map((data: ConfigElementFrontendPackResponse) => this.getConfigElementsByType(data, configElementType)),
        map((configElements: ConfigElementValueDto[]) => configElements
          .filter((configElement: ConfigElementValueDto) => keys.includes(configElement.key))),
      );
  }

  /**
   * GET config elements by specific type
   * @param data
   * @param elementType
   * @private
   */
  public getConfigElementsByType(
    data: ConfigElementFrontendPackResponse,
    elementType: FrontendConfigElementsTypeEnum): ConfigElementValueDto[] {
    return data?.configElementValuesDtos
      ?.find((configElements: ConfigElementValuesDto) => configElements.configElementType === elementType)
      ?.values || [];
  }

  private parseWithExceptionHandling<R>(data: string, exceptionMessage: string): R | Nil {
    try {
      return JSON.parse(data) as R;
    } catch (e) {
      this.loggerService.logException(new Error(exceptionMessage), { extra: { error: e } });
    }
  }

  private parseFeSystemParam(
    key: string,
    value: string,
    paramType: SystemParamType = 'STRING',
  ): string | boolean | number | unknown[] | Record<string, unknown> | Nil {
    if (paramType === 'STRING') {
      return value;
    }

    try {
      switch (paramType) {
        case 'BOOLEAN':
          if (['true', 'false'].includes(value?.trim().toLowerCase())) {
            return value === 'true';
          }
          throw new Error();
        case 'JSON':
          return JSON.parse(value) as Record<string, unknown>;
        case 'NUMBER': {
          const isNumber = !isNil(coerceNumberProperty(value, null));

          if (isNumber) {
            return Number(value);
          }
          throw new Error();
        }
      }
    } catch (error) {
      this.loggerService.logException(new Error(`Invalid value for FE system param with key - ${ key }, Expected: ${ paramType }`));
      return undefined;
    }
  }

}
