import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { UserService } from '@shared/user/service/user.service';
import { ToastService } from '@common/toast/service/toast.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';

export const allowedToContactGuard: CanActivateFn =
  (): Observable<boolean> => {
    const userService = inject(UserService);
    const toastService = inject(ToastService);
    const authenticationService = inject(AuthenticationService);

    return !authenticationService.isLoggedIn()
      ? of(true)
      : userService.getActualStatistics()
        .pipe(
          map((actualStatistics: UserInterestStatisticsDto) => {
            if (isNil(actualStatistics)) {
              return false;
            }

            if (actualStatistics.accountBlockForSaleTypeEnum === 'BLOCK') {
              toastService.showWarning({ key: 'NOT_ALLOWED_TO_CONTACT' });
              return false;
            }

            return true;
          }),
        );
  };
