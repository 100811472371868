import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { StringUtils } from '@util/util/string.utils';
import { environment } from '@environment';
import type { NodeOptions } from '@sentry/node/build/types/types';
import type { BrowserOptions } from '@sentry/browser/build/npm/types/client';
// eslint-disable-next-line import/no-restricted-paths
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { ErrorUtil } from '@common/error/util/error-util';
import { ErrorEvent } from '@sentry/browser';
import { Nil } from '@util/helper-types/nil';

export class SentryUtil {

  public static isSentryEnabled(
    /**
     * For which platform it should be checked if it's enabled
     * SERVER - whether sentry is enabled on server
     * BROWSER - whether sentry is enabled in browser
     * SOME - whether sentry is enabled on BROWSER or SERVER
     */
    forPlatform: 'SERVER' | 'BROWSER' | 'SOME' = 'SOME',
    /**
     * Whether current platform is server
     */
    isServer: boolean,
  ): boolean {
    if (PlatformCommonService.isDevMode) {
      return false;
    }

    if (forPlatform === 'BROWSER') {
      return !isServer && !StringUtils.isBlank(environment.SENTRY_DSN);
    }

    if (forPlatform === 'SERVER') {
      return isServer && !StringUtils.isBlank(environment.SENTRY_DSN_SSR);
    }

    const dsn = environment.SENTRY_DSN ?? environment.SENTRY_DSN_SSR;

    // returns whether is sentry enabled on at least 1 platform
    return !StringUtils.isBlank(dsn);
  }

  public static getBaseSentryConfig(): NodeOptions | BrowserOptions {
    return {
      release: BuildInfoService.version,
      environment: environment.ENVIRONMENT,
      attachStacktrace: true,
      normalizeDepth: 0,
      tracesSampleRate: PlatformCommonService.isProductionMode ? 0.01 : 1,
      beforeSend: (event, hint): ErrorEvent | Nil => {
        // allow to send if there's no exception
        if (!hint?.originalException) {
          return event;
        }

        if (!ErrorUtil.canLogError(hint.originalException, false)) {
          return null;
        }

        return event;
      },
    };
  }

}
