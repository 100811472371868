import { DomainCode } from '@shared/domain/model/domain-code.type';
import { Nil } from '@util/helper-types/nil';
import { MyAukroUserDataDto } from '@api/aukro-api/model/my-aukro-user-data-dto';
import { BaseAddressDto } from '@api/aukro-api/model/base-address-dto';
import { DomainService } from '@shared/domain/service/domain.service';
import { isNotNil } from '@util/helper-functions/is-not-nil';

export class MyDataUtils {

  public static getDefaultCountryFormControlValue(
    domainService: DomainService,
    userRegistrationDomain: DomainCode | Nil,
    selectedCountry: DomainCode | Nil,
  ): DomainCode | { value: DomainCode; disabled?: boolean } {
    // allow domain selection only if it's allowed on current domain or user registration domain
    if (!domainService.currentDomainConfig.canSelectCountry
      || (isNotNil(userRegistrationDomain) && !domainService.getDomainConfig(userRegistrationDomain).canSelectCountry)
    ) {
      return { value: domainService.domain, disabled: true };
    }

    return selectedCountry ?? domainService.domain;
  }

  public static getAccountAddress(userData: MyAukroUserDataDto): BaseAddressDto | Nil {
    return userData?.userAccountTypeDataDto?.accountType === 'USER'
      ? userData?.userPersonalDataDto?.address
      : userData?.userCompanyDataDto?.address;
  }

}
