import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateTimeTypeFormatType } from '../model/date-time-type-format.type';
import { Nil } from '@util/helper-types/nil';
import { DateTimePipeService } from '@common/date-time/service/date-time-pipe.service';
import { StringDate } from '@util/helper-types/string-date';

/**
 * @deprecated
 * Instead please use {@link DateTimePipe } or {@link DateTimePipeService}
 */
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'mediumDateTime',
  standalone: true,
})
export class MediumDateTimePipe implements PipeTransform {

  constructor(
    private readonly dateTimePipeService: DateTimePipeService,
  ) {

  }

  public transform(value: StringDate | Date, formatType?: DateTimeTypeFormatType): string | Nil {
    return this.dateTimePipeService.transformOld(value, formatType);
  }

}
