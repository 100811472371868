import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Params } from '@angular/router';
import { combineLatest, merge, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { RoutesService } from '@shared/services/app/routes.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { actualStatisticsCacheBuster$ } from '@shared/user/constant/cache-busters';
import { UserService } from '@shared/user/service/user.service';
import { BrowserService } from '@shared/platform/browser.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { SkeletonLoadingService } from '@shared/skeleton-loading/service/skeleton-loading.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { AppHeaderSearchComponent } from '@shared/app-header/module/app-header-search/component/app-header-search/app-header-search.component';
import { HeaderUserControlActionClickType } from '@shared/app-header/module/app-header-user-controls/component/app-header-user-controls/model/header-user-controls-action-click.model';
import Debounce from 'debounce-decorator';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DomainService } from '@shared/domain/service/domain.service';

//Magic Number used for calculating small or big logo after scroll
const SEARCH_ICON_AFTER_SCROLL_WIDTH = 50;

@Component({
  selector: 'auk-app-header-menu',
  templateUrl: './app-header-menu.component.html',
  styleUrls: ['./app-header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderMenuComponent extends NgUnsubscribe implements OnInit, OnDestroy {

  @Input() public dummySearchInput: ElementRef<HTMLInputElement>;

  @ViewChild(AppHeaderSearchComponent) private readonly appHeaderSearchCmp: AppHeaderSearchComponent;
  @ViewChild('appHeaderLogo') private readonly appHeaderLogoCmp: ElementRef<HTMLDivElement>;
  @ViewChild('appHeaderUserControlsElm') private readonly appHeaderUserControlsElm: ElementRef<HTMLDivElement>;

  protected hideHeaderSearchBar: boolean = false;
  protected isMdAndLower: boolean = false;
  protected isLgAndLower: boolean = false;
  protected isMyAukroOpened: boolean = false;
  protected user: UserInterestStatisticsDto;
  protected displaySkeletonsOnTablet: boolean = false;
  protected displaySkeletonsOnMobile: boolean = false;
  protected displaySkeletons: boolean = false;
  protected searchOpened: boolean = false;
  protected skeletonIconsList: number[] = new Array(3).fill(0) as number[];
  protected forceSmallLogo: boolean = false;

  private headerSizeChangeObserver: ResizeObserver | Nil;

  @HostBinding('class.tw-shadow-underline2') protected hasShadow: boolean = true;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly authenticationService: AuthenticationService,
    private readonly browserService: BrowserService,
    private readonly userService: UserService,
    private readonly routesService: RoutesService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly skeletonLoadingService: SkeletonLoadingService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initHideHeaderSearchBarListener();

    combineLatest([
      this.skeletonLoadingService.displaySkeletonsOnMobile$,
      this.skeletonLoadingService.displaySkeletonsOnTablet$,
      this.skeletonLoadingService.displaySkeletons$,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([mobile, tablet, desktop]: [boolean, boolean, boolean]) => {
        this.displaySkeletonsOnMobile = mobile;
        this.displaySkeletonsOnTablet = tablet;
        this.displaySkeletons = desktop && !mobile;
        this.changeDetectorRef.detectChanges();

        this.checkAndSetBigLogo();
      });

    merge(actualStatisticsCacheBuster$, this.authenticationService.getLoginStatusChange())
      .pipe(
        startWith(null),
        filter((data: boolean) => {
          if (this.authenticationService.isLoggedIn() || data === undefined) {
            return true;
          } else {
            if (data !== null) {
              this.user = null;
              this.changeDetectorRef.detectChanges();
            }
            return false;
          }
        }),
        mergeMap(() => this.userService.getActualStatistics()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: UserInterestStatisticsDto) => {
        this.user = data;
        this.changeDetectorRef.detectChanges();
      });

    this.initIsMyAukroListener();

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;

        if (!this.isMdAndLower) {
          this.hideHeaderSearchBar = false;
        }

        this.checkAndSetBigLogo();
        this.changeDetectorRef.markForCheck();
      });

    this.responsivenessService.isLgAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isLgAndLower) => {
        this.isLgAndLower = isLgAndLower;

        if (!this.isLgAndLower) {
          this.hideHeaderSearchBar = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  @HostListener('window:resize.silent')
  @Debounce(200)
  protected onResize(): void {
    this.checkAndSetBigLogo();
  }

  protected get isSellButtonDisabled$(): Observable<boolean> {
    return this.domainService.currentDomainConfig$
      .pipe(
        map((domainConfig) =>
          !domainConfig.exposeConfig.simpleExposeAllowed && !domainConfig.exposeConfig.extendedExposeAllowed,
        ),
      );
  }

  protected checkAndSetBigLogo(): void {
    if (!this.isMdAndLower) {
      this.forceSmallLogo = false;
      return;
    }

    this.forceSmallLogo = false;
    this.changeDetectorRef.detectChanges();

    const logoWrapperWidth = this.appHeaderLogoCmp?.nativeElement?.offsetWidth;
    const appHeaderUserControlsWidth = this.appHeaderUserControlsElm?.nativeElement?.offsetWidth + SEARCH_ICON_AFTER_SCROLL_WIDTH;
    const viewPortWidth = this.responsivenessService.windowWidth;

    if (logoWrapperWidth + appHeaderUserControlsWidth > viewPortWidth) {
      this.forceSmallLogo = true;
      this.changeDetectorRef.markForCheck();
      return;
    }
    this.forceSmallLogo = false;
    this.changeDetectorRef.markForCheck();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.headerSizeChangeObserver?.disconnect();
  }

  protected get sellButtonLink(): {
    url: string;
    queryParams?: Params | Nil;
    } {
    if (!this.responsivenessService.isMdAndLower && !isNil(this.user)) {
      switch (this.user?.primaryFormType) {
        case 'BASIC_FORM_2023':
          return { url: '/jednoduche-vystaveni', queryParams: { simpleFormOnDesktopAllowed: true } };
        case 'EXTENDED_FORM_ORIGINAL':
          return { url: '/vystavit/rozsireny' };
        case 'BASIC_FORM_ORIGINAL':
          return { url: '/vystavit/mobilni' };
        default:
          return { url: '/vystavit' };
      }
    } else {
      return { url: '/vystavit' };
    }
  }

  public openSuggestionsDropdown(): void {
    this.appHeaderSearchCmp.openSuggestionsDropdown();
  }

  protected get isNativeApp(): boolean {
    return PlatformCommonService.isNativeApp;
  }

  protected get showSearchButton(): boolean {
    return this.isMyAukroOpened || this.hideHeaderSearchBar;
  }

  protected onSearchDropdownToggle(opened: boolean): void {
    this.searchOpened = opened;
  }

  protected userControlClick(type: HeaderUserControlActionClickType): void {
    if (type === 'SEARCH') {
      this.appHeaderSearchCmp.openSuggestionsDropdown();
    }
  }

  /** Inits hiding header search bar on scroll in tablet width. */
  private initHideHeaderSearchBarListener(): void {
    this.ngZoneUtilService
      .fromEventOut$(this.window, 'scroll')
      .pipe(
        startWith(null), // Check current scroll position on app start.
        filter(() => {
          // Do not hide header on desktop or when search is opened.
          if (!this.responsivenessService.isLgAndLower || this.searchOpened) {
            this.hideHeaderSearchBar = false;
            return false;
          } else {
            return true;
          }
        }),
        map(() => this.browserService.getScroll().top),
        tap((scroll) => {
          this.hasShadow = !!scroll;
          this.hideHeaderSearchBar = scroll > 0;
          this.changeDetectorRef.detectChanges();
        }),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private initIsMyAukroListener(): void {
    this.routesService.isMyAukroPage$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMyAukro: boolean) => {
        this.isMyAukroOpened = isMyAukro;
        this.changeDetectorRef.markForCheck();
      });
  }

}
