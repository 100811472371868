import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { DomainService } from '@shared/domain/service/domain.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isNil from 'lodash-es/isNil';
import { UrlService } from '@shared/platform/url.service';
import { take, takeUntil } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

@Injectable({
  providedIn: 'root',
})
export class DomainRedirectService extends BaseDestroy {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly router: Router,
    private readonly domainService: DomainService,
    private readonly urlService: UrlService,
  ) {
    super();
  }

  public crossDomainRegistrationNotAllowedRedirect(isRegistration: boolean, redirectDomainCode: DomainCode): void {
    if (isRegistration) {
      // Czech translation after / because czech routes are "keys"
      void this.router.navigate(['/registrace']);
    } else {
      // Different behaviour for aps
      if (PlatformCommonService.isNativeApp) {
        void this.domainService.saveDomainPreference(redirectDomainCode);
        return;
      }

      this.urlService.translateUrl(this.router.url)
        .pipe(
          take(1),
          takeUntil(this.destroy$),
        )
        .subscribe({
          next: (translatedRoute: string) => {
            this.openCrossDomain(this.domainService.getDomainUrlWithProtocol(redirectDomainCode), translatedRoute);
          },
        });
    }
  }

  private openCrossDomain(regDomainUrl: string, urlPart: string): void {
    // Open same location if exists, otherwise HP
    this.window.open(regDomainUrl + (!isNil(urlPart) ? urlPart : ''));
  }

}
