/**
 * Unit maps for key from API to French strings including plurals
 */
export const UNIT_CODE_MAPPINGS_FR = {
  PAIRS: {
    '=0': 'paires',
    '=1': 'paire',
    '=2': 'paires',
    '=3': 'paires',
    '=4': 'paires',
    other: 'paires',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'paires',
    '=1': 'paire',
    other: 'paires',
  },
  PIECES: {
    '=0': 'pièces',
    '=1': 'pièce',
    '=2': 'pièces',
    '=3': 'pièces',
    '=4': 'pièces',
    other: 'pièces',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'pièces',
    '=1': 'pièce',
    other: 'pièces',
  },
  PIECE_SHORT: {
    other: 'pièce',
  },
  SET: {
    '=0': 'ensembles',
    '=1': 'ensemble',
    '=2': 'ensembles',
    '=3': 'ensembles',
    '=4': 'ensembles',
    other: 'ensembles',
  },
  SET_ACCUSATIVE: {
    '=0': 'ensembles',
    '=1': 'ensemble',
    other: 'ensembles',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'enchérit',
    '=2': 'enchérissent',
    '=3': 'enchérissent',
    '=4': 'enchérissent',
    other: 'enchérit',
  },
  BET_PAST: {
    '=1': 'enchérissait',
    '=2': 'enchérissaient',
    '=3': 'enchérissaient',
    '=4': 'enchérissaient',
    other: 'enchérissait',
  },
  ITEM: {
    '=0': 'articles',
    '=1': 'article',
    '=2': 'articles',
    '=3': 'articles',
    '=4': 'articles',
    other: 'articles',
  },
  DAY: {
    '=0': 'jours',
    '=1': 'jour',
    '=2': 'jours',
    '=3': 'jours',
    '=4': 'jours',
    other: 'jours',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'jour',
    other: 'jours',
  },
  HOUR: {
    '=0': 'heures',
    '=1': 'heure',
    '=2': 'heures',
    '=3': 'heures',
    '=4': 'heures',
    other: 'heures',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'heure',
    other: 'heures',
  },
  MINUTE: {
    '=0': 'minutes',
    '=1': 'minute',
    '=2': 'minutes',
    '=3': 'minutes',
    '=4': 'minutes',
    other: 'minutes',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minute',
    other: 'minutes',
  },
  OTHER: {
    '=0': 'autres',
    '=1': 'autre',
    '=2': 'autres',
    '=3': 'autres',
    '=4': 'autres',
    other: 'autres',
  },
  FILE: {
    '=0': 'fichiers',
    '=1': 'fichier',
    '=2': 'fichiers',
    '=3': 'fichiers',
    '=4': 'fichiers',
    other: 'fichiers',
  },
  IMAGE: {
    '=0': 'images',
    '=1': 'image',
    '=2': 'images',
    '=3': 'images',
    '=4': 'images',
    other: 'images',
  },
  OFFER: {
    '=0': 'offres',
    '=1': 'offre',
    '=2': 'offres',
    '=3': 'offres',
    '=4': 'offres',
    other: 'offres',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'offres',
    '=1': 'offre',
    '=2': 'offres',
    '=3': 'offres',
    '=4': 'offres',
    other: 'offres',
  },
  POSITIVE_COMMENT: {
    '=0': 'commentaires positifs',
    '=1': 'commentaire positif',
    '=2': 'commentaires positifs',
    '=3': 'commentaires positifs',
    '=4': 'commentaires positifs',
    other: 'commentaires positifs',
  },
  USER_GENITIV: {
    '=0': 'utilisateurs',
    '=1': 'utilisateur',
    '=2': 'utilisateurs',
    '=3': 'utilisateurs',
    '=4': 'utilisateurs',
    other: 'utilisateurs',
  },
  USER_INSTRUMENTAL: {
    '=0': 'utilisateurs',
    '=1': 'utilisateur',
    other: 'utilisateurs',
  },
  ADDED_PASSIVE: {
    '=0': 'ajoutées',
    '=1': 'ajoutée',
    '=2': 'ajoutées',
    '=3': 'ajoutées',
    '=4': 'ajoutées',
    other: 'ajoutées',
  },
};
