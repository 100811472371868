import { Inject, Injectable } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { forkJoin, merge, Observable, of, Subject, switchMap } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { DOCUMENT } from '@angular/common';
import { AukWindow } from '@shared/model/auk-window.interface';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CaptchaWindowService } from './captcha-window.service';
import { CaptchaUtils } from './captcha.utils';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Nil } from '@util/helper-types/nil';
import { PlatformService } from '@shared/platform/service/platform.service';
import { LoggerService } from '@common/logger/service/logger.service';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService extends NgUnsubscribe {

  private customCaptchaDialogCloseObserver: MutationObserver;
  private readonly customCaptchaDialogCloseEvent$ = new Subject<void>();
  private isCaptchaIframeError: boolean = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    private readonly snackBar: MatSnackBar,
    private readonly captchaWindowService: CaptchaWindowService,
    private readonly platformService: PlatformService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public executeCaptcha(recaptchaComponent: RecaptchaComponent): Observable<string | Nil> {
    if (isNil(recaptchaComponent)) {
      return of(null);
    }

    return forkJoin([
      this.captchaWindowService.isCaptchaWindowReady(),
      this.captchaWindowService.isCaptchaIframeReady(),
    ]).pipe(
      switchMap(() => this.resolveCaptcha(recaptchaComponent)),
      finalize(() => {
        this.disconnectCustomCaptchaDialogCloseObserver();
        this.isCaptchaIframeError = false;
      }),
      takeUntil(this.ngUnsubscribe),
    );
  }

  private initCustomCaptchaCloseListener(): void {
    this.disconnectCustomCaptchaDialogCloseObserver();

    if (this.isCaptchaScriptLoaded()) {
      // find the reCaptcha iframe and the most parent div element
      const recaptchaIframe = CaptchaUtils.getCaptchaIframe(this.document);
      if (recaptchaIframe) {
        this.isCaptchaIframeError = false;
        const recaptchaWindow = recaptchaIframe.parentNode.parentNode as HTMLElement;

        recaptchaWindow?.classList?.add('auk-recaptcha-window');
        if (this.platformService.isMobile) {
          recaptchaWindow?.classList?.add('auk-recaptcha-mobile');
        }

        // and now we are listening on CSS changes on it
        // when the opacity has been changed to 0 we know that
        // the window has been closed
        this.customCaptchaDialogCloseObserver =
          new MutationObserver(() => recaptchaWindow.style.opacity === '0' && this.customCaptchaDialogCloseEvent$.next());
        this.customCaptchaDialogCloseObserver.observe(recaptchaWindow, { attributes: true, attributeFilter: ['style'] });
      } else {
        this.isCaptchaIframeError = true;
      }
    }
  }

  private disconnectCustomCaptchaDialogCloseObserver(): void {
    if (this.customCaptchaDialogCloseObserver instanceof MutationObserver) {
      this.customCaptchaDialogCloseObserver.disconnect();
    }
  }

  private isCaptchaScriptLoaded(): boolean {
    return CaptchaUtils.isCaptchaReady(this.window);
  }

  private resolveCaptcha(recaptchaComponent: RecaptchaComponent): Observable<string> {
    this.initCustomCaptchaCloseListener();

    // close all snackbar messages on new login/register click
    this.snackBar.dismiss();

    if (!this.isCaptchaScriptLoaded()) {
      this.snackBar.open('Nepodařilo se načíst službu Captcha, zkuste obnovit stránku, nebo to zkuste později.', 'Zavřít', {
        duration: 0,
        horizontalPosition: 'center',
      });

      this.loggerService.logException('Captcha script not loaded',
        { tags: { context: 'captcha', result: 'error' }, fingerprint: ['captcha-script-not-loaded'] });
    }

    if (this.isCaptchaIframeError) {
      // this handles originally reported problem, with captcha error message and impossible login
      // when captcha script tries to contact google and there is error, then iframe is not rendered
      // this case is not emitted in recaptchaComponent.error !
      // original google message
      const snackbarRef = this.snackBar.open(
        'Ke službě reCAPTCHA se nepodařilo připojit.' +
        'Zkontrolujte připojení k internetu a načtěte stránku znovu, aby se zobrazila výzva k ověření reCAPTCHA.' +
        'Nebo to zkuste později.',
        'Obnovit stránku', {
          duration: 0,
          horizontalPosition: 'center',
        });
      snackbarRef.afterDismissed()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => this.window.location.reload());

      this.loggerService.logException('Captcha internal script error',
        { tags: { context: 'captcha', result: 'error' }, fingerprint: ['captcha-internal-script-error'] });
    }

    // error-callback, (usually network connectivity)
    // <re-captcha errorMode="handled"> must be set
    recaptchaComponent.error
      .pipe(
        take(1),
        tap(() => {
          recaptchaComponent?.reset();
        }),
        takeUntil(
          merge(
            this.ngUnsubscribe,
            recaptchaComponent.resolved,
            this.customCaptchaDialogCloseEvent$,
          ),
        ),
      )
      .subscribe(e => {
        this.snackBar.open('Nepodařilo se připojit ke službě Captcha, zkuste obnovit stránku, nebo to zkuste později.', 'Zavřít', {
          duration: 0,
          horizontalPosition: 'center',
        });

        this.loggerService.logException(`Captcha callback error ${ e.toString() }`,
          { tags: { context: 'captcha', result: 'error' }, fingerprint: ['captcha-callback-error'] });
      });

    // after calling .execute()
    // token is emitted in recaptchaComponent.resolved
    // or error is emitted in recaptchaComponent.error
    // or dialog to solve captcha opens, then if captcha is solved by user, emits token in recaptchaComponent.resolved
    // when captcha is not solved and user clicks outside of captcha dialog then customCaptchaDialogCloseEvent$ fires
    recaptchaComponent.execute();

    return recaptchaComponent.resolved
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
        takeUntil(recaptchaComponent.error),
        takeUntil(this.customCaptchaDialogCloseEvent$),
        // close .resolved observable, when script is not loaded or captcha iframe is not rendered
        takeUntil(of(!this.isCaptchaScriptLoaded() || this.isCaptchaIframeError).pipe(filter(Boolean))),
        tap(() => {
          recaptchaComponent?.reset();
        }),
      );
  }

}

