import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { finalize, mergeMap, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { UserService } from '@shared/user/service/user.service';
import { PhoneNumberService } from '@shared/services/phone-number/phone-number.service';
import { TfvDialogData } from '../tfv-dialog-data.interface';
import { RequestWithHeadersCallback, TwoFactorVerificationComponent } from '../two-factor-verification.component';
import { TranslationSource } from '@common/translations/model/translation-source';
import { HttpError } from '@shared/rest/model/http-error';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { UpdatePhoneRequestParams } from '@api/aukro-api/api/users-me-api.service';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { AccountDetailDto } from '@api/aukro-api/model/account-detail-dto';

@Component({
  selector: 'auk-tfv-enforced-dialog',
  templateUrl: './tfv-enforced-dialog.component.html',
  // Do not set change detection strategy OnPush here because of async validator
})
export class TfvEnforcedDialogComponent extends NgUnsubscribe implements OnInit {

  @ViewChild(TwoFactorVerificationComponent, { static: false })
  public twoFactorVerificationComponent: TwoFactorVerificationComponent<void, UpdatePhoneRequestParams>;

  public form: FormGroup;

  public isSubmitting: boolean = false;

  public successfullyVerified: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: TfvDialogData,
    private readonly matDialogRef: MatDialogRef<TfvEnforcedDialogComponent, boolean>,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly shareDataService: ShareDataService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    // TODO(PDEV-11896) Merge 2 requests for user.statistics and user.detail to 1 request
    this.userService.getActualStatistics()
      .pipe(
        mergeMap((userInterest: UserInterestStatisticsDto) => this.userService.detail(userInterest?.userId)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((accountDetailDto: AccountDetailDto) => this.buildForm(accountDetailDto.phone));

    // Always close dialog with result value
    this.matDialogRef.beforeClosed()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.matDialogRef.close(this.successfullyVerified));
  }

  private buildForm(currentPhone: string): void {
    this.form = this.formBuilder.group({
      phone: [currentPhone, {
        validators: [Validators.required, Validators.maxLength(19)],
        asyncValidators: [this.phoneNumberService.phoneNumberValidator(this.changeDetectorRef)],
      }],
    });
  }

  public get phoneControl(): FormControl {
    return this.form?.get('phone') as FormControl;
  }

  public get phoneErrorMessage(): TranslationSource {
    return PhoneNumberService.getPhoneErrorMessageForFormControl(this.phoneControl);
  }

  public onSubmit(): void {
    if (!this.form?.valid) {
      this.form?.markAllAsTouched();
      return;
    }

    const phone: string = this.form.value.phone;

    const params: UpdatePhoneRequestParams = {
      verifyPhoneDto: {
        phone,
      },
    };

    this.isSubmitting = true;

    this.twoFactorVerificationComponent.sendTfvWrappedRequest(params)
      .pipe(
        finalize(() => this.isSubmitting = false),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        () => {
          this.successfullyVerified = true;
          this.matDialogRef.close();
        },
        (error: HttpError) => {
          this.matDialogRef.disableClose = false;
          this.shareDataService.showSuccessMessageTranslated('ERROR_MESSAGE_PHONE_VERIFICATION_ERROR', 'error');
          throw error;
        },
      );
  }

  public onGoBackClicked(): void {
    this.isSubmitting = false;
  }

  public verifyPhoneCallback: RequestWithHeadersCallback<void, UpdatePhoneRequestParams> =
    (params: UpdatePhoneRequestParams, headers: { [key: string]: string }) =>
      this.userService.updatePhone(params, headers);

}
