import { Injectable } from '@angular/core';
import { ExchangeRatesService } from './exchange-rates.service';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { ExchangeRatesDto } from '@api/aukro-api/model/exchange-rates-dto';
import { ExchangeRateDto } from '@api/aukro-api/model/exchange-rate-dto';
import { MoneyDto } from '@api/aukro-api/model/money-dto';

@Injectable({
  providedIn: 'root',
})
export class ExchangedPriceService {

  constructor(
    private readonly exchangeRatesService: ExchangeRatesService,
  ) {
  }

  public priceMoney(value: MoneyDto, targetCurrency: CurrencyCodeType): Observable<number> {
    return this.price(value.amount, value.currency, targetCurrency);
  }

  public price(value: number, sourceCurrency: CurrencyCodeType, targetCurrency: CurrencyCodeType): Observable<number> {
    if (sourceCurrency === targetCurrency || isNil(value)) {
      return of(value);
    }

    return this.exchangeRatesService.exchangeRates(sourceCurrency)
      .pipe(
        take(1),
        map((exchangeRate: ExchangeRatesDto) => exchangeRate?.rates?.find((rate: ExchangeRateDto) => rate.currency === targetCurrency)),
        filter((rate: ExchangeRateDto) => !isNil(rate)),
        map((exchangeRate: ExchangeRateDto) => value / exchangeRate.rate),
      );
  }

  /**
   * Method for ensuring given currency rates are used when exchanging price
   */
  public exchange(money: MoneyDto, rates: ExchangeRatesDto, targetCurrency: CurrencyCodeType): MoneyDto {
    if (isNil(money)) {
      return null;
    }

    // no need to exchange
    if (money.currency === targetCurrency) {
      return money;
    }

    if (money.currency !== rates.currency) {
      throw new Error(`Exchanging with different currency rates! Money: ${ money.currency }, rates: ${ rates.currency } !`);
    }

    const targetRate = rates?.rates?.find((rate: ExchangeRateDto) => rate.currency === targetCurrency);

    if (isNil(targetRate)) {
      throw new Error('Rate for currency:' + targetCurrency + ' not found');
    }

    return this.exchangeWithRate(money, targetRate);
  }

  /**
   * Exchange with rate. WARNING: be sure that you use rate with correct currency
   */
  private exchangeWithRate(money: MoneyDto, rate: ExchangeRateDto): MoneyDto {
    return { amount: money.amount / rate.rate, currency: rate.currency };
  }

}
