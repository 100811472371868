import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { InboxControlState } from './model/inbox-control-state';
import { combineLatest } from 'rxjs';
import { MessageWebsocketDto } from '@shared/model/message-websocket-dto.model';
import { UserService } from '@shared/user/service/user.service';
import isNil from 'lodash-es/isNil';
import { AukBaseLocalStateComponent } from '@common/base-class/base-local-state.component';
import { UserMessageAsyncDataGlobalAdapterService } from '@shared/services/async/user-message/user-message-async-data-global-adapter.service';
import { HeaderItemModel } from '@shared/app-header/module/app-header-user-controls/component/header-item/model/header-item.model';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ConversationApiService } from '@api/aukro-api/api/conversation-api.service';

@Component({
  selector: 'auk-inbox-control',
  templateUrl: './inbox-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxControlComponent extends AukBaseLocalStateComponent<InboxControlState> implements OnInit {

  private static readonly maxUnreadMessagesCountInBadge = 99;

  protected isIconHovered: boolean = false;

  public readonly tooltipShowDelayMs = 500;

  constructor(
    private readonly conversationApiService: ConversationApiService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly userMessageAsyncDataGlobalAdapterService: UserMessageAsyncDataGlobalAdapterService,
    private readonly userService: UserService,
  ) {
    super();

    // set default state
    this.updateState({
      ...InboxControlComponent.getDefaultState(),
    });
  }

  public ngOnInit(): void {
    this.initialize();
  }

  private initialize(): void {
    // don't initialize on SSR
    if (this.platformCommonService.isServer) {
      return;
    }

    this.conversationApiService.getUnreadPrimeMessageExtIds$()
      .pipe(
        // set initial data
        tap(unreadMessagesIds =>
          this.updateState({
            ...InboxControlComponent.reduceUnreadPrimeMessagesIds(this.state, unreadMessagesIds),
          }),
        ),
        switchMap(() =>
          // listen to WS new IKEM messages
          combineLatest([
            this.userMessageAsyncDataGlobalAdapterService.userMessageData$,
            this.userService.getActualStatistics(),
          ])
            .pipe(
              // update state with new message
              tap(([userMessageData]) =>
                this.updateState(
                  InboxControlComponent.reduceNewIkemMessage(
                    this.state,
                    userMessageData,
                  ),
                ),
              ),
            ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private static getDefaultState(): InboxControlState {
    return {
      unreadMessagesCount: 0,
      unreadMessagesCountText: null,
      unreadPrimeMessagesExtIds: [],
    };
  }

  private static reduceNewIkemMessage(
    state: InboxControlState,
    ikemMessage: MessageWebsocketDto,
  ): Partial<InboxControlState> {
    // check if message is read
    if (isNil(ikemMessage.readDate)) {
      // check if message already exists
      if (!state.unreadPrimeMessagesExtIds.includes(ikemMessage.primeMessageExtId)) {
        // push it into array
        state.unreadPrimeMessagesExtIds.push(ikemMessage.primeMessageExtId);
      }
    } else {
      // message is read, so remove it from array
      state.unreadPrimeMessagesExtIds = state.unreadPrimeMessagesExtIds
        .filter((primeMsgId) => primeMsgId !== ikemMessage.primeMessageExtId);
    }

    const unreadMessagesCount = state.unreadPrimeMessagesExtIds.length;

    return {
      ...state,
      unreadMessagesCount,
      unreadMessagesCountText: InboxControlComponent.getUnreadMessagesCountText(unreadMessagesCount),
    };
  }

  private static reduceUnreadPrimeMessagesIds(state: InboxControlState, unreadMessagesIds: string[]): Partial<InboxControlState> {
    const unreadMessagesCount = unreadMessagesIds.length;

    return {
      ...state,
      unreadMessagesCount,
      unreadMessagesCountText: InboxControlComponent.getUnreadMessagesCountText(unreadMessagesCount),
      unreadPrimeMessagesExtIds: unreadMessagesIds,
    };
  }

  private static getUnreadMessagesCountText(unreadMessagesCount: number): string | undefined {
    if (unreadMessagesCount === 0) {
      return undefined;
    }

    return unreadMessagesCount <= InboxControlComponent.maxUnreadMessagesCountInBadge
      ? unreadMessagesCount.toString()
      : `${ InboxControlComponent.maxUnreadMessagesCountInBadge }+`;
  }

  protected setIconHoverState(isHovered: boolean): void {
    this.isIconHovered = isHovered;
  }

  protected get headerItemData(): HeaderItemModel {
    return {
      badgeText: this.state.unreadMessagesCount,
      icon: {
        source: 'chat_bubble_outline',
        type: 'MATERIAL',
        colorCombination: this.colorCombination,
      },
    };
  }

  protected get colorCombination(): ColorCombinationId {
    return this.isIconHovered ? 'SILVERSTEIN_200_900' : 'SILVERSTEIN_CONTRAST_200_600';
  }

}
