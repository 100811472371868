import { Injectable } from '@angular/core';
import { DomainService } from '@shared/domain/service/domain.service';
import { ToastService } from '@common/toast/service/toast.service';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { mergeMap, take, takeUntil } from 'rxjs/operators';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { BankAccountVerificationService } from '@shared/bank-account/module/user-bank-account/service/bank-account-verification.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { captureException } from '@sentry/browser';
import { ToastAction } from '@common/toast/model/toast-action';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { PaymentApiService } from '@api/aukro-api/api/payment-api.service';
import { CardVerificationPaymentService } from '@shared/user-account-verification/service/card-verification-payment.service';
import { Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAccountVerificationService extends BaseDestroy {

  constructor(
    private readonly bankAccountVerificationService: BankAccountVerificationService,
    private readonly cardVerificationPaymentService: CardVerificationPaymentService,
    private readonly domainService: DomainService,
    private readonly paymentApiService: PaymentApiService,
    private readonly toastService: ToastService,
  ) {
    super();
  }

  public startUserAccountVerification$(
    source: string,
    userAction?: UserActionType,
    showToastBeforeCardVerification: boolean = false,
  ): Observable<void> {
    return this.domainService.currentDomainConfig$
      .pipe(
        take(1),
        mergeMap((domainConfig: DomainConfigModel) => {
          switch (domainConfig.userAccountVerificationWay) {
            case 'BANK_TRANSFER':
              // Emits after user task is finished
              return this.bankAccountVerificationService.openBankAccountVerificationDialog$(source, userAction);
            case 'CARD_PAYMENT':
              this.handleCardPaymentVerification(showToastBeforeCardVerification);
              // Emits immediately since card payment verification is handled externally.
              // Return error to finish potential fulfillment of prerequisites.
              return throwError(() => 'User account verification is handled externally.');
            default:
              this.toastService.showDanger({ key: 'USER_ACCOUNT_VERIFICATION_START_ERROR' });
              captureException(
                `UserAccountVerificationService :: User account verification way ${ domainConfig.userAccountVerificationWay } not found`,
                { fingerprint: ['USER_ACCOUNT_VERIFICATION_WAY_NOT_FOUND'] },
              );
              return of(void 0);
          }
        }),
      );
  }

  private handleCardPaymentVerification(showToastBeforeCardVerification: boolean): void {
    if (showToastBeforeCardVerification) {
      this.toastService.showWarning(
        { key: 'ACCOUNT_NOT_VERIFIED_ERROR' },
        {
          actions: [{
            actionCallBack: () => this.cardVerificationPaymentService.initCardPaymentVerification(),
            label: { key: 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY_BUTTON' },
            colorCombination: 'WARNING_CONTRAST',
          }],
        },
      );
    } else {
      this.cardVerificationPaymentService.initCardPaymentVerification();
    }
  }

  public getUserNotVerifiedForBuyToastAction(source: string): ToastAction[] {
    return this.domainService.currentDomainConfig.canShowVerifyForBuyAction
      ? [{
        actionCallBack:
          () => this.checkPrerequisitesForNewVerification(source),
        label: { key: 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY_BUTTON' },
        colorCombination: 'WARNING_CONTRAST',
      }]
      : [];
  }

  private checkPrerequisitesForNewVerification(source: string): void {
    this.paymentApiService.checkPrerequisitesForNewVerification$(
      {},
      new HttpContext().set(USER_ACTION_TYPE, 'VERIFY_THROUGH_PAYMENT'),
    )
      .pipe(
        mergeMap(() => this.startUserAccountVerification$(source)),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

}
