import { Pipe, PipeTransform } from '@angular/core';
import { TranslateSourcePipe } from '@common/translations/pipe/translate-source.pipe';
import { TranslationSource } from '@common/translations/model/translation-source';
import { TimeToEndModel } from '@shared/time/model/time-to-end.model';
import { TimeToEndService } from '@shared/time/service/time-to-end.service';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {

  constructor(
    private readonly translateSourcePipe: TranslateSourcePipe,
    private readonly timeToEndService: TimeToEndService,
  ) {
  }

  public transform(date: string): string {
    if (date) {
      const relativeDate: TimeToEndModel = this.timeToEndService.getTimeToEnd(date);
      const prefix: TranslationSource = {
        key: relativeDate.past ? 'COMMON__BEFORE' : 'COMMON__AFTER',
      };
      return this.translateSourcePipe.transform(prefix) + ' ' + this.translateSourcePipe.transform(relativeDate?.label);
    }
    return date;
  }

}
