import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestCloneConfig } from '../rest/model/http-request-clone-config';
import { DomainService } from '@shared/domain/service/domain.service';
import { LocaleUtil } from '@shared/locale/util/locale.util';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { StringUtils } from '@util/util/string.utils';

/**
 * Adds an 'X-Accept-Language' header to the request, specifying the current language and region code. Compatible with both browser and SSR.
 */
@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {

  constructor(
    private readonly domainService: DomainService,
  ) {
  }

  public intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const languageHeader: string = this.domainService.languageWithRegionCode;

    if (StringUtils.isBlank(languageHeader)) {
      return next.handle(request);
    }

    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    requestCloneConfig.headers = request.headers.set(LocaleUtil.LANGUAGE_HEADER_NAME, languageHeader);

    return next.handle(request.clone(requestCloneConfig));
  }

}
