import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { AttachmentDto } from '../model/attachment-dto';
import { ContactFormMessageDto } from '../model/contact-form-message-dto';
import { ConversationSearchResult } from '../model/conversation-search-result';
import { ConversationsDto } from '../model/conversations-dto';
import { CreateNewTicketByAdminDto } from '../model/create-new-ticket-by-admin-dto';
import { CreatedMessageDto } from '../model/created-message-dto';
import { FindConversationParams } from '../model/find-conversation-params';
import { GetMessageCommunicationHistoryDefaultResponse } from '../model/get-message-communication-history-default-response';
import { MessageAnswerSaveDto } from '../model/message-answer-save-dto';
import { MessageOverviewDto } from '../model/message-overview-dto';
import { MessageShortDto } from '../model/message-short-dto';
import { UpdateTicketTypeDto } from '../model/update-ticket-type-dto';
import { environment } from '@environment';

export interface AddMessageAnswerRequestParams {
    extId: string;
    messageAnswerSaveDto: MessageAnswerSaveDto;
}

export interface AddMessageAnswerExtendedRequestParams {
    extId: string;
    messageAnswerSaveDto: MessageAnswerSaveDto;
}

export interface CreateMessageFromContactFormRequestParams {
    contactFormMessageDto: ContactFormMessageDto;
}

export interface CreateMessageFromReplyContactRequestParams {
    replyContactId: number;
}

export interface CreateNewTicketByAdminRequestParams {
    createNewTicketByAdminDto: CreateNewTicketByAdminDto;
}

export interface GetConversationByTopicAndObjectIdRequestParams {
    findConversationParams: FindConversationParams;
}

export interface GetMessageRequestParams {
    extId: string;
}

export interface GetMessageCommunicationHistoryRequestParams {
    extId: string;
    messagesOnly?: boolean;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUsersCommunicationHistoryRequestParams {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface RemoveBlockingAssignmentRequestParams {
    userId: number;
}

export interface SearchInMessageCommunicationHistoryRequestParams {
    term: string;
}

export interface SetMessageDeleteRequestParams {
    extId: string;
}

export interface SetMessageReadStatusRequestParams {
    extId: string;
}

export interface UpdateMessageCtxRequestParams {
    body: number;
    id: number;
}

export interface UpdateTicketTypeRequestParams {
    id: number;
    updateTicketTypeDto: UpdateTicketTypeDto;
}

export interface UploadAttachmentRequestParams {
    /** file */
    file: Blob;
    position: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConversationApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Adds answer to message
   */
  public addMessageAnswer$(
    requestParameters: AddMessageAnswerRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.messageAnswerSaveDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/answer`,
      options,
    );
  }

  /**
   * Adds answer to message
   */
  public addMessageAnswerExtended$(
    requestParameters: AddMessageAnswerExtendedRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.messageAnswerSaveDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/answer/extended`,
      options,
    );
  }

  /**
   * Creates message from contact form
   */
  public createMessageFromContactForm$(
    requestParameters: CreateMessageFromContactFormRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.contactFormMessageDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/contactForm`,
      options,
    );
  }

  /**
   * Creates message from reply contact
   */
  public createMessageFromReplyContact$(
    requestParameters: CreateMessageFromReplyContactRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MessageShortDto> {
    const replyContactId: number = requestParameters.replyContactId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'MessageShortDto'),
      headers,
    };
    return this.httpClient.request<MessageShortDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'replyContactId', value: replyContactId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Creates the new ticket by admin and sends the message to user by email
   */
  public createNewTicketByAdmin$(
    requestParameters: CreateNewTicketByAdminRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CreatedMessageDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.createNewTicketByAdminDto,
      responseType: this.configuration.getResponseType(false, 'Array<CreatedMessageDto>'),
      headers,
    };
    return this.httpClient.request<CreatedMessageDto[]>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/createNewTicketByAdmin`,
      options,
    );
  }

  /**
   * Finds conversation extId by given params
   */
  public getConversationByTopicAndObjectId$(
    requestParameters: GetConversationByTopicAndObjectIdRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ConversationSearchResult> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.findConversationParams,
      responseType: this.configuration.getResponseType(false, 'ConversationSearchResult'),
      headers,
    };
    return this.httpClient.request<ConversationSearchResult>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/search`,
      options,
    );
  }

  /**
   * Gets message by Ext ID
   */
  public getMessage$(
    requestParameters: GetMessageRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MessageShortDto> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'MessageShortDto'),
      headers,
    };
    return this.httpClient.request<MessageShortDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Gets communication history for message for logged user
   */
  public getMessageCommunicationHistory$(
    requestParameters: GetMessageCommunicationHistoryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<GetMessageCommunicationHistoryDefaultResponse> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      messagesOnly: requestParameters.messagesOnly,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'GetMessageCommunicationHistoryDefaultResponse'),
      headers,
    };
    return this.httpClient.request<GetMessageCommunicationHistoryDefaultResponse>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/communicationHistory`,
      options,
    );
  }

  /**
   * List of unread prime message ext ids
   */
  public getUnreadPrimeMessageExtIds$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<string>'),
      headers,
    };
    return this.httpClient.request<string[]>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/unreadPrimeMessageExtIds`,
      options,
    );
  }

  /**
   * Gets communication history slice for logged user
   */
  public getUsersCommunicationHistory$(
    requestParameters: GetUsersCommunicationHistoryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ConversationsDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ConversationsDto'),
      headers,
    };
    return this.httpClient.request<ConversationsDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/communicationHistory/sliced`,
      options,
    );
  }

  /**
   * Remove ticket blocking assignment for given admin
   */
  public removeBlockingAssignment$(
    requestParameters: RemoveBlockingAssignmentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/user/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/removeBlockingAssignment`,
      options,
    );
  }

  /**
   * Search in logged user\&#39;s message communication history
   */
  public searchInMessageCommunicationHistory$(
    requestParameters: SearchInMessageCommunicationHistoryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MessageOverviewDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      term: requestParameters.term,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<MessageOverviewDto>'),
      headers,
    };
    return this.httpClient.request<MessageOverviewDto[]>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/communicationHistory/search`,
      options,
    );
  }

  /**
   * Set removed date on message conversation by logged user
   */
  public setMessageDelete$(
    requestParameters: SetMessageDeleteRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/delete`,
      options,
    );
  }

  /**
   * Set read date on message conversation by logged user
   */
  public setMessageReadStatus$(
    requestParameters: SetMessageReadStatusRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const extId: string = requestParameters.extId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'extId', value: extId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/read`,
      options,
    );
  }

  /**
   * Updates refund claim id in message context for all messages in chain
   */
  public updateMessageCtx$(
    requestParameters: UpdateMessageCtxRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.body,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/updateRefundClaimMessage`,
      options,
    );
  }

  /**
   * Updates ticket_type_id for all tickets in the same chain as the given message
   */
  public updateTicketType$(
    requestParameters: UpdateTicketTypeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.updateTicketTypeDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/updateTicketType`,
      options,
    );
  }

  /**
   * Uploads new attachment to storage
   */
  public uploadAttachment$(
    requestParameters: UploadAttachmentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<AttachmentDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      position: requestParameters.position,
    };
    const formParams = {
      file: requestParameters.file,
    };
    const options: object = {
      context: httpContext,
      body: this.configuration.toFormData(formParams),
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'AttachmentDto'),
      headers,
    };
    return this.httpClient.request<AttachmentDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/conversation/message/attachment`,
      options,
    );
  }

}
