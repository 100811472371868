import { HttpParams } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, TransferState, ViewChild, makeStateKey } from '@angular/core';
import { TransferStateUtils } from '@util/util/transfer-state.utils';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ArrayUtils } from '@util/util/array.utils';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

import { SvgAutoCacheService } from '../service/svg-auto-cache.service';
import { SvgIconConstants } from '@common/ui-kit/component/svg-icon/component/svg-icon.component.constants';
import { take, takeUntil } from 'rxjs/operators';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { StringUtils } from '@util/util/string.utils';
import { LoggerService } from '@common/logger/service/logger.service';

@Component({
  selector: 'auk-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InnerHtmlDirective,
  ],
})
export class SvgIconComponent extends NgUnsubscribe implements OnInit, OnChanges {

  /** Icon identifier */
  @Input() public iconId: string;

  @ViewChild('inlineSvgElm') protected inlineSVGRef: ElementRef<HTMLDivElement>;

  protected path: string;
  protected svgContent: string;

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly transferState: TransferState,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly svgAutoCacheService: SvgAutoCacheService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadSvgIconInline();
  }

  public ngOnChanges(changes: AukSimpleChanges<SvgIconComponent>): void {
    if (changes.iconId) {
      this.path = `/assets/icon/${ this.iconId }.svg`;
    }

    this.loadSvgIconInline();
  }

  protected onSvgLoaded(svgContent: string): string {
    return this.assignDynamicIdsToSvgElements(this.setSvgAttributes(svgContent));
  }

  private assignDynamicIdsToSvgElements(svgContent: string): string {
    // We need to dynamically update ids within the svg element, so they are always unique
    // Why? Because based on HTML spec, id attribute must be always unique across the whole DOM, and if we wouldn't make it unique,
    // there would be corner cases which would cause icons to not be properly displayed

    // regex which will find all ids in the svg content by searching string inside `url(#` and `)`
    const svgIdsRegex = new RegExp(/url\(?#([^\s")]+)?\)/g);

    // find all ids by given regex
    const matched = Array.from(svgContent.matchAll(svgIdsRegex));

    if (ArrayUtils.isEmpty(matched)) {
      return svgContent;
    }

    // increase count
    const svgIconsCount = SvgIconConstants.SVG_ICON_COUNT++;

    // save current svg content
    let newSvgContent = svgContent;

    // foreach every match
    matched.forEach((match) => {
      // replace all ids within the inner html by giving the unique suffix
      newSvgContent = newSvgContent.replace(
        new RegExp(match[1], 'g'),
        `${ match[1] }-dyn-id-${ svgIconsCount }`,
      );
    });

    return newSvgContent;
  }

  private loadSvgIconInline(): void {
    this.svgAutoCacheService.getSvg(this.path)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((svgContent) => {
        // handle blank value
        if (StringUtils.isBlank(svgContent)) {
          this.svgContent = '';
          // log info
          this.loggerService.logMessage(
            'SvgIconComponent :: empty svg content loaded',
            {
              level: 'info',
              fingerprint: ['SVG_ICON_COMPONENT_EMPTY_CONTENT'],
              extra: {
                svgContent,
              },
            },
          );
        } else {
          this.svgContent = this.onSvgLoaded(svgContent);
          this.onSvgInserted(this.svgContent);
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  /**
   * Adds classes into svg string
   */
  private setSvgAttributes(svgElement: string): string {
    /**
     * First find index of opening svg element tag, then add class attribute to it
     */
    const searchSvgElmString = '<svg';
    const classAttrAddIndex = svgElement.indexOf(searchSvgElmString) + searchSvgElmString.length;
    const textToAdd = ' class="tw-h-full tw-w-full tw-fill-foreground-color"';

    svgElement = svgElement.slice(0, classAttrAddIndex)
      + textToAdd + svgElement.slice(classAttrAddIndex);

    return svgElement;
  }

  /**
   * Invoke after SVG was inlined.
   */
  protected onSvgInserted(svgElm: string): void {
    if (this.platformCommonService.isBrowser) {
      return;
    }

    this.saveSvgToTransferState(svgElm);
  }

  /**
   * The svg is cached on the server, so there might not be a http request to be saved into transfer state.
   * This method saves a fake request, that is used during the rehydration on the client.
   * @param svgEl svg element to be saved
   */
  private saveSvgToTransferState(svgEl: string): void {
    const key = makeStateKey<string>(TransferStateUtils.generateKeyForRequest({
      url: this.path,
      method: 'GET',
      params: new HttpParams(),
      body: null,
    }));

    this.transferState.set(key, svgEl);
  }

}
