import { DomainCode } from '@shared/domain/model/domain-code.type';
import { HttpError } from '@shared/rest/model/http-error';
import { Nil } from '@util/helper-types/nil';

export class DomainUtil {

  /**
   * In case of cross domain error we need to extract users reg domain code from arguments
   */
  public static resolveDomainCodeFromError(httpError: HttpError): DomainCode | Nil {
    return httpError?.body?.errors[0]?.arguments[0] as DomainCode;
  }

}
