import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import isNil from 'lodash-es/isNil';

// Injectable needed as we use angular hooks, otherwise build will throw error
@Injectable()
export abstract class BaseDestroy implements OnDestroy {

  private _destroy$: Subject<void> = new Subject<void>();

  /**
   * It should never be called programmatically as this is method which is used by Angular internally (use {@link destroy} fn instead)
   * It can be overridden
   */
  public ngOnDestroy(): void {
    this._destroy$?.next();
    this._destroy$?.complete();
    this._destroy$ = null;
  }

  /**
   * Use this method, if you want programmatically destroy all active subscriptions in given class
   */
  public destroy(): void {
    this.ngOnDestroy();
  }

  /**
   * Emits everytime given class is being destroyed (either by Angular's {@link destroy} hook or programmatically by {@link ngOnDestroy}
   * NOTE: that if the {@link _destroy$} subject is nil, it will be instantiated
   */
  public get destroy$(): Observable<void> {
    if (isNil(this._destroy$)) {
      this._destroy$ = new Subject<void>();
    }

    return this._destroy$.asObservable();
  }

}
