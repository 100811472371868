import { Component, Input } from '@angular/core';

import { PopoverModule } from '@shared/legacy/directive/popover/popover.module';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { StarIconModule } from '@shared/legacy/component/star-icon/star-icon.module';
import { RouterLink } from '@angular/router';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';
import { Translate2Module } from '@common/translations/translate2.module';
import { UserRatingDto, UserRatingDtoDomainEnum } from '@api/aukro-api/model/user-rating-dto';
import { IconWithTextComponent } from '@common/ui-kit/component/icon-with-text/component/icon-with-text.component';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { DomainService } from '@shared/domain/service/domain.service';

@Component({
  selector: 'auk-user-rating',
  templateUrl: './user-rating.component.html',
  standalone: true,
  imports: [
    PopoverModule,
    SvgIconModule,
    InnerHtmlDirective,
    StarIconModule,
    RouterLink,
    LinkFromHrefDirective,
    Translate2Module,
    IconWithTextComponent,
  ],
})
export class UserRatingComponent {

  @Input() public userRating: UserRatingDto;
  /** When true, 'O prodejci' is shown. When false, 'O mně' is shown. */
  @Input() public aboutSeller: boolean;
  /** When true, '(Kupující)' label is shown. */
  @Input() public showBuyer: boolean;
  /** When true, '(Prodávající)' label is shown. */
  @Input() public showSeller: boolean;
  /** Disable user link when anonymous */
  @Input() public anonymous: boolean;
  /** When true, Aukro plus icon is hidden */
  @Input() public hideAukroPlus: boolean;

  constructor(private readonly domainService: DomainService) {
  }

  public iconModel(domain: UserRatingDtoDomainEnum): IconModel {
    return {
      type: 'SVG',
      source: this.domainService.getFlagByDomain(domain),
      size: 'SM',
    };
  }

}
