import { Injectable } from '@angular/core';
import { Lang } from '@shared/platform/lang.const';
import translations from '../translations/native-app-translations.json';
import { NativeTranslations } from '@shared/native-app/model/native-translations.model';

type TranslationsMap = Record<Lang, NativeTranslations>;

@Injectable({
  providedIn: 'root',
})

export class NativeAppTranslationService {

  private translations: TranslationsMap = translations;

  public getTranslations(lang: Lang): NativeTranslations {
    return this.translations[lang];
  }

}
