import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, mapToCanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { mergeMap, Observable, of, zip } from 'rxjs';
import { UserService } from '@shared/user/service/user.service';
import { catchError, map } from 'rxjs/operators';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import isNil from 'lodash-es/isNil';
import { ItemFormTypeEnum } from '@api/generated/defs/ItemFormTypeEnum';
import { ToastService } from '@common/toast/service/toast.service';
import { OfferApiService } from '@api/generated/api/Offer';
import { ItemFormTypeDto } from '@api/generated/defs/ItemFormTypeDto';
import { HttpError } from '@shared/rest/model/http-error';
import { SimpleExposeFormQueryParamsModel } from '@section/simple-expose/model/simple-expose-form-query-params.model';
import { ExtendedExposeFormQueryParamsModel } from '@section/offer-expose/model/extended-expose-form-query-params.model';
import { Nil } from '@util/helper-types/nil';
import { UserErrorUtil } from '@shared/user/util/user-error.util';
import { LoggerService } from '@common/logger/service/logger.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { AddressDto } from '@api/aukro-api/model/address-dto';

/**
 * TODO: [PDEV-20803] - rewrite to functional guard
 * @deprecated use {@link formSwitcherGuard}
 */
@Injectable({
  providedIn: 'root',
})
export class FormSwitcherGuard {

  private readonly URL_PATH_SIMPLE_FORM = '/jednoduche-vystaveni';
  private readonly URL_PATH_EXTENDED_FORM = '/vystavit';

  constructor(
    private readonly offerApiService: OfferApiService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly toastService: ToastService,
    private readonly loggerService: LoggerService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const queryParams: SimpleExposeFormQueryParamsModel | ExtendedExposeFormQueryParamsModel = route.queryParams;

    return zip(
      this.userService.getActualStatistics(),
      this.getAddress$(),
      this.getSimpleFormGloballyEnabled$(),
      this.getEditOnSimpleFormGloballyEnabled$(),
      this.getOriginalItemFormType$(queryParams),
      this.userService.userRegDomainConfig$,
    )
      .pipe(
        mergeMap(([
          actualStatistics,
          address,
          simpleFormGloballyEnabled,
          editOnSimpleFormGloballyEnabled,
          itemFormType,
          { exposeConfig },
        ]) => {
          if (!exposeConfig.simpleExposeAllowed && !exposeConfig.extendedExposeAllowed) {
            this.toastService.showDanger({ key: 'EXPOSE_NOT_ALLOWED' });
            return of(false);
          }

          const isOnlySimpleExposeAllowed =
            exposeConfig.simpleExposeAllowed
            && !exposeConfig.extendedExposeAllowed;

          if (isOnlySimpleExposeAllowed) {
            // If user is on any other form, move him to simple form
            if (!this.isSimpleForm(state)) {
              return of(this.createUrlTreeForSimpleForm(queryParams));
            }
          }

          if (!isOnlySimpleExposeAllowed) {
            // EDIT
            if (!isNil(itemFormType)) {
              // if user has not enabled pva, always redirect him to extended form
              if (!this.isPaymentViaAukroEnabled(actualStatistics)) {
                // if is simple expose form route, map simple form query params to extended form query params
                if (this.isSimpleForm(state)) {
                  return of(this.createUrlTreeForExtendedFormAndMapSimpleExposeParams(queryParams));
                }

                // if user is not on simple form, it means he's already on extended form so just pass this guard
                return of(true);
              }

              // If is edit, sell similar or propagate and original item created and edited in simple form, move to simple form
              if (
                !this.isSimpleForm(state)
                && this.isSimpleFormType(itemFormType.editFormType)
                && editOnSimpleFormGloballyEnabled
              ) {
                return of(this.createUrlTreeForSimpleForm(queryParams));
              }

              // If edit or sell similar and original item not created or edited in simple form, move to extended form
              if (
                this.isSimpleForm(state)
                && (
                  !this.isSimpleFormType(itemFormType.editFormType)
                  || !editOnSimpleFormGloballyEnabled
                )
              ) {
                return of(this.createUrlTreeForExtendedFormAndMapSimpleExposeParams(queryParams));
              }

              // NEW OFFER
            } else {
              const simpleFormOnDesktopAllowed: boolean = queryParams.simpleFormOnDesktopAllowed === 'true';
              const extendedFormOnMobileAllowed: boolean = queryParams.extendedFormOnMobileAllowed === 'true';

              // Move user to simple form if all conditions satisfied
              if (!this.isSimpleForm(state)
                && this.canUserBeNavigatedToSimpleForm(actualStatistics, simpleFormGloballyEnabled, address, simpleFormOnDesktopAllowed)
                && !extendedFormOnMobileAllowed
              ) {
                return of(this.createUrlTreeForSimpleForm(queryParams));
              }

              // Move user to extended form if some condition not satisfied
              if (this.isSimpleForm(state)
                && !this.canUserBeNavigatedToSimpleForm(actualStatistics, simpleFormGloballyEnabled, address, simpleFormOnDesktopAllowed)
              ) {
                return of(this.createUrlTreeForExtendedFormAndMapSimpleExposeParams(queryParams));
              }
            }
          }

          // Current form allowed
          return of(true);
        }),
        catchError((httpError: HttpError) => this.userService.getActualStatistics()
          .pipe(
            map(stats => {
              if (UserErrorUtil.userNotFound(httpError) && stats.roles?.includes('USER_TIER_100')) {
                // don't log this to sentry and don't display warning toast
                // this guard should not be run, there is action to open edit address dialog prerequisite in AllowedToSaleGuardService
                // this is only to fix that userNotFound error
                return false;
              }

              // else
              this.loggerService.logException(new Error('Error occurred when loading data in FormSwitcherGuardService.'), {
                extra: {
                  httpError,
                },
                fingerprint: ['FORM_SWITCHER_GUARD_SERVICE_LOADING_ERROR'],
              });

              this.toastService.showDanger({ key: 'SIMPLE_EXPOSE_FORM_LOAD_ERROR' });
              return false;
            }),
          ),
        ),
        mergeMap((result) => {
          // In case it is the first navigation on a page, redirect to a homepage to prevent stacking in a spinner page
          if (result === false && this.router.routerState.snapshot.url === '') {
            return of(this.router.createUrlTree(['/']));
          }

          return of(result);
        }),
      );
  }

  private getAddress$(): Observable<AddressDto> {
    return this.userService.getActualStatistics()
      .pipe(
        mergeMap((actualStatistics) => this.userService.address(actualStatistics?.userId)),
      );
  }

  private getSimpleFormGloballyEnabled$(): Observable<boolean> {
    return this.configuratorCacheService.getFeSystemParam<boolean>('FEATURE_FLAG_JV_ENABLED', 'BOOLEAN');
  }

  private getEditOnSimpleFormGloballyEnabled$(): Observable<boolean> {
    return this.configuratorCacheService.getFeSystemParam<boolean>('SIMPLE_EXPOSE_FORM_CZ_EDIT_ENABLED', 'BOOLEAN');
  }

  private getOriginalItemFormType$(
    queryParams: SimpleExposeFormQueryParamsModel | ExtendedExposeFormQueryParamsModel,
  ): Observable<ItemFormTypeDto> {
    let itemId: number;

    if (!isNil(queryParams.offerId)) {
      itemId = parseInt(queryParams.offerId, 10);
    } else if (!isNil(queryParams.itemId)) {
      itemId = parseInt(queryParams.itemId, 10);
    } else if (!isNil(queryParams.baseItemId)) {
      itemId = parseInt(queryParams.baseItemId, 10);
    } else {
      return of(null);
    }

    return this.offerApiService.formType({ itemId });
  }

  private isSimpleForm(state: RouterStateSnapshot): boolean {
    return state.url.startsWith(this.URL_PATH_SIMPLE_FORM);
  }

  private isSimpleFormType(formType: ItemFormTypeEnum): boolean {
    return formType === 'BASIC_FORM_2023';
  }

  private canUserBeNavigatedToSimpleForm(
    actualStatistics: UserInterestStatisticsDto,
    simpleExposeGloballyEnabled: boolean,
    address: AddressDto,
    simpleFormOnDesktopAllowed: boolean,
  ): boolean {
    return actualStatistics?.simpleExposeEnabled
      && this.isPaymentViaAukroEnabled(actualStatistics)
      && simpleExposeGloballyEnabled
      && !isNil(address?.id)
      && (this.responsivenessService.isMdAndLower || simpleFormOnDesktopAllowed);
  }

  /**
   * Converts query params to simple form format and returns URL to simple form
   * @param extendedFormQueryParams query params in extended form format
   * @returns URL to simple form (with query params in simple form format)
   */
  private createUrlTreeForSimpleForm(extendedFormQueryParams: ExtendedExposeFormQueryParamsModel): UrlTree {
    let simpleFormQueryParams: SimpleExposeFormQueryParamsModel = {};

    switch (extendedFormQueryParams.type) {
      case 'edit':
        simpleFormQueryParams = {
          itemId: extendedFormQueryParams.offerId,
        };
        break;
      case 'sellSimilar':
        simpleFormQueryParams = {
          baseItemId: extendedFormQueryParams.offerId,
        };
        break;
      case 'sellInCategory':
        simpleFormQueryParams = {
          categoryId: extendedFormQueryParams.categoryId,
        };
    }

    if (extendedFormQueryParams.fastPropagate === 'true') {
      simpleFormQueryParams = {
        ...simpleFormQueryParams,
        propagate: 'true',
      };
    }

    return this.router.createUrlTree([this.URL_PATH_SIMPLE_FORM], { queryParams: simpleFormQueryParams });
  }

  /**
   * Converts query params to extended form format and returns URL to extended form
   * @param simpleFormQueryParams query param map in simple form format
   * @returns URL to extended form (with query params in extended form format)
   */
  private createUrlTreeForExtendedFormAndMapSimpleExposeParams(
    simpleFormQueryParams: SimpleExposeFormQueryParamsModel,
  ): UrlTree {
    let extendedFormQueryParams: ExtendedExposeFormQueryParamsModel = {};

    if (!isNil(simpleFormQueryParams.itemId)) {
      extendedFormQueryParams = {
        offerId: simpleFormQueryParams.itemId,
        type: 'edit',
      };
    }

    if (!isNil(simpleFormQueryParams.baseItemId)) {
      extendedFormQueryParams = {
        offerId: simpleFormQueryParams.baseItemId,
        type: 'sellSimilar',
      };
    }

    if (!isNil(simpleFormQueryParams.categoryId)) {
      extendedFormQueryParams = {
        categoryId: simpleFormQueryParams.categoryId,
        type: 'sellInCategory',
      };
    }

    if (simpleFormQueryParams.fastPropagate === 'true') {
      extendedFormQueryParams = {
        ...extendedFormQueryParams,
        fastPropagate: 'true',
      };
    }

    return this.createUrlTreeForExtendedForm(extendedFormQueryParams);
  }

  /**
   * @returns boolean, whether user has payment via aukro enabled (PPA)
   */
  private isPaymentViaAukroEnabled(actualStatistics: UserInterestStatisticsDto | Nil): boolean {
    return actualStatistics?.paymentViaAukroEnabled;
  }

  private createUrlTreeForExtendedForm(
    queryParams: ExtendedExposeFormQueryParamsModel,
  ): UrlTree {
    return this.router.createUrlTree([this.URL_PATH_EXTENDED_FORM], { queryParams });
  }

}

export const formSwitcherGuard: CanActivateFn = mapToCanActivate([FormSwitcherGuard])[0];
