import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { AppModule } from './app.module';
import { provideServiceWorker } from '@angular/service-worker';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { provideRouter, ViewTransitionInfo, withEnabledBlockingInitialNavigation, withViewTransitions } from '@angular/router';
import { ROUTES } from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient } from '@angular/common/http';
import { DomainInitializer } from '@shared/initializers/domain.initializer';
import { AukroErrorHandlerService } from '@shared/services/errors/aukro-error-handler.service';
import { RxStompService } from '@shared/websocket/service/rx-stomp.service';
import { MESSAGE_FORMAT_CONFIG } from '@common/translations/message-format/aukro-translate.constants';
import { TranslateRoutesInitializer } from '@shared/initializers/translate-routes.initializer';
import { SubbrandInitializer } from '@shared/subbrand/service/subbrand.initializer';
import { UrlAnnouncementInitializer } from '@shared/url-announcements/initializer/url-announcement-initializer.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { CookieService as NgxCookieService } from 'ngx-cookie';
import { CookieService, NGX_COOKIE_SERVICE } from '@common/cookie/service/cookie.service';
import { AppTranslationsLoadInitializer } from '@shared/initializers/app-translations-load.initializer';
import { AppUserGuardInitializer } from '@shared/initializers/app-user-guard.initializer';
import { TokenInitializer } from '@shared/initializers/token.initializer';
import { DynamicColorResolver } from '@common/dynamic-color/dynamic-color.resolver';
import { SubbrandsDynamicColorResolver } from '@shared/subbrand/service/subbrands-dynamic-color-resolver';
import { TokenInterceptor } from '@shared/rest/token.interceptor';
import { CookieInterceptor } from '@shared/rest/cookie.interceptor';
import { SubbrandInterceptor } from '@shared/subbrand/interceptor/subbrand.interceptor';
import { BrowserStateInterceptor } from '@shared/rest/browser-state.interceptor';
import { SaveJwtTokenInterceptor } from '@shared/rest/save-jwt-token.interceptor';
import { BotDetectionInterceptor } from '@shared/rest/bot-detection.interceptor';
import { ClientTypeInterceptor } from '@shared/rest/client-type.interceptor';
import { Error4xxInterceptor } from '@shared/rest/error.4xx.interceptor';
import { Error5xxInterceptor } from '@shared/rest/error.5xx.interceptor';
import { ErrorInterceptor } from '@shared/rest/error.interceptor';
import { AuthorizationInterceptor } from '@shared/rest/authorization.interceptor';
import { ActionViewInterceptor } from '@shared/user-action-view/interceptor/action-view-interceptor';
import { CaptchaInterceptor } from '@shared/captcha/interceptor/captcha.interceptor';
import { CurrencyPreferenceInterceptor } from '@shared/rest/currency-preference.interceptor';
import { SwitchSubbrandInterceptor } from '@shared/subbrand/interceptor/switch-subbrand-interceptor.service';
import { LanguageInterceptorService } from '@shared/platform/language-interceptor.service';
import { ServerTimeInterceptorService } from '@common/time/service/server-time-interceptor.service';
import { SERVER_TIME_INTERCEPTOR_CONFIG } from '@common/time/model/server-time-interceptor-config';
import { DIALOG_CONFIG } from '@common/dialog/di/dialog-config.injection-token';
import { DialogConfigModel } from '@common/dialog/model/dialog-config.model';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { SUBBRAND_CONFIG } from '@shared/subbrand/di/subbrand-config.injection-token';
import { SubbrandConfigModel } from '@util/util/model/subbrand-config.model';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { LegacyDateAdapter as DateAdapter, MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS } from '@angular/material/legacy-core';
import { DATEPICKER_DATE_FORMATS, MyDateAdapter } from '@shared/util/date.adapter';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/legacy-tooltip';
import { NG_EVENT_PLUGINS } from '@tinkoff/ng-event-plugins';
import { APP_SCOPED_MEMORY_CACHE } from '@common/cache/di/app-scoped-memory-cache.injection-token';
import { MemoryCache } from '@common/cache/domain/memory-cache';
import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { createDomainDependentInitializers } from '@shared/domain/const/create-domain-dependent-initializers';
import { GoogleTagManagerInitializer } from '@shared/google-tag-manager/initializer/google-tag-manager.initializer';
import { NativeHttpInterceptor } from '@shared/rest/native-http.interceptor';
import { NativeAppInitializer } from '@shared/native-app/initializer/native-app.initializer';
import { SocialLoginConfigService } from '@shared/social-login/service/social-login-config.service';
import { AccessibilityInitializer } from '@common/accessibility/initializer/accessibility.initializer';
import { RouterOutletWithLoaderService } from '@shared/router-outlet-with-loader/service/router-outlet-with-loader.service';
import isNil from 'lodash-es/isNil';
import { SentryUserInitializer } from '@shared/sentry/initializer/sentry-user.initializer';
import { DateLocaleInitializer } from '@shared/locale/initializer/date-locale.initializer';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideClientHydration(
      // TODO: [PDEV-23828] - use native angular solution
      // disables native angular http transfer state cache
      withNoHttpTransferCache(),
    ),
    provideHttpClient(),
    {
      provide: APP_INITIALIZER,
      useFactory: (sentryUserInitializer: SentryUserInitializer) => (): void => sentryUserInitializer.init(),
      deps: [SentryUserInitializer],
      multi: true,
    },
    createDomainDependentInitializers(
      TranslateRoutesInitializer,
      SubbrandInitializer,
      UrlAnnouncementInitializer,
      DateLocaleInitializer,
      AppTranslationsLoadInitializer,
      GoogleTagManagerInitializer,
      AppUserGuardInitializer,
      TokenInitializer,
      NativeAppInitializer,
      AccessibilityInitializer,
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: (domainInitializer: DomainInitializer) => (): Promise<void> => domainInitializer.init(),
      deps: [DomainInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (routerOutletWithLoaderService: RouterOutletWithLoaderService) =>
        (): void => routerOutletWithLoaderService.init(),
      deps: [RouterOutletWithLoaderService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: AukroErrorHandlerService },
    {
      provide: RxStompService,
      useFactory: (): RxStompService => new RxStompService(),
    },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['cs'] } },
    { provide: WINDOW_OBJECT, useFactory: () => window },
    {
      provide: NgxCookieService,
      // The CookieService is a custom wrapper for the ngx-cookie service.
      useClass: CookieService,
    },
    {
      provide: NGX_COOKIE_SERVICE,
      useClass: NgxCookieService,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: (socialLoginConfigService: SocialLoginConfigService) => socialLoginConfigService.getSocialLoginConfig(),
      deps: [SocialLoginConfigService],
    },
    {
      provide: DynamicColorResolver,
      useClass: SubbrandsDynamicColorResolver,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CookieInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SubbrandInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BrowserStateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SaveJwtTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BotDetectionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Error4xxInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Error5xxInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActionViewInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaptchaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CurrencyPreferenceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SwitchSubbrandInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerTimeInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NativeHttpInterceptor,
      multi: true,
    },
    {
      provide: SERVER_TIME_INTERCEPTOR_CONFIG,
      useValue: {
        responseHeaderName: 'X-Server-Time',
        endpoints: ['offerDetail/short'],
        maxTresholdMilis: 3_000,
      },
    },
    {
      provide: DIALOG_CONFIG,
      useValue: {
        priorityMap: {
          POPUP_FLOW: 10,
          SIMPLE_ACTION: 20,
          NAVIGATION: 30,
          MARKETING: 40,
        },
      } as DialogConfigModel<AukDialogType>,
    },
    {
      provide: SUBBRAND_CONFIG,
      useValue: {
        subbrandHeaderName: 'X-Accept-Subbrand',
        requestRoutesToIgnore: ['/searchItemsCommon'],
      } as SubbrandConfigModel,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        maxWidth: '580px',
        panelClass: 'custom-material-modal',
      },
    },
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DATEPICKER_DATE_FORMATS },
    ...NG_EVENT_PLUGINS,
    {
      provide: APP_SCOPED_MEMORY_CACHE,
      useFactory: (): MemoryCache<unknown> => new MemoryCache(),
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true,
      } as MatTooltipDefaultOptions,
    },
    provideRouter(
      ROUTES,
      withEnabledBlockingInitialNavigation(),
      withViewTransitions({
        skipInitialTransition: true,
        onViewTransitionCreated: (transitionInfo: ViewTransitionInfo) => {
          //This will prevent ERROR 5 crash in Chrome
          //https://github.com/angular/angular/issues/54679
          if (isNil(transitionInfo?.from?.component)) {
            transitionInfo.transition.skipTransition();
          }
        },
      }),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !PlatformCommonService.isDevMode && !PlatformCommonService.isNativeApp,
      registrationStrategy: 'registerWhenStable:5000',
    }),
    importProvidersFrom(AppModule),
  ],
};
