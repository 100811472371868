import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, TrackByFunction } from '@angular/core';
import { Router } from '@angular/router';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { TranslationSource } from '@common/translations/model/translation-source';
import { UserHeaderControlComponent } from '../user-header-control/user-header-control.component';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { HeaderUserPersonalizationMeasurementService } from '@shared/services/personalization/header-user-personalization-measurement.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DomainService } from '@shared/domain/service/domain.service';
import { Observable } from 'rxjs';

interface AukroLink {
  name: string;
  url: string;
}

@Component({
  selector: 'auk-account-control',
  templateUrl: './account-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountControlComponent extends UserHeaderControlComponent implements OnInit {

  @Input() public user: UserInterestStatisticsDto;
  public isIconHidden: boolean = true; // todo(richard.pesek): solves case when header jumps because of one icon

  protected aukroLinks: AukroLink[] = [
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_2', url: '/moje-aukro/moje-nakupy/prihazuji' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_3', url: '/moje-aukro/moje-nakupy/zakoupeno' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_4', url: '/moje-aukro/moje-nakupy/sledovani' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_5', url: '/moje-aukro/moje-nakupy/oblibene-vyhledavani' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_6', url: '/moje-aukro/muj-ucet/pridat-komentar' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_7', url: '/moje-aukro/muj-prodej/prodavam' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_8', url: '/moje-aukro/muj-prodej/prodano' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_9', url: '/moje-aukro/muj-ucet/aktualni-zustatek' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_10', url: '/moje-aukro/muj-ucet/moje-udaje' },
    { name: 'ACCOUNT_CONTROL_COMPONENT_HTML_11', url: '/moje-aukro/moje-zpravy' },
  ];

  protected exposeUrl: string = '/vystavit';
  protected trackByIndexFn: TrackByFunction<AukroLink> = trackByIndexFn;

  private lastPopoverShowId: number;

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly headerUserPersonalizationMeasurementService: HeaderUserPersonalizationMeasurementService,
    @Inject(WINDOW_OBJECT) window: Window,
    changeDetectorRef: ChangeDetectorRef,
    responsivenessService: ResponsivenessService,
    protected override ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
  ) {
    super(window, changeDetectorRef, responsivenessService, ngZoneUtilService);
  }

  public ngOnInit(): void {
    this.ngZoneUtilService.simpleTimerOut$(
      () => {
        this.isIconHidden = false;
        this.changeDetectorRef.detectChanges();
      },
      this.ngUnsubscribe,
      200,
    );
  }

  public get userSubtitle(): TranslationSource {
    return this.user ? { defaultValue: this.user.username } : { key: 'ACCOUNT_CONTROL_LOGIN' };
  }

  protected get userProfileUrl(): string {
    return '/uzivatel/' + this.user.username;
  }

  public onAddProductClick(event: MouseEvent): void {
    event.preventDefault();

    this.onPopoverLinkClick(this.exposeUrl);

    void this.googleAnalyticsTrackingService.trackSellButtonClick({ location: 'Top-Right Dropdown menu' });
    void this.router.navigate([this.exposeUrl]);
  }

  public measureAvatarOrLinkClick(url: string): void {
    const isDesktop = this.responsivenessService.isLgAndHigher;
    this.measureClick(this.lastPopoverShowId, url, isDesktop, !isDesktop);
  }

  protected onPopoverLinkClick(url: string): void {
    this.closeDropdown();

    this.measureClick(this.lastPopoverShowId, url, false, false);
  }

  protected measureClick(showId: number, targetUrl: string, desktopIconClick: boolean, mobileIconClick: boolean): void {
    this.headerUserPersonalizationMeasurementService.clickAccountPopover({
      showId,
      properties: {
        targetUrl,
        desktopIconClick,
        mobileIconClick,
      },
    });
  }

  protected override measurePopoverShow(): void {
    this.headerUserPersonalizationMeasurementService.showAccountPopover$()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((showId) => this.lastPopoverShowId = showId);
  }

  public logout(event: Event): void {
    event.stopPropagation();
    this.closeDropdown();
    this.authenticationService.logout();
  }

  /**
   * Prevents emitting two click events
   * if a user clicks on a label in dropdown
   */
  public onPopoverClick(event: Event): void {
    event.stopPropagation();
  }

  protected get isSellButtonEnabled$(): Observable<boolean> {
    return this.domainService.currentDomainConfig$
      .pipe(
        map((domainConfig) =>
          domainConfig.exposeConfig.simpleExposeAllowed || domainConfig.exposeConfig.extendedExposeAllowed,
        ),
      );
  }

}
