import { Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
// eslint-disable-next-line import/no-restricted-paths
import { RoutingHrefResultModel } from '@common/routing/model/routing-href-result.model';
import { Router, UrlTree } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
// we need to run fromEvent inside ngZone, otherwise event.preventDefault() wouldn't work
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { fromEvent, merge, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { MouseUtils } from '@util/util/mouse.utils';
import { TargetAttrType } from '@common/routing/model/target-attr.type';
import { StringUtils } from '@util/util/string.utils';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';
import { LoggerService } from '@common/logger/service/logger.service';

export const NATIVE_APP_ORIGIN = 'app.aukro.cz';

@Injectable({
  providedIn: 'root',
})
export class RoutingService extends NgUnsubscribe {

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly domainService: DomainService,
    private readonly router: Router,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public resolveTargetAttr(
    /**
     * Whether, should link open in new tab
     */
    shouldOpenInNewTab: boolean = false,
  ): TargetAttrType {
    // always set target to _self in native app (so it will navigate inside app and won't open the link in the browser)
    if (PlatformCommonService.isNativeApp) {
      return '_self';
    } else {
      // otherwise set blank if it should be opened in new tab
      return shouldOpenInNewTab ? '_blank' : '_self';
    }
  }

  public getHrefRoutingResult(
    href: string,
    shouldOpenInNewTab: boolean,
  ): RoutingHrefResultModel | Nil {
    // handle blank value
    if (StringUtils.isBlank(href)) {
      return null;
    }

    // handle javascript in href
    const startsWithJavascript = href?.startsWith('javascript:');

    if (startsWithJavascript) {
      return {
        href,
      };
    }

    // handle mailto in href
    const startsWithMailto = href?.startsWith('mailto:');

    if (startsWithMailto) {
      return {
        href,
      };
    }

    let targetAttr: TargetAttrType = '_self';

    const currentHostname = this.domainService.currentDomainHostWithNativeIf;
    // parse given href url
    const targetUrl = this.getTargetUrl(href, currentHostname);

    let hrefAttr: string | Nil = null;
    let urlTree: UrlTree | Nil = null;

    // if we have no target url, do nothing more and keep default values
    if (isNil(targetUrl)) {
      return {
        target: targetAttr,
        href: hrefAttr,
      };
    }

    // set href to the target url
    hrefAttr = targetUrl.toString();
    // set target attr based on param
    targetAttr = this.resolveTargetAttr(shouldOpenInNewTab);

    // in dev mode it can have 'localhost' hostname instead of real hostname
    const hasTargetSameHostnameInDevMode = PlatformCommonService.isDevMode && targetUrl.hostname === 'localhost';
    const hasTargetSameHostname = targetUrl.hostname === currentHostname;

    // if target url hostname equals current base url && href attr is _self -> we can create url tree for in-app navigation
    if (targetAttr === '_self' && (hasTargetSameHostname || hasTargetSameHostnameInDevMode)) {
      urlTree = this.router.createUrlTree(
        [targetUrl.pathname],
        {
          queryParams: Array.from(targetUrl.searchParams.entries())
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
          fragment: targetUrl.hash
            // remove # symbol from start
            ? targetUrl.hash.slice(1)
            : null,
        },
      );
    }

    return {
      target: targetAttr,
      href: hrefAttr,
      urlTree,
    };
  }

  public createAnchorClickListener(
    routingInfo: RoutingHrefResultModel,
    anchorElm: HTMLAnchorElement,
    /**
     * When this observable emits, it will destroy the click listener
     */
    destroy$: Observable<unknown>,
  ): void {
    // don't create listener on server, or if we don't have urlTree or if target is _blank
    if (
      this.platformCommonService.isServer
      || !routingInfo?.urlTree
      || routingInfo?.target === '_blank'
    ) {
      return;
    }

    fromEvent(anchorElm, 'click')
      .pipe(
        takeUntil(
          merge(
            destroy$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe((event: MouseEvent) => {
        if (!MouseUtils.canDoAppNavigation(event)) {
          return;
        }

        void this.router.navigateByUrl(routingInfo.urlTree);

        event.preventDefault();
      });
  }

  private getTargetUrl(
    targetHref: string | Nil,
    currentHostname: string,
  ): URL | Nil {
    let correctLink: string = targetHref;

    const startsWithBackslash = targetHref?.startsWith('/');
    const startsWithDotAndBackslash = targetHref?.startsWith('./');
    const startsWithProtocol = this.platformCommonService.schemes.some((scheme) => targetHref?.startsWith(scheme));
    const startsWithCapacitor = targetHref?.startsWith('capacitor://');

    // if href link is without hostname (e.g. '/route/another-sub-route' or './route/another-sub-route'),
    // or doesn't have protocol, we prepend current hostname to it
    if (startsWithBackslash || startsWithDotAndBackslash || (!startsWithProtocol && !startsWithCapacitor)) {

      targetHref = startsWithDotAndBackslash
        // strip dot if is present
        ? targetHref.slice(1)
        // add backslash to start if is not present
        : !startsWithProtocol && !startsWithBackslash ? `/${ targetHref }` : targetHref;

      correctLink = this.platformCommonService.getProtocol() + currentHostname + targetHref;
    } else if (targetHref?.includes(NATIVE_APP_ORIGIN)) {
      // handle native app (if link has app origin we need to set it back to web origin) e.g. app.aukro.cz => aukro.cz
      targetHref = targetHref.replace(NATIVE_APP_ORIGIN, currentHostname);
      correctLink = targetHref;
    }

    let newUrl: URL;
    // try to create url from given href link, otherwise log error into sentry
    try {
      newUrl = new URL(correctLink);
    } catch {
      this.loggerService.logMessage(
        'LinkFromHrefDirective :: Invalid href link (cannot construct URL from it)',
        {
          level: 'error',
          fingerprint: ['LINK_FROM_HREF_DIRECTIVE_INVALID_URL'],
          extra: {
            invalidLink: correctLink,
          },
        },
      );
    }

    return newUrl ? newUrl : null;
  }

}
