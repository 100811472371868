import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { take, takeUntil } from 'rxjs/operators';
import { PushNotificationService } from '@api/generated/api/PushNotification';
import { PUSH_NOTIFICATIONS_TOKENDATA_KEY, PushNotificationsTokenData } from '../../../../typings/original/internal';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import isNil from 'lodash-es/isNil';
import { CookieService } from '@common/cookie/service/cookie.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { OneSignalPlugin } from 'onesignal-cordova-plugin';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Router } from '@angular/router';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { LoggerService } from '@common/logger/service/logger.service';

const SUBSCRIBE_PLAYER_ID_COOKIE_NAME = 'subscribePlayerId';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService extends BaseDestroy{

  private isInit: boolean = false;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly localStorageService: LocalStorageService,
    private readonly authService: AuthenticationService,
    private readonly notificationsService: PushNotificationService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public init(): void {
    // Do not init OneSignal if it has already been initialized. Do not init it also on non-production environments or when SSR.
    if (!environment.ONESIGNAL_KEY.length || !this.platformCommonService.isBrowser) {
      return;
    }

    const OneSignal = this.window['OneSignal'] || [];
    if (this.isInit) {
      this.assignPlayerIdToUserWhenCookieIsMissing(OneSignal);
      return;
    }

    OneSignal.push(function() {
      OneSignal.SERVICE_WORKER_PARAM = { scope: '/push/onesignal/' };
      OneSignal.SERVICE_WORKER_PATH = 'OneSignalSDKWorker.js';
      OneSignal.SERVICE_WORKER_UPDATER_PATH = 'OneSignalSDKUpdaterWorker.js';
      OneSignal.init({
        appId: environment.ONESIGNAL_KEY,
      });
    });

    this.assignPlayerIdToUserWhenCookieIsMissing(OneSignal);

    const getUserIdCallback = (userId): void => {
      this.setTokenData(userId);
      if (this.authService.isLoggedIn()) {
        this.assignPlayerIdToUser();
      }
    };

    const subscriptionChangeCallback = (isSubscribed: boolean): void => {
      if (isSubscribed) {
        OneSignal.getUserId(getUserIdCallback);
      } else {
        this.destroyToken();
      }
    };

    OneSignal.push(function() {
      OneSignal.on('subscriptionChange', subscriptionChangeCallback);
    });
    this.isInit = true;
  }

  /** Init OneSignal only if user has already been subscribed to notifications. Called only once on app start. */
  public initOnAppStart(): void {
    if (PlatformCommonService.isNativeApp) {
      void import('onesignal-cordova-plugin')
        .then((x) => x.default)
        .then((OneSignal) => {
          this.oneSignalNativeInit(OneSignal);
        })
        .catch((error) => {
          this.loggerService.logException('Native OneSignal plugin not loaded', error);
        });
    } else if (this.getTokenData()) {
      this.init();
    }
  }

  private oneSignalNativeInit(OneSignal: OneSignalPlugin): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    OneSignal.setLaunchURLsInApp(true);
    OneSignal.setAppId(environment.ONESIGNAL_KEY);

    OneSignal.setNotificationOpenedHandler((notificationData) => {
      if (notificationData?.notification?.launchURL) {
        this.ngZoneUtilService.runIn(() => {
          const url = new URL(notificationData.notification.launchURL);
          if (url?.pathname) {
            void this.router.navigateByUrl(url.pathname + url.search);
          }
        });
      }
    });

    const cookiePlayerId = this.cookieService.get(SUBSCRIBE_PLAYER_ID_COOKIE_NAME);

    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      if (accepted) {
        OneSignal.getDeviceState((deviceState) => {
          if (isNil(cookiePlayerId) || cookiePlayerId !== deviceState.userId) {
            this.setTokenData(deviceState.userId);
            if (this.authService.isLoggedIn()) {
              this.assignPlayerIdToUser();
            } else {
              this.cookieService.remove(SUBSCRIBE_PLAYER_ID_COOKIE_NAME);
            }
          }
        });
      }
    });
  }

  private assignPlayerIdToUserWhenCookieIsMissing(OneSignal: any): void {
    const cookiePlayerId = this.cookieService.get(SUBSCRIBE_PLAYER_ID_COOKIE_NAME);
    const getUserIdToCheckCookieCallback = (userId): void => {
      if (isNil(cookiePlayerId) || cookiePlayerId !== userId) {
        if (this.authService.isLoggedIn()) {
          this.assignPlayerIdToUser();
        } else {
          this.cookieService.remove(SUBSCRIBE_PLAYER_ID_COOKIE_NAME);
        }
      }
    };
    const isPushNotificationsEnabledCallback = (isEnabled): void => {
      if (isEnabled) {
        OneSignal.push(function() {
          OneSignal.getUserId(getUserIdToCheckCookieCallback);
        });
      }
    };
    OneSignal.push(function() {
      OneSignal.isPushNotificationsEnabled(isPushNotificationsEnabledCallback);
    });
  }

  public assignPlayerIdToUser(): void {
    const tokenData = this.getTokenData();
    if (!tokenData) {
      return;
    }
    this.notificationsService.onesignal({
      playerId: tokenData.token,
      platformTypeEnum: null,
    })
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public destroyToken(): void {
    const tokenData = this.getTokenData();
    if (!tokenData) {
      return;
    }
    this.notificationsService
      .onesignalTEMP({
        playerId: tokenData.token,
      })
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.removeTokenData();
  }

  private setTokenData(token: string): void {
    this.localStorageService.setItem(PUSH_NOTIFICATIONS_TOKENDATA_KEY, { token } as PushNotificationsTokenData);
  }

  private getTokenData(): PushNotificationsTokenData {
    return this.localStorageService.getItem(PUSH_NOTIFICATIONS_TOKENDATA_KEY) || null;
  }

  private removeTokenData(): void {
    this.localStorageService.removeItem(PUSH_NOTIFICATIONS_TOKENDATA_KEY);
  }

}
