import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { mergeMap, Observable } from 'rxjs';
import { CacheService } from '@common/cache/service/cache.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { DateUtils } from '@util/util/date.utils';
import { OfferCategoryService } from '@api/generated/api/OfferCategory';
import { ItemCategoryDto } from '@api/generated/defs/ItemCategoryDto';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class ItemCategoryService extends NgUnsubscribe implements CacheAware {

  constructor(
    public readonly cacheService: CacheService,
    protected readonly offerCategoryService: OfferCategoryService,
    private readonly subbrandService: SubbrandService,
  ) {
    super();
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'ItemCategoryService#getRootCategories',
    localeDependent: true,
  })
  public getRootCategories(): Observable<ItemCategoryDto[]> {
    return this.offerCategoryService.rootCategories();
  }

  public getTopSearchLevelCategoriesBySessionSubbrand(): Observable<ItemCategoryDto[]> {
    return this.subbrandService.sessionSubbrand$
      .pipe(
        mergeMap(subbrand => this.getTopSearchLevelCategoriesBySubbrand(subbrand)),
      );
  }

  // TODO(PDEV-22241) better solution for invalidating/using cache on subbrand change
  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    keyResolver: (subbrand: SubbrandType | Nil) => isNil(subbrand) ?
      `ItemCategoryService#getTopSearchLevelCategoriesBySubbrand` :
      `ItemCategoryService#getTopSearchLevelCategoriesBySubbrand-${ subbrand }`,
    localeDependent: true,
  })
  private getTopSearchLevelCategoriesBySubbrand(subbrand: SubbrandType | Nil): Observable<ItemCategoryDto[]> {
    return this.offerCategoryService.topSearchLevelCategoriesBySubbrand();
  }

}
