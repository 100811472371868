import { I18nPluralPipe } from '@angular/common';
import moment, { Duration, Moment } from 'moment-mini-ts';
import isNil from 'lodash-es/isNil';
import { UnitUtil } from '@common/ui-kit/common/unit-pluralize/class/unit.util';
import { StringDate } from '@util/helper-types/string-date';
// eslint-disable-next-line import/no-restricted-paths
import { Lang } from '@shared/platform/lang.const';
import { Nil } from '@util/helper-types/nil';
import { MINUTES_THRESHOLD_TO_DISPLAY_SECONDS } from '@common/time/service/time.service';
import { MediumDateTimePipe } from '@common/date-time/pipe/medium-date-time.pipe';
import { DateTimeTypeFormatType } from '@common/date-time/model/date-time-type-format.type';

export class TimeUtil {

  public static getFormattedTime(
    mediumDateTimePipe: MediumDateTimePipe,
    time: StringDate,
    timeFormat: DateTimeTypeFormatType = 'full',
  ): string {
    return mediumDateTimePipe.transform(time, timeFormat);
  }

  public static getDay(date: string): number | Nil {
    if (isNil(date)) {
      return null;
    }
    return moment(date).day();
  }

  public static getHumanReadableRemainingTime(
    remainingTime: Duration,
    i18nPluralPipe: I18nPluralPipe,
    lang: Lang,
  ): string {
    if (isNil(remainingTime)) {
      return '';
    }

    if (remainingTime.asSeconds() < 0) {
      remainingTime = moment.duration(0);
    }

    if (remainingTime.asSeconds() === 0) {
      return '';
    }

    let remainingTimeHumanized: string;
    const remainingDays = Math.floor(remainingTime.asDays());
    // remainingDays
    if (remainingDays > 0) {
      remainingTimeHumanized = remainingDays.toString() +
        ` ${ UnitUtil.unitPluralize('DAY', remainingDays, i18nPluralPipe, lang) }`;

      if (remainingTime.hours() > 0) {
        remainingTimeHumanized
          += ', '
          + remainingTime.hours().toString()
          + ` ${ UnitUtil.unitPluralize('HOUR', remainingTime.hours(), i18nPluralPipe, lang) }`;
      }
    }
    // hours
    if (remainingDays === 0 && remainingTime.hours() > 0) {
      remainingTimeHumanized = remainingTime.hours().toString()
        + ` ${ UnitUtil.unitPluralize('HOUR', remainingTime.hours(), i18nPluralPipe, lang) }`;

      if (remainingTime.minutes() > 0) {
        remainingTimeHumanized
          += ', '
          + remainingTime.minutes().toString()
          + ` ${ UnitUtil.unitPluralize('MINUTE', remainingTime.minutes(), i18nPluralPipe, lang) }`;
      }
    }
    // minutes
    if (remainingDays === 0 && remainingTime.hours() === 0 && remainingTime.minutes() > 0) {
      remainingTimeHumanized = remainingTime.minutes().toString()
        + ` ${ UnitUtil.unitPluralize('MINUTE', remainingTime.minutes(), i18nPluralPipe, lang) }`;
    }
    // less than 5 minutes
    if (remainingDays === 0 && remainingTime.hours() === 0 && remainingTime.minutes() < MINUTES_THRESHOLD_TO_DISPLAY_SECONDS) {
      remainingTimeHumanized =
        `0${ remainingTime.minutes() }:${ remainingTime.seconds() > 9
          ? remainingTime.seconds().toString()
          : '0' + remainingTime.seconds().toString() }`;
    }
    // seconds
    if (remainingDays === 0 && remainingTime.hours() === 0 && remainingTime.minutes() === 0) {
      remainingTimeHumanized = `${ remainingTime.seconds() } s`;
    }

    return remainingTimeHumanized;
  }

  public static getTimeWithoutMilliseconds(time: Duration): Duration {
    return time.subtract(time.milliseconds(), 'milliseconds');
  }

  public static areTimesDifferent(aTime: string | Moment, bTime: string | Moment): boolean {
    return !moment(aTime).isSame(moment(bTime));
  }

}
