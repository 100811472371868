import { Injectable } from '@angular/core';
import { FacebookLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { environment } from '@environment';
import { LoggerService } from '@common/logger/service/logger.service';

@Injectable({
  providedIn: 'root',
})
export class SocialLoginConfigService extends NgUnsubscribe {

  private readonly FACEBOOK_GRAPH_API_VERSION_FALLBACK_VALUE: string = 'v20.0';

  /**
   * Regular expression for checking if the version is in valid format (e.g.`v20.0`)
   */
  private readonly VERSION_PATTERN: RegExp = /^v\d+\.\d+$/;

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  /**
   * Provides {@link SocialAuthServiceConfig} asynchronously because API version is resolved dynamically from the config element.
   * `angularx-social-login` allows providing config as a `Promise`.
   * @returns A `Promise` that resolves to social login config object
   */
  public getSocialLoginConfig(): Promise<SocialAuthServiceConfig> {
    const configSource$: Observable<SocialAuthServiceConfig> = this.configuratorCacheService.getFeSystemParam<string>(
      'FACEBOOK_GRAPH_API_VERSION',
      'STRING',
    )
      .pipe(
        catchError(() => {
          this.loggerService.logException(
            'SocialLoginConfigService :: Error while getting FACEBOOK_GRAPH_API_VERSION from configurator',
          );
          return of(null);
        }),
        map((version: string) => {
          if (!this.VERSION_PATTERN.test(version)) {
            this.loggerService.logException(
              'SocialLoginConfigService :: Config element FACEBOOK_GRAPH_API_VERSION has invalid format',
              { extra: { value: version } },
            );
            return this.FACEBOOK_GRAPH_API_VERSION_FALLBACK_VALUE;
          }
          return version;
        }),
        map((version: string) => this.getConfig(version)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      );

    return firstValueFrom(configSource$);
  }

  /**
   * Gets social login config
   * @param facebookGraphApiVersion version of FB Graph API (e.g. `v20.0`)
   * @returns full config object
   */
  private getConfig(facebookGraphApiVersion: string): SocialAuthServiceConfig {
    return {
      providers: [{
        id: FacebookLoginProvider.PROVIDER_ID,
        provider: new FacebookLoginProvider(
          environment.FACEBOOK_APP_ID, {
            auth_type: 'rerequest',
            scope: 'email',
            locale: 'cs_CZ',
            fields: 'name,email,picture,first_name,last_name',
            version: facebookGraphApiVersion,
          }),
      }],
    };
  }

}
