import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { CountWithFavouriteSearchDto } from '../model/count-with-favourite-search-dto';
import { FavouriteSearchDto } from '../model/favourite-search-dto';
import { FavouriteSearchUpdateDto } from '../model/favourite-search-update-dto';
import { FavouriteSearchesDto } from '../model/favourite-searches-dto';
import { LeaderBoardFilterDto } from '../model/leader-board-filter-dto';
import { NewSearchParamsDto } from '../model/new-search-params-dto';
import { PageFavouriteSellerDto } from '../model/page-favourite-seller-dto';
import { PagedModelSellerProfileDto } from '../model/paged-model-seller-profile-dto';
import { SellerProfileDto } from '../model/seller-profile-dto';
import { environment } from '@environment';

export interface AddFavouriteSearchRequestParams {
    newSearchParamsDto: NewSearchParamsDto;
}

export interface AddFavouriteSellerRequestParams {
    /** Id of seller which should be added to the user\&#39;s favourite sellers list */
    sellerId: number;
}

export interface DeleteFavouriteSearchTEMPRequestParams {
    /** Search ids to remove */
    searchesToRemove: number[];
}

export interface DeleteFavouriteSellerV2RequestParams {
    automaticallyAddFavouriteSellerEnabled: boolean;
    /** Ids of sellers */
    sellersToRemove: number[];
}

export interface FavouriteLeaderBoardProfileForLoggedUserRequestParams {
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'favourite_count:DESC' | 'popularity_growth:DESC';
}

export interface FavouriteLeaderBoardProfilesRequestParams {
    leaderBoardFilterDto?: LeaderBoardFilterDto;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'favourite_count:DESC' | 'popularity_growth:DESC';
}

export interface GetFavouriteSearchRequestParams {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'id:ASC' | 'id:DESC' | 'newItemsCount:ASC' | 'newItemsCount:DESC';
}

export interface GetFavouriteSellersRequestParams {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property:(ASC|DESC) */
    sort?: 'itemsCount:ASC' | 'seller.showName:ASC' | 'itemsCount:DESC' | 'seller.showName:DESC';
}

export interface GetSearchByHashRequestParams {
    hash: string;
}

export interface UpdateFavouriteSearchRequestParams {
    favouriteSearchUpdateDto: FavouriteSearchUpdateDto;
    /** Id of favourite search */
    searchId: number;
}

@Injectable({
  providedIn: 'root',
})
export class FavouritesApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Adding of new search to user\&#39;s favourite search list
   */
  public addFavouriteSearch$(
    requestParameters: AddFavouriteSearchRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CountWithFavouriteSearchDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.newSearchParamsDto,
      responseType: this.configuration.getResponseType(false, 'CountWithFavouriteSearchDto'),
      headers,
    };
    return this.httpClient.request<CountWithFavouriteSearchDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/search`,
      options,
    );
  }

  /**
   * Adding of new seller to user\&#39;s favourite sellers list
   */
  public addFavouriteSeller$(
    requestParameters: AddFavouriteSellerRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const sellerId: number = requestParameters.sellerId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/seller/${ this.configuration.encodeParam({ name: 'sellerId', value: sellerId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Temporary - Remove search from user\&#39;s favourite search list
   */
  public deleteFavouriteSearchTEMP$(
    requestParameters: DeleteFavouriteSearchTEMPRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      searchesToRemove: requestParameters.searchesToRemove,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'delete',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/searchTEMP`,
      options,
    );
  }

  /**
   * V2 - Remove seller from user\&#39;s favourite sellers list
   */
  public deleteFavouriteSellerV2$(
    requestParameters: DeleteFavouriteSellerV2RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      sellersToRemove: requestParameters.sellersToRemove,
      automaticallyAddFavouriteSellerEnabled: requestParameters.automaticallyAddFavouriteSellerEnabled,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'delete',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/seller_V2`,
      options,
    );
  }

  /**
   * Returns leaderboard profile for logged in user
   */
  public favouriteLeaderBoardProfileForLoggedUser$(
    requestParameters: FavouriteLeaderBoardProfileForLoggedUserRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<SellerProfileDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'SellerProfileDto'),
      headers,
    };
    return this.httpClient.request<SellerProfileDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/leaderboard/loggedSeller`,
      options,
    );
  }

  /**
   * Returns leaderboard profiles
   */
  public favouriteLeaderBoardProfiles$(
    requestParameters: FavouriteLeaderBoardProfilesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelSellerProfileDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.leaderBoardFilterDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelSellerProfileDto'),
      headers,
    };
    return this.httpClient.request<PagedModelSellerProfileDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/leaderboard`,
      options,
    );
  }

  /**
   * Obtain favourite searches
   */
  public getFavouriteSearch$(
    requestParameters: GetFavouriteSearchRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<FavouriteSearchesDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'FavouriteSearchesDto'),
      headers,
    };
    return this.httpClient.request<FavouriteSearchesDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/search`,
      options,
    );
  }

  /**
   * Obtain user\&#39;s favourite sellers
   */
  public getFavouriteSellers$(
    requestParameters: GetFavouriteSellersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PageFavouriteSellerDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
      sort: requestParameters.sort,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PageFavouriteSellerDto'),
      headers,
    };
    return this.httpClient.request<PageFavouriteSellerDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/seller`,
      options,
    );
  }

  /**
   * Obtain preview of user\&#39;s favourite sellers
   */
  public getFavouriteSellersPreview$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PageFavouriteSellerDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'PageFavouriteSellerDto'),
      headers,
    };
    return this.httpClient.request<PageFavouriteSellerDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/seller/preview`,
      options,
    );
  }

  /**
   * Get search by hash
   */
  public getSearchByHash$(
    requestParameters: GetSearchByHashRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<FavouriteSearchDto> {
    const hash: string = requestParameters.hash;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'FavouriteSearchDto'),
      headers,
    };
    return this.httpClient.request<FavouriteSearchDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/search/hash/${ this.configuration.encodeParam({ name: 'hash', value: hash, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Change properties in favourite search
   */
  public updateFavouriteSearch$(
    requestParameters: UpdateFavouriteSearchRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const searchId: number = requestParameters.searchId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.favouriteSearchUpdateDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/favourites/search/${ this.configuration.encodeParam({ name: 'searchId', value: searchId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

}
