/**
 * Unit maps for key from API to Hungarian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_HU = {
  PAIRS: {
    '=0': 'párok',
    '=1': 'pár',
    '=2': 'párok',
    '=3': 'párok',
    '=4': 'párok',
    other: 'párok',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'párok',
    '=1': 'párt',
    other: 'párok',
  },
  PIECES: {
    '=0': 'darabok',
    '=1': 'darab',
    '=2': 'darabok',
    '=3': 'darabok',
    '=4': 'darabok',
    other: 'darabok',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'darabok',
    '=1': 'darabot',
    other: 'darabok',
  },
  PIECE_SHORT: {
    other: 'db',
  },
  SET: {
    '=0': 'készletek',
    '=1': 'készlet',
    '=2': 'készletek',
    '=3': 'készletek',
    '=4': 'készletek',
    other: 'készletek',
  },
  SET_ACCUSATIVE: {
    '=0': 'készletek',
    '=1': 'készlet',
    other: 'készletek',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'licitál',
    '=2': 'licitálnak',
    '=3': 'licitálnak',
    '=4': 'licitálnak',
    other: 'licitál',
  },
  BET_PAST: {
    '=1': 'licitált',
    '=2': 'licitáltak',
    '=3': 'licitáltak',
    '=4': 'licitáltak',
    other: 'licitált',
  },
  ITEM: {
    '=0': 'tételek',
    '=1': 'tétel',
    '=2': 'tételek',
    '=3': 'tételek',
    '=4': 'tételek',
    other: 'tételek',
  },
  DAY: {
    '=0': 'napok',
    '=1': 'nap',
    '=2': 'napok',
    '=3': 'napok',
    '=4': 'napok',
    other: 'napok',
  },
  HOUR: {
    '=0': 'órák',
    '=1': 'óra',
    '=2': 'órák',
    '=3': 'órák',
    '=4': 'órák',
    other: 'órák',
  },
  MINUTE: {
    '=0': 'percek',
    '=1': 'perc',
    '=2': 'percek',
    '=3': 'percek',
    '=4': 'percek',
    other: 'percek',
  },
  OTHER: {
    '=0': 'mások',
    '=1': 'más',
    '=2': 'mások',
    '=3': 'mások',
    '=4': 'mások',
    other: 'mások',
  },
  FILE: {
    '=0': 'fájlok',
    '=1': 'fájl',
    '=2': 'fájlok',
    '=3': 'fájlok',
    '=4': 'fájlok',
    other: 'fájlok',
  },
  IMAGE: {
    '=0': 'képek',
    '=1': 'kép',
    '=2': 'képek',
    '=3': 'képek',
    '=4': 'képek',
    other: 'képek',
  },
  OFFER: {
    '=0': 'ajánlatok',
    '=1': 'ajánlat',
    '=2': 'ajánlatok',
    '=3': 'ajánlatok',
    '=4': 'ajánlatok',
    other: 'ajánlatok',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'ajánlatokat',
    '=1': 'ajánlatot',
    '=2': 'ajánlatokat',
    '=3': 'ajánlatokat',
    '=4': 'ajánlatokat',
    other: 'ajánlatokat',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozitív értékelések',
    '=1': 'pozitív értékelés',
    '=2': 'pozitív értékelések',
    '=3': 'pozitív értékelések',
    '=4': 'pozitív értékelések',
    other: 'pozitív értékelések',
  },
  USER_GENITIV: {
    '=0': 'felhasználók',
    '=1': 'felhasználó',
    '=2': 'felhasználók',
    '=3': 'felhasználók',
    '=4': 'felhasználók',
    other: 'felhasználók',
  },
  USER_INSTRUMENTAL: {
    '=0': 'felhasználóval',
    '=1': 'felhasználóval',
    other: 'felhasználókkal',
  },
  ADDED_PASSIVE: {
    '=0': 'hozzáadott',
    '=1': 'hozzáadott',
    '=2': 'hozzáadott',
    '=3': 'hozzáadott',
    '=4': 'hozzáadott',
    other: 'hozzáadott',
  },
};
