import { Inject, Injectable, Optional, PLATFORM_ID, isDevMode } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { environment } from '@environment';
import { Capacitor } from '@capacitor/core';
import { ENVIRONMENT_PRODUCTION } from '../../../app.constants';
import { StringUtils } from '@util/util/string.utils';
import { REQUEST } from '../../../server/const/request';
import { ExpressRequest } from '@common/platform/model/request';
import { WINDOW_OBJECT } from '@util/const/window-object';

const GOOGLE_STORE_BOT_USER_AGENT: string = 'Storebot';

@Injectable({
  providedIn: 'root',
})
export class PlatformCommonService {

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: unknown,
    @Optional() @Inject(REQUEST) private readonly request: ExpressRequest,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
  }

  /**
   * Returns whether user agent is bot
   */
  public get isBot(): boolean {
    if (!this.isServer) {
      return false;
    }

    return StringUtils.parseBoolean(this.request.headers['x-is-bot'] as string);
  }

  /**
   * Returns if app is running on touch device
   */
  public get isTouchDevice(): boolean {
    if (!this.window.matchMedia) {
      return false;
    }

    return this.window.matchMedia('(hover: none)').matches;
  }

  /**
   * Returns whether app is running in browser
   */
  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  /**
   * Returns whether app is running in server
   */
  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  /**
   * Returns whether app is running in capacitor native app
   */
  public static get isNativeApp(): boolean {
    return Capacitor.isNativePlatform();
  }

  /**
   * Returns whether app is running in capacitor native app
   */
  public static get isNativeAndroidApp(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  /**
   * Returns whether app is running in capacitor native app
   */
  public static get isNativeIosApp(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  /**
   * Returns whether app is running in dev mode
   */
  public static get isDevMode(): boolean {
    return isDevMode();
  }

  /**
   * Returns whether app is running in production mode
   */
  public static get isProductionMode(): boolean {
    return environment.ENVIRONMENT === ENVIRONMENT_PRODUCTION;
  }

  /**
   * Google Store Bot is special variant of bot, which must be able to accomplish whole buy process on Aukro, simulating real user.
   * Thus, we don't classify it as other bots intentionally.
   */
  public get isGoogleStoreBot(): boolean {
    if (this.isBrowser) {
      return this.window.navigator.userAgent.includes(GOOGLE_STORE_BOT_USER_AGENT);
    }

    return this.request?.useragent?.source.includes(GOOGLE_STORE_BOT_USER_AGENT);
  }

  public getProtocol(): 'https://' | 'http://' {
    return PlatformCommonService.isProductionMode ? 'https://' : 'http://';
  }

  public get schemes(): ['https://', 'http://'] {
    return ['https://', 'http://'];
  }

}
