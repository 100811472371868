import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { OldNativeAppUpdateRequiredDialogComponent } from '@shared/native-app/component/old-native-app-update-required-dialog/old-native-app-update-required-dialog.component';
import { map, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { LayoutService } from '@common/layout/layout.service';
import { App, AppInfo } from '@capacitor/app';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { Observable, combineLatest, from, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OldNativeAppUpdateRequiredInitializer extends NgUnsubscribe implements AppInitializer {

  constructor(
    private readonly platformService: PlatformService,
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly layoutService: LayoutService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();
  }

  public init(): Observable<void> {
    return this.openModalCheck();
  }

  public openModalCheck(): Observable<void> {
    if (this.platformService.isOldAndroidNativeApp || this.platformService.isOldIosNativeApp) {
      this.openUpdateModal();
      return of(void 0);
    }

    if (PlatformCommonService.isNativeApp) {
      /**
       * This will get version info from Capacitor App and can Force Update to users if version is smaller
       * then MOBILE_UPDATE_VERSION_ANDROID or MOBILE_UPDATE_VERSION_IOS. In future we should use update banner for minor update and
       * non-skippable banner for major updates.
       */
      return combineLatest([from(App.getInfo()),
        this.configuratorCacheService.getFeSystemParam<string>('MINIMUM_NATIVE_APP_VERSION_FORCE_UPDATE', 'STRING')])
        .pipe(
          map(([info, minimumNativeAppVersion]: [AppInfo, string]) => {
            const updateVersion = info.version?.localeCompare(
              minimumNativeAppVersion || '0',
              undefined,
              { numeric: true, sensitivity: 'base' },
            );

            if (updateVersion === -1) {
              this.openUpdateModal();
            }
          }),
          take(1),
        );
    }

    return of(void 0);
  }

  private openUpdateModal(): void {
    this.layoutService.updateIsAppScrollable(false);

    this.aukMatDialogService.openSimple$(OldNativeAppUpdateRequiredDialogComponent, {
      width: '100vw',
      height: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      enterAnimationDuration: '0s',
      disableClose: true,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
