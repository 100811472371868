import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CartItemModel } from '@shared/cart/model/cart-item.model';
import { AddToCartService } from '@shared/cart/service/add-to-cart.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Component({
  selector: 'auk-cart-notification',
  templateUrl: './cart-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartNotificationComponent extends NgUnsubscribe implements OnInit {

  protected insertionFailed: boolean = false;
  protected showNotification: boolean = false;
  protected data: CartItemModel = null;

  constructor(private readonly addToCartService: AddToCartService,
    private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.addToCartService.getBasketNotification()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((show: boolean) => {
        this.showNotification = show;
        this.changeDetectorRef.detectChanges();
      });

    this.addToCartService.getInsertedItem()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: CartItemModel) => {
        this.data = data;
        this.insertionFailed = !data.successful;
        this.addToCartService.setBasketNotification(true);
        this.changeDetectorRef.detectChanges();
      });
  }

  public onClose(): void {
    this.addToCartService.setBasketNotification(false);
  }

  public get isCartUpdateNotificationShown(): boolean {
    return this.showNotification && !!this.data;
  }

  protected get isIosApp(): boolean {
    return PlatformCommonService.isNativeIosApp;
  }

}
