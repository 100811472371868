import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { distinctUntilChanged, startWith, take, takeUntil } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { TileComponent } from '@common/ui-kit/component/tile/component/tile/tile.component';
import { ListMenuComponent } from '@common/ui-kit/component/list-menu/component/list-menu.component';
import { TabControlComponent } from '@common/ui-kit/component/tab-control/component/tab-control/tab-control.component';
import { DialogHeaderComponent } from '@common/ui-kit/component/dialog/component/dialog-header/dialog-header.component';
import isNil from 'lodash-es/isNil';
import { SubbrandType, SubbrandTypeArr } from '@shared/subbrand/model/subbrand.type';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { Router } from '@angular/router';
import { CategoryTileListContainerModel } from '@shared/app-header/component/category-dialog/model/category-tile-list-container.model';
import { CategoryTileData } from '@shared/app-header/component/category-dialog/model/category-tile-data.model';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { DomainPickerModule } from '@shared/domain-picker/domain-picker.module';
import { DomainPickerService } from '@shared/domain-picker/service/domain-picker-service';
import { CategoryTileListModel } from '@shared/app-header/component/category-dialog/model/category-tile-list.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { AnalyticsTrackingDirective } from '@common/tracking/directive/analytics-tracking.directive';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { TabControlModel } from '@common/ui-kit/component/tab-control/model/tab-control';
import { CategoryDialogModel } from '@shared/app-header/component/category-dialog/model/category-dialog-model';
import { Nil } from '@util/helper-types/nil';
import { OfferCategoryService } from '@api/generated/api/OfferCategory';
import { Translate2Module } from '@common/translations/translate2.module';
import { ScrollService } from '@common/scroll/service/scroll.service';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { ItemCategoryWithItemCountDto } from '@api/generated/defs/ItemCategoryWithItemCountDto';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { delayIndicator } from '@util/rxjs-operators/delay-indicator';
import { DomainService } from '@shared/domain/service/domain.service';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';
import { AccessibilityDirective } from '@common/accessibility/directive/accessibility.directive';

const DELAY_TO_SHOW_SPINNER = 500;

/**
 * Hamburger menu
 */
@Component({
  selector: 'auk-category-dialog-content',
  templateUrl: './category-dialog-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconComponent,
    TabControlComponent,
    TranslateModule,
    ListMenuComponent,
    DialogHeaderComponent,
    TileComponent,
    DomainPickerModule,
    AnalyticsTrackingDirective,
    AccessibilityDirective,
    TestIdentificationDirective,
    Translate2Module,
    SpinnerComponent,
    InnerHtmlDirective,
  ],
})
export class CategoryDialogContentComponent extends NgUnsubscribe implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public categoryDialogModel: CategoryDialogModel | Nil;

  @Input()
  public currentSubbrand: SubbrandType | Nil;

  @Output() public dialogClose: EventEmitter<void> = new EventEmitter<void>();

  @Output() public subbrandSelected: EventEmitter<SubbrandType> = new EventEmitter<SubbrandType>();

  /**
   * Emits once component is initialized (AfterViewInit).
   * This makes possible to react to the long rendering time of the component due to its heaviness.
   */
  @Output() public componentInitialized: EventEmitter<void> = new EventEmitter<void>();

  protected readonly trackByIndexFn = trackByIndexFn;

  protected categoryTabList: TabControlModel[] = [];
  protected tileListContainerHeader: CategoryTileListModel[] = [];
  protected tileListContainerBodyMap: Record<SubbrandType, CategoryTileListModel[]> = {
    NOT_SPECIFIED: [],
    BAZAAR: [],
    ANTIQUE: [],
  };

  protected isMdAndLower: boolean = false;
  protected subLevelCategories: ItemCategoryWithItemCountDto[][] = [];
  protected currentSubCategory: ItemCategoryWithItemCountDto[] = [];
  protected isLoadingSubcategories: boolean = false;

  private fetchSubcategoriesCompleted: boolean = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService,
    private readonly subbrandService: SubbrandService,
    private readonly domainPickerService: DomainPickerService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
    private readonly offerCategoryService: OfferCategoryService,
    private readonly scrollService: ScrollService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessService.isMdAndLower$
      .pipe(
        startWith(this.responsivenessService.isMdAndLower),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        if (isMdAndLower !== this.isMdAndLower) {
          this.isMdAndLower = isMdAndLower;
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.componentInitialized.next();
  }

  public ngOnChanges(changes: AukSimpleChanges<CategoryDialogContentComponent>): void {
    if (changes.categoryDialogModel && isNotNil(changes.categoryDialogModel.currentValue)) {
      this.processData(
        changes.categoryDialogModel.currentValue.categoryTabList,
        changes.categoryDialogModel.currentValue.tileListContainer,
      );
    }

    if (changes.currentSubbrand) {
      this.changeDefault(changes.currentSubbrand.currentValue);
    }
  }

  protected get tabList(): TabControlModel[] {
    return this.categoryTabList ?? [];
  }

  protected get isIosApp(): boolean {
    return PlatformCommonService.isNativeIosApp;
  }

  protected get selectedTabId(): SubbrandType | Nil {
    return this.tabList.find(t => t.default)?.id as SubbrandType ?? this.currentSubbrand;
  }

  protected clickDialogClose(): void {
    this.subLevelCategories = [];
    this.dialogClose.emit();
  }

  private processData(tabList: TabControlModel[], tileListContainer: CategoryTileListContainerModel): void {
    if (isNotNil(tileListContainer)) {
      this.tileListContainerHeader = this.getListContainerHeader(tileListContainer);
      for (const subbrand of SubbrandTypeArr) {
        this.tileListContainerBodyMap[subbrand] = this.getCleanedListContainerBody(tileListContainer, subbrand);
      }
    }

    this.categoryTabList = tabList;
  }

  protected getForTab(subbrandType: SubbrandType): CategoryTileListModel[] {
    return this.tileListContainerBodyMap[subbrandType];
  }

  public tabChange(tabName: SubbrandType | Nil): void {
    if (isNil(tabName)) {
      return;
    }
    this.subbrandSelected.emit(tabName);
  }

  protected onTileClick(data: CategoryTileData): void {
    if (isNil(data)) {
      return;
    }

    this.personalizationMeasurementService.clickMeasurementWithCode(data.moCode);

    // Fetch Subcategories on small breakpoints if `onMobileOperation` exists
    if (this.responsivenessService.isMdAndLower && data.onMobileOperation === 'showCategoryTree') {
      this.subLevelCategories = [];
      this.currentSubCategory.push({
        shortName: data?.label,
        seoUrl: data?.link,
      });
      this.fetchSubcategories(data.categoryId, data.link);
    } else {
      this.handleDesktopOperation(data);
      this.clickDialogClose();
    }
  }

  // TODO(PDEV-22571) unify hp operations
  private handleDesktopOperation(data: CategoryTileData): void {
    switch (data.onDesktopOperation) {
      case 'openUrlWithSubbrand':
        this.subbrandService.setSessionSubbrandWithRedirect(data.subbrand, [data?.link]);
        break;
      case 'switchSubbrand':
        this.subbrandService.setSessionSubbrandWithHomepageRedirect(data.subbrand);
        break;
      case 'openUrl':
        void this.router.navigate([data?.link]);
        break;
      case 'pickDomain':
        this.domainPickerService.openRegionPreferenceModal(this.domainService.locale);
        break;
      case 'openMyAukro':
        void this.router.navigate(this.responsivenessService.isMdAndLower ? ['/moje-aukro/rozcestnik'] : ['/moje-aukro']);
        break;
    }
  }

  protected get currentSubLevelCategories(): ItemCategoryWithItemCountDto[] {
    return this.subLevelCategories[this.subLevelCategories.length - 1] || [];
  }

  protected onSubCategoryTileClick(data: ItemCategoryWithItemCountDto): void {
    this.currentSubCategory.push(data);

    if (data.leaf) {
      this.onShowAllFromSelectedCategory(data?.seoUrl);
    } else {
      this.fetchSubcategories(data.id);
    }
  }

  private fetchSubcategories(categoryId?: number, link?: string): void {
    if (!categoryId) {
      this.onShowAllFromSelectedCategory(link);
      return;
    }

    this.fetchSubcategoriesCompleted = false;

    this.offerCategoryService
      .subcategoriesWithItemsCount({ categoryId })
      .pipe(
        take(1),
        delayIndicator(
          this.ngZoneUtilService,
          () => {
            this.isLoadingSubcategories = true;
            this.changeDetectorRef.detectChanges();
          },
          () => {
            this.isLoadingSubcategories = false;
            this.changeDetectorRef.detectChanges();
          },
          DELAY_TO_SHOW_SPINNER,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((itemCategoryWithItemCountDto: ItemCategoryWithItemCountDto[]) => {
        if (!this.fetchSubcategoriesCompleted) {
          if (itemCategoryWithItemCountDto.length > 0) {
            this.subLevelCategories.push(itemCategoryWithItemCountDto);
            this.scrollToTop();
            this.fetchSubcategoriesCompleted = true;
          } else {
            // redirect to the selected category if there are no sub-level categories
            this.onShowAllFromSelectedCategory(link);
          }
          this.changeDetectorRef.markForCheck();
        }
      });

  }

  // Check if there are sublevel categories
  protected get hasSubLevelCategories(): boolean {
    return this.currentSubLevelCategories.length > 0;
  }

  // Get the data of the current sublevel category
  protected get currentSubLevelCategory(): ItemCategoryWithItemCountDto {
    const lastSubCategory = this.currentSubCategory[this.currentSubCategory.length - 1];
    return {
      shortName: lastSubCategory.shortName,
      seoUrl: lastSubCategory.seoUrl,
    };
  }

  protected onBackClick(): void {
    this.subLevelCategories = this.subLevelCategories.slice(0, this.subLevelCategories.length - 1);
    this.currentSubCategory = this.currentSubCategory.slice(0, this.currentSubCategory.length - 1);

    this.scrollToTop();
  }

  protected onShowAllFromSelectedCategory(seoUrl: string): void {
    this.subLevelCategories = [];
    void this.router.navigate([seoUrl]);
    this.clickDialogClose();
  }

  private getListContainerHeader(tileListContainer: CategoryTileListContainerModel): CategoryTileListModel[] {
    return tileListContainer?.sections['HEADER']?.items.map((tabList) => tabList) ?? [];
  }

  private getCleanedListContainerBody(tileListContainer: CategoryTileListContainerModel, tabName: string): CategoryTileListModel[] {
    return tileListContainer?.sections['CONTENT']
      ?.items.map((tabList) => tabList?.filter((tile) => isNil(tile.data?.type) || tile.data?.type === tabName)) ?? [];
  }

  private changeDefault(newDefault: string): void {
    this.categoryTabList = this.categoryTabList.map(tab => ({
      ...tab,
      default: tab.id === newDefault,
    }));
  }

  private scrollToTop(): void {
    this.scrollService.scrollIntoViewSelector('.header-dialog-content');
  }

}
