import { Injectable } from '@angular/core';
import { CartItemModel } from '@shared/cart/model/cart-item.model';
import { defer, iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounce, distinctUntilChanged, filter, map, merge, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { HttpError } from '@shared/rest/model/http-error';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { ItemDataProviderService } from '@shared/item/service/item-data-provider.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@common/toast/service/toast.service';
import { BrowserService } from '@shared/platform/browser.service';
import isNil from 'lodash-es/isNil';
import isFunction from 'lodash-es/isFunction';
import { ProductDetailActionBlockedEnum } from '../../../../typings/original/internal';
import { CartService } from '@shared/cart/service/cart.service';
import { cartErrorMessages } from '@shared/cart/helper/cart.helper';
import { CartItemFormDto } from '@api/generated/defs/CartItemFormDto';
import { CartApiService } from '@api/generated/api/Cart';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CartOverviewDto } from '@api/generated/defs/CartOverviewDto';
import { CartItemOverviewDto } from '@api/generated/defs/CartItemOverviewDto';
import { UserPermissionsService } from '@shared/user/service/user-permissions.service';
import { Privilege } from '@shared/user/model/privilege.enum';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { MoneyCalcUtils } from '@shared/currency/util/money-calc.utils';
import { UserAccountVerificationService } from '@shared/user-account-verification/service/user-account-verification.service';

const CART_RESPONSE_ERROR_MESSAGES: Record<string, [ProductDetailActionBlockedEnum, string]> = {
  'validation.item.shopping.unregisterd.payViaAukro':
    ['UNREGISTERED_BUY_NOW_ONLY_AUKRO_PAYMENT', 'UNREGISTERED_BUY_NOW_ONLY_AUKRO_PAYMENT_TEXT'],
  'validation.item.shopping.blacklist': ['BLOCKED_BY_SELLER', 'ALERT_BID_STATUS_BLOCKED_BY_SELLER'],
  'validation.item.shopping.user.notVerified': ['USER_NOT_VERIFIED_FOR_BUY', 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY'],
  'validation.cart.purchase.limit': ['NOT_ALLOWED_TO_BUY', 'SHOPPING_CART_PURCHASE_LIMIT_ADD_ANOTHER_WARNING_MESSAGE'],
};

@Injectable({
  providedIn: 'root',
})
export class AddToCartService extends NgUnsubscribe {

  public readonly errorMessages = cartErrorMessages;

  private cartItem = new Subject<CartItemModel>();
  private showBasketNotification: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly itemDataProviderService: ItemDataProviderService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly authenticationService: AuthenticationService,
    private readonly userAccountVerificationService: UserAccountVerificationService,
    private readonly userPermissionsService: UserPermissionsService,
    private readonly browserService: BrowserService,
    private readonly cartService: CartService,
    private readonly cartApiService: CartApiService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly router: Router,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();

    this.initAutoHideOfBasketNotification();

  }

  public addToBasket(cartItem: CartItemModel, quantity: number, callback?: () => void): Observable<void> {
    // Check if user has privilege only if is logged in
    return iif<boolean, boolean>(
      () => this.authenticationService.isLoggedIn(),
      defer(() => this.userPermissionsService.hasPrivilege$(Privilege.PURCHASE_BUY_NOW_ITEM)
        .pipe(
          tap((hasPrivilegePurchaseBuyNowItem: boolean) => {
            if (!hasPrivilegePurchaseBuyNowItem) {
              this.showNotVerifiedToBuyToast();
            }
          }),
        )),
      defer(() => of(true)),
    )
      .pipe(
        filter((verified: boolean) => verified),
        mergeMap(() => this.insertItemById(cartItem?.itemId, quantity)),
        tap(() => {
          // hack for mobile - show header by scroll to top by one px
          // TODO: [PDEV-15623] - refactor this
          if (this.responsivenessService.isMdAndLower) {
            this.browserService.smoothScroll(this.browserService.getScrollTop() - 1, 0);
          }
        }),
        catchError((error: HttpError) => {
          this.handleFailedInsertion(error, cartItem, callback);
          return throwError(() => error);
        }),
      );
  }

  private insertItemById(itemId: number, quantity: number): Observable<void> {
    const data: CartItemFormDto = {
      item: {
        itemId,
        itemQuantity: quantity,
      },
      update: false,
    };
    return this.cartApiService.insertCartItemUsingPOST(
      { cartItemFormDto: data },
      null,
      new HttpContext().set(USER_ACTION_TYPE, 'BUY_NOW'),
    )
      .pipe(
        tap((newCartOverview: CartOverviewDto) => {
          this.cartService.setCartOverviewValue(newCartOverview);
          const newCartItem = newCartOverview.cartItems?.find((item) => item.currentlyInserted);
          if (newCartItem) {
            // announce newly inserted item into cart
            const { itemName, totalPrice, sellerId, sellerLogin } = newCartItem;
            this.translateService.get('ADD_TO_CART_POPUP_TEXT_SUCCESS')
              .pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe((message: string) => {
                this.cartItem.next({
                  successful: true,
                  message,
                  itemName,
                  name: itemName,
                  itemId,
                  totalPrice,
                  seller: {
                    userId: sellerId,
                    showName: sellerLogin,
                  },
                });
              });

            const cartOverview: CartOverviewDto = {
              ...newCartOverview,
              cartItems: newCartOverview.cartItems.filter((item: CartItemOverviewDto) => item.currentlyInserted || item.itemId === itemId),
            };
            void this.googleAnalyticsTrackingService.trackCartQuantity({
              type: 'add',
              cartOverview,
              currentAmount: quantity,
            });
          }
        }),
        map<unknown, void>(() => void 0),
      );
  }

  private showNotVerifiedToBuyToast(): void {
    this.toastService.showWarning({ key: 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY' },
      {
        actions: this.userAccountVerificationService.getUserNotVerifiedForBuyToastAction('item-detail-buy-event'),
      },
    );
  }

  public handleFailedInsertion(error: HttpError, cartItem: CartItemModel, onBlockinErrorCallback?: () => void): void {
    let shouldThrow: boolean = true;
    let errorMessage: string;
    const itemQuantityInCart = this.cartService.getItemQuantityInCart(cartItem.itemId);

    if (error.body.errors) {
      const errorCode: string = error.body.errors[0].code;
      errorMessage = this.errorMessages.ITEM_SINGLE[errorCode];
      if (errorMessage) {
        shouldThrow = false;
      }

      if (errorCode === 'validation.item.shopping.quantity' && itemQuantityInCart > 0) {
        this.translateService.get('ADD_TO_CART_POPUP_TEXT_SUCCESS_ALREADY_INSERTED')
          .pipe(
            take(1),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((message: string) => {
            this.cartItem.next({
              successful: true,
              message,
              itemName: cartItem.itemName,
              name: cartItem.name,
              itemId: cartItem.itemId,
              totalPrice: MoneyCalcUtils.multiply(cartItem.buyNowPrice, itemQuantityInCart),
              seller: {
                userId: cartItem.seller.userId,
                showName: cartItem.seller.showName,
              },
              buyNowPrice: cartItem.buyNowPrice,
              buyNowActive: cartItem.buyNowActive,
            });
          });
        return;
      }

      const actionBlock = CART_RESPONSE_ERROR_MESSAGES[error.body.errors[0].code];
      const nonBlockingActions = ['SHOPPING_CART_PURCHASE_LIMIT_ADD_ANOTHER_WARNING_MESSAGE'];
      if (!isNil(actionBlock)) {

        if (actionBlock[1] === 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY') {
          this.showNotVerifiedToBuyToast();
        } else {
          this.toastService.showDanger({ key: actionBlock[1] });
        }

        if (nonBlockingActions.includes(actionBlock[1])) {
          return;
        }
        if (isFunction(onBlockinErrorCallback)) {
          onBlockinErrorCallback();
        }
        return;
      }
    }

    this.itemDataProviderService.getItemDetailWithErrorHandled(cartItem.itemId)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((insertedItem: CartItemModel) => {
        this.cartItem.next({
          successful: false,
          message: errorMessage,
          itemName: insertedItem.name,
          itemId: insertedItem.itemId,
          totalPrice: insertedItem.buyNowPrice,
          seller: {
            userId: insertedItem.seller.userId,
            showName: insertedItem.seller.showName,
          },
          buyNowPrice: insertedItem.buyNowPrice,
          buyNowActive: insertedItem.buyNowActive,
        });
      });

    if (shouldThrow) {
      throw error;
    }
  }

  public setBasketNotification(state: boolean): void {
    this.showBasketNotification.next(state);
  }

  public getBasketNotification(): Observable<boolean> {
    return this.showBasketNotification.pipe(distinctUntilChanged());
  }

  public getInsertedItem(): Observable<CartItemModel> {
    return this.cartItem.asObservable();
  }

  public initAutoHideOfBasketNotification(): void {
    // hide basket notification after a while or when user navigates to another page
    const hideDelay = 4000; // ms
    const navigationChanged = this.router.events
      .pipe(
        filter((data: RouterEvent) => data instanceof NavigationEnd),
      );
    this.showBasketNotification
      .pipe(
        filter((shown: boolean) => shown),
        debounce(() => this.ngZoneUtilService.timerOut$(hideDelay)),
        merge(navigationChanged),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.setBasketNotification(false));
  }

}
