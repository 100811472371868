import { Inject, Injectable } from '@angular/core';
import { browserTracingIntegration, init, captureConsoleIntegration } from '@sentry/browser';
import { captureException } from '@sentry/core';
import { environment } from '@environment';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { SentryUtil } from '@common/sentry/util/sentry.util';

/**
 * Service which initializes Sentry in browser (Sentry on server is initialized in sentry-server-init file
 */
@Injectable({
  providedIn: 'root',
})
export class SentryBrowserInitializer implements AppInitializer {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
  ) {
  }

  public init(): void {
    if (!SentryUtil.isSentryEnabled('BROWSER', false)) {
      return;
    }

    init({
      ...SentryUtil.getBaseSentryConfig(),
      dsn: environment.SENTRY_DSN,
      allowUrls: [
        /.+\.aukro\.cloud/,
        /aukro\.cz/,
      ],
      integrations: (integrations) => {
        integrations = integrations
          .filter(integration => integration.name !== 'TryCatch');

        // monitor CWV
        integrations.push(browserTracingIntegration({
          enableInp: true,
        }));

        // capture console.error logs
        integrations.push(
          captureConsoleIntegration({
            levels: ['error'],
          }),
        );

        return integrations;
      },
    });

    // Make Sentry logging globally accessible to be used in GTM
    this.window.captureExceptionToSentry = captureException;
  }

}
