import { Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { Observable, of } from 'rxjs';
import { StringDate } from '@util/helper-types/string-date';
import { DateTimeTypeExtendedFormatType } from '@common/date-time/model/date-time-type-extended-format.type';
import moment, { Moment } from 'moment-mini-ts';
import { DatePipe } from '@angular/common';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';
import { DateTimeTypeFormatType } from '@common/date-time/model/date-time-type-format.type';
import { CalendarUtil } from '@util/util/calendar.util';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

type PossibleDateType = StringDate | Date | Nil;

interface DateTimeTranslations {
  yesterday: string;
  today: string;
  tomorrow: string;
}

@Injectable({
  providedIn: 'root',
})
export class DateTimePipeService {

  private readonly mediumWithoutSecFormat: string = 'd. MMMM yyyy, HH:mm';
  private readonly mediumWithSecFormat: string = 'd. MMMM yyyy, HH:mm:ss';
  private readonly mediumOnlyNumbersFormat: string = 'd. M. yyyy, HH:mm';
  private readonly mediumOnlyNumbersNoYearFormat: string = 'd. M. H:mm:ss';
  private readonly mediumOnlyNumbersNoTimeFormat: string = 'd. M. yyyy';
  private readonly longerWithoutSecFormat: string = 'EEEEEE d. MMMM yyyy, HH:mm';
  private readonly longerFormat: string = 'EEEEEE d. MMMM yyyy, HH:mm:ss';
  private readonly fullFormat: string = 'EEEE, d. M. yyyy, HH:mm:ss';
  private readonly fullWithoutHours: string = 'EEEE, d. M. yyyy';
  private readonly fullDayWithoutYearTimeFormat: string = 'EEEE, d. M.';
  private readonly shortFormat: string = 'd. MMMM yyyy';
  private readonly shortDateTimeFormat: string = 'dd.MM.YYYY HH:mm';
  private readonly monthAndYearFormat: string = 'M. yyyy';
  private readonly hoursAndMinutesFormat: string = 'HH:mm';
  private readonly dayMonthYearFormat: string = 'd. MMMM y';
  private readonly dayShortMonthYearFormat: string = 'd MMM y';
  private readonly monthShortDayYearFormat: string = 'MMM d,y';
  private readonly dayMonthHoursMinutesFormat: string = 'dd.MM. HH:MM';
  private readonly dayMonthYearHoursMinutesSecondsFormat: string = 'd. M. yyyy, HH:mm:ss';
  private readonly dayShortMonthShortHoursMinutesFormat: string = 'd. M. HH:mm';
  private readonly longDayMonthHoursMinutesFormat: string = 'EEEEEE d.M. HH:mm';

  constructor(
    private readonly datePipe: DatePipe,
    private readonly domainService: DomainService,
    private readonly translateService: TranslateService,
  ) {
  }

  /**
   * Transforms date string or date object into readable string
   *
   * NOTE: if {@link value } is invalid date, it returns nil
   */
  public transform$(
    date: PossibleDateType,
    dateTimeFormatType: DateTimeTypeExtendedFormatType = 'mediumWithoutSec',
  ): Observable<string | Nil> {
    if (dateTimeFormatType === 'economical') {
      return this.getEconomicalDate$(date);
    }

    return of(this.transformShared(date, dateTimeFormatType));
  }

  /**
   * Transforms date string or date object into readable string
   *
   * NOTE: if {@link value } is invalid date, it returns nil
   * @param dateTimeTranslations - use {@link getDateTimeTranslations$ } to get this data
   */
  public transform(
    dateTimeTranslations: DateTimeTranslations,
    value: PossibleDateType,
    dateTimeFormatType: DateTimeTypeExtendedFormatType = 'mediumWithoutSec',
  ): string | Nil {
    if (dateTimeFormatType === 'economical') {
      return this.getEconomicalDate(dateTimeTranslations, value);
    }

    return this.transformShared(value, dateTimeFormatType);
  }

  /**
   * @deprecated
   * Instead please use {@link transform} or preferably {@link transform$}
   */
  public transformOld(
    value: PossibleDateType,
    dateTimeFormatType: DateTimeTypeFormatType = 'mediumWithoutSec',
  ): string | Nil {
    return this.transformShared(value, dateTimeFormatType);
  }

  private transformShared(value: StringDate | Date | Nil, dateTimeFormatType: DateTimeTypeFormatType = 'mediumWithoutSec'): string | Nil {
    if (!value) {
      return null;
    }

    const date = moment(value);

    if (!date.isValid()) {
      return null;
    }

    const format: string = this.getFormat(dateTimeFormatType ?? 'mediumWithoutSec');

    return this.transformByDatePipe(date, format);
  }

  public getDateTimeTranslations$(): Observable<DateTimeTranslations> {
    return this.translateService.get([
      'COMMON__YESTERDAY',
      'COMMON__TODAY',
      'COMMON__TOMORROW',
    ])
      .pipe(
        map(translations => ({
          yesterday: translations['COMMON__YESTERDAY'] as string,
          today: translations['COMMON__TODAY'] as string,
          tomorrow: translations['COMMON__TOMORROW'] as string,
        })),
      );
  }

  private getEconomicalDate$(date: PossibleDateType): Observable<string | Nil> {
    return this.getDateTimeTranslations$()
      .pipe(
        map(translations => this.getEconomicalDate(translations, date)),
      );
  }

  private getEconomicalDate(
    dateTimeTranslations: DateTimeTranslations,
    date: PossibleDateType,
  ): string | Nil {
    if (!date) {
      return null;
    }

    const unifiedDate = moment(date);

    if (!unifiedDate.isValid()) {
      return null;
    }

    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (CalendarUtil.isSameDay(unifiedDate, today)) {
      return `${ dateTimeTranslations.today } ${ this.transformByDatePipe(unifiedDate, this.hoursAndMinutesFormat) }`;
    }
    if (CalendarUtil.isSameDay(unifiedDate, tomorrow)) {
      return `${ dateTimeTranslations.tomorrow } ${ this.transformByDatePipe(unifiedDate, this.hoursAndMinutesFormat) }`;
    }
    if (CalendarUtil.isSameDay(unifiedDate, yesterday)) {
      return `${ dateTimeTranslations.yesterday } ${ this.transformByDatePipe(unifiedDate, this.hoursAndMinutesFormat) }`;
    }
    if (CalendarUtil.isSameYear(unifiedDate, today)) {
      return this.transformByDatePipe(unifiedDate, this.longDayMonthHoursMinutesFormat);
    }
    return null;
  }

  private transformByDatePipe(date: Moment, format: string): string | Nil {
    return this.datePipe.transform(date.toDate(), format, null, this.domainService.lang);
  }

  private getFormat(type: DateTimeTypeFormatType | Nil): string {
    switch (type) {
      case 'longer':
        return this.longerFormat;
      case 'longerWithoutSec':
        return this.longerWithoutSecFormat;
      case 'short':
        return this.shortFormat;
      case 'medium':
        return this.mediumWithSecFormat;
      case 'mediumOnlyNumbersNoYear':
        return this.mediumOnlyNumbersNoYearFormat;
      case 'mediumOnlyNumbersNoTime':
        return this.mediumOnlyNumbersNoTimeFormat;
      case 'mediumOnlyNumbers':
        return this.mediumOnlyNumbersFormat;
      case 'full':
        return this.fullFormat;
      case 'fullDayWithoutYearTime':
        return this.fullDayWithoutYearTimeFormat;
      case 'fullWithoutHours':
        return this.fullWithoutHours;
      case 'shortDateTime':
        return this.shortDateTimeFormat;
      case 'monthAndYear':
        return this.monthAndYearFormat;
      case 'hoursAndMinutes':
        return this.hoursAndMinutesFormat;
      case 'dayMonthYear':
        return this.dayMonthYearFormat;
      case 'dayShortMonthYear':
        return this.dayShortMonthYearFormat;
      case 'monthShortDayYear':
        return this.monthShortDayYearFormat;
      case 'dayMonthHoursMinutes':
        return this.dayMonthHoursMinutesFormat;
      case 'dayMonthYearHoursMinutesSeconds':
        return this.dayMonthYearHoursMinutesSecondsFormat;
      case 'dayShortMonthShortHoursMinutes':
        return this.dayShortMonthShortHoursMinutesFormat;
      case 'longDayMonthHoursMinutes':
        return this.longDayMonthHoursMinutesFormat;
      default:
        // default is 'mediumWithoutSec' type
        return this.mediumWithoutSecFormat;
    }
  }

}
