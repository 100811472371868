import { Injectable } from '@angular/core';
import { TimeToEndModel } from '@shared/time/model/time-to-end.model';
import { StringDate } from '@util/helper-types/string-date';
import moment, { Moment } from 'moment-mini-ts';
import { TranslationSource } from '@common/translations/model/translation-source';
import { UnitPluralizePipe } from '@common/ui-kit/common/unit-pluralize/pipe/unit-pluralize.pipe';

@Injectable({
  providedIn: 'root',
})
export class TimeToEndService {

  constructor(
    private readonly unitPluralizePipe: UnitPluralizePipe,
  ) {
  }

  public getTimeToEnd(endingTime: StringDate | Moment): TimeToEndModel {
    const endMoment: Moment = moment(endingTime);
    const now: Moment = moment();
    let unit: 'DAY' | 'HOUR' | 'MINUTE';
    let count: number;

    count = endMoment.diff(now, 'days');
    unit = 'DAY';

    if (count === 0) {
      count = endMoment.diff(now, 'hours');
      unit = 'HOUR';

      if (count === 0) {
        count = endMoment.diff(now, 'minutes');
        unit = 'MINUTE';
      }
    }

    let label: TranslationSource;

    if (count === 0 && unit === 'MINUTE' && endMoment.isAfter(now)) {
      label = { key: 'TIME_TO_END_LESS_THAN_MINUTE' };
    } else if (count < 0) {
      const countPositive: number = count * (-1);
      let unitInstrumental: 'DAY_INSTRUMENTAL' | 'HOUR_INSTRUMENTAL' | 'MINUTE_INSTRUMENTAL';
      switch (unit) {
        case 'DAY':
          unitInstrumental = 'DAY_INSTRUMENTAL';
          break;
        case 'HOUR':
          unitInstrumental = 'HOUR_INSTRUMENTAL';
          break;
        case 'MINUTE':
          unitInstrumental = 'MINUTE_INSTRUMENTAL';
      }
      label = { defaultValue: `${ countPositive } ${ this.unitPluralizePipe.transform(unitInstrumental, countPositive) }` };
    } else {
      label = { defaultValue: `${ count } ${ this.unitPluralizePipe.transform(unit, count) }` };
    }

    return {
      label,
      past: count < 0,
    };
  }

}
