import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BasicRegistrationFormModel } from '@shared/registration/module/basic-registration-form/basic-registration-form.model';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { ValidationMessagesDirective } from '@shared/legacy/directive/validation-messages/validation-messages.directive';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ONLY_NUMBER_PATTERN } from '@shared/validators/patterns';
import { LimitMaxLengthDirectiveModule } from '@shared/legacy/directive/limit-max-length/limit-max-length.directive.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { TrimDirectiveModule } from '@util/directive/trim/trim.directive.module';
import { ValidationMessageComponent } from '@shared/legacy/component/validation-message/validation-message.component';
import { mergeMap, startWith , takeUntil } from 'rxjs/operators';
import { ToastService } from '@common/toast/service/toast.service';
import { HintComponent } from '@common/ui-kit/component/hint/component/hint.component';
import { AlertBannerModule } from '@shared/legacy/component/alert-banner/alert-banner.module';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { VatService } from '@shared/vat/service/vat.service';
import { RegistryService } from '@shared/registry/registry.service';
import { getCountryByIdOrCode } from '@shared/util/method/country-by-id-or-code-util';
import { RegistryApiService } from '@api/aukro-api/api/registry-api.service';
import { RegistryItemDto } from '@api/aukro-api/model/registry-item-dto';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RegistrationSharedService } from '@shared/registration/service/registration-shared.service';

@Component({
  selector: 'auk-business-registration',
  templateUrl: './business-registration-form.component.html',
  styleUrls: ['./business-registration-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    Translate2Module,
    MatSlideToggleModule,
    LimitMaxLengthDirectiveModule,
    ValidationMessageComponent,
    ValidationMessagesDirective,
    TrimDirectiveModule,
    HintComponent,
    AlertBannerModule,
    InnerHtmlDirective,
    AsyncPipe,
  ],
})
export class BusinessRegistrationFormComponent extends NgUnsubscribe implements OnInit {

  @Input() public basicRegistrationForm: FormGroup<BasicRegistrationFormModel>;

  protected showBusinessRegistrationToggle$: Observable<boolean> = this.registrationSharedService.getCompanyRegistrationEnabled$();

  protected legalForms: RegistryItemDto[];
  protected isCompanyNameHidden: boolean = false;

  constructor(
    private readonly registryApiService: RegistryApiService,
    private readonly registryService: RegistryService,
    private readonly toastService: ToastService,
    private readonly vatService: VatService,
    private readonly registrationSharedService: RegistrationSharedService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initLegalForms();
  }

  protected get isVatPayer(): boolean {
    return this.basicRegistrationForm?.controls['isVatPayer']?.value;
  }

  protected get isBusinessRegistration(): boolean {
    return this.basicRegistrationForm?.controls['isBusinessRegistration']?.value;
  }

  protected toggleBusinessRegistration(): void {
    this.basicRegistrationForm?.controls['isBusinessRegistration'].setValue(!this.isBusinessRegistration);
    this.setValidatorsForBusinessRegistration();
  }

  protected toggleVatPayer(): void {
    this.basicRegistrationForm?.controls['isVatPayer'].setValue(!this.isVatPayer);
    this.setValidatorsForVatPayer();
  }

  protected legalFormChange(): void {
    if (this.basicRegistrationForm?.controls['legalForm']?.value === 'TRADESMAN') {
      this.basicRegistrationForm.removeControl('companyName');
      this.isCompanyNameHidden = true;
    } else {
      this.basicRegistrationForm.addControl('companyName', new FormControl(null, [Validators.required, Validators.minLength(1)]));
      this.isCompanyNameHidden = false;
    }
  }

  private initLegalForms(): void {
    const countryControl = this.basicRegistrationForm.controls.address.controls.country;

    this.registryService.getCountries()
      .pipe(
        mergeMap((countries) => countryControl.valueChanges
          .pipe(
            startWith(countryControl.value),
            mergeMap((countryCode) => {
              const countryByCode = getCountryByIdOrCode(countryCode, countries);

              return this.registryApiService.getBusinessFormsForCountry$({ countryId: countryByCode.id });
            }),
          ),
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (forms: RegistryItemDto[]) => {
          this.legalForms = forms;
        },
        error: () => {
          this.toastService.showDanger({ key: 'DEFAULT_API_ERROR' });
        },
      });
  }

  private setValidatorsForBusinessRegistration(): void {
    if (this.isBusinessRegistration === true) {
      this.basicRegistrationForm.addControl('companyName', new FormControl(null, [Validators.required, Validators.minLength(1)]));
      this.basicRegistrationForm.addControl('regNumber', new FormControl(null, [
        Validators.required,
        Validators.pattern(ONLY_NUMBER_PATTERN),
        Validators.minLength(8)]),
      );
      this.basicRegistrationForm.addControl('legalForm', new FormControl(null, [Validators.required]));
      this.basicRegistrationForm.addControl('vatNumber', new FormControl(null, null));
    } else {
      this.basicRegistrationForm.removeControl('companyName');
      this.basicRegistrationForm.removeControl('regNumber');
      this.basicRegistrationForm.removeControl('legalForm');
      this.basicRegistrationForm.removeControl('vatNumber');
    }
  }

  private setValidatorsForVatPayer(): void {
    if (this.isVatPayer === true) {
      this.basicRegistrationForm.removeControl('vatNumber');
      this.basicRegistrationForm.addControl(
        'vatNumber',
        new FormControl(null, [Validators.required, this.vatService.vatValidator('address.country')]),
      );
    } else {
      this.basicRegistrationForm.removeControl('vatNumber');
      this.basicRegistrationForm.addControl('vatNumber', new FormControl(null, null));
    }
  }

}
