import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ItemQuestionFormDto } from '../model/item-question-form-dto';
import { QuestionFormDto } from '../model/question-form-dto';
import { environment } from '@environment';

export interface GetQuestionFormRequestParams {
    /** object ID ( item, dealTransaction, ...) */
    objectId: number;
    /** Message recipient type ( SELLER or BUYER ) */
    questionTo: 'SELLER' | 'BUYER';
    /** Question type ( ITEM, DEAL_TRANSACTION, DEAL, ...) */
    type: 'ADMIN' | 'USER' | 'MESSAGE' | 'ITEM' | 'DEAL' | 'DEAL_TRANSACTION' | 'BID' | 'CART' | 'CONFIG_ELEMENT' | 'EMAIL_BATCH' | 'REFUND_CLAIM' | 'PAYMENT_VIA_AUKRO' | 'REQUEST' | 'BANK_ACCOUNT' | 'LANDING_PAGE' | 'FILTER_MENU_ITEM' | 'ITEM_CATEGORY' | 'BANK_TRANSFER' | 'OUTGOING_PAYMENT' | 'BARGAINING' | 'PACKET' | 'ITEM_ATTRIBUTE' | 'ITEM_ATTRIBUTE_LIST_VALUE' | 'ITEM_REPORT' | 'ITEM_PRIVATE' | 'USER_ITEM_ADMISSION' | 'ITEM_EDIT' | 'ITEMS_COLLECTION' | 'USER_ITEMS_COLLECTION_ADMISSION' | 'SECOND_CHANCE_OFFER' | 'AUKRO_GRID_FILTER' | 'USER_FEEDBACK' | 'BANNER' | 'USER_PRICE_LIST' | 'ITEM_IMAGE';
}

export interface SendQuestionRequestParams {
    questionFormDto: QuestionFormDto;
}

@Injectable({
  providedIn: 'root',
})
export class IkemApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Return question form based on type
   */
  public getQuestionForm$(
    requestParameters: GetQuestionFormRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemQuestionFormDto> {
    const objectId: number = requestParameters.objectId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      type: requestParameters.type,
      questionTo: requestParameters.questionTo,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ItemQuestionFormDto'),
      headers,
    };
    return this.httpClient.request<ItemQuestionFormDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/ikem/${ this.configuration.encodeParam({ name: 'objectId', value: objectId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/questionForm`,
      options,
    );
  }

  /**
   * Send question
   */
  public sendQuestion$(
    requestParameters: SendQuestionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.questionFormDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/ikem/sendQuestion`,
      options,
    );
  }

}
