import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConsentStancesModalClose } from '../../../../../../typings/original/internal';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { UnpaidAlertUtil } from '../util/unpaid-alert.util';
import { Event, NavigationStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  selector: 'auk-unpaid-alert',
  templateUrl: './unpaid-alert.component.html',
  styleUrls: ['./unpaid-alert.component.scss'],
})
export class UnpaidAlertComponent extends NgUnsubscribe implements OnInit {

  private consentModalData: ConsentStancesModalClose;

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { consentModalData: ConsentStancesModalClose },
    private readonly localStorageService: LocalStorageService,
    private readonly dialogRef: MatDialogRef<UnpaidAlertComponent>,
    private readonly router: Router,
  ) {
    super();
    this.consentModalData = data.consentModalData;
  }

  public ngOnInit(): void {
    //Close on navigation (when user clicks on link)
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.close());
  }

  public close(): void {
    this.localStorageService.setItem(
      UnpaidAlertUtil.getUnpaidAlertDisplayedKey(this.consentModalData.actualStatistics.userId),
      true,
    );

    this.dialogRef.close();
  }

}
