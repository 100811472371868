import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nil } from '@util/helper-types/nil';
import { take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UsersApiService } from '@api/aukro-api/api/users-api.service';
import { NewUserProfileBaseDto } from '@api/aukro-api/model/new-user-profile-base-dto';
import { NewUserProfileAllDto } from '@api/aukro-api/model/new-user-profile-all-dto';

@Injectable({
  providedIn: 'root',
})
/**
 * @deprecated
 *
 * Instead of using this manage userProfile object somewhere in your specific page
 */
export class UserProfileService extends NgUnsubscribe {

  private _userProfileAll: BehaviorSubject<NewUserProfileAllDto | Nil> =
    new BehaviorSubject<NewUserProfileAllDto>(null);

  constructor(
    private readonly usersApiService: UsersApiService,
  ) {
    super();
  }

  public get userProfileAll(): NewUserProfileAllDto {
    return this._userProfileAll.getValue();
  }

  public set userProfileAll(userProfile: NewUserProfileAllDto) {
    this._userProfileAll.next(userProfile);
  }

  public get userProfileAll$(): Observable<NewUserProfileAllDto> {
    return this._userProfileAll.asObservable();
  }

  /**
   * Fetches and updates userProfileData Subject
   * @param username - username of the user
   * @param apiType - type of API to be used (note: MINIMAL doesn't contain all the data like FULL)
   */
  public reloadCurrentlyLoggedUserProfileData(username: string, apiType: 'MINIMAL' | 'FULL' = 'FULL'): void {
    // choose API
    const obs$: Observable<NewUserProfileBaseDto | NewUserProfileAllDto> = apiType === 'MINIMAL'
      ? this.usersApiService.findUserProfileByUsernameMinimal$({ username })
      : this.usersApiService.getUserProfileByUsernameAll$({ username });

    obs$
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((userProfile: NewUserProfileAllDto | NewUserProfileBaseDto) => {
        // update the data service
        this.userProfileAll = {
          ...this.userProfileAll,
          ...userProfile,
          // specifically update avatar url, because if user doesn't have avatar set,
          // api won't return null field, so it won't be rewritten
          avatarUrl: userProfile?.avatarUrl ?? null,
        };
      });
  }

}
