import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ChangeEmailDto } from '../model/change-email-dto';
import { ChangePasswordDto } from '../model/change-password-dto';
import { MyAukroCompanyChangeDataDto } from '../model/my-aukro-company-change-data-dto';
import { MyAukroUserDataDto } from '../model/my-aukro-user-data-dto';
import { MyAukroUserPreferencesChangeDto } from '../model/my-aukro-user-preferences-change-dto';
import { RestrictionsParametersDto } from '../model/restrictions-parameters-dto';
import { VerifyPhoneDto } from '../model/verify-phone-dto';
import { environment } from '@environment';

export interface CheckRestrictionsRequestParams {
    restrictionsParametersDto: RestrictionsParametersDto;
}

export interface GetUserDataDtoRequestParams {
    withCompanyData?: boolean;
}

export interface UpdateEmailRequestParams {
    changeEmailDto: ChangeEmailDto;
}

export interface UpdatePasswordRequestParams {
    changePasswordDto: ChangePasswordDto;
}

export interface UpdatePhoneRequestParams {
    verifyPhoneDto: VerifyPhoneDto;
}

export interface UpdatePreferencesRequestParams {
    myAukroUserPreferencesChangeDto: MyAukroUserPreferencesChangeDto;
}

@Injectable({
  providedIn: 'root',
})
export class UsersMeApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Remove pending email change
   */
  public cancelEmailChange$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'delete',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/newEmail`,
      options,
    );
  }

  /**
   * Change user preferences
   */
  public checkRestrictions$(
    requestParameters: CheckRestrictionsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.restrictionsParametersDto,
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/check-restrictions`,
      options,
    );
  }

  /**
   * Getting data for My Aukro - My data - Changing to company
   */
  public getUserChangingToCompanyData$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MyAukroCompanyChangeDataDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'MyAukroCompanyChangeDataDto'),
      headers,
    };
    return this.httpClient.request<MyAukroCompanyChangeDataDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/myData/companyChange`,
      options,
    );
  }

  /**
   * Getting data for My Aukro - My data page
   */
  public getUserDataDto$(
    requestParameters: GetUserDataDtoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<MyAukroUserDataDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      withCompanyData: requestParameters.withCompanyData,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'MyAukroUserDataDto'),
      headers,
    };
    return this.httpClient.request<MyAukroUserDataDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/myData`,
      options,
    );
  }

  /**
   * Resend email change confirmation notification
   */
  public resendEmailChangeConfirmEmail$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/newEmail/resend`,
      options,
    );
  }

  /**
   * Change email with TFV
   */
  public updateEmail$(
    requestParameters: UpdateEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.changeEmailDto,
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/email`,
      options,
    );
  }

  /**
   * Change password with TFV
   */
  public updatePassword$(
    requestParameters: UpdatePasswordRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.changePasswordDto,
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/password`,
      options,
    );
  }

  /**
   * Phone change / verify
   */
  public updatePhone$(
    requestParameters: UpdatePhoneRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.verifyPhoneDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/phone`,
      options,
    );
  }

  /**
   * Change user preferences
   */
  public updatePreferences$(
    requestParameters: UpdatePreferencesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.myAukroUserPreferencesChangeDto,
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/preference`,
      options,
    );
  }

}
