<auk-dialog-content
  [dialogTitle]="payload.title"
  [dialogSubtitle]="payload.subtitle"
  >
  <ng-container content>
    <auk-user-task-login-step-not-you
      [email]="payload.email"
      [isNotThatYou]="payload.isNotThatYou"
      (isNotThatYouClick)="onBackClick()"
    ></auk-user-task-login-step-not-you>
  </ng-container>

  <ng-container actions>
    <div class="tw-flex tw-flex-col tw-gap-y-6 tw-text-center tw-mt-3">
      @if (!isNativeIosApp) {
        <auk-facebook-button
          [buttonText]="payload.continueViaFacebook"
          (facebookButtonClick)="loginViaFB()"
        ></auk-facebook-button>
      }
      <p class="tw-text-base tw-leading-sm" [aukInnerHtml]="payload.terms | translateSource"></p>
    </div>
  </ng-container>
</auk-dialog-content>
