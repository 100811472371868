import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ConfigElementFrontendPackResponse } from '../model/config-element-frontend-pack-response';
import { ConfigElementValueDto } from '../model/config-element-value-dto';
import { StandAlonePageDto } from '../model/stand-alone-page-dto';
import { environment } from '@environment';

export interface GetConfigElementRequestParams {
    configElementTypeEnum: 'WEB_TEXT' | 'BEHAVIOR_DIRECTIVE' | 'SYSTEM_PARAMETER' | 'STANDALONE_PAGE' | 'WEB_HTML_BLOCK' | 'EMAIL_TEMPLATE' | 'DYNAMIC_SEGMENT' | 'EMAIL_HTML_BLOCK' | 'ENUM' | 'MENUS_LAYOUTS' | 'INVOICE' | 'FEED' | 'CONTACT_FORM' | 'FE_SPEC_OPS' | 'TICKET_TYPE' | 'TICKET_MESSAGE_TEMPLATE' | 'TICKET_PARAMETERS' | 'SYSTEM_PARAMETER_FE' | 'SEARCH_REDIRECT_MAP' | 'BUTTON' | 'POPUP_FLOW' | 'INDEXED_SEARCH_MAP' | 'LISTING_MOD' | 'ITEM_DETAIL_MOD';
    keys?: string[];
}

export interface GetStandalonePageRequestParams {
    siteUrlPart: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Gets config elements by given keys and type
   */
  public getConfigElement$(
    requestParameters: GetConfigElementRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ConfigElementValueDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      keys: requestParameters.keys,
      configElementTypeEnum: requestParameters.configElementTypeEnum,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ConfigElementValueDto>'),
      headers,
    };
    return this.httpClient.request<ConfigElementValueDto[]>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/configurator/configElement`,
      options,
    );
  }

  /**
   * Gets all config elements by given type
   */
  public getConfigElementForFrontend$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ConfigElementFrontendPackResponse> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ConfigElementFrontendPackResponse'),
      headers,
    };
    return this.httpClient.request<ConfigElementFrontendPackResponse>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/configurator/configElement/frontend`,
      options,
    );
  }

  /**
   * Gets standalone page by site url part
   */
  public getStandalonePage$(
    requestParameters: GetStandalonePageRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<StandAlonePageDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      siteUrlPart: requestParameters.siteUrlPart,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'StandAlonePageDto'),
      headers,
    };
    return this.httpClient.request<StandAlonePageDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/configurator/configElement/standAlonePage`,
      options,
    );
  }

}
