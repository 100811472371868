import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DomainService } from '@shared/domain/service/domain.service';
import { Router } from '@angular/router';
import { finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { getCountryByIdOrCode } from '@shared/util/method/country-by-id-or-code-util';
import { UserService } from '@shared/user/service/user.service';
import { MyAukroUserDataDto } from '@api/generated/defs/MyAukroUserDataDto';
import { UserMeService } from '@api/generated/api/UserMe';
import { UserAccountDetailDto } from '@api/generated/defs/UserAccountDetailDto';
import { HttpError } from '@shared/rest/model/http-error';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { RegistryService } from '@shared/registry/registry.service';
import { cityNameValidator, streetValidator, zipCodeValidatorByPattern } from '@shared/validators/validators';
import { CommonModule } from '@angular/common';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { UpdateCompanyAccountDetailDto } from '@api/generated/defs/UpdateCompanyAccountDetailDto';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MyDataUtils } from '../../my-data.utils';
import { DomainCode } from '@shared/domain/model/domain-code.type';

@Component({
  selector: 'auk-change-address-modal-dialog',
  templateUrl: './change-address-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    Translate2Module,
    ButtonComponent,
    DialogWrapperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    MatInputModule,
    MatSelectModule,
  ],
  standalone: true,
})
export class ChangeAddressDialogComponent extends NgUnsubscribe {

  public personalDataForm: FormGroup;

  public formData: MyAukroUserDataDto;

  public countries: RegistryCountryItemDto[];

  constructor(
    private dialogRef: MatDialogRef<ChangeAddressDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly userMeService: UserMeService,
    private readonly registryService: RegistryService,
    private readonly userService: UserService,
    private readonly shareDataService: ShareDataService,
    private readonly router: Router,
    private readonly domainService: DomainService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.loadDataAndInitForm();
  }

  private get isPersonalAccount(): boolean {
    return this.formData?.userAccountTypeDataDto?.accountType === 'USER';
  }

  public onPersonalDataFormSubmit(): void {
    const payload = {
      address: {
        address: this.personalDataForm?.get('address.address')?.value,
        zipCode: this.personalDataForm?.get('address.zipCode')?.value,
        city: this.personalDataForm?.get('address.city')?.value,
        country: getCountryByIdOrCode(this.personalDataForm?.get('address.country')?.value, this.countries),
      },
    };

    const payloadPersonal: UserAccountDetailDto = {
      ...payload,
      birthDate: this.formData?.userPersonalDataDto?.birthDate,
      firstName: this.formData?.userPersonalDataDto?.address?.firstName,
      lastName: this.formData?.userPersonalDataDto?.address?.lastName,
    };

    const payloadCompany: UpdateCompanyAccountDetailDto = {
      address: {
        ...payload.address,
        firstName: this.formData?.userCompanyDataDto?.address?.firstName,
        lastName: this.formData?.userCompanyDataDto?.address?.lastName,
        id: this.formData?.userCompanyDataDto?.address?.id,
      },
      firstName: this.formData?.userCompanyDataDto?.userNameDto?.firstName,
      lastName: this.formData?.userCompanyDataDto?.userNameDto?.lastName,
      regNumber: this.formData?.userCompanyDataDto?.regNumber,
      vatNumber: this.formData?.userCompanyDataDto?.vatNumber,
      taxNumber: this.formData?.userCompanyDataDto?.taxNumber,
      accountCompanyFormEnum: this.formData?.userCompanyDataDto?.companyType?.code,
    };

    if (this.isPersonalAccount) {
      this.submitDataForPersonalAccount(payloadPersonal);
    } else {
      this.submitDataForCompanyAccount(payloadCompany);
    }
  }

  private submitDataForCompanyAccount(payload: UpdateCompanyAccountDetailDto): void {
    this.userService.updateCompanyDetail(this.formData.userId, payload)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.shareDataService.showSuccessMessageTranslated('CHANGE_ADDRESS_MODAL_ACTION_DONE_COMPANY_INFO');
          void this.router.navigate(['/']);

          this.dialogRef.close(true);
        },
        error: (error: HttpError) => {
          this.shareDataService.showSuccessMessageTranslated('CHANGE_ADDRESS_MODAL_ACTION_DONE_COMPANY_INFO');

          this.dialogRef.close(true);
        },
      });
  }

  private submitDataForPersonalAccount(payload: UserAccountDetailDto): void {
    this.userService.updateUserDetail(this.formData.userId, payload)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.shareDataService.showSuccessMessageTranslated('SUCCESS_MESSAGE_ACCOUNT_DATA_CHANGED');
          void this.router.navigate(['/jednoduche-vystaveni']);

          this.dialogRef.close(false);
        },
        error: (error: HttpError) => {
          this.dialogRef.close(false);
        },
      });
  }

  private loadDataAndInitForm(): void {
    this.userMeService.myData()
      .pipe(
        take(1),
        tap((formData: MyAukroUserDataDto) => this.formData = formData),
        mergeMap(() => {
          const getCountries = this.isPersonalAccount ? this.registryService.getCountries() : this.registryService.getEUCountries();
          return getCountries.pipe(
            take(1),
            tap((countries: RegistryCountryItemDto[]) => {
              this.countries = countries;
            }),
          );
        }),
        mergeMap(() => this.userService.getActualStatistics()),
        finalize(() => this.changeDetectorRef.detectChanges()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((ac) => this.initForm(ac?.registrationDomainCode));
  }

  private initForm(userRegistrationDomain?: DomainCode): void {
    const country = MyDataUtils.getDefaultCountryFormControlValue(
      this.domainService,
      userRegistrationDomain,
      MyDataUtils.getAccountAddress(this.formData)?.country?.code as DomainCode,
    );

    this.personalDataForm = this.formBuilder.group({
      address: this.formBuilder.group({
        address: [MyDataUtils.getAccountAddress(this.formData)?.address,
          [Validators.required, streetValidator]],
        zipCode: [MyDataUtils.getAccountAddress(this.formData)?.zipCode,
          [zipCodeValidatorByPattern('address.country', this.countries)]],
        city: [MyDataUtils.getAccountAddress(this.formData)?.city,
          [Validators.required, cityNameValidator]],
        country: [country, [Validators.required]],
      }),
    });
    this.changeDetectorRef.detectChanges();
  }

  protected onCloseClick(): void {
    this.dialogRef.close(true);
  }

}
