import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { catchError, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SW_UPDATE_KEY } from '@shared/platform/service/platform.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BehaviorSubject, merge, Observable, of } from 'rxjs';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { DateUtils } from '@util/util/date.utils';
import { HttpClient } from '@angular/common/http';
import { VersionFrontendModel } from '@shared/version/model/version-frontend.model';
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { CookieService } from '@common/cookie/service/cookie.service';
import { StringUtils } from '@util/util/string.utils';
import { atLeastXSec } from '@util/rxjs-operators/at-least-x-sec';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * Time, after which should be checked if new version is available (except first check)
 */
const VERSION_CHECK_INTERVAL: number = DateUtils.convertMinutesToMilliseconds(10);
/**
 * Time, after which should be checked if new version is available (only for first check)
 */
const FIRST_TIME_VERSION_CHECK_INTERVAL: number = DateUtils.convertSecondsToMilliseconds(30);
/**
 * Minimum time, after which we can show new version banner (if new version is available) after app is started
 */
const MINIMUM_DELAY_OF_NEW_VERSION_BANNER_SHOW_IN_SECONDS: number = DateUtils.convertMinutesToSeconds(60);
const BROKEN_SW_UNREGISTERED_VERSION_LOG_COOKIE_NAME = 'broken-sw-unregistered-version-log';

@Injectable({
  providedIn: 'root',
})
export class VersionService extends NgUnsubscribe {

  private _isNewVersionAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly platformCommonService: PlatformCommonService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly httpClient: HttpClient,
    private readonly cookieService: CookieService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public init(): void {
    this.initVersionCheck();
    this.logBrokenSwUnregisteredInGtm();
  }

  public get isNewVersionAvailable$(): Observable<boolean> {
    return this._isNewVersionAvailable$.asObservable();
  }

  private get isSwEnabled(): boolean {
    return this.swUpdate.isEnabled;
  }

  private initVersionCheck(): void {
    if (!this.canInitVersionCheck()) {
      return;
    }

    const versionAvailable$ = this.isSwEnabled
      ? this.isNewVersionAvailableSw$()
      : this.isNewVersionAvailableNoSw$();

    atLeastXSec(
      versionAvailable$,
      MINIMUM_DELAY_OF_NEW_VERSION_BANNER_SHOW_IN_SECONDS,
      this.ngZoneUtilService,
    )
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isAvailable) => {
        this._isNewVersionAvailable$.next(isAvailable);
        this.sessionStorageService.setItem(SW_UPDATE_KEY, true);
      });
  }

  private isNewVersionAvailableSw$(): Observable<boolean> {
    const versionReady$ = this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(() => true),
      );

    const versionCheckTimer$ =
      this.ngZoneUtilService.timerOut$(FIRST_TIME_VERSION_CHECK_INTERVAL, VERSION_CHECK_INTERVAL)
        .pipe(
          tap(() => void this.swUpdate.checkForUpdate()),
          map(() => false),
        );

    return merge(versionReady$, versionCheckTimer$)
      .pipe(
        filter((isAvailable: boolean) => isAvailable),
        take(1),
      );
  }

  private isNewVersionAvailableNoSw$(): Observable<boolean> {
    return this.ngZoneUtilService.timerOut$(FIRST_TIME_VERSION_CHECK_INTERVAL, VERSION_CHECK_INTERVAL)
      .pipe(
        // fetch version of frontend from SSR
        mergeMap(() =>
          this.httpClient.get(`/version-frontend`)
            .pipe(
              // catch error must be in inner pipe, otherwise error API call would stop whole observable
              catchError(() => of(null)),
            ),
        ),
        // new version available is when SSR version differs from current version
        map((versionFrontend: VersionFrontendModel | Nil) => {
          // handle nil from catchError
          if (isNil(versionFrontend)) {
            return false;
          }
          return versionFrontend.version !== BuildInfoService.version;
        }),
        filter((isAvailable: boolean) => isAvailable),
        take(1),
      );
  }

  private canInitVersionCheck(): boolean {
    return this.platformCommonService.isBrowser && !PlatformCommonService.isDevMode;
  }

  /**
   * Saves log if broken SW has been unregistered in GTM
   */
  private logBrokenSwUnregisteredInGtm(): void {
    if (!this.platformCommonService.isBrowser) {
      return;
    }

    const brokenSwUnregisteredVersion = this.cookieService.get(BROKEN_SW_UNREGISTERED_VERSION_LOG_COOKIE_NAME);

    if (StringUtils.isEmpty(brokenSwUnregisteredVersion)) {
      return;
    }

    this.loggerService.logMessage(
      'Broken Service Worker has been unregistered by GTM.',
      {
        tags: {
          unregisteredVersion: brokenSwUnregisteredVersion,
        },
        level: 'info',
      },
    );
    this.cookieService.remove(BROKEN_SW_UNREGISTERED_VERSION_LOG_COOKIE_NAME);
  }

}
