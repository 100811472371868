import { ItemCategorySeoDto } from '@api/generated/defs/ItemCategorySeoDto';
import { ConsentStanceBasicDto } from '@api/generated/defs/ConsentStanceBasicDto';
import { ItemSearchFilter } from '@api/generated/defs/ItemSearchFilter';
import { BoughtItemDto } from '@api/generated/defs/BoughtItemDto';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { BillingPaymentProviderEnumPaymentMethodsDto, ItemSearchCategory, MoneyDto, PayByLinkDto } from '@api/generated/model';
import { ItemAttributeFilter } from '@api/generated/defs/ItemAttributeFilter';
import { Params } from '@angular/router';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Lang } from '@shared/platform/lang.const';

export interface ItemSearchCurrentCategory extends ItemCategorySeoDto {
  nonInteractive?: boolean;
  url?: string;
}

export interface ItemSearchCategoryPath extends ItemSearchCategory {
  nonInteractive?: boolean;
  url?: string;
}

export type StartEndTimeType = 'startingAfterRel' | 'endingBeforeRel';

export type ProductDetailActionBlockedEnum =
  'NOT_ALLOWED_TO_BUY'
  | 'BLOCKED_BY_SELLER'
  | 'USER_NOT_VERIFIED_FOR_BUY'
  | 'UNREGISTERED_BUY_NOW_ONLY_AUKRO_PAYMENT';

export const PUSH_NOTIFICATIONS_TOKENDATA_KEY = 'push-notifications-tokendata';

export interface PushNotificationsTokenData {
  token: string;
}

export interface ConsentStancesAdditionalInfo {
  content?: string;
  subject?: string;
}

export interface ConsentStanceBasicWithContent extends ConsentStanceBasicDto, ConsentStancesAdditionalInfo {
}

export interface ConsentStancesSection {
  key: string;
  name: string;
  items: ConsentStanceBasicDto[];
}

export interface ConsentStancesModalClose {
  closed: boolean;
  actualStatistics: UserInterestStatisticsDto;
}

export interface FavouriteItemSearchFilter extends ItemSearchFilter {
  routerLink?: string;
  favouriteSearch?: boolean;
  ignoreSubbrand?: boolean;
}

export interface AddToFavouriteSeller {
  userId: number;
  favouriteSeller: boolean;
}

export interface ItemSearchFilterExtended extends ItemSearchFilter {
  filter?: ItemAttributeFilterExtended[];
  directUrl?: boolean;
  activeAttributeSeoUrl?: string;
  ignoreSubbrand?: boolean;
}

export type OfferType = 'auction' | 'buyNowActive' | 'hotAuction' | 'none';

export interface ItemSearchFilterTag {
  key: string;
  value: string | string[] | MoneyDto;
  suffix: string;
  description: string;
  shouldValueBeVisible?: boolean;
  shouldValueBeEmitted?: boolean;
}

export interface ItemAttributeFilterExtended extends ItemAttributeFilter {
  attributeSeoUrl?: string;
}

export interface ItemTag {
  name: string;
  url: string;
}

export interface SliderChange {
  type: 'update' | 'change';
  value: [number, number];
}

export interface LeftPanelContentSubcategory {
  name: string;
  url?: string;
  href?: string;
  lock?: boolean;
  // Hides subcategory if the items-import is disabled
  hideForFileImportDisabled?: boolean;
  arrow?: boolean;
  queryParams?: Params;
  show?: () => boolean;
}

export interface LeftPanelContentCategory {
  name: string;
  url?: string;
  subcategories?: LeftPanelContentSubcategory[];
  show?: () => boolean;
  expanded?: boolean;
  disabled?: boolean;
  disabledTooltipConfigElement?: string;
}

export enum MyAukroTabs {
  myAccount = 0,
  myPurchases = 1,
  mySales = 2
}

export interface LeftPanelContent {
  tab: MyAukroTabs;
  categories: LeftPanelContentCategory[];
}

export interface MyAukroFilter {
  field: string;
  label?: string;
}

export type MyAukroTransactionType = 'publication' | 'purchases' | 'sold' | 'unsold';

export interface SeoTitleParams {
  userName?: string;
  name?: string;
}

export interface SeoSettings {
  delayedConfigure?: boolean;
  description?: TranslationSource;
  applicationName?: string;
  index?: boolean;
  isListing?: boolean;
  keywords?: string;
  title?: string;
  titleParams?: SeoTitleParams;
  openGraph?: OpenGraphSettings;
  hreflangs?: Record<Lang, string>;
}

export interface OpenGraphImage {
  url: string;
  width: number;
  height: number;
}

export interface OpenGraphSettings {
  type?: string;
  url?: string;
  applicationName?: string;
  title?: string;
  titleParams?: SeoTitleParams;
  image?: OpenGraphImage;
  description?: string;
}

export interface SeoConfigureDelayedComponentType {
  seoConfigureDelayed?: boolean;
}

export interface AnnouncementDisabledComponentType {
  announcementDisabled?: boolean;
}

export interface BoughtItemExtendedDto extends BoughtItemDto {
  unitPrice?: MoneyDto;
  sellerId?: number;
}

export type WysiwygMode = 'html' | 'text' | 'builder';

export interface NumericInputLabelLink {
  link: string;
  label: string;
}

export interface PaymentFromAccountReturnParams {
  iban: string;
  trid: string;
  completed: 'Y' | 'N';
  status?: 'SUCCESS' | 'ERROR';
  variableSymbol?: number;
  specificSymbol?: number;
  amount?: number;
}

/** Marketing mail settings page mode */
export enum MarketingMailSettingsPageModeEnum {
  AUTHENTICATED = 1,
  NOT_AUTHENTICATED
}

export enum PaymentProvider {
  PAYU = 'PAYU',
  PAYS = 'PAYS'
}

export interface SelectedBillingPayment {
  paymentProvider: BillingPaymentProviderEnumPaymentMethodsDto;
  paymentMethod: PayByLinkDto;
}

export interface City {
  name: string;
  slug: string;
  postCode: string; //because of trailing zeros in other countries
  coords: {
    lat: number;
    lon: number;
  };
}

export const PAYS_METHODS: PayByLinkDto[] = [
  {
    value: 'c',
    name: 'Platba kartou online',
    status: 'ENABLED',
  },
  {
    value: 'bt',
    name: 'Běžný bankovní převod',
    status: 'ENABLED',
  }];

export const PAY_VIA_AUKRO_REEXPOSE_ERRORS: { [key: string]: string } =
  {
    'validation.item.reexpose.adding_pay_via_aukro.missing_required_shipping':
      'Pro nastavený druh přepravy nelze použít Platbu přes Aukro. Upravte přepravní metodu v rozšířeném vystavovacím formuláři.',
    'validation.item.reexpose.adding_pay_via_aukro.maximum_delivery_exceed':
      'U této nabídky je nastavena doba dodání více než 10 dní, a proto nemůže využívat Platbu přes Aukro. Upravte dobu dodání v rozšířeném vystavovacím formuláři.',

  };

/**
 * Interface holding all PVA state fields
 */
export interface PvaStateGlobal {
  id: number;
  active: boolean;
  code: string,
  name?: string,
  nameForBuyer?: string,
  nameForSeller?: string,
  tooltipForBuyer?: string,
  tooltipForSeller?: string,
  // In case we have two similiar states we want to show users as one, this field points to key of previous same state
  groupedByWithState?: string
}

/**
 * Interface holding processed PVA state (already holding correct data either for buyer, seller, admin, ...
 */
export interface PvaState {
  id: number;
  code: string,
  name?: string,
  tooltip?: string,
  // Usually holding only self key but in case we have grouped states, we want to show only one state and filter for multiple states
  filterStates?: string[]
}

export interface ItemReportReasonEnumDto {
  code?: string;
  active?: boolean;
  position?: number;
  /** If user can add comment to reason */
  canComment?: boolean;
  /** Name of report reason */
  name?: string;
}

export enum ViewerType {
  BUYER,
  SELLER,
  UNKNOWN
}

/**
 * Const holding separator between pva states that should be filtered by one option for more than one state
 */
export const PVA_STATE_FILTER_SEPARATOR = '|';

