import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { TranslateCompiler, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { StringUtils } from '@util/util/string.utils';

@Injectable({
  providedIn: 'root',
})
export class TranslateSourcePipeService {

  constructor(
    private readonly translateService: TranslateService,
    private readonly translateCompiler: TranslateCompiler,
  ) {
  }

  /**
   * Translates given value into string,
   *
   * If {@link translatePipe} is not set, it uses {@link TranslateService} for translation
   */
  public transform(
    value: TranslationSource,
    translateService: TranslateService = this.translateService,
    translateCompiler: TranslateCompiler = this.translateCompiler,
    translatePipe?: TranslatePipe,
  ): string | Nil {
    // if there's no value return Nil
    if (isNil(value)) {
      return null;
    }

    // if there's userValue don't process it by translation lib and only return it
    if (isNotNil(value.userValue)) {
      return value.userValue;
    }

    if (isNil(value.params)) {
      value.params = {};
    }

    if (value.defaultValue) {
      value.params.defaultValue = value.defaultValue;
    }

    let translatedVal: string | Nil;
    if (!StringUtils.isBlank(value.key)) {
      if (isNil(translatePipe)) {
        translatedVal = translateService.instant(value.key, value.params) as string;
      } else {
        translatedVal = translatePipe.transform(value.key, value.params) as string;
      }
    }

    // return translated value if it's not blank
    if (!StringUtils.isBlank(translatedVal)) {
      return translatedVal;
    }

    // Take text from `defaultValue` and interpolate params
    // Compilation of translation is necessary for ICU support
    if (!isNil(value.defaultValue)) {
      return translateService.getParsedResult(
        { KEY: translateCompiler.compile(value.defaultValue, null) },
        'KEY',
        value.params ?? {},
      ) as string;
    }

    return '';
  }

  /**
   * Does the same as {@link transform} but instead returns observable which emits on every translation change event
   */
  public transform$(
    value: TranslationSource,
  ): Observable<string | Nil> {
    return merge(
      this.translateService.onTranslationChange,
      this.translateService.onLangChange,
      this.translateService.onDefaultLangChange,
    )
      .pipe(
        // skip events from same tick window
        debounceTime(0),
        // always emit first value immediately
        startWith(null),
        map(() => this.transform(value)),
        distinctUntilChanged(),
      );
  }

}
