import { Inject, Injectable, makeStateKey, Renderer2, RendererFactory2, TransferState } from '@angular/core';
import { combineLatest, mergeMap, Observable } from 'rxjs';
import { SubbrandSessionDataService } from '@shared/subbrand/repository/subbrand-session-data.service';
import { distinctUntilChanged, filter, map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import isNil from 'lodash-es/isNil';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { Nil } from '@util/helper-types/nil';
import moment from 'moment-mini-ts';
import { NavigationService } from '@shared/navigation/service/navigation.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { CookieService } from '@common/cookie/service/cookie.service';
import { NavigationExtras, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { DomainService } from '@shared/domain/service/domain.service';
import { UsersApiService } from '@api/aukro-api/api/users-api.service';

/**
 * Cookie acts as feature flag for unlogged users
 */
const SUBBRANDS_UNLOGGED_ENABLED = 'subbrands-unlogged-enabled';
const SUBBRANDS_COOKIE = 'subbrand';
const SUBBRANDS_QUERY_PARAM = 'subbrand';
const SUBBRAND_TRANSFER_STATE_KEY = 'ssr-subbrand';
const DATA_SUBBRAND_ATTRIBUTE = 'data-subbrand';

@Injectable({
  providedIn: 'root',
})
export class SubbrandService extends NgUnsubscribe {

  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly subbrandSessionDataService: SubbrandSessionDataService,
    private readonly transferState: TransferState,
    private readonly cookieService: CookieService,
    private readonly userService: UserService,
    private readonly authenticationService: AuthenticationService,
    private readonly usersApiService: UsersApiService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly navigationService: NavigationService,
    private readonly router: Router,
    private readonly domainService: DomainService,
    protected readonly rendererFactory: RendererFactory2,
  ) {
    super();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Called via APP_INITIALIZER.
   */
  public initSessionSubbrandListener(): void {
    this.initSessionValue();

    // Actions performed on logging in or app startup
    this.initSessionSubbrandOnLoginOrStartup();
    this.updateLastSubbrandOnLoginOrStartup();

    // Actions performed on logging in
    this.syncFeatureFlagsOnLogin();

    // Actions performed only on app startup
    this.toggleCookieFeatureFlagOnAppStartup();

    // Set subbrand data attribute
    this.setDataSubbrandAttribute();
  }

  /**
   * Initializes session subbrand synchronously. Looks up query parameter, transfer state and cookie value.
   * Session subbrand can be undefined.
   */
  private initSessionValue(): void {
    // TODO(PDEV-24199) Delete subbrand FF
    this.setSubbrandsCookie(true);

    let sessionSubbrand: SubbrandType;

    // Query param subbrand has highest priority
    sessionSubbrand = this.navigationService.getAppQueryParamForInitializer(SUBBRANDS_QUERY_PARAM)?.toUpperCase() as SubbrandType;

    // Try to get session subbrand from SSR via transfer state
    if (isNil(sessionSubbrand) && this.platformCommonService.isBrowser) {
      sessionSubbrand = this.transferState.get(makeStateKey<SubbrandType>(SUBBRAND_TRANSFER_STATE_KEY), null);
    }

    // Otherwise use cookie value
    if (isNil(sessionSubbrand)) {
      sessionSubbrand = this.getSubbrandCookieValue();
    }

    if (!isNil(sessionSubbrand)) {
      this.setSessionSubbrand(sessionSubbrand);
    }
  }

  /**
   * Listen on user login action and sets session value from user preferences in case no cookie exists and user
   * with last subbrand value defined logs in.
   */
  private initSessionSubbrandOnLoginOrStartup(): void {
    this.userHasLoggedOrAppStartupAsLogged$()
      .pipe(
        filter(() => isNil(this.subbrandSessionDataService.sessionSubbrand)),
        mergeMap(() => this.userService.getActualStatistics(true)),
        filter((ac) => ac.subbrandEnabled === true),
        map((actualStatistics: UserInterestStatisticsDto) => this.getSubbrandCookieValue() ?? actualStatistics.lastSubbrand),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((subbrand: SubbrandType) => this.setSessionSubbrand(subbrand));
  }

  /**
   * Updates user preferences last subbrand value according to session subbrand when necessary via dedicated endpoint.
   */
  private updateLastSubbrandOnLoginOrStartup(): void {
    this.userHasLoggedOrAppStartupAsLogged$()
      .pipe(
        mergeMap(() => this.userService.getActualStatistics()),
        map((actualStatistics: UserInterestStatisticsDto) => [
          actualStatistics.lastSubbrand,
          this.subbrandSessionDataService.sessionSubbrand,
        ]),
        filter(([userPreferenceSubbrand, sessionSubbrand]) => !isNil(sessionSubbrand) &&
          userPreferenceSubbrand !== sessionSubbrand),
        mergeMap(([_, sessionSubbrand]) =>
          this.usersApiService.updateUserPreferenceSubbrand$({ updateUserPreferenceSubbrandDto: {
            subbrand: sessionSubbrand,
          },
          })),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * Enables cookie FF according to preferences FF and vice versa on logging in.
   */
  private syncFeatureFlagsOnLogin(): void {
    this.userHasLogged$()
      .pipe(
        mergeMap(() => this.userService.getActualStatistics()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((stats) => {
        const cookieFF = this.getCookieFFValue();
        if (cookieFF && stats.subbrandEnabled === false) {
          this.setSubbrandsUserPreferenceFeatureFlag(true);
          return;
        }

        if (!cookieFF && stats?.subbrandEnabled === true) {
          this.setSubbrandsCookieFeatureFlag(true);
        }
      });
  }

  /**
   * Disable cookie FF on logged user app startup if user has preference FF false.
   */
  private toggleCookieFeatureFlagOnAppStartup(): void {
    combineLatest([
      this.appStartupAsLogged$(),
      this.userPreferenceFeatureFlag$(),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([_, userPreferenceFF]) => {
        if (userPreferenceFF !== this.getCookieFFValue()) {
          this.setSubbrandsCookieFeatureFlag(userPreferenceFF);
        }
        // Logged user should not store last subbrand cookie
        if (userPreferenceFF === false && !isNil(this.getSubbrandCookieValue())) {
          this.setSessionSubbrand(null);
        }
      });
  }

  private userHasLoggedOrAppStartupAsLogged$(): Observable<boolean> {
    return this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(filter((loggedIn) => loggedIn === true));
  }

  private userHasLogged$(): Observable<boolean> {
    return this.authenticationService.getLoginStatusChange()
      .pipe(filter((loggedIn) => loggedIn === true));
  }

  private appStartupAsLogged$(): Observable<boolean> {
    return this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        take(1),
        filter((loggedIn) => loggedIn === true),
      );
  }

  /**
   * @returns current session subbrand (if session subbrand is Nil, returns 'NOT_SPECIFIED'
   */
  public get sessionSubbrand$(): Observable<SubbrandType> {
    return this.subbrandSessionDataService.sessionSubbrand$
      .pipe(
        map<SubbrandType, SubbrandType>((sessionSubbrand: SubbrandType) =>
          isNil(sessionSubbrand) ? 'NOT_SPECIFIED' : sessionSubbrand,
        ),
        distinctUntilChanged(),
      );
  }

  public get shouldSpecifySubbrand$(): Observable<boolean> {
    return this.sessionSubbrand$
      .pipe(
        map((subbrand) => subbrand === 'NOT_SPECIFIED'),
        distinctUntilChanged(),
      );
  }

  /**
   * Returns correct logo from subbrand and non subbrand users
   */
  public get sessionSubbrandLogoLocalized$(): Observable<string> {
    return this.sessionSubbrand$
      .pipe(
        mergeMap((subbrand) =>
          this.domainService.currentDomainConfig$
            .pipe(
              map((currentDomainConfig) => currentDomainConfig.subbrandLogosMap[subbrand]),
            ),
        ),
      );
  }

  /**
   * Emits on distinct change of session subbrand. Does not emit on first change (init)
   */
  public sessionSubbrandChanged$(): Observable<SubbrandType | Nil> {
    return this.subbrandSessionDataService.sessionSubbrand$
      .pipe(
        map((subbrand) => isNil(subbrand) ? null : subbrand),
        distinctUntilChanged(),
        skip(1),
      );
  }

  public setSessionSubbrandWithHomepageRedirect(subbrand: SubbrandType): void {
    this.setSessionSubbrandWithRedirect(subbrand, ['/']);
  }

  public setSessionSubbrandWithRedirect(subbrand: SubbrandType, url: string[], extras?: NavigationExtras): void {
    this.setSessionSubbrand(subbrand);
    void this.router.navigate(url, extras);
  }

  private userPreferenceFeatureFlag$(): Observable<boolean> {
    return this.userService.getActualStatistics()
      .pipe(map((ac: UserInterestStatisticsDto) => ac?.subbrandEnabled ?? false));
  }

  /**
   * Toggle users preference feature flag
   */
  private setSubbrandsUserPreferenceFeatureFlag(enabled: boolean): void {
    this.usersApiService.enableUserSubbrandFeatureFlag$({ subbrandsEnabled: enabled })
      .pipe(
        mergeMap(() => this.userService.getActualStatistics(true)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private setSubbrandsCookieFeatureFlag(enabled: boolean): void {
    if (this.getCookieFFValue() !== enabled) {
      this.setSubbrandsCookie(enabled);
    }
  }

  private setSubbrandsCookie(enabled: boolean): void {
    this.cookieService.put(SUBBRANDS_UNLOGGED_ENABLED, String(enabled), { expires: moment().add(365, 'days').toDate() });
  }

  public setSessionSubbrand(subbrand: SubbrandType | Nil): void {
    if (subbrand === this.subbrandSessionDataService.sessionSubbrand) {
      return;
    }

    this.subbrandSessionDataService.sessionSubbrand = subbrand;

    // On browser, store session subbrand to cookies
    if (!this.platformCommonService.isServer && this.getSubbrandCookieValue() !== subbrand) {
      this.saveSubbrandCookie(subbrand);
      return;
    }

    // On server, store session subbrand to transfer state to hand it over to browser
    if (this.platformCommonService.isServer && !isNil(subbrand)) {
      this.transferState.set(makeStateKey<SubbrandType>(SUBBRAND_TRANSFER_STATE_KEY), subbrand);
    }
  }

  private getSubbrandCookieValue(): SubbrandType {
    return this.cookieService.get(SUBBRANDS_COOKIE) as SubbrandType;
  }

  private getCookieFFValue(): boolean {
    return this.cookieService.get(SUBBRANDS_UNLOGGED_ENABLED) === 'true';
  }

  private saveSubbrandCookie(subbrand: SubbrandType): void {
    this.cookieService.put(SUBBRANDS_COOKIE, subbrand, { expires: moment().add(365, 'days').toDate() });
  }

  /**
   * Initializes the `data-subbrand` attribute on the document element based on the current subbrand cookie value
   * Updates the attribute in real-time whenever the subbrand changes
   */
  private setDataSubbrandAttribute(): void {
    this.sessionSubbrandChanged$()
      .pipe(
        startWith(this.cookieService.get(SUBBRANDS_COOKIE)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((subbrand: SubbrandType) => {
        if (isNotNil(subbrand)) {
          this.renderer.setAttribute(this.document.documentElement, DATA_SUBBRAND_ATTRIBUTE, subbrand.toLowerCase());
        } else {
          this.renderer.removeAttribute(this.document.documentElement, DATA_SUBBRAND_ATTRIBUTE);
        }
      });
  }

}
