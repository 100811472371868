import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { actualStatisticsCacheBuster$ } from '../constant/cache-busters';
import { Observable, of } from 'rxjs';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { CacheService } from '@common/cache/service/cache.service';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UsersApiService } from '@api/aukro-api/api/users-api.service';

@Injectable({
  providedIn: 'root',
})
export class UserActualStatisticsService extends NgUnsubscribe implements CacheAware {

  constructor(
    public readonly cacheService: CacheService,
    private readonly usersApiService: UsersApiService,
    private readonly authCacheService: AuthCacheService,
  ) {
    super();
  }

  public loadActualStatistics(): Observable<UserInterestStatisticsDto> {
    if (!this.authCacheService.isLoggedIn()) {
      return of(null);
    }
    return this.freshOrCachedActualStatistics();
  }

  public loadActualStatisticsIgnoringCache(): Observable<UserInterestStatisticsDto> {
    actualStatisticsCacheBuster$.next();
    return this.loadActualStatistics();
  }

  @Cacheable({
    cacheBuster$: actualStatisticsCacheBuster$,
    key: 'UserService#loadActualStatistics',
  })
  private freshOrCachedActualStatistics(): Observable<UserInterestStatisticsDto> {
    return this.usersApiService.getUserInterestStatistics$();
  }

}
