/**
 * This file is dynamically generated before angular build/serve inside prerun script
 *
 * NOTE: If you want to run some other domain on localhost (SK, AT, etc…) change appropriate values in this file
 *
 * As example, if you want to run SK on localhost - find and change values
 * of these keys HOST_FRONTEND_SK and HOST_INT_FRONTEND_SK to 'localhost'
 * ```
 *   HOST_FRONTEND_SK: 'localhost'
 *   HOST_INT_FRONTEND_SK: 'localhost'
 * ```
 */
import { Environment } from './model/environment.model';

export const environment: Environment = {
  SENTRY_DSN: '__SENTRY_DSN__',
  SENTRY_DSN_SSR: '__SENTRY_DSN_SSR__',
  YUSP_SRC_1: '__YUSP_SRC_1__',
  YUSP_SRC_2: '__YUSP_SRC_2__',
  YUSP_1: '__YUSP_1__',
  YUSP_2: '__YUSP_2__',
  CAPTCHA_SITE_KEY: '__CAPTCHA_SITE_KEY__',
  MOBILE_UPDATE_VERSION_ANDROID: '__MOBILE_UPDATE_VERSION_ANDROID__',
  MOBILE_UPDATE_VERSION_IOS: '__MOBILE_UPDATE_VERSION_IOS__',
  MOBAPP_TEST_IDENTIFIER: '__MOBAPP_TEST_IDENTIFIER__',
  GMC_GSV_AT: '__GMC_GSV_AT__',
  GA4_ID_AT: '__GA4_ID_AT__',
  GTM_AUTH_AT: '__GTM_AUTH_AT__',
  GTM_ID_AT: '__GTM_ID_AT__',
  GTM_PREVIEW_AT: '__GTM_PREVIEW_AT__',
  HOST_FRONTEND_AT: '__HOST_FRONTEND_AT__',
  HOST_INT_FRONTEND_AT: '__HOST_INT_FRONTEND_AT__',
  GMC_GSV_BE: '__GMC_GSV_BE__',
  GA4_ID_BE: '__GA4_ID_BE__',
  GTM_AUTH_BE: '__GTM_AUTH_BE__',
  GTM_ID_BE: '__GTM_ID_BE__',
  GTM_PREVIEW_BE: '__GTM_PREVIEW_BE__',
  HOST_FRONTEND_BE: '__HOST_FRONTEND_BE__',
  HOST_INT_FRONTEND_BE: '__HOST_INT_FRONTEND_BE__',
  GMC_GSV_BG: '__GMC_GSV_BG__',
  GA4_ID_BG: '__GA4_ID_BG__',
  GTM_AUTH_BG: '__GTM_AUTH_BG__',
  GTM_ID_BG: '__GTM_ID_BG__',
  GTM_PREVIEW_BG: '__GTM_PREVIEW_BG__',
  HOST_FRONTEND_BG: '__HOST_FRONTEND_BG__',
  HOST_INT_FRONTEND_BG: '__HOST_INT_FRONTEND_BG__',
  GMC_GSV_CZ: '__GMC_GSV_CZ__',
  GA4_ID_CZ: '__GA4_ID_CZ__',
  GTM_AUTH_CZ: '__GTM_AUTH_CZ__',
  GTM_ID_CZ: '__GTM_ID_CZ__',
  GTM_PREVIEW_CZ: '__GTM_PREVIEW_CZ__',
  HOST_FRONTEND_CZ: '__HOST_FRONTEND_CZ__',
  HOST_INT_FRONTEND_CZ: '__HOST_INT_FRONTEND_CZ__',
  GMC_GSV_DE: '__GMC_GSV_DE__',
  GA4_ID_DE: '__GA4_ID_DE__',
  GTM_AUTH_DE: '__GTM_AUTH_DE__',
  GTM_ID_DE: '__GTM_ID_DE__',
  GTM_PREVIEW_DE: '__GTM_PREVIEW_DE__',
  HOST_FRONTEND_DE: '__HOST_FRONTEND_DE__',
  HOST_INT_FRONTEND_DE: '__HOST_INT_FRONTEND_DE__',
  GMC_GSV_FR: '__GMC_GSV_FR__',
  GA4_ID_FR: '__GA4_ID_FR__',
  GTM_AUTH_FR: '__GTM_AUTH_FR__',
  GTM_ID_FR: '__GTM_ID_FR__',
  GTM_PREVIEW_FR: '__GTM_PREVIEW_FR__',
  HOST_FRONTEND_FR: '__HOST_FRONTEND_FR__',
  HOST_INT_FRONTEND_FR: '__HOST_INT_FRONTEND_FR__',
  GMC_GSV_GR: '__GMC_GSV_GR__',
  GA4_ID_GR: '__GA4_ID_GR__',
  GTM_AUTH_GR: '__GTM_AUTH_GR__',
  GTM_ID_GR: '__GTM_ID_GR__',
  GTM_PREVIEW_GR: '__GTM_PREVIEW_GR__',
  HOST_FRONTEND_GR: '__HOST_FRONTEND_GR__',
  HOST_INT_FRONTEND_GR: '__HOST_INT_FRONTEND_GR__',
  GMC_GSV_HR: '__GMC_GSV_HR__',
  GA4_ID_HR: '__GA4_ID_HR__',
  GTM_AUTH_HR: '__GTM_AUTH_HR__',
  GTM_ID_HR: '__GTM_ID_HR__',
  GTM_PREVIEW_HR: '__GTM_PREVIEW_HR__',
  HOST_FRONTEND_HR: '__HOST_FRONTEND_HR__',
  HOST_INT_FRONTEND_HR: '__HOST_INT_FRONTEND_HR__',
  GMC_GSV_HU: '__GMC_GSV_HU__',
  GA4_ID_HU: '__GA4_ID_HU__',
  GTM_AUTH_HU: '__GTM_AUTH_HU__',
  GTM_ID_HU: '__GTM_ID_HU__',
  GTM_PREVIEW_HU: '__GTM_PREVIEW_HU__',
  HOST_FRONTEND_HU: '__HOST_FRONTEND_HU__',
  HOST_INT_FRONTEND_HU: '__HOST_INT_FRONTEND_HU__',
  GMC_GSV_IT: '__GMC_GSV_IT__',
  GA4_ID_IT: '__GA4_ID_IT__',
  GTM_AUTH_IT: '__GTM_AUTH_IT__',
  GTM_ID_IT: '__GTM_ID_IT__',
  GTM_PREVIEW_IT: '__GTM_PREVIEW_IT__',
  HOST_FRONTEND_IT: '__HOST_FRONTEND_IT__',
  HOST_INT_FRONTEND_IT: '__HOST_INT_FRONTEND_IT__',
  GMC_GSV_NL: '__GMC_GSV_NL__',
  GA4_ID_NL: '__GA4_ID_NL__',
  GTM_AUTH_NL: '__GTM_AUTH_NL__',
  GTM_ID_NL: '__GTM_ID_NL__',
  GTM_PREVIEW_NL: '__GTM_PREVIEW_NL__',
  HOST_FRONTEND_NL: '__HOST_FRONTEND_NL__',
  HOST_INT_FRONTEND_NL: '__HOST_INT_FRONTEND_NL__',
  GMC_GSV_PL: '__GMC_GSV_PL__',
  GA4_ID_PL: '__GA4_ID_PL__',
  GTM_AUTH_PL: '__GTM_AUTH_PL__',
  GTM_ID_PL: '__GTM_ID_PL__',
  GTM_PREVIEW_PL: '__GTM_PREVIEW_PL__',
  HOST_FRONTEND_PL: '__HOST_FRONTEND_PL__',
  HOST_INT_FRONTEND_PL: '__HOST_INT_FRONTEND_PL__',
  GMC_GSV_RO: '__GMC_GSV_RO__',
  GA4_ID_RO: '__GA4_ID_RO__',
  GTM_AUTH_RO: '__GTM_AUTH_RO__',
  GTM_ID_RO: '__GTM_ID_RO__',
  GTM_PREVIEW_RO: '__GTM_PREVIEW_RO__',
  HOST_FRONTEND_RO: '__HOST_FRONTEND_RO__',
  HOST_INT_FRONTEND_RO: '__HOST_INT_FRONTEND_RO__',
  GMC_GSV_SI: '__GMC_GSV_SI__',
  GA4_ID_SI: '__GA4_ID_SI__',
  GTM_AUTH_SI: '__GTM_AUTH_SI__',
  GTM_ID_SI: '__GTM_ID_SI__',
  GTM_PREVIEW_SI: '__GTM_PREVIEW_SI__',
  HOST_FRONTEND_SI: '__HOST_FRONTEND_SI__',
  HOST_INT_FRONTEND_SI: '__HOST_INT_FRONTEND_SI__',
  GMC_GSV_SK: '__GMC_GSV_SK__',
  GA4_ID_SK: '__GA4_ID_SK__',
  GTM_AUTH_SK: '__GTM_AUTH_SK__',
  GTM_ID_SK: '__GTM_ID_SK__',
  GTM_PREVIEW_SK: '__GTM_PREVIEW_SK__',
  HOST_FRONTEND_SK: '__HOST_FRONTEND_SK__',
  HOST_INT_FRONTEND_SK: '__HOST_INT_FRONTEND_SK__',
  APP_HOST: '__APP_HOST__',
  APP_BACKOFFICEFRONTEND_HOST: '__APP_BACKOFFICEFRONTEND_HOST__',
  API_DEFINITIONS: {"BACKEND_WEB":{"name":"aukro-api","url":"__API_URL__","docs_url":null,"dir":"src/app/api/aukro-api"},"TRANSLATIONS":{"name":"aukro-translations-api","url":"__API_URL__","docs_url":null,"dir":"src/app/api/aukro-translations-api"}},
  API_URL: '__API_URL__',
  WS_URL: '__WS_URL__',
  ONESIGNAL_KEY: '__ONESIGNAL_KEY__',
  ENVIRONMENT: '__ENVIRONMENT__',
  FACEBOOK_APP_ID: '__FACEBOOK_APP_ID__',
};
