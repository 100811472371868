import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { Injectable } from '@angular/core';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { Router } from '@angular/router';
import { NumberUtils } from '@util/util/number.utils';
import { PlatformService } from '@shared/platform/service/platform.service';
import { DomainRedirectService } from '@shared/domain-redirect-module/domain-redirect.service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepPasswordOrThirdPartyResolutionHandlerService
  extends UserTaskLoginStepResolutionHandlerService<'PASSWORD_OR_THIRD_PARTY'> {

  constructor(
    private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly domainRedirectService: DomainRedirectService,
  ) {
    super();
  }

  public handle(resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'PASSWORD_OR_THIRD_PARTY'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>): UserTaskStepUnionModel<'LOGIN'> {

    /**
     * Safari + IOS fix to offer "save password" in password manager, which opens only after url has changed
     */
    if (resolution.type === 'RESOLUTION_SUCCESS' && (this.platformService.isIos || this.platformService.isSafari)) {
      void this.router.navigate([], {
        fragment: `login${ NumberUtils.getRandomNumber(1, 10000) }`,
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
    }

    if (resolution.type === 'RESOLUTION_FB_LOAD') {
      return {
        type: 'FB_LOAD',
        payload: {
          ...taskPayload?.stepsText?.FB_LOAD,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_LOGIN_EMAIL') {
      return {
        type: 'LOGIN_EMAIL',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.LOGIN_EMAIL,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_FORGOT_PASSWORD') {
      void this.router.navigate(['/zapomenute-heslo']);
      throw new Error('User failed to login');
    }

    if (resolution.type === 'RESOLUTION_REDIRECT') {
      this.domainRedirectService.crossDomainRegistrationNotAllowedRedirect(
        resolution.payload.isRegistration,
        resolution.payload.redirectDomain,
      );
    }

    if (resolution.type === 'RESOLUTION_PASSWORD_CHANGE_REQUIRED') {
      throw new Error('User password change is required');
    }
  }

}
