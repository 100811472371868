import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipeService } from '@shared/currency/service/currency-pipe.service';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
// TODO: [PDEV-18307] - fix dependency
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { MoneyUtils } from '@shared/currency/util/money.utils';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { DomainCurrencyService } from '@shared/currency/service/domain-currency.service';
import { Nil } from '@util/helper-types/nil';
import { BasePipe } from '@util/base-class/base-pipe.class';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * Format given value to money format. Can perform currency exchange if needed. Works with currency user specified currency.
 * Usage:
 *
 * Value is formatted as is with input money dto currency. Money exchange is not performed.
 *
 * {{ item.totalPrice | currency }}
 *
 *
 *
 * Value is formatted with currency specified by pipe parameter.
 * If parameter currency differs from input money dto currency, money exchange is performed with warning.
 *
 * {{ item.totalPrice | currency: 'CZK' }}
 *
 *
 *
 * The same as above without warning.
 *
 * {{ item.totalPrice | currency: 'CZK':false }}
 *
 */
@Pipe({
  name: 'currency',
  pure: false,
  standalone: true,
})
export class CurrencyPipe extends BasePipe implements PipeTransform {

  private currentValue: MoneyDto;
  private currentTargetCode: CurrencyCodeType;
  private approximationWarn: boolean = false;

  private transformedValue: string | Nil;

  constructor(
    private readonly currencyPipeService: CurrencyPipeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainCurrencyService: DomainCurrencyService,
    private readonly loggerService: LoggerService,
  ) {
    super();

    // Listen on currency/preferences/etc. changes and transform if differs
    this.currencyPipeService.currencySourceChanged$()
      .pipe(
        mergeMap(() => this.transformValues$()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public transform(
    value: MoneyDto,
    targetCurrencyCode?: CurrencyCodeType,
    approximationWarn: boolean = true,
  ): string | Nil {

    if (isNil(value)) {
      return null;
    }

    // fallback to CZK
    if (typeof value === 'number') {
      const currentDomainCurr = this.domainCurrencyService.currentDomainCurrency;

      this.loggerService.logMessage(
        `CurrencyPipe#transform :: value of type number has been passed, mapping to default currency (CZK)`,
        {
          level: 'error',
          fingerprint: ['CURRENCY_PIPE_NUMBER_VALUE_PASSED'],
          extra: {
            value,
            currentDomainCurr,
          },
        },
      );

      value = MoneyUtils.of(value, 'CZK');
    }

    if (!MoneyUtils.moneyEquals(this.currentValue, value) || this.currentTargetCode !== targetCurrencyCode) {

      this.currentValue = value;
      this.currentTargetCode = targetCurrencyCode;
      this.approximationWarn = approximationWarn;

      this.transformValues$()
        .pipe(
          take(1),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }

    return this.transformedValue;
  }

  private transformValues$(): Observable<string> {
    return this.currencyPipeService.transform(this.currentValue, this.currentTargetCode, this.approximationWarn)
      .pipe(
        tap((transformedValue) => {
          this.transformedValue = transformedValue;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
      );
  }

}
