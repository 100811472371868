import { Injectable } from '@angular/core';
import { BaseDomainConfig } from '@shared/domain/module/domain-config/domain/base-domain-config';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { CountryCodeEnum } from '@shared/domain/model/country-code.enum';
import { BANK_ACCOUNT_PATTERN } from '@shared/validators/patterns';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';

@Injectable({
  providedIn: 'root',
})
export class DomainConfigCzService extends BaseDomainConfig {

  private readonly czechIbanNumberPattern = /^(CZ[0-9]{2}[0-9A-Z]{2,30})$/;

  public override getConfig(): DomainConfigModel {
    return {
      countryCode: CountryCodeEnum.CZECH_REPUBLIC,
      phonePrefix: '420',
      currencyConfig: {
        currencyCode: 'CZK',
        currencyNumberType: 'INTEGER',
        selectableCurrencyCodes: ['CZK', 'EUR'],
        minOnlinePayment: 3,
        currencySuffix: 'Kč',
      },
      canSelectCountry: true,
      subbrandLogosMap: {
        BAZAAR: 'logo',
        ANTIQUE: 'logo-antique',
        NOT_SPECIFIED: 'logo',
      },
      canFixZipCode: true,
      showDialogForAddressChange: false,
      isShippingTemplatesPageAllowed: true,
      showMpLink: true,
      billBalanceDomesticPaymentAllowed: true,
      vatConfig: {
        requiredTaxNumber: false,
      },
      allowedToChangeCurrency: true,
      canShowVerifyForBuyAction: true,
      canShowVerifyBankAccountAction: true,
      showContinueButtonOnCartShippingAndPaymentPage: true,
      explicitLangInItemDetailDescription: false,
      isYuspAllowed: true,
      metaTagRobotIndexFollowAlways: true,
      differentiateBetweenCompanyNamesInCompanyForm: true,
      zipPattern: new RegExp([/\d/, /\d/, /\d/, ' ', /\d/, /\d/].join('|'), 'gi'),
      zipMask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/],
      exposeConfig: {
        fastExposeAllowed: true,
        simpleExposeAllowed: true,
        extendedExposeAllowed: true,
      },
      countryIconId: 'czech-republic',
      countryCircleIconId: 'czech-republic-circle',
      countryName: 'COUNTRY_NAME_CZ',
      vatPattern: /^(([A-Z]{2}\d{8,10}))$/,
      getCurrencyByBankAccountNumberOrIban: (bankAccountNumber): CurrencyCodeType => {
        if (BANK_ACCOUNT_PATTERN.test(bankAccountNumber) || this.czechIbanNumberPattern.test(bankAccountNumber)) {
          return 'CZK';
        } else {
          return 'EUR';
        }
      },
      userAccountVerificationWay: 'BANK_TRANSFER',
      bankButtonsAllowed: true,
      urlParamCity: 'mesto',
    };
  }

}
