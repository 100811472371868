import { APP_INITIALIZER, FactoryProvider, Injector, Type } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { DomainService } from '../service/domain.service';
import { Observable, from, of, switchMap, take, forkJoin } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';

export function createDomainDependentInitializers(
  ...initializers: Type<AppInitializer>[]
): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (domainService: DomainService, injector: Injector) =>
      (): Observable<unknown> => domainService.domainInitialized$
        .pipe(
          take(1),
          switchMap(() => {
            const asyncInitializers =
              initializers
                .reduce((acc, initializer) => {
                  const serviceInitReturnValue = injector.get(initializer).init();

                  // handle if init method returns void
                  if (isNil(serviceInitReturnValue)) {
                    return acc;
                  }

                  acc.push(from(serviceInitReturnValue as Observable<unknown> | Promise<unknown>));

                  return acc;
                }, [] as (Observable<unknown> | Promise<unknown>)[]);

            return ArrayUtils.isEmpty(asyncInitializers) ? of(void 0) : forkJoin(asyncInitializers);
          }),
        ),
    deps: [DomainService, Injector],
  };
}
