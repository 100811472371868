import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { DomainService } from '@shared/domain/service/domain.service';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { validatePattern } from '@shared/validators/validators';
import { DomainCode } from '@shared/domain/model/domain-code.type';

@Injectable({
  providedIn: 'root',
})
export class VatService {

  constructor(
    private readonly domainService: DomainService,
  ) {
  }

  /**
   * @param countryControlPath - path for country control
   * @param extractDomainCodeFromCountryValueFn - fn which should return domain code from given country control value
   *   if not set it will use country control value as domain code
   */
  public vatValidator<T>(
    countryControlPath: string,
    extractDomainCodeFromCountryValueFn?: (countryControlValue: T) => DomainCode,
  ): (abstractControl: AbstractControl) => (Nil | ValidationErrors) {

    return (abstractControl: AbstractControl) => {
      const { value } = abstractControl;
      const countryControl: AbstractControl<T> = abstractControl.root.get(countryControlPath);

      if (isNil(value) || isNil(countryControl?.value)) {
        return null;
      }

      const domainCode =
        extractDomainCodeFromCountryValueFn?.(countryControl.value) ?? countryControl.value as DomainCode;

      const vatPattern = this.domainService.getDomainConfig(domainCode).vatPattern;

      return validatePattern(vatPattern, 0, 20, 'vat', abstractControl, false);
    };
  }

}
