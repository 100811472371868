import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { map, take } from 'rxjs/operators';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { CurrencyConfigElementEnumModel } from '../model/currency-config-element-enum.model';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { CurrencyUtil } from '@shared/currency/util/currency.util';
import { RegistryApiService } from '@api/aukro-api/api/registry-api.service';
import { RegistrySupportedCountryItemDto } from '@api/aukro-api/model/registry-supported-country-item-dto';
import uniq from 'lodash-es/uniq';
import { CurrencyConfigFeModel } from '@shared/currency/model/currency-config-fe.model';
import { CurrencyConfigModel } from '@shared/currency/model/currency-config.model';
import isNil from 'lodash-es/isNil';
import { DomainCurrencyService } from '@shared/currency/service/domain-currency.service';
import { Nil } from '@util/helper-types/nil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CurrencyModelMapPartial } from '../model/currency-model-map-partial.type';
import { LoggerService } from '@common/logger/service/logger.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends NgUnsubscribe implements CacheAware {

  // [ADD_DOMAIN]
  // TODO: [PDEV-] - this config should be completely moved into CURRENCY_ENUM CE and made async
  private readonly currenciesMap: Record<CurrencyCodeType, CurrencyConfigFeModel> = {
    CZK: {
      roundToWholeNumber: true,
      showApproximation: false,
      minOfferPriceCheckDelivery: 200,
    },
    EUR: {
      roundToWholeNumber: false,
      showApproximation: true,
      minOfferPriceCheckDelivery: 8,
    },
    BGN: {
      roundToWholeNumber: false,
      showApproximation: true,
      minOfferPriceCheckDelivery: 8,
    },
    HUF: {
      roundToWholeNumber: false,
      showApproximation: true,
      minOfferPriceCheckDelivery: 8,
    },
    PLN: {
      roundToWholeNumber: false,
      showApproximation: true,
      minOfferPriceCheckDelivery: 8,
    },
    RON: {
      roundToWholeNumber: false,
      showApproximation: true,
      minOfferPriceCheckDelivery: 8,
    },
  };

  constructor(
    public readonly cacheService: CacheService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly registryApiService: RegistryApiService,
    private readonly domainCurrencyService: DomainCurrencyService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  @Cacheable('CurrencyService#getCurrencies$')
  public getCurrencies$(): Observable<CurrencyConfigElementEnumModel[]> {
    return forkJoin([
      this.configuratorCacheService.enums<CurrencyConfigElementEnumModel>('CURRENCY_ENUM'),
      this.getSupportedCountriesCurrencyCodes$(),
    ])
      .pipe(
        take(1),
        // Return only active currencies that belong to at least one currently supported country
        map(([currencyEnum, supportedCurrencies]) =>
          currencyEnum?.content?.filter(currency => currency.active && supportedCurrencies.includes(currency.code))),
      );
  }

  @Cacheable('CurrencyService#getCurrenciesMap$')
  public getCurrenciesMap$(): Observable<CurrencyModelMap> {
    return this.getCurrencies$()
      .pipe(
        map((currenciesArr: CurrencyConfigElementEnumModel[]) =>
          CurrencyUtil.currenciesListToMap(currenciesArr, this.currenciesMap)),
      );
  }

  @Cacheable('CurrencyService#getSelectableCurrenciesMap$')
  public getSelectableCurrenciesMap$(): Observable<CurrencyModelMapPartial> {
    return combineLatest([
      this.getCurrenciesMap$(),
      this.domainCurrencyService.currentDomainCurrencyConfig$
        .pipe(
          map(config => config.selectableCurrencyCodes),
        ),
    ])
      .pipe(
        map(([currenciesMap, selectableCurrencyCodes]) => {
          if (!selectableCurrencyCodes) {
            return currenciesMap;
          }
          const filteredCurrenciesMap: CurrencyModelMapPartial = {};
          selectableCurrencyCodes.forEach(code => {
            if (currenciesMap[code]) {
              filteredCurrenciesMap[code] = currenciesMap[code];
            }
          });
          return filteredCurrenciesMap;
        }),
      );
  }

  public getCurrencyConfigByCode(
    currenciesMap: CurrencyModelMap,
    currencyCode: CurrencyCodeType,
  ): CurrencyConfigModel {
    return this.getCurrencyConfig(currenciesMap, currencyCode);
  }

  /**
   * @deprecated Please use {@link getCurrencyConfigByCode$} instead
   */
  public getCurrencyConfigFeByCode(currencyCode: CurrencyCodeType): CurrencyConfigFeModel {
    return this.getCurrencyConfig(this.currenciesMap, currencyCode);
  }

  public getCurrencyCodeWithDomainDefaultFallback(currencyCode: CurrencyCodeType | Nil): CurrencyCodeType {
    return currencyCode ?? this.domainCurrencyService.currentDomainCurrency;
  }

  public getCurrencyConfigByCode$(currencyCode: CurrencyCodeType): Observable<CurrencyConfigModel> {
    return this.getCurrenciesMap$()
      .pipe(
        map((currenciesMap) =>
          this.getCurrencyConfig(currenciesMap, currencyCode)),
      );
  }

  private getCurrencyConfig<T extends Record<CurrencyCodeType, CurrencyConfigFeModel | CurrencyConfigModel>, C extends CurrencyCodeType>(
    currenciesConfigMap: T,
    currencyCode: C,
  ): T[C] {
    const currencyConfig = currenciesConfigMap[currencyCode];

    if (isNil(currencyConfig)) {
      const currentDomainCurr = this.domainCurrencyService.currentDomainCurrency;

      this.loggerService.logMessage(
        `CurrencyService#getCurrencyConfig :: currency config missing for given currencyCode `
        + `(using current domain currency as fallback)`,
        {
          level: 'error',
          fingerprint: ['CURRENCY_SERVICE_CURRENCY_CONFIG_MISSING'],
          extra: {
            currencyCode,
            currentDomainCurr,
          },
        },
      );

      return currenciesConfigMap[currentDomainCurr];
    }

    return currencyConfig;
  }

  private getSupportedCountriesCurrencyCodes$(): Observable<CurrencyCodeType[]> {
    return this.registryApiService.getSupportedCountries$()
      .pipe(
        map((supportedCountries: RegistrySupportedCountryItemDto[]) => supportedCountries.flatMap(country => country.currencyCode)),
        map((currencyCodes: CurrencyCodeType[]) => uniq(currencyCodes)),
      );
  }

}
