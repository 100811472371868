/**
 * Unit maps for key from API to German strings including plurals
 */
export const UNIT_CODE_MAPPINGS_DE = {
  PAIRS: {
    '=0': 'Paare',
    '=1': 'Paar',
    '=2': 'Paare',
    '=3': 'Paare',
    '=4': 'Paare',
    other: 'Paare',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'Paare',
    '=1': 'Paar',
    other: 'Paare',
  },
  PIECES: {
    '=0': 'Stücke',
    '=1': 'Stück',
    '=2': 'Stücke',
    '=3': 'Stücke',
    '=4': 'Stücke',
    other: 'Stücke',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'Stücke',
    '=1': 'Stück',
    other: 'Stücke',
  },
  PIECE_SHORT: {
    other: 'Stk.',
  },
  SET: {
    '=0': 'Sets',
    '=1': 'Set',
    '=2': 'Sets',
    '=3': 'Sets',
    '=4': 'Sets',
    other: 'Sets',
  },
  SET_ACCUSATIVE: {
    '=0': 'Sets',
    '=1': 'Set',
    other: 'Sets',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'bietet',
    '=2': 'bieten',
    '=3': 'bieten',
    '=4': 'bieten',
    other: 'bietet',
  },
  BET_PAST: {
    '=1': 'bot',
    '=2': 'boten',
    '=3': 'boten',
    '=4': 'boten',
    other: 'bot',
  },
  ITEM: {
    other: 'Artikel',
  },
  DAY: {
    '=0': 'Tage',
    '=1': 'Tag',
    '=2': 'Tage',
    '=3': 'Tage',
    '=4': 'Tage',
    other: 'Tage',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'Tag',
    other: 'Tagen',
  },
  HOUR: {
    '=0': 'Stunden',
    '=1': 'Stunde',
    '=2': 'Stunden',
    '=3': 'Stunden',
    '=4': 'Stunden',
    other: 'Stunden',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'Stunde',
    other: 'Stunden',
  },
  MINUTE: {
    '=0': 'Minuten',
    '=1': 'Minute',
    '=2': 'Minuten',
    '=3': 'Minuten',
    '=4': 'Minuten',
    other: 'Minuten',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'Minute',
    other: 'Minuten',
  },
  OTHER: {
    '=0': 'andere',
    '=1': 'anderer',
    '=2': 'andere',
    '=3': 'andere',
    '=4': 'andere',
    other: 'andere',
  },
  FILE: {
    '=0': 'Dateien',
    '=1': 'Datei',
    '=2': 'Dateien',
    '=3': 'Dateien',
    '=4': 'Dateien',
    other: 'Dateien',
  },
  IMAGE: {
    '=0': 'Bilder',
    '=1': 'Bild',
    '=2': 'Bilder',
    '=3': 'Bilder',
    '=4': 'Bilder',
    other: 'Bilder',
  },
  OFFER: {
    '=0': 'Angebote',
    '=1': 'Angebot',
    '=2': 'Angebote',
    '=3': 'Angebote',
    '=4': 'Angebote',
    other: 'Angebote',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'Angebote',
    '=1': 'Angebot',
    '=2': 'Angebote',
    '=3': 'Angebote',
    '=4': 'Angebote',
    other: 'Angebote',
  },
  POSITIVE_COMMENT: {
    '=0': 'positive Bewertungen',
    '=1': 'positive Bewertung',
    '=2': 'positive Bewertungen',
    '=3': 'positive Bewertungen',
    '=4': 'positive Bewertungen',
    other: 'positive Bewertungen',
  },
  USER_GENITIV: {
    '=0': 'Benutzer',
    '=1': 'Benutzers',
    '=2': 'Benutzer',
    '=3': 'Benutzer',
    '=4': 'Benutzer',
    other: 'Benutzer',
  },
  USER_INSTRUMENTAL: {
    '=0': 'Benutzer',
    '=1': 'Benutzer',
    other: 'Benutzern',
  },
  ADDED_PASSIVE: {
    other: 'hinzugefügt',
  },
};
