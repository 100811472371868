import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { DomainService } from '@shared/domain/service/domain.service';
import isEmpty from 'lodash-es/isEmpty';
import findKey from 'lodash-es/findKey';
import { LocaleUtil } from '@shared/locale/util/locale.util';
import { AukroMobileTab } from '@shared/legacy/component/aukro-tabs/aukro-tabs.interface';
import { Lang } from './lang.const';
import { isNotNil } from '@util/helper-functions/is-not-nil';

@Injectable({
  providedIn: 'root',
})
export class UrlService {

  private readonly routesTranslationListForCurrentLang: Observable<Record<string, string>>;

  private readonly FALLBACK_LANG: Lang = 'cs';

  private readonly ROUTES_BY_LANG_MAP: Record<Lang, Promise<unknown>> = {
    cs: import('../../../assets/i18n/cs-routes.json'),
    sk: import('../../../assets/i18n/sk-routes.json'),
    de: import('../../../assets/i18n/de-routes.json'),
    hu: import('../../../assets/i18n/hu-routes.json'),
    pl: import('../../../assets/i18n/pl-routes.json'),
    ro: import('../../../assets/i18n/ro-routes.json'),
    it: import('../../../assets/i18n/it-routes.json'),
    fr: import('../../../assets/i18n/fr-routes.json'),
    el: import('../../../assets/i18n/el-routes.json'),
    nl: import('../../../assets/i18n/nl-routes.json'),
    hr: import('../../../assets/i18n/hr-routes.json'),
    sl: import('../../../assets/i18n/sl-routes.json'),
    bg: import('../../../assets/i18n/bg-routes.json'),
  };

  constructor(
    private readonly domainService: DomainService,
  ) {
    const lang: Lang = domainService.lang ?? this.FALLBACK_LANG;
    this.routesTranslationListForCurrentLang = this.createObservableFromImportPromise$(this.ROUTES_BY_LANG_MAP[lang]);
  }

  /**
   * Returns List of Translated URLs as Observable
   */
  public translateUrlTabs(tabsLocal: AukroMobileTab[]): Observable<AukroMobileTab[]> {
    const localURLs = tabsLocal.map(tab => tab.url);
    const translateUrlList = localURLs.map((localURL) => this.translateUrl(localURL));
    return forkJoin(translateUrlList)
      .pipe(
        take(1),
        map((translatedURLs) => tabsLocal.map((tab, index) => ({ ...tab, translatedUrl: translatedURLs[index] }))),
      );
  }

  /**
   * Returns prepared data for translating or reversed translating url
   */
  private prepareUrlData(localURL: string): { subPaths: string[]; queryParams: string } {
    // Split URL to path and query param
    const urlSplit = localURL.split('?').filter(subPath => subPath);
    const justPaths = urlSplit[0];

    // If available, prepare queryParams for alter append
    const queryParams = urlSplit[1] ? '?' + urlSplit[1] : '';

    // Split URL to parts so we can translate just parts
    const subPaths = justPaths.split('/').filter(subPath => subPath);

    return { subPaths, queryParams };
  }

  /**
   * Returns Translated URL as Observable every part of URL is localized separately
   */
  public translateUrl(localURL: string): Observable<string> {
    const { subPaths, queryParams } = this.prepareUrlData(localURL);

    if (isEmpty(subPaths)) {
      return of(localURL);
    } else {
      return this.routesTranslationListForCurrentLang
        .pipe(
          take(1),
          switchMap((routesTranslationList: Record<string, string>) => {
            if (isNotNil(routesTranslationList)) {
              return of(routesTranslationList);
            } else {
              return this.createObservableFromImportPromise$(this.ROUTES_BY_LANG_MAP[this.FALLBACK_LANG]);
            }
          }),
          map((routesTranslationList: Record<string, string>) => {
            //Translate every part of URL one by one from routesTranslationList (JSON file with translation data)
            const translatedSubPaths = subPaths.map((subPath) =>
              routesTranslationList[`${ LocaleUtil.ROUTES_PREFIX }${ subPath.replace(/-/g, '_').toUpperCase() }`] || subPath);
            return `/${ translatedSubPaths.join('/') }${ queryParams }`;
          }),
        );
    }
  }

  /**
   * Returns Reversed Translated URL as Observable every part of URL is taken from key separately
   * @param localURL localized URL
   * @param explicitLang explicit language from which route should be translated (if not present, current language is used)
   */
  public reverseTranslateUrl(localURL: string, explicitLang?: Lang): Observable<string> {
    const { subPaths, queryParams } = this.prepareUrlData(localURL);

    const lang: Lang = explicitLang ?? this.domainService.lang;

    return this.createObservableFromImportPromise$(this.ROUTES_BY_LANG_MAP[lang])
      .pipe(
        take(1),
        map((routesTranslationList: Record<string, string>) => {
          //Find keys of translated part of URL so we can reverse translate back to CS (cs translation is key)
          const reversedSubPathsUrl = subPaths.map((subPath) => {
            const translationKey = findKey(routesTranslationList, (translatedTitle) => subPath === translatedTitle);
            if (!isNil(translationKey)) {
              return translationKey.replace(LocaleUtil.ROUTES_PREFIX, '').replace(/_/g, '-').toLowerCase();
            } else {
              return subPath;
            }
          });
          return `/${ reversedSubPathsUrl.join('/') }${ queryParams }`;
        }),
      );
  }

  private createObservableFromImportPromise$(importPromise: Promise<unknown>): Observable<Record<string, string>> {
    return from(importPromise as Promise<Record<string, string>>);
  }

}
