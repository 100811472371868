import { Injectable } from '@angular/core';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { Observable } from 'rxjs';

/**
 * Service for verifying user bank account
 */
@Injectable({
  providedIn: 'root',
})
export class BankAccountVerificationService extends BaseDestroy {

  constructor(
    private readonly userTaskService: UserTaskService,
  ) {
    super();
  }

  public openBankAccountVerificationDialog$(
    source: string,
    userAction?: UserActionType,
    bankAccountNumberToVerify?: string,
  ): Observable<void> {
    return this.userTaskService.executeTaskWithCommonAndCustomPayload$(
      'VERIFIED_BANK_ACCOUNT',
      { action: `universal run from source: ${ source }` },
      { source, bankAccountNumberToVerify },
      userAction,
    );
  }

}
