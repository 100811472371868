import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from '@shared/avatar/avatar.module';
import { UserAccountTypeBadgeComponent } from '@shared/user-chip/components/user-account-type-badge/user-account-type-badge.component';
import { RouterModule } from '@angular/router';
import { Translate2Module } from '@common/translations/translate2.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { UserProfileAdminActionsComponent } from '@shared/user-chip/components/user-profile-admin-actions/user-profile-admin-actions.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { UnitPluralizeModule } from '@common/ui-kit/common/unit-pluralize/unit-pluralize.module';
import { StarIconComponent } from '@common/ui-kit/component/star-icon/star-icon.component';
import { MediumDateTimePipe } from '@common/date-time/pipe/medium-date-time.pipe';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import isNil from 'lodash-es/isNil';
import { UserAccountTypeChipComponent } from '@common/ui-kit/component/user-account-type-chip/user-account-type-chip.component';
import { UserChipType } from '@shared/user-chip/model/user-chip-type';
import { Nil } from '@util/helper-types/nil';
import { UserChip } from '@shared/user-chip/model/user-chip';
import { StopPropagationOnClickDirective } from '@shared/legacy/directive/stop-propagation/stop-propagation-on-click.directive';
import { ChipComponent } from '@common/ui-kit/component/chip/component/chip.component';
import { ChipModel } from '@common/ui-kit/component/chip/model/chip.model';
import { RouterLinkTargetDirective } from '@common/routing/directive/router-link-target.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';
import { AccessibilityDirective } from '@common/accessibility/directive/accessibility.directive';
import { IconWithTextComponent } from '@common/ui-kit/component/icon-with-text/component/icon-with-text.component';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { UserChipSmallDto } from '@api/aukro-api/model/user-chip-small-dto';
import { DomainService } from '@shared/domain/service/domain.service';
import { NewUserProfileAllDto } from '@api/aukro-api/model/new-user-profile-all-dto';
import { DomainCode } from '@shared/domain/model/domain-code.type';

@Component({
  selector: 'auk-user-chip',
  templateUrl: './user-chip.component.html',
  styleUrls: ['./user-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AvatarModule,
    UserAccountTypeBadgeComponent,
    RouterModule,
    Translate2Module,
    MatTooltipModule,
    SvgIconComponent,
    UserAccountTypeChipComponent,
    UserProfileAdminActionsComponent,
    IconComponent,
    UnitPluralizeModule,
    StarIconComponent,
    MediumDateTimePipe,
    StopPropagationOnClickDirective,
    ChipComponent,
    RouterLinkTargetDirective,
    TestIdentificationDirective,
    AccessibilityDirective,
    IconWithTextComponent,
  ],
})
export class UserChipComponent implements OnChanges {

  @Input() public chipType: UserChipType;
  @Input() public hasUserReadPrivilege: boolean = false;
  @Input() public userInfo: UserChip & NewUserProfileAllDto & UserChipSmallDto;
  @Input() public isCurrentlyLoggedUser: boolean = false;
  @Output() public avatarDialogClose: EventEmitter<void> = new EventEmitter<void>();
  public chipTypeClass: string | Nil = null;

  constructor(private readonly domainService: DomainService) {
  }

  public ngOnChanges(changes: AukSimpleChanges<UserChipComponent>): void {
    if (changes.chipType || isNil(this.chipTypeClass)) {
      this.chipTypeClass = UserChipComponent.getChipTypeClass(this.chipType);
    }
  }

  protected onUserAvatarChange(): void {
    this.avatarDialogClose.emit();
  }

  private static getChipTypeClass(chipType: UserChipType): string {
    return `${ chipType.toLowerCase() }-chip`;
  }

  protected get hasRating(): boolean {
    const userChipSmallDtoInstance = this.userInfo as UserChipSmallDto;
    return userChipSmallDtoInstance?.starType && (userChipSmallDtoInstance?.feedbackUniqueUserCount > 0);
  }

  protected get isBlocked(): boolean {
    return this.userInfo?.accountBlockType === 'BLOCK';
  }

  protected get itemRouterLink(): string[] {
    return ['/uzivatel', this.userInfo?.showName, 'nabidky'];
  }

  protected get ratingRouterLink(): string[] {
    return ['/uzivatel', this.userInfo?.showName];
  }

  protected get aukroTopSellerInfo(): ChipModel {
    return {
      type: 'TEXT',
      icon: {
        source: 'aukro-crown',
        type: 'SVG',
        colorCombination: 'PRIMARY',
      },
      colorCombination: 'PRIMARY',
      label: {
        key: 'AUKRO_TOP_SELLER',
      },
      tooltip: {
        key: 'TOP_SELLER_ICON_TOOLTIP',
      },
    };
  }

  protected iconModel(domain: DomainCode): IconModel {
    return {
      type: 'SVG',
      source: this.domainService.getFlagByDomain(domain),
      size: 'MD',
    };
  }

}
