import { UserActionType } from '@shared/user-action/model/user-action.type';
import { ActionViewAnalyticsDataModel } from '../model/action-view-analytics-data.model';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

/**
 * Responsible for resolving a result of the specific view
 */
export abstract class BaseViewResolver<T> extends BaseDestroy {

  public abstract resolve(
    action: UserActionType,
    analyticsData: ActionViewAnalyticsDataModel,
    viewResult?: T,
  ): void;

}
