import { DomainService } from '@shared/domain/service/domain.service';
import { Injectable } from '@angular/core';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { mergeMap, Observable, of } from 'rxjs';
import { DomainCurrencyConfigModel } from '@shared/domain/module/domain-config/model/domain-currency-config.model';
import { map } from 'rxjs/operators';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { UserService } from '@shared/user/service/user.service';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';

@Injectable({
  providedIn: 'root',
})
export class DomainCurrencyService {

  constructor(
    private readonly domainService: DomainService,
    private readonly userService: UserService,
  ) {
  }

  public get currentDomainCurrencyConfig$(): Observable<DomainCurrencyConfigModel> {
    return this.domainService.currentDomainConfig$
      .pipe(
        map((config) => config.currencyConfig),
      );
  }

  public getDomainCurrencyConfig$(domainCode: DomainCode): Observable<DomainCurrencyConfigModel> {
    return this.domainService.getDomainConfig$(domainCode)
      .pipe(
        map((config) => config.currencyConfig),
      );
  }

  /**
   * @returns Currency config model for current user, if user is not logged in, returns {@link Nil}
   */
  public getUserRegDomainCurrencyConfig$(): Observable<DomainCurrencyConfigModel | Nil> {
    return this.userService.getActualStatistics()
      .pipe(
        mergeMap((userStatistics) =>
          isNotNil(userStatistics)
            ? this.domainService.getDomainConfig$(userStatistics.registrationDomainCode)
              .pipe(
                map((config) => config.currencyConfig),
              )
            : of(null),
        ),
      );
  }

  /**
   * @deprecated please use {@link getUserRegDomainCurrencyConfig$} instead
   */
  public getUserRegDomainCurrencyConfig(userStatistics: UserInterestStatisticsDto): DomainCurrencyConfigModel {
    return this.domainService.getDomainConfig(userStatistics.registrationDomainCode)?.currencyConfig;
  }

  /**
   * @deprecated please use {@link currentDomainCurrencyConfig$} instead
   */
  public get currentDomainCurrencyConfig(): DomainCurrencyConfigModel {
    return this.domainService.currentDomainConfig.currencyConfig;
  }

  public get currentDomainCurrency$(): Observable<CurrencyCodeType> {
    return this.currentDomainCurrencyConfig$
      .pipe(
        map((config) => config.currencyCode),
      );
  }

  /**
   * @deprecated please use {@link currentDomainCurrency$} instead
   */
  public get currentDomainCurrency(): CurrencyCodeType {
    return this.currentDomainCurrencyConfig.currencyCode;
  }

}
