import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DomainService } from '@shared/domain/service/domain.service';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { ZipMaskModel } from '@shared/zip/model/zip-mask.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { conformToMask } from 'text-mask-core';
import { Observable } from 'rxjs';
import { TextMaskModel } from '@common/text-mask/model/text-mask.model';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Nil } from '@util/helper-types/nil';
import { textMaskValidator } from '@shared/validators/validators';

@Injectable({
  providedIn: 'root',
})
export class ZipCodeService {

  constructor(
    private readonly domainService: DomainService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
  }

  /**
   * conforms string value to this mask for given domain, and returns this string.
   * @returns conformed string value
   *
   * if {@link domainCode} is not set, current domain code will be used
   */
  public updateZipCodeMaskString(
    value: string,
    domainCode: DomainCode = this.domainService.domain,
  ): string {
    value = value || '';
    const zipCodeTextMask: ZipMaskModel = this.domainService.getDomainConfig(domainCode).zipMask;

    if (isNotNil(zipCodeTextMask)) {
      return conformToMask(value, zipCodeTextMask, { guide: false }).conformedValue as string;
    } else {
      return value.replace(/_/g, '').trim(); // remove mask manually
    }
  }

  /**
   * Conforms current FormControl value to mask for given domain code, and returns this mask.
   * @returns zip mask for given domain code (if zip zipMask is not needed, returns false)
   *
   * if {@link domainCode} is not set, current domain code will be used
   */
  public updateZipCodeMask(
    control: AbstractControl<string | Nil>,
    timeoutDestroy$: Observable<void>,
    domainCode: DomainCode = this.domainService.domain,
  ): TextMaskModel {
    const zipCodeTextMask: ZipMaskModel = this.domainService.getDomainConfig(domainCode).zipMask;

    const oldVal: string = control.value;
    if (oldVal) {
      if (isNotNil(zipCodeTextMask)) {
        const newVal = conformToMask(oldVal, zipCodeTextMask, { guide: false }).conformedValue as string;
        control.setValue(newVal);
      } else {
        // update value after mask updating
        this.ngZoneUtilService.simpleTimerOut$(
          () => {
            const newVal = oldVal.replace(/_/g, '').trim(); // remove mask manually
            control.setValue(newVal);
          },
          timeoutDestroy$,
        );
      }
    }

    return zipCodeTextMask ?? false;
  }

  public getZipCodeValidator(
    controlPath: string,
    domainCode: DomainCode = this.domainService.domain,
  ): ValidatorFn {
    return textMaskValidator(this.domainService.getDomainConfig(domainCode).zipMask, controlPath);
  }

}
