import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import isNil from 'lodash-es/isNil';
// eslint-disable-next-line import/no-restricted-paths
import { LocaleUtil } from '@shared/locale/util/locale.util';
// eslint-disable-next-line import/no-restricted-paths
import { TranslationCacheService } from '@shared/services/translation-cache/translation-cache.service';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * Handling missing translation in specific key - return empty string into target element
 */
export class MissingTranslationsHandler implements MissingTranslationHandler {

  constructor(
    private readonly translationCacheService: TranslationCacheService,
    private readonly loggerService: LoggerService,
  ) {
  }

  public handle(params: MissingTranslationHandlerParams): string {
    let defaultValue: string = '';
    if (!isNil(params.interpolateParams)) {
      defaultValue = params.interpolateParams['defaultValue'] as string;
    }
    if (params.key.startsWith(LocaleUtil.ROUTES_PREFIX)) {
      defaultValue = params.key;
    }

    if (!defaultValue && this.translationCacheService.translationsLoaded && !this.translationCacheService.hasTranslation(params.key)) {
      const title = 'Configurator: Missing translation - ' + params.key;
      this.loggerService.logException(
        new Error(title),
        {
          fingerprint: [title],
          tags: { group: 'configurator-translation-missing' },
          extra: {
            countOfTranslations: this.translationCacheService.translationsCount(),
            translations: this.translationCacheService.translationsContent(),
          },
        },
      );
    }
    return defaultValue ? defaultValue : '';
  }

}
