import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { mergeMap, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActionViewService } from '../service/action-view.service';
import isNil from 'lodash-es/isNil';
import { SuggestedViewDto } from '../model/suggested-view-dto';
import { ActionViewType } from '../model/action-view-type';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { IGNORE_SUGGESTED_VIEW } from '../token/ignore-suggested-view-http-context.token';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * Opens an appropriate view if HTTP response contains a valid `x-aukro-suggested-view` header
 */
@Injectable()
export class ActionViewInterceptor implements HttpInterceptor {

  private readonly SUGGESTED_VIEW_HEADER_NAME: string = 'x-aukro-suggested-view';

  constructor(
    private readonly userActionViewService: ActionViewService,
    private readonly loggerService: LoggerService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(req)
      .pipe(
        mergeMap((request: HttpRequest<unknown>) => next.handle(request)
          .pipe(
            tap((httpEvent: HttpEvent<unknown>) => {
              if (!(httpEvent instanceof HttpResponse)) {
                return;
              }

              const userAction: UserActionType = req.context.get(USER_ACTION_TYPE);
              const ignoreSuggestedView: boolean = req.context.get(IGNORE_SUGGESTED_VIEW);
              const suggestedViewHeader: string = httpEvent.headers.get(this.SUGGESTED_VIEW_HEADER_NAME);

              if (isNil(userAction) || isNil(suggestedViewHeader) || ignoreSuggestedView) {
                return;
              }

              let suggestedViewDto: SuggestedViewDto<ActionViewType> = null;

              try {
                suggestedViewDto = JSON.parse(suggestedViewHeader) as SuggestedViewDto<ActionViewType>;
              } catch (e) {
                const errorMessage: string = 'Error while parsing suggested view response header.';
                console.error(errorMessage, e);
                this.loggerService.logException(errorMessage, { extra: { error: e } });
              }

              if (suggestedViewDto != null) {
                this.userActionViewService.openView(suggestedViewDto, userAction);
              }

            }),
          ),
        ),
      );
  }

}
