import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { Injectable } from '@angular/core';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { DomainRedirectService } from '@shared/domain-redirect-module/domain-redirect.service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepFacebookAuthorizeFailedResolutionHandlerService
  extends UserTaskLoginStepResolutionHandlerService<'FACEBOOK_AUTHORIZE_FAILED'> {

  constructor(
    private readonly domainRedirectService: DomainRedirectService,
  ) {
    super();
  }

  public handle(
    resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'FACEBOOK_AUTHORIZE_FAILED'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>,
  ): UserTaskStepUnionModel<'LOGIN'> {
    if (resolution.type === 'RESOLUTION_LOGIN_EMAIL') {
      return {
        type: 'LOGIN_EMAIL',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.LOGIN_EMAIL,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_CANCEL') {
      throw new Error('User failed to login via Facebook');
    }

    if (resolution.type === 'RESOLUTION_REDIRECT') {
      this.domainRedirectService.crossDomainRegistrationNotAllowedRedirect(
        resolution.payload.isRegistration,
        resolution.payload.redirectDomain,
      );
    }
  }

}
