import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NavigationStart, Params, Router, UrlTree } from '@angular/router';
import intersection from 'lodash-es/intersection';
import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';
import isNil from 'lodash-es/isNil';
import { combineLatest, combineLatestWith, iif, Observable, of, Subject, switchMap } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, startWith, take, takeUntil } from 'rxjs/operators';
import { ClickEventDto } from '@api/generated/defs/ClickEventDto';
import {
  ItemCategoryDto,
  ItemSearchFilter,
  ItemSearchPagedResources,
  ShowEventDto,
  SuggestAllRequest,
  SuggestAllResponse,
  UserShowNameDto,
} from '@api/generated/model';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { ListingType } from '@shared/listing/model/listing-type.enum';
import { ListingTypeService } from '@shared/listing/service/listing-type.service';
import { ListingService } from '@shared/listing/service/listing.service';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { JsonLdService } from '@shared/legacy/component/json-ld/service/json-ld.service';
import { UserProfileService } from '@shared/user/service/user-profile.service';
import { UserService } from '@shared/user/service/user.service';
import { KeyboardUtil } from '@util/util/keyboard.util';
import { CategoryItemComponent } from '@shared/app-header/module/app-header-search/component/category-item/category-item.component';
import { AppHeaderSearchService } from '../../service/app-header-search.service';
import { SuggestionItemComponent } from '@shared/app-header/module/app-header-search/component/suggestion-item/suggestion-item.component';
import { SuggestionUserComponent } from '@shared/app-header/module/app-header-search/component/suggestion-user/suggestion-user.component';
import { stripHtml } from '@util/util/method/strip-html.util';
import { trimSpaces } from '@util/util/method/trim-spaces.util';
import { ProductUtil } from '@shared/util/product/product.util';
import { ItemCategoryService } from '@shared/item-categories/service/item-category.service';
import { PopularSearchesDto } from '@api/generated/defs/PopularSearchesDto';
import { UrlUtils } from '@util/util/url.utils';
import { PopularSearchMeasurementService } from '@shared/app-header/module/app-header-search/service/popular-search-measurement.service';
import { SuggestionService } from '@api/generated/api/Suggestion';
import { PopularSearchDataDto } from '@api/generated/defs/PopularSearchDataDto';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { SubbrandUtil } from '@shared/subbrand/util/subbrand.util';
import { NavigationService } from '@shared/navigation/service/navigation.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { SearchCategoryModel } from '@shared/app-header/module/app-header-search/model/search-category.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { MEASUREMENT_SUGGEST_SPLIT_GROUP_KEY } from '@shared/services/personalization/personalization-measurement.helper';
import { APP_CONSTANTS } from '@app-constants';
import { Nil } from '@util/helper-types/nil';
import { AppHeaderMeasurementServiceService } from '@shared/app-header/service/app-header-measurement-service.service';
import { ArrayUtils } from '@util/util/array.utils';
import { SuggestAllResponseContentElementCategory } from '@api/generated/defs/SuggestAllResponseContentElementCategory';
import { SuggestAllResponseContent } from '@api/generated/defs/SuggestAllResponseContent';
import { SuggestAllResponseContentElementItem } from '@api/generated/defs/SuggestAllResponseContentElementItem';
import { SuggestAllWithUsersResponse } from '@api/generated/defs/SuggestAllWithUsersResponse';
import { BackToTopService } from '@shared/services/back-to-top/back-to-top.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { SuggestAllResponseContentElementLandingPage } from '@api/generated/defs/SuggestAllResponseContentElementLandingPage';
import { LandingPageItemComponent } from '@shared/app-header/module/app-header-search/component/landing-page-item/landing-page-item.component';
import { SuggestPersonalizationMeasurementService } from '@shared/services/personalization/suggest-personalization-measurement.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DialogDropdownDirective } from '@common/dialog/directive/dialog-dropdown.directive';
import { DialogUtil } from '@common/dialog/utils/dialog.util';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { CategoryItemSuggestParams } from '@api/generated/defs/category-item-suggest.params';
import { ItemSearchFilterExtended } from '../../../../../../../typings/original/internal';
import { LoggerService } from '@common/logger/service/logger.service';

const SEARCH_DROPDOWN_DIALOG: DialogModel<AukDialogType> = DialogUtil.of('NAVIGATION', 'search-dropdown');
const SUBBRAND_EXCLUSIVE_DEFAULT: boolean = false;

@Component({
  selector: 'auk-app-header-search',
  templateUrl: './app-header-search.component.html',
  styleUrls: ['./app-header-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderSearchComponent extends NgUnsubscribe implements OnInit, AfterViewInit {

  @Output() public searchDropdownToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public clickAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() public openSearch: EventEmitter<void> = new EventEmitter<void>();

  @Input() public openExpanded: boolean = false;
  @Input() public dummySearchInput: ElementRef<HTMLInputElement>;

  @ViewChild('searchDropdown') private readonly searchDropdownDirective: DialogDropdownDirective;

  @ViewChildren(LandingPageItemComponent) private readonly landingPageItems: QueryList<LandingPageItemComponent>;
  @ViewChildren(CategoryItemComponent) private readonly categoryItems: QueryList<CategoryItemComponent>;
  @ViewChildren(SuggestionItemComponent) private readonly offerItems: QueryList<SuggestionItemComponent>;
  @ViewChildren(SuggestionUserComponent) private readonly userItems: QueryList<SuggestionUserComponent>;

  protected isMdAndLower: boolean = false;
  protected compactSearchRef: ElementRef<HTMLInputElement>;
  protected searchResultLandingPages: SuggestAllResponseContentElementLandingPage[];
  protected searchResultCategories: SuggestAllResponseContentElementCategory[];
  protected searchResultItems: SuggestAllResponseContentElementItem[];
  protected searchResultUsers: UserShowNameDto[];
  protected searchResultItemsTotal: number;
  // Selected search category
  protected searchCategory: SearchCategoryModel | Nil;
  protected searchCategories: SearchCategoryModel[];
  protected popularSearchData: PopularSearchDataDto;
  protected searchData: ItemSearchFilterExtended = {
    text: '',
    categoryId: null,
    finished: false,
    specialFlagBasicStartingPrice: false,
    specialFlagEndingSoon: false,
    hotAuction: false,
    searchAll: true,
  };
  protected closeDropdownOnTouchOutside: boolean = this.responsivenessService.isLgAndLower;
  protected searchAlwaysInDescription: boolean = false;

  private readonly suggestionsTrigger: Subject<void> = new Subject<void>();
  private readonly suggestionsMeasurementTrigger: Subject<void> = new Subject<void>();
  private readonly popularSearchMeasurementTrigger: Subject<void> = new Subject<void>();
  private readonly SEARCH_MIN_LENGTH: number = 2;

  private focusTarget: number = null;
  private originalTopSearchLevelCategories: SearchCategoryModel[];
  private skipNextSuggestion: boolean; // skip displaying of suggestion list when form is already submitted
  private lastSearchParams: ItemSearchFilter;

  private lastSuggestedLandingPagesShowId: number;
  private lastSuggestedCategoriesShowId: number;
  private lastSuggestedItemsShowId: number;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly userService: UserService,
    private readonly userProfileService: UserProfileService,
    private readonly router: Router,
    private readonly listingService: ListingService,
    private readonly listingTypeService: ListingTypeService,
    private readonly headerSearchService: AppHeaderSearchService,
    private readonly navigationService: NavigationService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly jsonLdService: JsonLdService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
    private readonly suggestPersonalizationMeasurementService: SuggestPersonalizationMeasurementService,
    private readonly popularSearchMeasurementService: PopularSearchMeasurementService,
    private readonly itemCategoryService: ItemCategoryService,
    private readonly suggestionService: SuggestionService,
    private readonly subbrandService: SubbrandService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly appHeaderMeasurementServiceService: AppHeaderMeasurementServiceService,
    private readonly backToTopService: BackToTopService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly loggerService: LoggerService,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
    super();

    this.searchCategory = this.headerSearchService.getAllSearchPreference();
    this.subbrandService.sessionSubbrandChanged$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.searchCategory = this.headerSearchService.getAllSearchPreference();
        this.changeDetectorRef.markForCheck();
      });

    this.configuratorCacheService.getFeSystemParam<boolean>(
      'FEATURE_FLAG_ALWAYS_SEARCH_IN_DESCRIPTION',
      'BOOLEAN',
    )
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((searchAlwaysInDescription: boolean) => {
        this.searchAlwaysInDescription = searchAlwaysInDescription ?? false;
      });

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngAfterViewInit(): void {
    if (this.openExpanded) {
      this.openSuggestionsDropdown();
      this.searchInputNativeElement?.focus();
    }
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.jsonLdService.remove('SUGGESTIONS'));

    this.suggestionsTrigger
      .pipe(
        debounceTime(250),
        map(() => {
          if (!this.skipNextSuggestion) {
            if (this.searchAlwaysInDescription) {
              this.searchData.searchAll = true;
            }
            return this.searchData;
          } else {
            this.clearSearchItems();
            return null;
          }
        }),
        filter((searchData: ItemSearchFilter) => !isNil(searchData)),
        switchMap(() => {
          if (this.isUserSearch && this.searchData.text) {
            return this.getUserSuggestions$(this.searchData);
          }
          return this.getOfferSuggestions$(this.suggestAllRequest);
        }),
        filter((res: SuggestAllWithUsersResponse | Nil) => !isNil(res)),
        map((res: SuggestAllWithUsersResponse) => {
          if (this.skipNextSuggestion) {
            return;
          }
          this.resetFocusedItems();
          this.focusTarget = null;

          const isItemsFound = res.content?.some((content) => content?.type === 'ITEM' && ArrayUtils.isNotEmpty(content?.elements));
          const isUsersFound = ArrayUtils.isNotEmpty(res.userSuggestions);
          if (isItemsFound || isUsersFound) {
            if (!this.isMdAndLower) {
              this.openSuggestionsDropdown();
            }
          } else {
            this.clearSearchItems();
          }
          if (res.userSuggestions) {
            this.searchResultUsers = res.userSuggestions;
            return;
          }
          this.jsonLdService.configureSuggestions(res, this.searchData, this.searchCategories, this.specialFlagSelected());
          this.handleResult(res);
        }),
        catchError((e) => { // skip logic when http error
          throw e;
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.changeDetectorRef.markForCheck());

    this.initSuggestionsMeasurement();
    this.initPopularSearchMeasurementTrigger();

    this.authService.getLoginStatusChange()
      .pipe(
        startWith(null),
        mergeMap(() => this.userService.getActualStatistics()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.changeDetectorRef.markForCheck());

    this.listingService.getSearchFilter$()
      .pipe(
        combineLatestWith(this.subbrandService.sessionSubbrand$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([searchParams, subbrand]) => {
        this.searchData.text = searchParams && searchParams.text || '';
        // Add subbrand to search data to reflect it in url
        if (isNotNil(subbrand)) {
          this.searchData.subbrand = subbrand;
        }
        this.searchData.subbrandExclusive = searchParams?.subbrandExclusive || SUBBRAND_EXCLUSIVE_DEFAULT;
        this.searchData.searchAll = searchParams?.searchAll ?? this.searchData.searchAll;
        this.searchData.finished = searchParams && searchParams.finished || false;

        this.setTextToMainSearchElement(searchParams);
        if (searchParams === null) {
          this.clearSearchItems();
        }
        this.changeDetectorRef.markForCheck();
      });

    this.itemCategoryService.getTopSearchLevelCategoriesBySessionSubbrand()
      .pipe(
        finalize(() => this.changeDetectorRef.markForCheck()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((categories: ItemCategoryDto[]) => {
        this.initTopSearchLevelCategories(categories ?? []);
        this.changeDetectorRef.markForCheck();
      });

    this.listingService.getSearchResponse$()
      .pipe(
        filter(() => this.searchData.text !== ''),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((searchResponse: ItemSearchPagedResources) => {
        this.updateSearchCategory(searchResponse);
        this.changeDetectorRef.markForCheck();
      });

    this.listingService.getSearchAll$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((value: boolean) => {
        this.searchData.searchAll = value;
        this.changeDetectorRef.markForCheck();
      });

    this.initBlurInputOnScroll();
  }

  /**
   * Set the search term to main search input only if necessary. In all the other cases set an empty string there:
   * <ul>
   *   <li>searching some term in category
   *   <li>searching some term in landing page
   *   <li>searching some term in ended items
   * </ul>
   * In all these cases listed above we want to clear the main search input - just to make sure that the value from potential
   * previous general search doesn't stay there.
   */
  private setTextToMainSearchElement(searchParams: ItemSearchFilterExtended): void {
    const isCategoryOrLandingPageOrEndedItemsWithTextSearch: boolean = searchParams?.text?.length > 0
      && (isNotNil(searchParams?.categoryId) || this.listingTypeService.isActive(ListingType.LANDING_PAGE) || searchParams.finished);

    const inputValue: string = isCategoryOrLandingPageOrEndedItemsWithTextSearch ? '' : (searchParams?.text ?? '');

    if (!isNil(this.searchInputNativeElement)) {
      this.searchInputNativeElement.value = inputValue;
      if (isNotNil(this.dummySearchInput)) {
        this.dummySearchInput.nativeElement.value = inputValue;
      }
    }
  }

  private get suggestAllRequest(): SuggestAllRequest {
    return {
      text: this.searchData.text,
      categoryId: this.searchData.categoryId,
      itemState: this.searchData.finished ? 'ENDED' : 'ACTIVE',
      hotAuction: this.searchData.hotAuction,
      specialFlagBasicStartingPrice: this.searchData.specialFlagBasicStartingPrice,
      specialFlagEndingSoon: this.searchData.specialFlagEndingSoon,
      specialFlagNewOffers: this.searchData.specialFlagNewOffers,
      specialFlagThreeHundred: this.searchData.specialFlagThreeHundred,
      specialFlagPopularOffers: this.searchData.specialFlagPopularOffers,
      splitGroupKey: MEASUREMENT_SUGGEST_SPLIT_GROUP_KEY,
      splitGroupValue: this.personalizationMeasurementService.getGroup(APP_CONSTANTS.SUGGEST_COOKIE_NAME),
      searchAll: this.searchData.searchAll,
    };
  }

  public get isUserSearch(): boolean {
    return this.searchCategory?.catalogKey === 'user';
  }

  private get focusListLength(): number {
    const landingPagesAmount = this.landingPageItems?.length ?? 0;
    const categoriesAmount = this.categoryItems?.length ?? 0;
    const offersAmount = this.offerItems?.length ?? 0;
    const usersAmount = this.userItems?.length ?? 0;
    return landingPagesAmount + categoriesAmount + offersAmount + usersAmount;
  }

  protected get dropdownDialogModel(): DialogModel<AukDialogType> {
    return SEARCH_DROPDOWN_DIALOG;
  }

  protected get isPopularSearchVisible(): boolean {
    return !trimSpaces(this.searchInputNativeElement?.value)?.length && !isEmpty(this.popularSearchData?.links);
  }

  public get searchInputNativeElement(): HTMLInputElement {
    return this.compactSearchRef?.nativeElement;
  }

  private getUserSuggestions$(request: ItemSearchFilter): Observable<SuggestAllWithUsersResponse | Nil> {
    return iif(() => this.searchData.text?.length >= this.SEARCH_MIN_LENGTH,
      this.userService.showName(request?.text)
        .pipe(
          map((res: UserShowNameDto[]) => ({ userSuggestions: res })),
          take(1),
          catchError(() => {
            this.clearSearchItems();
            return of(null);
          }),
        ),
      of(null),
    );
  }

  private getOfferSuggestions$(request: SuggestAllRequest): Observable<SuggestAllResponse> {
    return iif(() => this.searchData.text?.length >= this.SEARCH_MIN_LENGTH,
      this.suggestionService.suggestAll({ params: request })
        .pipe(
          take(1),
          catchError(() => {
            this.clearSearchItems();
            return of(null);
          }),
        ),
      of(null),
    );
  }

  private initTopSearchLevelCategories(rootCategories: ItemCategoryDto[]): void {
    this.originalTopSearchLevelCategories = this.headerSearchService.transformCategories(rootCategories);
    this.searchCategories = this.headerSearchService.renderSearchCategories(this.originalTopSearchLevelCategories);
  }

  private initBlurInputOnScroll(): void {
    let lastScrollY: number = null;

    this.ngZoneUtilService.fromEventOut$(this.window, 'scroll')
      .pipe(
        this.ngZoneUtilService.throttleTimeOut(2000, { leading: true }),
        filter(() => this.responsivenessService.isLgAndLower),
        map(() => this.window.scrollY),
        filter((scrollY: number) => scrollY !== lastScrollY),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        lastScrollY = scrollY;
        this.searchInputNativeElement?.blur();
      });

    if (this.responsivenessService.isLgAndLower) {
      // Blur search input to close virtual keyboard
      this.searchInputNativeElement?.blur();
    }
  }

  public handleKeyboardActions(event: KeyboardEvent): void {
    if (KeyboardUtil.isEscapePressed(event)) {
      event.preventDefault();
      this.clearSearchItems();
    } else if (KeyboardUtil.isEnterPressed(event)) {
      event.preventDefault();
      if (this.focusTarget !== null) {
        const focused = this.getItemFromFocusListByIndex(this.focusTarget);
        if (focused instanceof SuggestionItemComponent) {
          const landingPagesAmount = this.searchResultLandingPages?.length ?? 0;
          const categoriesAmount = this.searchResultCategories?.length ?? 0;
          const elementRank: number = this.focusTarget + 1 - landingPagesAmount - categoriesAmount;
          this.sendClickItemMeasurement(focused.item, elementRank);
          void this.router.navigate(ProductUtil.getOfferUrl(focused.item));
          this.clearSearch();
        } else if (focused instanceof LandingPageItemComponent) {
          this.onLandingPageClick(null, focused.landingPage, this.focusTarget + 1);
        } else if (focused instanceof CategoryItemComponent) {
          this.onCategoryClick(null, focused.category, this.focusTarget + 1);
        } else if (focused instanceof SuggestionUserComponent) {
          void this.router.navigate(['/uzivatel/', focused.item.showName]);
          this.clearSearch();
        }
      } else {
        if (this.searchData.text?.length < this.SEARCH_MIN_LENGTH) {
          this.searchData.text = '';
        }
        this.submit();
      }
    } else if (this.searchDropdownDirective.isOpen && (KeyboardUtil.isUpArrowPressed(event) || KeyboardUtil.isDownArrowPressed(event))) {
      event.preventDefault();

      if (!this.focusListLength) {
        return;
      }

      let diff = KeyboardUtil.isDownArrowPressed(event) ? 1 : -1;
      if (this.focusTarget === null) {
        diff = 0;
      }

      this.focusTarget += diff;
      // Keep target in the list boundaries
      this.focusTarget = this.focusTarget < 0 ?
        this.focusListLength - 1 :
        (this.focusTarget > this.focusListLength - 1 ? 0 : this.focusTarget);

      // Set focus state
      this.resetFocusedItems();
      this.getItemFromFocusListByIndex(this.focusTarget).focus = true;
    }
  }

  protected loadAndShowSuggestions(): void {
    this.handleSuggestions();
    this.openSuggestionsDropdown();
  }

  public handleSuggestions(): void {
    this.skipNextSuggestion = false;

    this.searchData.text = trimSpaces(this.searchInputNativeElement.value);
    this.searchData.text = stripHtml(this.searchData.text);

    if (this.isPopularSearchVisible) {
      // if user changes the typed search term so that the popular searches are visible, we have to measure it
      this.popularSearchMeasurementTrigger.next();
    }
    if (this.searchData.text?.length < this.SEARCH_MIN_LENGTH) {
      this.clearSearchItems();
      return;
    }

    this.suggestionsTrigger.next();
  }

  protected onSearchCompactValueChanged(): void {
    this.handleSuggestions();

    if (isNil(this.dummySearchInput) || isNil(this.compactSearchRef)) {
      return;
    }

    this.dummySearchInput.nativeElement.value = this.compactSearchRef.nativeElement.value;
  }

  public searchAllChanged(): void {
    if (this.searchData.text?.length >= this.SEARCH_MIN_LENGTH) {
      this.suggestionsTrigger.next();
    }
    if (this.platformCommonService.isBrowser) {
      this.searchInputNativeElement.focus();
    }
  }

  protected setCompactSearchRef(ref: ElementRef<HTMLInputElement>): void {
    this.compactSearchRef = ref;
  }

  protected get isDropdownVisible(): boolean {
    if (this.isMdAndLower) {
      return true;
    }

    if (this.isPopularSearchVisible) {
      return true;
    }

    if (this.searchResultItemsTotal > 0) {
      return true;
    }

    if (this.searchResultUsers?.length > 0) {
      return true;
    }

    return !this.searchAlwaysInDescription;
  }

  protected onPopularSearchClick(index: number): void {
    this.listingService.searchDirectUrl$.next();
    this.popularSearchMeasurementService.clickOnPopularSearch(index);

    void this.router.navigateByUrl(UrlUtils.getRelativeUrl(this.popularSearchData?.links?.[index]?.url));

    this.closeSuggestionsDropdown();
  }

  public openSuggestionsDropdown(): void {
    if (isNil(this.popularSearchData)) {
      this.loadPopularSearches();
    }

    this.searchDropdownDirective.open();

    if (this.isMdAndLower) {
      this.handleSuggestions();
    }

    this.changeDetectorRef.markForCheck();

    if (this.isPopularSearchVisible) { // if user opens the suggestion dropdown and the popular searches are visible, we have to measure it
      this.popularSearchMeasurementTrigger.next();
    }
  }

  public closeSuggestionsDropdown(): void {
    this.searchDropdownDirective?.close();
  }

  private clearInputData(): void {
    if (isNotNil(this.dummySearchInput)) {
      this.dummySearchInput.nativeElement.value = '';
    }
    if (isNotNil(this.searchInputNativeElement)) {
      this.searchInputNativeElement.value = '';
    }
    this.searchData.text = '';
  }

  private loadPopularSearches(): void {
    combineLatest([
      this.suggestionService.popularSearches().pipe(take(1)),
      this.subbrandService.sessionSubbrand$,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: ([popularSearchesDto, sessionSubbrand]: [PopularSearchesDto, SubbrandType]) => {
          this.popularSearchData = SubbrandUtil.getBySubbrand(popularSearchesDto, sessionSubbrand, this.loggerService);
          if (this.isPopularSearchVisible) { // if the popular searches are found, we have to measure it
            this.popularSearchMeasurementTrigger.next();
          }
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  public onCatalogChange(searchCategory: SearchCategoryModel): void {
    this.setSearchCategory(searchCategory, true);
    this.compactSearchRef.nativeElement.focus();
  }

  public clearSearch(): void {
    const element = this.searchInputNativeElement;

    this.clearSearchItems();
    this.searchData.text = '';
    if (!isNil(element)) {
      element.value = '';
      if (this.platformCommonService.isBrowser) {
        element?.blur();
      }
    }
    this.searchData.text = '';
    this.clearSearchItems();
    this.resetFocusedItems();
    this.focusTarget = null;
    this.closeSuggestionsDropdown();
    this.clearInputData();
  }

  public clearSearchText(): void {
    this.clearInputData();
    if (this.listingTypeService.someListingOpened()) {
      this.submit();
    } else {
      this.clearSearchItems();
      this.closeSuggestionsDropdown();
      this.searchInputNativeElement.focus();
    }
  }

  public submit(event?: Event, allProducts: boolean = false): void {
    if (event) {
      event.preventDefault();
    }
    if (this.searchAlwaysInDescription) {
      this.searchData.searchAll = true;
    }
    this.skipNextSuggestion = true;
    if (this.platformCommonService.isBrowser) {
      this.searchInputNativeElement.blur();
    }

    if (this.isUserSearch) {
      this.clearSearchItems();
      this.appHeaderMeasurementServiceService.clickOnSearchUser(this.searchData.text);
      void this.router.navigate(['uzivatel', this.searchData.text]);
    } else {
      this.appHeaderMeasurementServiceService.clickOnSearchItemCategory(this.searchData,
        allProducts ? 'all_items_field' : 'magnifier');

      if (this.searchData.text?.length >= this.SEARCH_MIN_LENGTH) {
        // prevent search phrases in "ended" offers or is all products search
        this.searchData.searchRedirectDisabled = this.searchData.finished || allProducts;

        this.getQueryParamsFromForm$(this.searchData)
          .pipe(
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((queryParams: Params) => {
            this.doSearchNavigation(queryParams);
            this.clearSearchItems();
            this.changeDetectorRef.markForCheck();
          });
      }
    }
    this.closeDropdown();
    this.closeSuggestionsDropdown();
  }

  protected onLandingPageClick(event: Event, landingPage: SuggestAllResponseContentElementLandingPage, elementRank: number): void {
    if (event) {
      event.preventDefault();
    }

    if (elementRank < 1) {
      throw new Error('Element rank has to start with 1.');
    }
    const clickEventDto: ClickEventDto = { showId: this.lastSuggestedLandingPagesShowId, landingPageId: landingPage.id, elementRank };
    this.suggestPersonalizationMeasurementService.clickOnSuggestedLandingPage(clickEventDto);
    this.appHeaderMeasurementServiceService.clickOnSearchItemCategory(this.searchData, 'landing_page_suggest');

    this.listingService.searchDirectUrl$.next();
    void this.router.navigateByUrl(landingPage.seoUrl);

    this.closeDropdown();
    this.closeSuggestionsDropdown();
  }

  protected onCategoryClick(event: Event, category: SuggestAllResponseContentElementCategory, elementRank: number): void {
    if (event) {
      event.preventDefault();
    }

    if (elementRank < 1) {
      throw new Error('Element rank has to start with 1.');
    }
    const clickEventDto: ClickEventDto = { showId: this.lastSuggestedCategoriesShowId, categoryId: category.id, elementRank };
    this.suggestPersonalizationMeasurementService.clickOnSuggestedCategory(clickEventDto);
    this.appHeaderMeasurementServiceService.clickOnSearchItemCategory(this.searchData, 'category_suggest');

    const searchRequest: CategoryItemSuggestParams = this.createCategoryItemSuggestParams(this.searchData, category);

    const urlTree: UrlTree = this.headerSearchService.createSuggestedCategoryItemUrlTree(searchRequest);
    void this.router.navigateByUrl(urlTree);

    this.closeDropdown();
    this.closeSuggestionsDropdown();
  }

  protected onSuggestionClick(item, index): void {
    this.clearSearch();
    this.sendClickItemMeasurement(item, index + 1);
    this.closeSuggestionsDropdown();
  }

  protected inputSearchClick(event: MouseEvent): void {
    if (this.searchResultItemsTotal > 0 || ArrayUtils.isNotEmpty(this.searchResultUsers)) {
      return;
    }

    if (this.isMdAndLower) {
      this.openSuggestionsDropdown();
    } else {
      this.emitClickAction(event);
    }

    this.handleSuggestions();
  }

  public sendClickItemMeasurement(item: SuggestAllResponseContentElementItem, elementRank: number): void {
    if (elementRank < 1) {
      throw new Error('Element rank has to start with 1.');
    }
    const clickEventDto: ClickEventDto = { showId: this.lastSuggestedItemsShowId, itemId: item.id, elementRank };
    this.suggestPersonalizationMeasurementService.clickOnSuggestedItem(clickEventDto);
    this.appHeaderMeasurementServiceService.clickOnSearchItemCategory(this.searchData, 'item_suggest');
  }

  /**
   * Emits Click Action and
   * Stop opened search dropdown from being closed
   * by subsequent mouse clicks on search input
   * @param e
   */
  public emitClickAction(e: Event): void {
    this.clickAction.emit();
    return this.searchDropdownDirective.isOpen && e.stopPropagation();
  }

  public trackBySearchResultItemId(index: number, searchItem: SuggestAllResponseContentElementItem): number {
    return searchItem.id || 0;
  }

  public trackBySearchResultLandingPageId(index: number, searchLandingPage: SuggestAllResponseContentElementLandingPage): number {
    return searchLandingPage.id || 0;
  }

  public trackBySearchResultCategoryId(index: number, searchCategory: SuggestAllResponseContentElementCategory): number {
    return searchCategory.id || 0;
  }

  public onDropdownClose(): void {
    this.changeDetectorRef.markForCheck();
  }

  public dropdownToggled(opened: boolean): void {
    if (this.isMdAndLower) {
      this.searchDropdownToggle.emit(opened);
      if (opened) {
        this.backToTopService.setVisibility(false);
        this.compactSearchRef.nativeElement.focus();
      } else {
        this.backToTopService.setVisibility(true);
      }
    }
  }

  // Reset all focus states
  private resetFocusedItems(): void {
    this.resetFocus(this.landingPageItems);
    this.resetFocus(this.categoryItems);
    this.resetFocus(this.offerItems);
    this.resetFocus(this.userItems);
  }

  private getItemFromFocusListByIndex(index: number):
    LandingPageItemComponent
    | CategoryItemComponent
    | SuggestionItemComponent
    | SuggestionUserComponent {
    let items: QueryList<LandingPageItemComponent | CategoryItemComponent | SuggestionItemComponent | SuggestionUserComponent>;
    if (this.isUserSearch) {
      items = this.userItems;
    } else {
      items = this.landingPageItems;
      if (index >= this.landingPageItems?.length) {
        items = this.categoryItems;
        index -= (this.landingPageItems?.length ?? 0);
        if (index >= this.categoryItems?.length) {
          items = this.offerItems;
          index -= (this.categoryItems?.length ?? 0);
        }
      }
    }
    return items.find((_, i) => i === index);
  }

  private handleResult(res: SuggestAllResponse): void {
    this.searchResultLandingPages = res.content
      .find((content: SuggestAllResponseContent) => content.type === 'LANDING_PAGE')
      ?.elements as SuggestAllResponseContentElementLandingPage[]
      || [];

    this.searchResultCategories = res.content
      .find((content: SuggestAllResponseContent) => content.type === 'CATEGORY')
      ?.elements as SuggestAllResponseContentElementCategory[]
      || [];

    const suggestAllResponseContentItems = res.content.find((content: SuggestAllResponseContent) => content.type === 'ITEM');
    this.searchResultItems = suggestAllResponseContentItems?.elements as SuggestAllResponseContentElementItem[] || [];
    this.searchResultItemsTotal = suggestAllResponseContentItems?.totalElements;

    const landingPagesAmount = this.searchResultLandingPages?.length ?? 0;
    const categoriesAmount = this.searchResultCategories?.length ?? 0;
    const itemsAmount = this.searchResultItems?.length ?? 0;

    if (landingPagesAmount + categoriesAmount + itemsAmount > 0) {
      this.suggestionsMeasurementTrigger.next();
    }
  }

  private getQueryParamsFromForm$(form: ItemSearchFilter): Observable<Params> {
    const queryParams: Params = { ...form };
    if (queryParams.splitGroups) {
      delete queryParams.splitGroups;
    }
    if (isEqual(this.lastSearchParams, form)) {
      queryParams.timestamp = Date.now();
    }
    this.lastSearchParams = form;
    return of(queryParams);
  }

  private doSearchNavigation(queryParams: Params): void {
    const isExtraCategory = this.searchCategory.extraCategory;
    if (!queryParams.text && this.searchCategory.categorySeoUrl && !this.specialFlagSelected()) {
      // when text is cleared and page is of default type (category-offers),
      // navigate to selected category keeping search params
      queryParams = this.headerSearchService.prepareQueryParams(queryParams);
      queryParams = this.headerSearchService.removeFromQueryParams(queryParams, 'categoryId');
      void this.router.navigate(['/', this.searchCategory.categorySeoUrl], { queryParams });
    } else if (this.listingTypeService.isActive(ListingType.CATEGORY_OFFERS) || !isExtraCategory) {
      // when ordinary catalog is selected in the searchCategory (on special search page),
      // navigate to the default search result page
      queryParams = this.headerSearchService.prepareQueryParams(queryParams);
      void this.router.navigate(['/vysledky-vyhledavani'], { queryParams });
    } else if (this.listingTypeService.isActive(ListingType.LANDING_PAGE)) {
      // remove categoryId if user search over Landing Page
      queryParams = this.headerSearchService.prepareQueryParams(queryParams);
      queryParams = this.headerSearchService.removeFromQueryParams(queryParams, 'categoryId');
      void this.router.navigate([], { queryParams });
    } else if (this.listingTypeService.someListingOpened()) {
      // when page is search result page, stay on the same page and only change queryParams
      queryParams = this.headerSearchService.prepareQueryParams(queryParams);
      void this.router.navigate([], { queryParams });
    } else {
      // from other pages navigate to search result page
      queryParams = this.navigationService.removeEmptyProps(queryParams);
      void this.router.navigate(['/vysledky-vyhledavani'], { queryParams });
    }
  }

  private closeDropdown(): void {
    this.clearSearchItems();
    this.resetFocusedItems();
    this.focusTarget = null;
    this.changeDetectorRef.markForCheck();
  }

  private clearSearchItems(): void {
    this.searchResultItemsTotal = 0;
    this.searchResultItems = [];
    this.searchResultUsers = [];
    this.searchResultLandingPages = [];
    this.searchResultCategories = [];
    this.focusTarget = null;
    this.resetFocusedItems();
  }

  private resetFocus(
    items: QueryList<LandingPageItemComponent | CategoryItemComponent | SuggestionItemComponent | SuggestionUserComponent>,
  ): void {
    (items?.toArray?.() || [])
      .filter((item) => item.focus)
      .map((item) => item.focus = false);
  }

  private setSearchCategory(searchCategory: SearchCategoryModel, emitEvent: boolean = false): void {
    this.searchCategory = searchCategory;
    const shouldIgnoreSubbrand = searchCategory === this.headerSearchService.predefinedCategories.all;

    if (this.searchData.ignoreSubbrand !== shouldIgnoreSubbrand) {
      this.headerSearchService.saveAllSearchPreferenceToCookie(
        shouldIgnoreSubbrand ? 'ALL' : 'WITHIN-SUBBRAND',
      );
    }

    this.searchData = {
      ...this.searchData,
      ...{
        categoryId: searchCategory.categoryId,
        finished: searchCategory.catalogKey === 'completed',
        sellerId: searchCategory.sellerId,
        ppMainPage: searchCategory.ppMainPage,
        ppCategoryPage: searchCategory.ppCategoryPage,
        specialFlagBasicStartingPrice: searchCategory.specialFlagBasicStartingPrice,
        specialFlagEndingSoon: searchCategory.specialFlagEndingSoon,
        hotAuction: searchCategory.hotAuction,
        ignoreSubbrand: shouldIgnoreSubbrand,
      },
    };
    if (emitEvent) {
      this.clearSearchItems();
      // onCatalogChange() we want suggestionsTrigger.next() no matter this.searchData.text.length
      // there is mapped resetFocusedItems() etc.. in this.suggestionsTrigger.pipe
      this.suggestionsTrigger.next();
    }
  }

  private updateSearchCategory(searchResponse: ItemSearchPagedResources): void {
    const searchCategory = this.headerSearchService.getAllSearchPreference();
    this.setSearchCategory(searchCategory);
    this.searchCategories = this.headerSearchService.renderSearchCategories(this.originalTopSearchLevelCategories);

    const isGeneralSearch: boolean = this.listingTypeService.isActive(ListingType.SEARCH) && isNil(searchResponse?.categoryPath);
    if (!isGeneralSearch) {
      this.clearSearch();
    }
  }

  private specialFlagSelected(): boolean {
    return intersection(
      Object.keys(this.searchCategory),
      ['ppMainPage', 'ppCategoryPage', 'specialFlagBasicStartingPrice', 'specialFlagEndingSoon', 'sellerId', 'hotAuction'],
    ).length > 0;
  }

  private initSuggestionsMeasurement(): void {
    this.suggestionsMeasurementTrigger
      .pipe(
        debounceTime(800),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        const showEventDto: ShowEventDto = { searchTerm: this.searchData.text };
        if (this.landingPageItems?.length > 0) {
          this.suggestPersonalizationMeasurementService.showSuggestedLandingPages$(showEventDto)
            .pipe(
              take(1),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe((showId) => this.lastSuggestedLandingPagesShowId = showId);
        }
        if (this.categoryItems?.length > 0) {
          this.suggestPersonalizationMeasurementService.showSuggestedCategories$(showEventDto)
            .pipe(
              take(1),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe((showId) => this.lastSuggestedCategoriesShowId = showId);
        }
        if (this.offerItems?.length > 0) {
          this.suggestPersonalizationMeasurementService.showSuggestedItems$(showEventDto)
            .pipe(
              take(1),
              takeUntil(this.ngUnsubscribe),
            )
            .subscribe((showId) => this.lastSuggestedItemsShowId = showId);
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  private initPopularSearchMeasurementTrigger(): void {
    this.popularSearchMeasurementTrigger
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.popularSearchMeasurementService.showPopularSearch(this.popularSearchData?.links);
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  private createCategoryItemSuggestParams(
    searchFilter: ItemSearchFilter,
    category: SuggestAllResponseContentElementCategory): CategoryItemSuggestParams {

    const isSpecialFlag = this.specialFlagSelected();
    let catSeoUrl = category?.seoUrl;
    if (isSpecialFlag) {
      searchFilter.categoryId = category?.id;

      // match found in category name, go to category listing without text filter
    } else if (category?.name?.toLowerCase().includes(searchFilter.text?.toLowerCase())) {
      searchFilter.text = '';
      this.clearInputData();

      // else go to category specific filter with search text
    } else {
      searchFilter.categoryId = category?.id;
      catSeoUrl = null;
    }

    return {
      itemSearchFilter: searchFilter,
      categorySeoUrl: catSeoUrl,
      specialFlag: isSpecialFlag,
    };
  }

}
