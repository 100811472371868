import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserTaskLoginStepComponent } from '../user-task-login-step.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Nil } from '@util/helper-types/nil';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { AuthenticateWrapperComponent } from '@shared/legacy/component/authenticate-wrapper/component/authenticate-wrapper.component';
import { StringUtils } from '@util/util/string.utils';
import { TranslationSource } from '@common/translations/model/translation-source';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { HttpError } from '@shared/rest/model/http-error';
import { DialogAlert } from '@common/dialog-wrapper/dialog-alert';
import isNil from 'lodash-es/isNil';
import { DomainUtil } from '@shared/domain/util/domain.util';
import { DomainService } from '@shared/domain/service/domain.service';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { BiometryService } from '@common/biometry/service/biometry.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { RegistrationApiService } from '@api/aukro-api/api/registration-api.service';
import { UserAccountStateDto, UserAccountStateDtoRegistrationChannelEnumEnum } from '@api/aukro-api/model/user-account-state-dto';
import { LoginVM } from '@api/aukro-api/model/login-vm';

interface FormModel {
  loginOrEmail: FormControl<string | Nil>;
  hiddenPassword: FormControl<string | Nil>;
}

@Component({
  selector: 'auk-user-task-login-step-login-email',
  templateUrl: 'user-task-login-step-login-email.component.html',
  styleUrls: ['./user-task-login-step-login-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskLoginStepLoginEmailComponent extends UserTaskLoginStepComponent<'LOGIN_EMAIL'> implements OnInit {

  @ViewChild(AuthenticateWrapperComponent) protected authenticateWrapperComponent: AuthenticateWrapperComponent;

  protected isSubmitting: boolean = false;
  protected loginForm: FormGroup<FormModel>;
  protected isNativeIosApp: boolean = PlatformCommonService.isNativeIosApp;
  protected dialogAlerts: DialogAlert[] = [];
  protected isPasswordInputVisible: boolean = true;
  protected isCrossDomain: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly registrationApiService: RegistrationApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly errorService: ErrorsService,
    private readonly domainService: DomainService,
    private readonly biometryService: BiometryService,
  ) {
    super();

    if (PlatformCommonService.isNativeApp) {
      void this.biometryService.getCredentials().then(credentials => {
        if (credentials) {
          this.loginIfPasswordIsPrefilled(credentials.username, credentials.password, ['EMAIL']);
        }
      });
    }
  }

  protected get loginOrEmailControl(): FormModel['loginOrEmail'] {
    return this.loginForm?.controls?.loginOrEmail;
  }

  private get hiddenPasswordControl(): FormModel['hiddenPassword'] {
    return this.loginForm?.controls?.hiddenPassword;
  }

  public ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      loginOrEmail: [null],
      hiddenPassword: [null],
    });
  }

  protected get loginOrEmailErrorText(): TranslationSource | Nil {
    if (this.loginOrEmailControl.hasError('invalidLoginOrEmail')) {
      return { key: 'LOGIN_OR_EMAIL_INVALID' };
    }

    if (this.loginOrEmailControl.hasError('missingLoginOrEmail')) {
      return { key: 'LOGIN_OR_EMAIL_MISSING' };
    }

    if (this.loginOrEmailControl.hasError('invalidLogin')) {
      return { key: 'LOGIN_INVALID' };
    }

    if (this.loginOrEmailControl.hasError('maxLength')) {
      return { key: 'LOGIN_MAX_LENGTH_EXCEED' };
    }

    return null;
  }

  protected onSubmit(): void {

    if (this.isSubmitting) {
      return;
    }

    if (this.loginForm.invalid) {
      this.loginForm.markAsTouched();
      return;
    }

    const loginOrEmail = this.loginOrEmailControl.value;

    this.isSubmitting = true;
    this.dialogAlerts = [];

    /**
     * We need to hide password input if password is empty, otherwise browser can offer to save password for different user
     */
    if (StringUtils.isBlank(this.hiddenPasswordControl.value)) {
      this.isPasswordInputVisible = false;
      this.changeDetectorRef.detectChanges();
    }

    this.registrationApiService.validateUserAccount$({
      registrationValidationDto: {
        loginOrEmail,
        sourceAction: this.payload.sourceAction,
      },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (userAccountState: UserAccountStateDto) => {

          switch (userAccountState.regStatusEnum) {
            case 'INIT':
            case 'REGISTERED':
            case 'DISCARDED':
            case 'NO_REG_BUY':
            case 'COLLECTED_EMAIL':
              this.handleRegisteredEmail(loginOrEmail, userAccountState);
              break;
            case 'TERMINATED':
              this.handleNotRegisteredEmail(loginOrEmail);
              break;
            default:
              this.handleUnexpectedState();
              break;
          }

        },
        error: (httpError: HttpError) => {
          this.unsetSubmitButtonAsLoading();
          if (httpError?.code === 400) {
            this.handleBadRequest(httpError, loginOrEmail);
            return;
          }

          if (this.errorService.isEmailDisallowed(httpError)) {
            this.dialogAlerts = [{ type: 'WARNING', text: { key: 'REGISTRATION_STEP_1_EMAIL_DISALLOWED' } }];
            return;
          }
          console.error(httpError);
          this.handleUnexpectedState();
        },
        complete: () => {
          this.isPasswordInputVisible = true;
        },
      });
  }

  private handleRegisteredEmail(loginOrEmail: string, userAccountState: UserAccountStateDto): void {
    // Password not set
    if (userAccountState.passwordSet === false) {
      this.resolve.emit({
        type: 'RESOLUTION_SET_PASSWORD',
        payload: {
          email: loginOrEmail,
        },
      });
      return;
    }

    const registrationChannels: UserAccountStateDtoRegistrationChannelEnumEnum[] = userAccountState.registrationChannelEnum;

    // If only FACEBOOK on iOS
    if (this.isNativeIosApp && registrationChannels.includes('FACEBOOK') && !registrationChannels.includes('EMAIL')) {
      this.dialogAlerts = [{ type: 'WARNING', text: { key: 'IOS_FB_REGISTRATION_NOT_ALLOWED' } }];
      return;
    }

    // EMAIL or FACEBOOK or both
    const hiddenPassword = this.loginForm?.get('hiddenPassword').value;

    // Only EMAIL
    if (!isNil(loginOrEmail) && !isNil(hiddenPassword) &&
      !registrationChannels.includes('FACEBOOK') && registrationChannels.includes('EMAIL')) {
      this.loginIfPasswordIsPrefilled(loginOrEmail, hiddenPassword, registrationChannels);
      return;
    }

    this.handlePasswordOrThirdParty(loginOrEmail, hiddenPassword, registrationChannels, this.isCrossDomain, null);
  }

  private handleNotRegisteredEmail(loginOrEmail: string): void {
    this.resolve.emit({
      type: 'RESOLUTION_EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL',
      payload: {
        email: loginOrEmail,
      },
    });
  }

  private handleBadRequest(httpError: HttpError, loginOrEmail: string): void {
    if (this.errorService.isLoginOrEmailMissing(httpError)) {
      this.loginOrEmailControl.setErrors({ missingLoginOrEmail: true });
      return;
    }

    if (this.errorService.isLoginOrEmailInvalid(httpError)) {
      this.loginOrEmailControl.setErrors({ invalidLoginOrEmail: true });
      return;
    }

    // if is login not found, don't resolve step
    if (this.errorService.isLoginNotFound(httpError)) {
      this.loginOrEmailControl.setErrors({ invalidLogin: true });
      return;
    }

    // if login exceeds max length
    if (this.errorService.isLoginLengthExceeded(httpError)) {
      this.loginOrEmailControl.setErrors({ maxLength: true });
      return;
    }

    // if is email not found, resolve step
    if (this.errorService.isEmailNotFound(httpError)) {
      this.handleNotRegisteredEmail(loginOrEmail);
      return;
    }

    this.handleUnexpectedState();
  }

  private handleUnexpectedState(): void {
    this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LOGIN_FAILED' } }];
  }

  protected loginViaFB(): void {
    this.resolve.emit({
      type: 'RESOLUTION_FB_LOAD',
      payload: {},
    });
  }

  private loginIfPasswordIsPrefilled(
    loginOrEmail: string,
    hiddenPassword: string,
    registrationChannels: UserAccountStateDtoRegistrationChannelEnumEnum[]): void {

    this.isSubmitting = true;
    this.changeDetectorRef.markForCheck();

    const loginVM: LoginVM = {
      username: loginOrEmail,
      password: hiddenPassword,
    };

    this.authenticateWrapperComponent.doAuthenticate(loginVM)
      .pipe(
        finalize(() => {
          this.unsetSubmitButtonAsLoading();
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          if (PlatformCommonService.isNativeApp) {
            void this.biometryService.saveCreditials(loginOrEmail, hiddenPassword);
          }
          this.resolve.emit({
            type: 'RESOLUTION_SUCCESS',
            payload: {},
          });
        },
        error: (httpError: HttpError) => {
          let regDomainCode: DomainCode = null;
          if (this.errorService.isPasswordChangeRequired(httpError)) {
            this.resolve.emit({
              type: 'RESOLUTION_PASSWORD_CHANGE_REQUIRED',
              payload: {},
            });
            return;
          } else if (this.errorService.isCrossDomainNotAllowed(httpError)) {
            regDomainCode = DomainUtil.resolveDomainCodeFromError(httpError);
            this.dialogAlerts = [{
              type: 'WARNING', text: {
                key: 'CROSS_DOMAIN_SING_IN_NOT_ALLOWED_OLD_LOGIN_PAGE',
                params: {
                  regDomain: this.domainService.getDomainHostUpperCaseCapitalLetter(regDomainCode),
                  regDomainUrl: this.domainService.getDomainUrlWithProtocol(regDomainCode),
                  actualDomain: this.domainService.getDomainHostUpperCaseCapitalLetter(this.domainService.domain),
                },
              },
            }];
            this.isCrossDomain = true;

          }
          this.handlePasswordOrThirdParty(loginOrEmail, hiddenPassword, registrationChannels, this.isCrossDomain,
            regDomainCode);
        },
      });
  }

  private handlePasswordOrThirdParty(
    loginOrEmail: string,
    hiddenPassword: string,
    registrationChannels: UserAccountStateDtoRegistrationChannelEnumEnum[],
    crossDomain: boolean,
    regDomainCode: DomainCode,
  ): void {
    this.resolve.emit({
      type: 'RESOLUTION_PASSWORD_OR_THIRD_PARTY',
      payload: {
        email: loginOrEmail,
        hiddenPassword,
        registrationChannels,
        crossDomain,
        dialogAlerts: this.dialogAlerts,
        regDomainCode,
      },
    });
  }

  private unsetSubmitButtonAsLoading(): void {
    this.isSubmitting = false;
    this.changeDetectorRef.markForCheck();
  }

  protected resetHiddenPassword(): void {
    const hiddenPassword = this.loginForm?.controls.hiddenPassword.value;

    if (hiddenPassword) {
      this.loginForm.controls.hiddenPassword.setValue(null);
    }

  }

}
