import { Injectable, Injector } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { mergeMap, Observable, of, Subject, timeout } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { actualStatisticsCacheBuster$, actualUserProfileStatisticsCacheBuster$, userEmailInfoCacheBuster$, userProfileCacheBuster$ } from '@shared/user/constant/cache-busters';
import { AuthCacheService } from './auth-cache.service';
import { HttpContext, HttpErrorResponse } from '@angular/common/http';
import { CrossTabMessagingService } from '@shared/services/cross-tab-messaging/service/cross-tab-messaging.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Nil } from '@util/helper-types/nil';
import { UserActualStatisticsService } from '@shared/user/service/user-actual-statistics.service';
import { CurrencyResolverService } from '@shared/currency/service/currency-resolver.service';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserRegistrationSourceAction } from '@shared/user-task/login/model/user-registration-source-action.type';
import { AuthenticateApiService, AuthenticateRequestParams } from '@api/aukro-api/api/authenticate-api.service';
import { LoginVM } from '@api/aukro-api/model/login-vm';
import { JWTInfo } from '@api/aukro-api/model/jwt-info';
import { PlatformService } from '@shared/platform/service/platform.service';
import { UserAgentService } from '@shared/platform/service/user-agent.service';
import { JWTInfoWithAukroToken } from '@api/aukro-api/model/jwt-info-with-aukro-token';
import { NativeAppService } from '@shared/native-app/service/native-app.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends NgUnsubscribe {

  private INIT_USER_PREFERENCE_CALL_TIMEOUT_MILLIS: number = 2000;

  private loginStatusChange = new Subject<boolean>();

  constructor(
    private readonly authCacheService: AuthCacheService,
    private readonly authenticateApiService: AuthenticateApiService,
    private readonly crossTabMessagingService: CrossTabMessagingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly injector: Injector,
    private readonly userTaskService: UserTaskService,
    private readonly userAgentService: UserAgentService,
  ) {
    super();

    this.listenToResetUserCrossTabMessage();
    this.listenToLoginStatusChange();
  }

  public get currentLoggedUserId(): number | Nil {
    return this.authCacheService.currentLoggedUserId;
  }

  public setAuthToken(token: string, domainChangeLogout: boolean = false): void {
    this.authCacheService.authToken = token;

    this.resetCurrentUserInternal();

    if (domainChangeLogout) {
      return;
    }

    //We need to inject the servises here to avoid circular dependency with Domain.service - selector
    const currencyResolverService = this.injector.get(CurrencyResolverService);
    const userActualStatisticsService = this.injector.get(UserActualStatisticsService);

    // Logout
    if (isNil(token)) {
      currencyResolverService.resolvePreferredCurrency();
      this.emitLoginStatusChange();
      return;
    }

    // Log in - load actual statistics to resolve preferred currency before emit login status change
    userActualStatisticsService.loadActualStatistics()
      .pipe(
        mergeMap((userInterestStatisticsDto) =>
          currencyResolverService.resolvePreferredCurrency(userInterestStatisticsDto.currency)),
        timeout(this.INIT_USER_PREFERENCE_CALL_TIMEOUT_MILLIS),
        catchError(() => of(void 0)),
        finalize(() => this.emitLoginStatusChange()),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  /**
   * Authenticate user and perform actions related with successful authentication
   * @param loginVM - login data
   * @param headers - HTTP headers
   * @param context - value in context (params: token, value)
   * @returns - authenticate API call source
   */
  public authenticate(loginVM: LoginVM, headers: { [key: string]: string } = {}, context?: HttpContext): Observable<JWTInfo> {
    const params: AuthenticateRequestParams = {
      loginVM,
      platformType: PlatformService.platformType,
      userAgent: this.userAgentService.userAgent,
    };

    return this.authenticateApiService.authenticate$(params, headers, context)
      .pipe(
        tap((jwtInfo: JWTInfoWithAukroToken) => {
          const nativeAppService = this.injector.get(NativeAppService);
          return nativeAppService.saveAukroTokenIfNativeApp(jwtInfo.aukroToken);
        }),
      );
  }

  public logout(domainChangeLogout: boolean = false): void {
    if (isNil(this.authCacheService.authToken)) {
      return;
    }

    this.setAuthToken(null, domainChangeLogout);
  }

  /**
   * New value is emitted with every login status change.
   * Value is not emitted when application starts.
   */
  public getLoginStatusChange(): Observable<boolean> {
    return this.loginStatusChange.asObservable();
  }

  /**
   * New value is emitted with every login status change.
   * always emits current value immediately when subscribed
   */
  public getLoginStatusChangeWithStartValue(): Observable<boolean> {
    return this.getLoginStatusChange()
      .pipe(
        startWith(this.isLoggedIn()),
      );
  }

  /**
   * New value is emitted only when login status changes i.e. after successful login and logout.
   * always emits current value immediately when subscribed
   */
  public getLoginStatusChangeWithStartValueDistinct$(): Observable<boolean> {
    return this.getLoginStatusChangeWithStartValue()
      .pipe(
        distinctUntilChanged(),
      );
  }

  public isLoggedIn(): boolean {
    return this.authCacheService.isLoggedIn();
  }

  public isAuthTokenExpired(): boolean {
    return this.authCacheService.isAuthTokenExpired();
  }

  public resetCurrentUser(): void {
    this.resetCurrentUserInternal();
    this.emitLoginStatusChange();
  }

  /**
   * Reset current user without login status emit
   */
  private resetCurrentUserInternal(): void {
    this.crossTabMessagingService.postMessage({ type: 'RESET_USER' });
    this.invalidateUserRelatedCache();
  }

  private invalidateUserRelatedCache(): void {
    actualStatisticsCacheBuster$.next();
    actualUserProfileStatisticsCacheBuster$.next();
    userProfileCacheBuster$.next();
    userEmailInfoCacheBuster$.next();
  }

  private emitLoginStatusChange(): void {
    this.loginStatusChange.next(this.isLoggedIn());
  }

  private listenToResetUserCrossTabMessage(): void {
    this.crossTabMessagingService.listenToMessageType$('RESET_USER')
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.invalidateUserRelatedCache();
      });
  }

  private listenToLoginStatusChange(): void {
    this.getLoginStatusChange()
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          return;
        }

        // navigate user to homepage if he logs out and is on page which requires user logged in
        const routeData = (this.activatedRoute?.firstChild?.routeConfig?.data as { loggedInRequired: boolean });

        if (routeData?.loggedInRequired) {
          void this.router.navigate(['/']);
        }
      });
  }

  public isOnLoginPage(): boolean {
    return this.router.url.startsWith('/prihlaseni');
  }

  public isOnLoginPageWithReturnUrl(): boolean {
    return this.router.url.startsWith('/prihlaseni?') && this.router.url.includes('returnUrl=');
  }

  public redirectToLoginPage(queryParams: NavigationExtras): Promise<void> {
    return this.router.navigate(['prihlaseni'], queryParams)
      .then(() => this.logout());
  }

  public logoutOnAuthError(error: HttpErrorResponse): void {
    if (this.isOnLoginPageWithReturnUrl()) {
      // do not call logout() when user is on the login page and query params contain returnUrl
      return;
    }
    if (error.status === 401) {
      this.logout();
    }
  }

  public loginTroughPopupFlow(sourceAction: UserRegistrationSourceAction): Observable<void> {
    return this.userTaskService.executeTaskWithCommonAndCustomPayload$(
      'LOGIN',
      {
        action: 'LOGIN',
      },
      {
        sourceAction,
      },
      'LOGIN',
    );
  }

}
