import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { HeaderUserControlActionClickType } from './model/header-user-controls-action-click.model';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';
import { AccountControlComponent } from '@shared/app-header/module/app-header-user-controls/component/account-control/account-control.component';
import { CartService } from '@shared/cart/service/cart.service';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-app-header-user-controls',
  templateUrl: './app-header-user-controls.component.html',
  styleUrls: ['./app-header-user-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderUserControlsComponent extends NgUnsubscribe implements OnInit, OnDestroy {

  @Input() public user: UserInterestStatisticsDto;
  @Input() public showSearchButton: boolean = false;

  @Output() public clickAction: EventEmitter<HeaderUserControlActionClickType> = new EventEmitter<HeaderUserControlActionClickType>();

  @ViewChild(AccountControlComponent) private readonly accountControlComponent: AccountControlComponent;

  private isMdAndLower: boolean = false;
  private cartItemsRemainingCount: number | Nil;

  constructor(
    private readonly appHeaderHamburgerService: AppHeaderHamburgerService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService,
    private readonly authenticationService: AuthenticationService,
    private readonly cartService: CartService,
  ) {
    super();

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnInit(): void {
    this.cartService.getCartOverview()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((cart) => {
        this.cartItemsRemainingCount = cart?.cart?.quantity;
        this.changeDetectorRef.detectChanges();
      });
  }

  protected toggleNavigationMenu(): void {
    this.appHeaderHamburgerService.openCategoryMenu();
  }

  protected buttonClicked(action: HeaderUserControlActionClickType): void {
    this.clickAction.emit(action);
  }

  protected get showSearch(): boolean {
    return this.showSearchButton && this.isMdAndLower;
  }

  protected get showCart(): boolean {
    if (!this.isMdAndLower) {
      return true;
    }
    if (isNil(this.cartItemsRemainingCount) || this.cartItemsRemainingCount === 0) {
      return false;
    }
    return true;
  }

  protected onUserInfoClick(): void {
    if (isNil(this.user)) {
      this.startLoginTask();
      return;
    }

    const url: string = this.responsivenessService.isLgAndLower ? '/moje-aukro/rozcestnik' : '/moje-aukro/moje-nakupy/zakoupeno';

    this.accountControlComponent.measureAvatarOrLinkClick(url);
    void this.router.navigate([url]);
  }

  private startLoginTask(): void {
    this.authenticationService.loginTroughPopupFlow('LOGIN')
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
