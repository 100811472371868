/**
 * Unit maps for key from API to Romanian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_RO = {
  PAIRS: {
    '=0': 'pere',
    '=1': 'pere',
    '=2': 'pere',
    '=3': 'pere',
    '=4': 'pere',
    other: 'pere',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'pare',
    '=1': 'pare',
    other: 'pare',
  },
  PIECES: {
    '=0': 'bucăți',
    '=1': 'bucată',
    '=2': 'bucăți',
    '=3': 'bucăți',
    '=4': 'bucăți',
    other: 'bucăți',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'bucatile',
    '=1': 'bucata',
    other: 'bucatile',
  },
  PIECE_SHORT: {
    other: 'buc.',
  },
  SET: {
    '=0': 'seturi',
    '=1': 'set',
    '=2': 'seturi',
    '=3': 'seturi',
    '=4': 'seturi',
    other: 'seturi',
  },
  SET_ACCUSATIVE: {
    '=0': 'seturile',
    '=1': 'setul',
    other: 'seturile',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',

  BET: {
    '=1': 'pariază',
    '=2': 'pariază',
    '=3': 'pariază',
    '=4': 'pariază',
    other: 'pariază',
  },
  BET_PAST: {
    '=1': 'a pariat',
    '=2': 'au pariat',
    '=3': 'au pariat',
    '=4': 'au pariat',
    other: 'a pariat',
  },
  ITEM: {
    '=0': 'articole',
    '=1': 'articol',
    '=2': 'articole',
    '=3': 'articole',
    '=4': 'articole',
    other: 'articole',
  },
  DAY: {
    '=0': 'zile',
    '=1': 'zi',
    '=2': 'zile',
    '=3': 'zile',
    '=4': 'zile',
    other: 'zile',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'zi',
    other: 'zile',
  },
  HOUR: {
    '=0': 'ore',
    '=1': 'oră',
    '=2': 'ore',
    '=3': 'ore',
    '=4': 'ore',
    other: 'ore',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'oră',
    other: 'ore',
  },
  MINUTE: {
    '=0': 'minute',
    '=1': 'minut',
    '=2': 'minute',
    '=3': 'minute',
    '=4': 'minute',
    other: 'minute',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minut',
    other: 'minute',
  },
  OTHER: {
    '=0': 'alte',
    '=1': 'alt',
    '=2': 'alte',
    '=3': 'alte',
    '=4': 'alte',
    other: 'alte',
  },
  FILE: {
    '=0': 'fișiere',
    '=1': 'fișier',
    '=2': 'fișiere',
    '=3': 'fișiere',
    '=4': 'fișiere',
    other: 'fișiere',
  },
  IMAGE: {
    '=0': 'imagini',
    '=1': 'imagine',
    '=2': 'imagini',
    '=3': 'imagini',
    '=4': 'imagini',
    other: 'imagini',
  },
  OFFER: {
    '=0': 'oferte',
    '=1': 'ofertă',
    '=2': 'oferte',
    '=3': 'oferte',
    '=4': 'oferte',
    other: 'oferte',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'oferte',
    '=1': 'ofertă',
    '=2': 'oferte',
    '=3': 'oferte',
    '=4': 'oferte',
    other: 'oferte',
  },
  POSITIVE_COMMENT: {
    '=0': 'comentarii pozitive',
    '=1': 'comentariu pozitiv',
    '=2': 'comentarii pozitive',
    '=3': 'comentarii pozitive',
    '=4': 'comentarii pozitive',
    other: 'comentarii pozitive',
  },
  USER_GENITIV: {
    '=0': 'utilizatori',
    '=1': 'utilizator',
    '=2': 'utilizatori',
    '=3': 'utilizatori',
    '=4': 'utilizatori',
    other: 'utilizatori',
  },
  USER_INSTRUMENTAL: {
    '=0': 'utilizatorilor',
    '=1': 'utilizatorului',
    other: 'utilizatori',
  },
  ADDED_PASSIVE: {
    '=0': 'adăugate',
    '=1': 'adăugată',
    '=2': 'adăugate',
    '=3': 'adăugate',
    '=4': 'adăugate',
    other: 'adăugate',
  },
};
