import { IcuLocaleModel } from '@shared/locale/model/icu-locale.model';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { Lang } from '@shared/platform/lang.const';
import { StringUtils } from '@util/util/string.utils';
import { ObjectUtils } from '@util/util/object.utils';
import { LoggerService } from '@common/logger/service/logger.service';
import { LangBeType } from '@shared/lang/model/lang-be.type';

export class LocaleUtil {

  public static readonly LANGUAGE_HEADER_NAME: string = 'X-Accept-Language';

  // [ADD_DOMAIN]
  private static DOMAIN_TO_LOCALE_MAP: Record<DomainCode, IcuLocaleModel> = {
    SK: {
      hrefLangLocale: 'sk-sk',
      languageWithRegionCode: 'sk-SK',
      country: 'SK',
      language: 'sk',
      flag: 'flag-icon-slovakia',
      label: 'SK_LOCALE_LABEL',
      domainUrl: 'aukro.sk',
      testIdentification: 'domain-selector-sk',
      accessibilityId: 'ACS_LOCATION_SELECTOR_SK',
      nonTranslatedLabel: 'Aukro.sk / Slovensko',
    },
    AT: {
      hrefLangLocale: 'de-at',
      languageWithRegionCode: 'de-AT',
      country: 'AT',
      language: 'de',
      flag: 'flag-icon-slovakia',
      label: 'DE_LOCALE_LABEL',
      domainUrl: 'aukro.de',
      testIdentification: 'domain-selector-de',
      accessibilityId: 'ACS_LOCATION_SELECTOR_DE',
      nonTranslatedLabel: 'Aukro.de / Deutschland',
    },
    BE: {
      hrefLangLocale: 'nl-be',
      languageWithRegionCode: 'nl-BE',
      country: 'BE',
      language: 'nl',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-be',
      accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
      nonTranslatedLabel: 'Aukro.be / België',
    },
    BG: {
      hrefLangLocale: 'bg-bg',
      languageWithRegionCode: 'bg-BG',
      country: 'BG',
      language: 'bg',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-bg',
      accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
      nonTranslatedLabel: 'Aukro.bg / България',
    },
    DE: {
      hrefLangLocale: 'de-de',
      languageWithRegionCode: 'de-DE',
      country: 'DE',
      language: 'de',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-de',
      accessibilityId: 'ACS_LOCATION_SELECTOR_DE',
      nonTranslatedLabel: 'Aukro.de / Deutschland',
    },
    FR: {
      hrefLangLocale: 'fr-fr',
      languageWithRegionCode: 'fr-FR',
      country: 'FR',
      language: 'fr',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-fr',
      accessibilityId: 'ACS_LOCATION_SELECTOR_FR',
      nonTranslatedLabel: 'Aukro.fr / France',
    },
    GR: {
      hrefLangLocale: 'el-gr',
      languageWithRegionCode: 'el-GR',
      country: 'GR',
      language: 'el',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-gr',
      accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
      nonTranslatedLabel: 'Aukro.gr / Ελλάδα',
    },
    HR: {
      hrefLangLocale: 'hr-hr',
      languageWithRegionCode: 'hr-HR',
      country: 'HR',
      language: 'hr',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-hr',
      accessibilityId: 'ACS_LOCATION_SELECTOR_HR',
      nonTranslatedLabel: 'Aukro.hr / Hrvatska',
    },
    HU: {
      hrefLangLocale: 'hu-hu',
      languageWithRegionCode: 'hu-HU',
      country: 'HU',
      language: 'hu',
      flag: 'flag-icon-hungary',
      label: 'HU_LOCALE_LABEL',
      domainUrl: 'aukro.hu',
      testIdentification: 'domain-selector-hu',
      accessibilityId: 'ACS_LOCATION_SELECTOR_HU',
      nonTranslatedLabel: 'Aukro.hu / Magyarország',
    },
    IT: {
      hrefLangLocale: 'it-it',
      languageWithRegionCode: 'it-IT',
      country: 'IT',
      language: 'it',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-it',
      accessibilityId: 'ACS_LOCATION_SELECTOR_IT',
      nonTranslatedLabel: 'Aukro.it / Italia',
    },
    NL: {
      hrefLangLocale: 'nl-nl',
      languageWithRegionCode: 'nl-NL',
      country: 'NL',
      language: 'nl',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-nl',
      accessibilityId: 'ACS_LOCATION_SELECTOR_NL',
      nonTranslatedLabel: 'Aukro.nl / Nederland',
    },
    PL: {
      hrefLangLocale: 'pl-pl',
      languageWithRegionCode: 'pl-PL',
      country: 'PL',
      language: 'pl',
      flag: 'flag-icon-slovakia',
      label: 'PL_LOCALE_LABEL',
      domainUrl: 'aukro.pl',
      testIdentification: 'domain-selector-pl',
      accessibilityId: 'ACS_LOCATION_SELECTOR_PL',
      nonTranslatedLabel: 'Aukro.pl / Polska',
    },
    RO: {
      hrefLangLocale: 'ro-ro',
      languageWithRegionCode: 'ro-RO',
      country: 'RO',
      language: 'ro',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: 'domain-selector-ro',
      accessibilityId: 'ACS_LOCATION_SELECTOR_RO',
      nonTranslatedLabel: 'Aukro.ro / România',
    },
    SI: {
      hrefLangLocale: 'sl-si',
      languageWithRegionCode: 'sl-SI',
      country: 'SI',
      language: 'sl',
      flag: '',
      label: '',
      domainUrl: '',
      testIdentification: '',
      accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
    },
    CZ: {
      hrefLangLocale: 'cs-cz',
      languageWithRegionCode: 'cs-CZ',
      country: 'CZ',
      language: 'cs',
      flag: 'flag-icon-czech-republic',
      label: 'CZ_LOCALE_LABEL',
      domainUrl: 'aukro.cz',
      accessibilityId: 'ACS_LOCATION_SELECTOR_CZ',
      testIdentification: 'domain-selector-cz',
      nonTranslatedLabel: 'Aukro.cz / Česká republika',
    },
  };

  // [ADD_DOMAIN]
  private static ACTIVE_DOMAINS: DomainCode[] = ['CZ', 'SK'];

  private static LANG_CODE_TO_DOMAIN_CODE: Record<Lang, DomainCode> = {
    cs: 'CZ',
    sk: 'SK',
    de: 'DE', // Or AT
    fr: 'FR',
    it: 'IT',
    pl: 'PL',
    hu: 'HU',
    ro: 'RO',
    bg: 'BG',
    nl: 'NL', // Or BE
    sl: 'SI',
    hr: 'HR',
    el: 'GR',
  };

  /**
   * Fallback locale is always CZ
   */
  private static FALLBACK_LOCALE = this.DOMAIN_TO_LOCALE_MAP['CZ'];

  public static getDomainLocale(
    domainCode: DomainCode,
    loggerService: LoggerService,
  ): IcuLocaleModel {
    const locale = this.DOMAIN_TO_LOCALE_MAP[domainCode];

    if (locale) {
      return locale;
    }

    loggerService.logMessage(
      `LocaleUtil#getDomainLocale :: locale not resolved by domain code (using fallback ${ this.FALLBACK_LOCALE.country })`,
      {
        level: 'error',
        fingerprint: ['LOCALE_UTIL_LOCALE_NOT_RESOLVED_BY_DOMAIN_CODE'],
        extra: {
          domain: domainCode,
        },
      },
    );

    return this.FALLBACK_LOCALE;
  }

  public static getDefaultLocale(): IcuLocaleModel {
    return this.FALLBACK_LOCALE;
  }

  public static getDomainCodeByLangCode(
    langCode: Lang,
    loggerService: LoggerService,
  ): DomainCode {
    const domainCode = this.LANG_CODE_TO_DOMAIN_CODE[langCode];

    if (!StringUtils.isBlank(langCode)) {
      return domainCode;
    }

    loggerService.logMessage(
      `LocaleUtil#getDomainCodeByLangCode :: domain code not resolved by lang code (using fallback ${ this.FALLBACK_LOCALE.country })`,
      {
        level: 'error',
        fingerprint: ['LOCALE_UTIL_DOMAIN_CODE_NOT_RESOLVED_BY_LANG_CODE'],
        extra: {
          langCode,
        },
      },
    );

    return this.FALLBACK_LOCALE.country;
  }

  public static getAllDomainsLocales(): IcuLocaleModel[] {
    return ObjectUtils.values(this.DOMAIN_TO_LOCALE_MAP);
  }

  public static getAllActiveDomainsLocales(): IcuLocaleModel[] {
    return this.getAllDomainsLocales()
      .filter((locale: IcuLocaleModel) => this.ACTIVE_DOMAINS.includes(locale?.country));
  }

  public static readonly ROUTES_PREFIX: string = 'ROUTES.';

  /**
   * Added via Nginx, see frontend ingress config
   */
  public static readonly NGINX_SSR_LANGUAGE_HEADER: string = 'X-Aukro-SSR-Language';

  public static getDomainLanguageUppercase(
    domainCode: DomainCode,
    loggerService: LoggerService,
  ): LangBeType {
    return LocaleUtil.getDomainLocale(domainCode, loggerService).language.toUpperCase() as LangBeType;
  }

}
