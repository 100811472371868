import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { cityNameValidator, personNameValidator, streetValidator, zipCodeValidatorByPattern } from '@shared/validators/validators';
import { Constants } from '@shared/constants';
import { mergeMap, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { getCountryByIdOrCode } from '@shared/util/method/country-by-id-or-code-util';
import isNil from 'lodash-es/isNil';
import { UserService } from '@shared/user/service/user.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PhoneNumberService } from '@shared/services/phone-number/phone-number.service';
import { RegistryService } from '@shared/registry/registry.service';
import { DomainService } from '@shared/domain/service/domain.service';
import { ValidationMessage, validationMessages } from '@shared/legacy/directive/validation-messages/validation-messages';
import { forkJoin } from 'rxjs';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { excludeCadRepublic } from '@shared/util/method/exclude-cad-republic-util';
import { Nil } from '@util/helper-types/nil';
import { BasicRegistrationFormModel } from './basic-registration-form.model';
import moment from 'moment-mini-ts';
import { TranslationSource } from '@common/translations/model/translation-source';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { TextMaskModel } from '@common/text-mask/model/text-mask.model';
import { ZipCodeService } from '@shared/zip/service/zip-code.service';
import { DomainCode } from '@shared/domain/model/domain-code.type';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { AccountDetailDto } from '@api/aukro-api/model/account-detail-dto';
import { InitialBasicDataInsertRequestParams } from '@api/aukro-api/api/users-api.service';
import { RegistryCountryItemDto } from '@api/aukro-api/model/registry-country-item-dto';
import { InitBasicDataFormDto } from '@api/aukro-api/model/init-basic-data-form-dto';
import { CompanyDataFormDto } from '@api/aukro-api/model/company-data-form-dto';

@Component({
  selector: 'auk-basic-registration-form',
  templateUrl: './basic-registration-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicRegistrationFormComponent extends NgUnsubscribe implements OnInit, OnChanges {

  @Input() public isSubmitting: boolean = false;
  @Input() public onlyPhoneEnabled: boolean = false;
  @Input() public isPhoneRequired: boolean = true;
  @Input() public showAdditionalInfo: boolean = false;

  @Output() public formSubmit: EventEmitter<InitialBasicDataInsertRequestParams> = new EventEmitter<InitialBasicDataInsertRequestParams>();

  public basicRegistrationForm: FormGroup<BasicRegistrationFormModel>;
  public countries: RegistryCountryItemDto[] = [];
  public isZipCodeInputVisible: boolean;
  public zipCodeTextMask: TextMaskModel;
  protected maxBirthDate: Date = moment().startOf('day').subtract(Constants.MINIMUM_REGISTERED_USER_AGE, 'years').toDate();
  protected minBirthDate: Date = moment().startOf('day').subtract(Constants.MAXIMUM_REGISTERED_USER_AGE, 'years').toDate();

  private userId: number;

  constructor(
    protected readonly responsivenessService: ResponsivenessService,
    private readonly userService: UserService,
    private readonly formBuilder: FormBuilder,
    private readonly phoneNumberService: PhoneNumberService,
    private readonly registryService: RegistryService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainService: DomainService,
    private readonly zipCodeService: ZipCodeService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnChanges(changes: AukSimpleChanges<BasicRegistrationFormComponent>): void {
    if (changes.onlyPhoneEnabled && this.onlyPhoneEnabled === true) {
      this.basicRegistrationForm.get('firstName').disable();
      this.basicRegistrationForm.get('lastName').disable();
      this.basicRegistrationForm.get('address').disable();
    }
  }

  public get phoneControl(): FormControl {
    return this.basicRegistrationForm?.get('phone') as FormControl;
  }

  private initForm(): void {
    const currentDomainConfig = this.domainService.currentDomainConfig;
    const country = currentDomainConfig.canSelectCountry
      ? this.domainService.domain
      : { value: this.domainService.domain, disabled: true };

    this.basicRegistrationForm = this.formBuilder.group({
      firstName: ['', [Validators.required, personNameValidator]],
      lastName: ['', [Validators.required, personNameValidator]],
      // TODO: [PDEV-15410]
      address: this.formBuilder.group({
        zipCode: ['', []],
        address: ['', [Validators.required, streetValidator]],
        city: ['', [Validators.required, cityNameValidator]],
        country: [country, [Validators.required]],
      }),
      phone: this.createPhoneFormControl(''),
      birthDate: [''],
      isVatPayer: [false] ,
      isBusinessRegistration: [false],
    });

    this.getUserData();
  }

  private getUserData(): void {
    forkJoin([
      this.userService.getActualStatistics()
        .pipe(
          take(1),
          tap((actualStatistics) => this.userId = actualStatistics?.userId),
          mergeMap(() => this.userService.detail(this.userId)),
        ),
      this.registryService.getCountries()
        .pipe(
          take(1),
        ),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([accountDetail, countries]) => {
        this.updateFormAccount(accountDetail);
        this.loadCountries(countries);
        this.changeDetectorRef.markForCheck();
      });
  }

  private updateFormAccount(account: AccountDetailDto): void {
    if (account) {
      if (account.firstName) {
        this.basicRegistrationForm.get('firstName').setValue(account.firstName);
      }
      if (account.lastName) {
        this.basicRegistrationForm.get('lastName').setValue(account.lastName);
      }
      if (account.address) {
        if (account.address.zipCode) {
          this.basicRegistrationForm.get('address.zipCode').setValue(account.address.zipCode);
        }
        if (account.address.address) {
          this.basicRegistrationForm.get('address.address').setValue(account.address.address);
        }
        if (account.address.city) {
          this.basicRegistrationForm.get('address.city').setValue(account.address.city);
        }
        if (account.address.country) {
          this.basicRegistrationForm.get('address.country').setValue(account.address.country.code as DomainCode);
        }
      }
      if (account.phone) {
        // has to be new instance of FormControl for <phone-field [control]> to register change
        this.basicRegistrationForm.removeControl('phone');
        this.basicRegistrationForm.addControl(
          'phone',
          this.createPhoneFormControl(account.phone),
        );
      }
    }
  }

  private createPhoneFormControl(defaultValue?: string): FormControl {
    return this.formBuilder.control(
      defaultValue,
      {
        validators: [
          ...(this.isPhoneRequired ? [Validators.required] : []),
          Validators.maxLength(19),
        ],
        asyncValidators: [
          this.phoneNumberService.phoneNumberValidator(this.changeDetectorRef),
        ],
      });
  }

  private loadCountries(countries: RegistryCountryItemDto[]): void {
    this.countries = excludeCadRepublic(countries);
    this.basicRegistrationForm?.get('address.zipCode').addValidators(zipCodeValidatorByPattern('address.country', this.countries));

    // TODO: [PDEV-15410]
    this.basicRegistrationForm
      ?.get('address.country')
      ?.valueChanges
      .pipe(
        startWith(this.basicRegistrationForm?.get('address.country')?.value),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((country) => {
        const selectedCountry = getCountryByIdOrCode(country, this.countries);
        const deliveryZipControl = this.basicRegistrationForm?.get('address.zipCode');
        const postcodeRegexp = selectedCountry?.postcodeRegexp;

        this.isZipCodeInputVisible = !isNil(postcodeRegexp);

        if (!this.isZipCodeInputVisible) {
          deliveryZipControl.removeValidators(Validators.required);
          deliveryZipControl.setValue(null);
        } else if (!deliveryZipControl.hasValidator(Validators.required)) {
          deliveryZipControl.addValidators(Validators.required);
        }

        this.zipCodeTextMask = this.zipCodeService.updateZipCodeMask(
          deliveryZipControl,
          this.ngUnsubscribe,
          selectedCountry?.code as DomainCode,
        );

        deliveryZipControl.updateValueAndValidity();
        this.changeDetectorRef.markForCheck();
      });

  }

  public onSubmit(): void {

    const { valid } = this.basicRegistrationForm;
    const value = this.basicRegistrationForm.getRawValue();
    if (!valid) {
      return;
    }

    if (this.onlyPhoneEnabled) {
      this.formSubmit.emit({
        id: this.userId,
        initBasicDataFormDto: {
          phone: value.phone,
          address: null,
          firstName: null,
          lastName: null,
        },
      });
      return;
    }

    const countryByCode = getCountryByIdOrCode(value.address.country, this.countries);

    const accountUpdate: InitBasicDataFormDto = {
      firstName: value.firstName,
      lastName: value.lastName,
      address: {
        zipCode: value.address.zipCode,
        address: value.address.address,
        city: value.address.city,
        country: {
          code: value.address.country,
          id: countryByCode.id,
        },
        phone: value.phone,
      },
      phone: value.phone,
    };

    if (value.isBusinessRegistration) {
      accountUpdate.companyData = this.getCompanyData(this.basicRegistrationForm);
    }

    if (value.birthDate) {
      accountUpdate.birthDate = moment(value.birthDate).format('YYYY-MM-DD');
    }

    this.formSubmit.emit({
      id: this.userId,
      initBasicDataFormDto: accountUpdate,
    });
  }

  public getValidationMsgForPhone(errCode: string): TranslationSource {
    const msg = validationMessages[errCode];
    if (isNil(msg)) {
      return { key: 'BASIC_REGISTRATION_FORM_ERROR_REQUIRED' };
    }
    return (validationMessages[errCode] as ValidationMessage).text;
  }

  private getCompanyData(basicRegForm: FormGroup<BasicRegistrationFormModel>): CompanyDataFormDto {
    const { value } = basicRegForm;
    const companyData: CompanyDataFormDto = {
      regNumber: value.regNumber,
      companyName: this.getCompanyName(basicRegForm),
      accountCompanyFormEnum: value.legalForm,
      vatNumber: value.isVatPayer ? value.vatNumber : null,
    };
    return companyData;
  }

  private getCompanyName(basicRegForm: FormGroup<BasicRegistrationFormModel>): string | Nil {
    const { value } = basicRegForm;
    if (value.legalForm === 'TRADESMAN') {
      return null;
    } else {
      return value.companyName;
    }
  }

}
