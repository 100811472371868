import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AlertType } from './model/alert-type';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { ColorCombinationComponent } from '@common/colors/component/color-combination/color-combination.component';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { TranslationSource } from '@common/translations/model/translation-source';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { CommonModule } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';

import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Nil } from '@util/helper-types/nil';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { RouterLink } from '@angular/router';
import { StickerComponent } from '@common/ui-kit/component/sticker/component/sticker.component';
import { AlertVisualType } from '@common/ui-kit/component/alert-banner/component/model/alert-visual-type';
import isNil from 'lodash-es/isNil';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { ButtonModel } from '@common/ui-kit/component/button/model/button-model';
import { StickerModel } from '@common/ui-kit/component/sticker/model/sticker-model';
import { RouterLinkWithQueryParams } from '@common/ui-kit/common/router/router-link-with-query-params-type';

@Component({
  selector: 'auk-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    Translate2Module,
    InnerHtmlDirective,
    ButtonComponent,
    RouterLink,
    StickerComponent,
  ],
})
export class AlertBannerComponent extends ColorCombinationComponent implements OnChanges {

  @Input() public type: AlertType;
  @Input() public visualType: AlertVisualType = 'ICON';
  @Input() public text: TranslationSource;
  @Input() public textAlign: 'LEFT' | 'CENTER' = 'LEFT';
  @Input() public hasBlockContent: boolean = false;
  @Input() public sticker: StickerModel | Nil = null;
  @Input() public button: ButtonModel | Nil = null;
  @Input() public link: RouterLinkWithQueryParams | Nil = null;
  /**
   * see {@link InnerHtmlDirective#allowStrictSanitize}
   */
  @Input() public allowStrictSanitize: boolean = true;

  public iconModel: IconModel | Nil;

  public ngOnChanges(changes: AukSimpleChanges<AlertBannerComponent>): void {
    if (changes.type) {
      this.iconModel = this.visualType === 'ICON' || isNil(this.visualType)
        ? AlertBannerComponent.getIconByType(this.type)
        : null;

      this.setColorCombination(
        AlertBannerComponent.getColorCombinationByType(this.type),
      );
    }
  }

  private static getIconByType(type: AlertType): IconModel {
    const baseIconModel = {
      type: 'MATERIAL',
      size: 'XL',
    } as IconModel;

    switch (type) {
      case 'INFO':
        return {
          ...baseIconModel,
          colorCombination: 'MATTER_LIGHT_BLUE',
          source: 'info',
        };
      case 'ABSENCE':
        return {
          ...baseIconModel,
          colorCombination: 'TERTIARY_CONTRAST',
          source: 'flight_takeoff',
        };
      case 'UPDATE':
        return {
          ...baseIconModel,
          colorCombination: 'TERTIARY_CONTRAST',
          source: 'update',
        };
      case 'SUCCESS':
        return {
          ...baseIconModel,
          colorCombination: 'SUCCESS_CONTRAST',
          source: 'check_circle',
        };
      case 'WARNING':
        return {
          ...baseIconModel,
          colorCombination: 'WARNING_CONTRAST',
          source: 'warning',
        };
      case 'PRIMARY':
        return {
          ...baseIconModel,
          colorCombination: 'PRIMARY_CONTRAST',
          source: 'info',
        };
      case 'DANGER':
        return {
          ...baseIconModel,
          colorCombination: 'DANGER_CONTRAST',
          source: 'warning',
        };
    }
  }

  private static getColorCombinationByType(type: AlertType): ColorCombinationId {
    switch (type) {
      case 'INFO':
        return 'MATTER_LIGHT_BLUE';
      case 'ABSENCE':
        return 'TERTIARY_CONTRAST';
      case 'UPDATE':
        return 'TERTIARY_CONTRAST';
      case 'SUCCESS':
        return 'SUCCESS_50_CONTRAST_OPPOSITE_400';
      case 'WARNING':
        return 'WARNING_CONTRAST';
      case 'PRIMARY':
        return 'PRIMARY_CONTRAST';
      case 'DANGER':
        return 'DANGER_50_CONTRAST_OPPOSITE_300';
    }
  }

}
