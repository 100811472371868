import { Injectable } from '@angular/core';
import { from, map, Observable, of, ReplaySubject, take, takeUntil } from 'rxjs';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';
// eslint-disable-next-line import/no-restricted-paths
import { AccessiblityTranslationKey } from '../model/accessibility-translation-key';
// eslint-disable-next-line import/no-restricted-paths
import { Lang } from '@shared/platform/lang.const';
import { switchMap } from 'rxjs/operators';
import { isNotNil } from '@util/helper-functions/is-not-nil';

@Injectable({
  providedIn: 'root',
})
export class AccessibilityService extends BaseDestroy {

  private readonly FALLBACK_LANG: Lang = 'cs';

  private readonly ACCESSIBILITY_TRANSLATION_BY_LANG_MAP: Record<Lang, Promise<unknown>> = {
    cs: import('../../../common/translations/files/accessibility/cs-accessibility.json'),
    sk: import('../../../common/translations/files/accessibility/sk-accessibility.json'),
    de: import('../../../common/translations/files/accessibility/de-accessibility.json'),
    hu: import('../../../common/translations/files/accessibility/hu-accessibility.json'),
    pl: import('../../../common/translations/files/accessibility/pl-accessibility.json'),
    ro: import('../../../common/translations/files/accessibility/ro-accessibility.json'),
    it: import('../../../common/translations/files/accessibility/it-accessibility.json'),
    fr: import('../../../common/translations/files/accessibility/fr-accessibility.json'),
    el: import('../../../common/translations/files/accessibility/el-accessibility.json'),
    nl: import('../../../common/translations/files/accessibility/nl-accessibility.json'),
    hr: import('../../../common/translations/files/accessibility/hr-accessibility.json'),
    sl: import('../../../common/translations/files/accessibility/sl-accessibility.json'),
    bg: import('../../../common/translations/files/accessibility/bg-accessibility.json'),
  };

  private translation$: ReplaySubject<Record<AccessiblityTranslationKey, string>> =
    new ReplaySubject<Record<AccessiblityTranslationKey, string>>();

  constructor(
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public init(): void {
    this.setTranslationData(this.domainService.lang);
  }

  private setTranslationData(lang: string): void {
    this.createObservableFromImportPromise$(this.ACCESSIBILITY_TRANSLATION_BY_LANG_MAP[lang])
      .pipe(
        switchMap((value: Record<AccessiblityTranslationKey, string>) => {
          if (isNotNil(value)) {
            return of(value);
          } else {
            return this.createObservableFromImportPromise$(this.ACCESSIBILITY_TRANSLATION_BY_LANG_MAP[this.FALLBACK_LANG]);
          }
        }),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe((translationData: Record<AccessiblityTranslationKey, string>) => {
        this.translation$.next(translationData);
      });
  }

  private createObservableFromImportPromise$(importPromise: Promise<unknown>): Observable<Record<AccessiblityTranslationKey, string>> {
    return from(importPromise.then((res: { default: Record<AccessiblityTranslationKey, string> }) => res?.default));
  }

  public getTranslated$(id: AccessiblityTranslationKey): Observable<string> {
    return this.translation$
      .pipe(
        take(1),
        map((translationData) => translationData[id]),
      );
  }

}
