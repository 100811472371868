import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';
// eslint-disable-next-line import/no-restricted-paths
import { TranslationCacheService } from '@shared/services/translation-cache/translation-cache.service';
// eslint-disable-next-line import/no-restricted-paths
import { Lang } from '@shared/platform/lang.const';
import { isNotNil } from '@util/helper-functions/is-not-nil';

export class TranslationsLoader implements TranslateLoader {

  private readonly FALLBACK_LANG: Lang = 'cs';

  // TODO [PDEV-25819] Add translated title file for each language
  private readonly TITLES_BY_LANG_MAP: Record<Lang, Promise<unknown>> = {
    cs: import('../files/titles/cs-titles.json'),
    sk: import('../files/titles/sk-titles.json'),
    de: import('../files/titles/de-titles.json'),
    hu: import('../files/titles/hu-titles.json'),
    pl: import('../files/titles/pl-titles.json'),
    ro: import('../files/titles/ro-titles.json'),
    it: import('../files/titles/it-titles.json'),
    fr: import('../files/titles/fr-titles.json'),
    el: import('../files/titles/el-titles.json'),
    nl: import('../files/titles/nl-titles.json'),
    hr: import('../files/titles/hr-titles.json'),
    sl: import('../files/titles/sl-titles.json'),
    bg: import('../files/titles/bg-titles.json'),
  };
  
  constructor(
    private readonly translationCacheService: TranslationCacheService,
    private readonly domainService: DomainService,
  ) {
  }

  //Combine translations from i18n assets and from Web Text.
  public getTranslation(): Observable<Record<string, string>> {
    const domainLang: Lang = this.domainService.lang;
    const translations$: Observable<Record<string, string>> = this.translationCacheService.getAll();
    const titlesJSON$: Observable<Record<string, string>> = this.getTitlesTranslationFiles$(domainLang);

    return combineLatest([translations$, titlesJSON$])
      .pipe(
        map(([webTexts, titlesJSON]) => ({
          ...webTexts,
          ...titlesJSON,
        })),
        take(1),
      );
  }

  private getTitlesTranslationFiles$(lang: Lang): Observable<Record<string, string>> {
    return this.createObservableFromImportPromise$(this.TITLES_BY_LANG_MAP[lang])
      .pipe(
        switchMap((value: Record<string, string>) => {
          if (isNotNil(value)) {
            return of(value);
          } else {
            return this.createObservableFromImportPromise$(this.TITLES_BY_LANG_MAP[this.FALLBACK_LANG]);
          }
        }),
      );
  }

  private createObservableFromImportPromise$(importPromise: Promise<unknown>): Observable<Record<string, string>> {
    return from(importPromise.then((res: { default: Record<string, string> }) => res?.default));
  }

}
