import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Observable, takeUntil } from 'rxjs';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';

@Component({
  selector: 'auk-app-header-v2',
  templateUrl: './app-header-v2.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderV2Component extends NgUnsubscribe implements OnInit {

  public isSearchOpened: boolean = false;
  public isSearchExpanded: boolean = false;

  constructor(
    private readonly appHeaderHamburgerService: AppHeaderHamburgerService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessService.isLgAndHigher$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isLgAndHigher: boolean) => {
        this.isSearchOpened = isLgAndHigher;
        this.isSearchExpanded = !isLgAndHigher;
        this.changeDetectorRef.markForCheck();
      });
  }

  protected toggleSearchInput(): void {
    this.isSearchOpened = !this.isSearchOpened;
  }

  public openHamburgerMenu(): void {
    this.appHeaderHamburgerService.openCategoryMenu();
  }

  protected categoryMenuOpened$(): Observable<boolean> {
    return this.appHeaderHamburgerService.categoryMenuOpened$();
  }

}
