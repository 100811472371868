import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';

import { ServerService } from '@api/generated/api/Server';
import { OutageSettingDto } from '@api/generated/defs/OutageSettingDto';
import { StatusDto } from '@api/generated/defs/StatusDto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { SW_UPDATE_KEY } from '@shared/platform/service/platform.service';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import { UserService } from '@shared/user/service/user.service';
import { ToastService } from '@common/toast/service/toast.service';
import isNil from 'lodash-es/isNil';
import { USER_BLOCK_REASONS } from '@shared/user/constant/user-block-reasons.constant';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { HttpError } from '@shared/rest/model/http-error';
import { UserActualStatisticsService } from '@shared/user/service/user-actual-statistics.service';
import { actualStatisticsCacheBuster$ } from '@shared/user/constant/cache-busters';
import { merge } from 'rxjs';
import { RoutesService } from '@shared/services/app/routes.service';
import { DomainService } from '@shared/domain/service/domain.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DateUtils } from '@util/util/date.utils';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import {
  UserInterestStatisticsDto,
  UserInterestStatisticsDtoAccountBlockForSaleTypeEnumEnum,
} from '@api/aukro-api/model/user-interest-statistics-dto';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { PvaBanner } from '@api/aukro-api/model/pva-banner';

/**
 * NOTICE: For responsive (mobile) view is used in my-aukro-page.html and in app.page.html
 */
@Component({
  selector: 'auk-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopPanelComponent extends NgUnsubscribe implements OnInit, OnDestroy {

  public outage: OutageSettingDto;
  public blockBannerType: UserInterestStatisticsDtoAccountBlockForSaleTypeEnumEnum;
  public userId: number;
  public showMalformedEmailBanner: boolean = false;
  public showDac7DataValidationRequired: boolean = false;
  public showCrossDomainLoginWarning: boolean = false;
  public routeToEditPersonalDataByUserType: 'PERSONAL_DATA' | 'COMPANY_DATA' = 'PERSONAL_DATA';
  public showUnconfirmedRegistrationBanner: boolean = false;
  public showSetPasswordBanner: boolean = false;
  public showPhoneVerificationRequiredBanner: boolean = false;
  public resendEmailStatus: 'resendDone' | 'resendLimit' | 'userTerminated' | '';
  public clickingOnResendAgainLink: boolean = false;
  public displayUpdateBanner: boolean = false;
  private accountBlockReasonId: number;
  protected pvaBanner: PvaBanner = null;
  private readonly SW_UPDATE_BANNER_TIMEOUT_DAYS: number = 1;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly userService: UserService,
    private readonly sharedService: ShareDataService,
    private readonly userActualStatisticsService: UserActualStatisticsService,
    private readonly serverService: ServerService,
    private readonly authenticationService: AuthenticationService,
    private readonly errorsService: ErrorsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly toastService: ToastService,
    private readonly authCacheService: AuthCacheService,
    private readonly userTaskService: UserTaskService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
    protected readonly routesService: RoutesService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        mergeMap(() => this.userService.getActualStatistics()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((userInterest: UserInterestStatisticsDto) => {
        if (isNil(userInterest)) {
          this.resetAll();
          return;
        }
        this.userId = userInterest.userId;
        this.showDac7DataValidationRequired = userInterest.dac7DataValidationRequired;
        this.showCrossDomainLoginWarning = userInterest.registrationDomainCode !== this.domainService.domain;
        if (userInterest.accountTypeEnum === 'COMPANY') {
          this.routeToEditPersonalDataByUserType = 'COMPANY_DATA';
        }
        if (userInterest.emailMalformed) {
          this.showMalformedEmailBanner = true;
        }
        this.accountBlockReasonId = userInterest.accountBlockReasonId;
        this.showUnconfirmedRegistrationBanner = !userInterest.emailVerified;
        this.showSetPasswordBanner = !!userInterest.emailVerified && !this.authCacheService.decodedToken?.hasPassword;
        this.showPhoneVerificationRequiredBanner = !!userInterest.phoneVerificationRequired;
        this.pvaBanner = userInterest.pvaBanner;
        this.changeDetectorRef.markForCheck();
      });

    merge(
      this.authenticationService.getLoginStatusChangeWithStartValueDistinct$(),
      actualStatisticsCacheBuster$,
    )
      .pipe(
        mergeMap(() => this.userActualStatisticsService.loadActualStatistics()),
        tap((value: UserInterestStatisticsDto) => {
          if (isNil(value)) {
            this.blockBannerType = null;
            return;
          }
          switch (value.accountBlockForSaleTypeEnum) {
            case 'BLOCK':
              this.blockBannerType = 'BLOCK';
              break;
            case 'LIGHT_BLOCK':
            case 'STOP':
              this.blockBannerType = 'LIGHT_BLOCK';
              break;
            default:
              this.blockBannerType = null;
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.changeDetectorRef.detectChanges());

    this.authenticationService.getLoginStatusChange()
      .pipe(
        filter((loggedIn: boolean) => !loggedIn),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.showUnconfirmedRegistrationBanner = false;
        this.showSetPasswordBanner = false;
        this.showPhoneVerificationRequiredBanner = false;
        this.changeDetectorRef.markForCheck();
      });

    this.serverService.status()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (data: StatusDto) => {
          this.sharedService.setVersion(data.version);
          if (data.outage.running && !data.outage.showBanner) {
            if (PlatformCommonService.isNativeApp) {
              void this.router.navigate(['/zavreno-native']);
            } else {
              void this.router.navigate(['/zavreno']);
            }
            return;
          }

          this.outage = data.outage;
          this.changeDetectorRef.detectChanges();
        },
        error: () => {
          if (PlatformCommonService.isNativeApp) {
            void this.router.navigate(['/zavreno-native']);
          } else {
            void this.router.navigate(['/zavreno']);
          }
        },
      });

    // show only in browser
    if (this.platformCommonService.isBrowser) {
      return;
    }

    // check if banner has not been already shown
    if (this.sessionStorageService.getItem(SW_UPDATE_KEY)) {
      // check if should be update banner shown
      this.configuratorCacheService.getFeSystemParam<boolean>('SW_UPDATE_BANNER_ENABLED', 'BOOLEAN')
        .pipe(
          // pass only if is banner enabled
          filter((isSwUpdateBannerEnabled) => isSwUpdateBannerEnabled),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          // show & set banner content
          this.displayUpdateBanner = true;

          // hide banner automatically after X hours
          this.ngZoneUtilService.simpleTimerOut$(
            () => this.hideUpdateBanner(),
            this.ngUnsubscribe,
            DateUtils.convertDaysToMilliseconds(this.SW_UPDATE_BANNER_TIMEOUT_DAYS),
          );

          this.changeDetectorRef.markForCheck();
        });
    }
  }

  private resetAll(): void {
    this.userId = null;
    this.showDac7DataValidationRequired = false;
    this.showCrossDomainLoginWarning = false;
    this.routeToEditPersonalDataByUserType = 'PERSONAL_DATA';
    this.showMalformedEmailBanner = false;
    this.accountBlockReasonId = null;
    this.showUnconfirmedRegistrationBanner = false;
    this.showSetPasswordBanner = false;
    this.showPhoneVerificationRequiredBanner = false;
    this.pvaBanner = null;
    this.changeDetectorRef.markForCheck();
  }

  public get hasBanner(): boolean {
    return !isNil(this.blockBannerType)
      || !isNil(this.outage?.message)
      || this.showMalformedEmailBanner
      || this.showUnconfirmedRegistrationBanner
      || this.showSetPasswordBanner
      || this.showPhoneVerificationRequiredBanner
      || this.showDac7DataValidationRequired
      || this.showCrossDomainLoginWarning
      || !isNil(this.pvaBanner);
  }

  public hideUpdateBanner(): void {
    // unset banner content, so banner will be hidden
    this.displayUpdateBanner = false;

    // remove sw update key, so banner won't be shown again
    this.sessionStorageService.removeItem(SW_UPDATE_KEY);

    this.changeDetectorRef.markForCheck();
  }

  public sendConfirmationEmailAgain(): void {
    if (this.clickingOnResendAgainLink || ['resendLimit', 'userTerminated'].includes(this.resendEmailStatus)) {
      return;
    }
    this.clickingOnResendAgainLink = true;
    this.userService.resendActivationEmail(this.userId)
      .pipe(
        take(1),
        finalize(() => this.clickingOnResendAgainLink = false),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        () => {
          this.resendEmailStatus = 'resendDone';
          this.toastService.showInfo({ key: 'RESEND_CONFIRMATION_EMAIL_SUCCESS_TOAST' });
        },
        (error: HttpError) => {
          if (this.errorsService.isUserTerminated(error)) {
            this.resendEmailStatus = 'userTerminated';
            this.toastService.showWarning({ key: 'RESEND_CONFIRMATION_EMAIL_TERMINATED_TOAST' });
          } else {
            this.resendEmailStatus = 'resendLimit';
            this.toastService.showWarning({ key: 'RESEND_CONFIRMATION_EMAIL_LIMIT_TOAST' });
          }
        },
      );
  }

  public get isBlockedDueToDuplicatePhone(): boolean {
    return this.accountBlockReasonId === USER_BLOCK_REASONS['BLOCKED_DUE_TO_DUPLICATE_VERIFIED_PHONE_NUMBER'];
  }

  public onPhoneVerificationEndingClick(): void {
    this.userTaskService.executeTaskWithCommonPayload$('VERIFIED_PHONE', { action: 'VERIFY_PHONE' })
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
