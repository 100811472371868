import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, PermissionStatus, Photo } from '@capacitor/camera';
import { map, switchMap, take, takeUntil } from 'rxjs';
import { Filesystem } from '@capacitor/filesystem';
import { AukButtonDialogComponent } from '@common/ui-kit/component/form/auk-button-dialog/component/auk-button-dialog.component';
import { AukButtonDialogModel } from '@common/ui-kit/component/form/auk-button-dialog/model/auk-button-dialog.model';
import { decode } from 'base64-arraybuffer';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { CameraDialogStatus } from '../const/camera-dialog-status.const';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { MatDialogService } from '@common/dialog/service/mat-dialog.service';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';
import { Dialog } from '@capacitor/dialog';

const DEFAULT_CAMERA_RESULT_QUALITY = 90;
const DEFAULT_GALLERY_LIMIT = 8;

@Injectable({
  providedIn: 'root',
})
export class CameraService extends NgUnsubscribe {

  constructor(private readonly matDialogService: MatDialogService<BaseDialogType>) {
    super();
  }

  public async getCameraImages(galleryLimit: number = DEFAULT_GALLERY_LIMIT, quality: number = DEFAULT_CAMERA_RESULT_QUALITY):
    Promise<File[]> {
    if (!PlatformCommonService.isNativeApp) {
      return [];
    }

    const permisionAllowed = await this.checkPermisions();

    if (!permisionAllowed) {
      return [];
    }

    const dialog = this.matDialogService.openSimple$<
      AukButtonDialogComponent,
      AukButtonDialogModel,
      CameraDialogStatus
    >(
      AukButtonDialogComponent,
      {
        width: '100%',
        position: { bottom: '0px', left: '0px' },
        data: {
          name: { key: 'MOBILE_CAMERA_DIALOG_NAME' },
          showCloseButton: true,
          buttons: [{
            text: { key: 'MOBILE_CAMERA_DIALOG_CAMERA' },
            action: CameraDialogStatus.CAMERA,
            icon: 'gallery',
            colorCombination: 'SURFACE_3',
          }, {
            text: { key: 'MOBILE_CAMERA_DIALOG_GALLERY' },
            action: CameraDialogStatus.GALLERY,
            icon: 'camera',
            colorCombination: 'SURFACE_3',
          }],
        },
      },
    );

    return new Promise<File[]>((resolve, reject) => {
      dialog.pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        take(1),
        map(async(result: CameraDialogStatus) => {
          if (!result || result === CameraDialogStatus.CLOSE) {
            return [];
          }

          if (result === CameraDialogStatus.CAMERA) {
            const photo = await Camera.getPhoto({
              quality,
              resultType: CameraResultType.Base64,
              source: CameraSource.Camera,
            });

            const blobImage = this.b64toBlob(photo);

            // Convert Blob to File
            const file = new File([blobImage], 'photo.jpg', { type: 'image/jpeg' });
            return [file];
          }

          if (result === CameraDialogStatus.GALLERY) {
            const images = await Camera.pickImages({
              quality,
              limit: galleryLimit,
            });
            if (images.photos?.length === 0) {
              throw new Error('No photos selected');
            }
            const blobImagePromises = images.photos.map(async image => {
              const contents = await Filesystem.readFile({
                path: image.path || image.webPath,
              });
              if (typeof contents.data === 'string') {
                const blobImage = this.b64toBlob({ base64String: contents.data, format: image.format, saved: false });
                // Convert Blob to File
                return new File([blobImage], 'photo.jpg', { type: 'image/jpeg' });
              }
            });
            return Promise.all(blobImagePromises);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
        .subscribe({
          next: (result) => {
            resolve(result);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  private async checkPermisions(): Promise<boolean> {
    if (!PlatformCommonService.isNativeApp) {
      return Promise.resolve(false);
    }

    const permissionResult = await Camera.checkPermissions();

    if (!this.getPermissionResult(permissionResult)) {
      return await this.requestPermissions();
    }

    return Promise.resolve(true);
  }

  private async requestPermissions(): Promise<boolean> {
    const permissionResult = await Camera.requestPermissions({ permissions: ['camera', 'photos'] });

    return this.getPermissionResult(permissionResult);
  }

  private getPermissionResult(permissionResult: PermissionStatus): boolean {
    if (permissionResult.photos === 'denied') {
      void Dialog.alert({
        title: 'Přístup k fotografiím',
        message: 'Přístup k fotografiím byl zamítnut. Prosím povolte ho v nastavení telefonu.',
      });
    }

    if (permissionResult.camera === 'denied') {
      void Dialog.alert({
        title: 'Přístup k fotoaparátu',
        message: 'Přístup k fotoaparátu byl zamítnut. Prosím povolte ho v nastavení telefonu.',
      });
    }

    if ((permissionResult.photos === 'granted' || permissionResult.photos === 'limited') && permissionResult.camera === 'granted') {
      return true;
    }

    return false;
  }

  private b64toBlob(photo: Photo): Blob {
    return new Blob([new Uint8Array(decode(photo.base64String))], {
      type: `image/${ photo.format }`,
    });
  }

}
