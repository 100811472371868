import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ExchangeRatesDto } from '../model/exchange-rates-dto';
import { environment } from '@environment';

export interface ExchangeRatesRequestParams {
    /** Currency for which exchange rates are requested */
    currency: 'CZK' | 'EUR' | 'PLN' | 'HUF' | 'RON' | 'BGN';
}

@Injectable({
  providedIn: 'root',
})
export class CurrencyApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Retrieve exchange rates for given currency
   */
  public exchangeRates$(
    requestParameters: ExchangeRatesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ExchangeRatesDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      currency: requestParameters.currency,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ExchangeRatesDto'),
      headers,
    };
    return this.httpClient.request<ExchangeRatesDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/currency`,
      options,
    );
  }

}
