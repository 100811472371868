import { DomainCode } from '@shared/domain/model/domain-code.type';
import { BaseDomainConfig } from '@shared/domain/module/domain-config/domain/base-domain-config';
import { DomainConfigCzService } from '@shared/domain/module/domain-config/service/domain-config-cz.service';
import { LocaleUtil } from '@shared/locale/util/locale.util';
import { Injector, Type } from '@angular/core';
import { DomainConfigSkService } from '@shared/domain/module/domain-config/service/domain-config-sk.service';
import { DomainConfigFrService } from '@shared/domain/module/domain-config/service/domain-config-fr.service';
import { DomainConfigAtService } from '@shared/domain/module/domain-config/service/domain-config-at.service';
import { DomainConfigSiService } from '@shared/domain/module/domain-config/service/domain-config-si.service';
import { DomainConfigGrService } from '@shared/domain/module/domain-config/service/domain-config-gr.service';
import { DomainConfigBeService } from '@shared/domain/module/domain-config/service/domain-config-be.service';
import { DomainConfigDeService } from '@shared/domain/module/domain-config/service/domain-config-de.service';
import { DomainConfigNlService } from '@shared/domain/module/domain-config/service/domain-config-nl.service';
import { DomainConfigPlService } from '@shared/domain/module/domain-config/service/domain-config-pl.service';
import { DomainConfigHrService } from '@shared/domain/module/domain-config/service/domain-config-hr.service';
import { DomainConfigBgService } from '@shared/domain/module/domain-config/service/domain-config-bg.service';
import { DomainConfigHuService } from '@shared/domain/module/domain-config/service/domain-config-hu.service';
import { DomainConfigItService } from '@shared/domain/module/domain-config/service/domain-config-it.service';
import { DomainConfigRoService } from '@shared/domain/module/domain-config/service/domain-config-ro.service';
import { CountryCodeEnum } from '@shared/domain/model/country-code.enum';
import { ObjectUtils } from '@util/util/object.utils';
import isNil from 'lodash-es/isNil';

export class DomainConfigUtil {

  // [ADD_DOMAIN]
  private static domainConfigsMap: Record<DomainCode, Type<BaseDomainConfig>> = {
    CZ: DomainConfigCzService,
    SK: DomainConfigSkService,
    AT: DomainConfigAtService,
    SI: DomainConfigSiService,
    GR: DomainConfigGrService,
    BE: DomainConfigBeService,
    DE: DomainConfigDeService,
    FR: DomainConfigFrService,
    NL: DomainConfigNlService,
    PL: DomainConfigPlService,
    HR: DomainConfigHrService,
    BG: DomainConfigBgService,
    HU: DomainConfigHuService,
    IT: DomainConfigItService,
    RO: DomainConfigRoService,
  };

  /**
   * Is initialized in {@link getDomainConfigByCountryId}
   */
  private static domainConfigsMapByCountryId: Record<CountryCodeEnum, BaseDomainConfig>;

  public static getDomainConfig(domainCode: DomainCode): Type<BaseDomainConfig> {
    return DomainConfigUtil.domainConfigsMap[domainCode]
      // get fallback domain config
      ?? DomainConfigUtil.domainConfigsMap[LocaleUtil.getDefaultLocale().country];
  }

  public static getDomainConfigByCountryId(
    countryId: CountryCodeEnum,
    injector: Injector,
  ): BaseDomainConfig {
    // initialize config if it's not already initialized
    if (isNil(this.domainConfigsMapByCountryId)) {
      this.domainConfigsMapByCountryId = ObjectUtils.values(this.domainConfigsMap)
        .reduce((acc, domainConfigServiceType) => {

          const domainConfigService = injector.get(domainConfigServiceType);

          acc[domainConfigService.getConfig().countryCode] = domainConfigService;

          return acc;
        }, {} as Record<CountryCodeEnum, BaseDomainConfig>);
    }

    return this.domainConfigsMapByCountryId[countryId.toString()] as BaseDomainConfig;
  }

}
