import { Injectable } from '@angular/core';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { Observable } from 'rxjs';
import { UserAccountVerificationService } from '@shared/user-account-verification/service/user-account-verification.service';
import { UserActionPrerequisiteFulfillmentService } from '@shared/user-action/service/fulfillment/user-action-prerequisite-fulfillment.service';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteVerifiedBankAccountFulfillmentService
  extends UserActionPrerequisiteFulfillmentService<['VERIFIED_BANK_ACCOUNT']> {

  public readonly prerequisiteCombination: ['VERIFIED_BANK_ACCOUNT'] = ['VERIFIED_BANK_ACCOUNT'];

  constructor(
    private readonly userAccountVerificationService: UserAccountVerificationService,
  ) {
    super();
  }

  public fulfillPrerequisite(userAction: UserActionType): Observable<void> {
    // Task VERIFIED_BANK_ACCOUNT can not be started directly because verification could be also processed by card payment
    return this.userAccountVerificationService.startUserAccountVerification$('VERIFIED_BANK_ACCOUNT', userAction, true);
  }

}
