/**
 * Unit maps for key from API to czech strings including plurals
 */
export const UNIT_CODE_MAPPINGS_CS = {
  PAIRS: {
    '=0': 'párů',
    '=1': 'pár',
    '=2': 'páry',
    '=3': 'páry',
    '=4': 'páry',
    other: 'párů',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'páry',
    '=1': 'pár',
    other: 'páry',
  },
  PIECES: {
    '=0': 'kusů',
    '=1': 'kus',
    '=2': 'kusy',
    '=3': 'kusy',
    '=4': 'kusy',
    other: 'kusů',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'kusy',
    '=1': 'kus',
    other: 'kusy',
  },
  PIECE_SHORT: {
    other: 'ks',
  },
  SET: {
    '=0': 'sad',
    '=1': 'sada',
    '=2': 'sady',
    '=3': 'sady',
    '=4': 'sady',
    other: 'sad',
  },
  SET_ACCUSATIVE: {
    '=0': 'sady',
    '=1': 'sadu',
    other: 'sady',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'přihazuje',
    '=2': 'přihazují',
    '=3': 'přihazují',
    '=4': 'přihazují',
    other: 'přihazuje',
  },
  BET_PAST: {
    '=1': 'přihazoval',
    '=2': 'přihazovali',
    '=3': 'přihazovali',
    '=4': 'přihazovali',
    other: 'přihazovalo',
  },
  ITEM: {
    '=0': 'položek',
    '=1': 'položku',
    '=2': 'položky',
    '=3': 'položky',
    '=4': 'položky',
    other: 'položek',
  },
  DAY: {
    '=0': 'dní',
    '=1': 'den',
    '=2': 'dny',
    '=3': 'dny',
    '=4': 'dny',
    other: 'dní',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'dnem',
    other: 'dny',
  },
  HOUR: {
    '=0': 'hodin',
    '=1': 'hodina',
    '=2': 'hodiny',
    '=3': 'hodiny',
    '=4': 'hodiny',
    other: 'hodin',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'hodinou',
    other: 'hodinami',
  },
  MINUTE: {
    '=0': 'minut',
    '=1': 'minuta',
    '=2': 'minuty',
    '=3': 'minuty',
    '=4': 'minuty',
    other: 'minut',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minutou',
    other: 'minutami',
  },
  OTHER: {
    '=0': 'další',
    '=1': 'další',
    '=2': 'další',
    '=3': 'další',
    '=4': 'další',
    other: 'dalších',
  },
  FILE: {
    '=0': 'souborů',
    '=1': 'soubor',
    '=2': 'soubory',
    '=3': 'soubory',
    '=4': 'soubory',
    other: 'souborů',
  },
  IMAGE: {
    '=0': 'obrázků',
    '=1': 'obrázek',
    '=2': 'obrázky',
    '=3': 'obrázky',
    '=4': 'obrázky',
    other: 'obrázků',
  },
  OFFER: {
    '=0': 'nabídek',
    '=1': 'nabídka',
    '=2': 'nabídky',
    '=3': 'nabídky',
    '=4': 'nabídky',
    other: 'nabídek',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'nabídek',
    '=1': 'nabídku',
    '=2': 'nabídky',
    '=3': 'nabídky',
    '=4': 'nabídky',
    other: 'nabídek',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozitivních hodnocení',
    '=1': 'pozitivní hodnocení',
    '=2': 'pozitivní hodnocení',
    '=3': 'pozitivní hodnocení',
    '=4': 'pozitivní hodnocení',
    other: 'pozitivních hodnocení',
  },
  USER_GENITIV: {
    '=0': 'uživatelů',
    '=1': 'uživatele',
    '=2': 'uživatelů',
    '=3': 'uživatelů',
    '=4': 'uživatelů',
    other: 'uživatelů',
  },
  USER_INSTRUMENTAL: {
    '=0': 'uživatelem',
    '=1': 'uživatelem',
    other: 'uživateli',
  },
  ADDED_PASSIVE: {
    '=0': 'přidáno',
    '=1': 'přidána',
    '=2': 'přidány',
    '=3': 'přidány',
    '=4': 'přidány',
    other: 'přidáno',
  },
};
