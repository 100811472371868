import { Injectable, OnDestroy } from '@angular/core';
import { App, BackButtonListenerEvent, URLOpenListenerEvent } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationStart, Router } from '@angular/router';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PluginListenerHandle } from '@capacitor/core';
import { Location } from '@angular/common';
import { BundleInfo, CapacitorUpdater, CurrentBundleResult, DownloadCompleteEvent, DownloadEvent, DownloadFailedEvent, LatestVersion } from '@capgo/capacitor-updater';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { CookieService } from '@common/cookie/service/cookie.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import moment from 'moment-mini-ts';
import { UrlService } from '@shared/platform/url.service';
import { BehaviorSubject, combineLatest, combineLatestWith, filter, finalize, from, mergeMap, Observable, of, take, takeUntil } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Dialog } from '@capacitor/dialog';
import { DateUtils } from '@util/util/date.utils';
import { captureException } from '@sentry/browser';
import { LoggerService } from '@common/logger/service/logger.service';
import { Network } from '@capacitor/network';
import { Directory, FileInfo, Filesystem } from '@capacitor/filesystem';
import { Nil } from '@util/helper-types/nil';
import { DomainService } from '@shared/domain/service/domain.service';
import { NativeAppUpdateNavigationService } from '@shared/native-app/service/native-app-update-navigation.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { NativeAppTranslationService } from './native-app-translation.service';
import { RoutesService } from '@shared/services/app/routes.service';

@Injectable({
  providedIn: 'root',
})
export class NativeAppService extends NgUnsubscribe implements OnDestroy {

  private backButtonListener: PluginListenerHandle;
  private appUrlOpenListener: PluginListenerHandle;

  public isUpdateDownloadInProgress: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isUpdateCheckInProgress: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private capacitorBundleUpdate: BundleInfo | Nil = null;
  private nativeAppUpdateInProgess: boolean = false;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly routesService: RoutesService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly cookieService: CookieService,
    private readonly urlService: UrlService,
    private readonly domainService: DomainService,
    private readonly nativeAppUpdateNavigationService: NativeAppUpdateNavigationService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly nativeAppTranslationService: NativeAppTranslationService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public init(): void {
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    // Navigate to last known route after package update
    this.registerRouterListener();

    void this.cleanOldAppBundles();
    void CapacitorUpdater.notifyAppReady();
    void this.registerBackSwipeListener();
    void this.registerDeepLinkListener();
    void this.registerUpdateListeners();
    void this.replaceHistoryForNativeBackButton();
    void StatusBar.setStyle({ style: Style.Light });
    if (PlatformCommonService.isNativeAndroidApp) {
      void StatusBar.setBackgroundColor({ color: '#FFFFFF' });
    }

    if (
      PlatformCommonService.isNativeIosApp &&
      'scrollRestoration' in history
    ) {
      history.scrollRestoration = 'manual';
    }

    //Continuous update chceck (initial and then every 20 minutes)
    this.ngZoneUtilService.timerOut$(DateUtils.convertMinutesToMilliseconds(1), DateUtils.convertMinutesToMilliseconds(20))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updaterModalCheck();
      });
  }

  private registerRouterListener(): void {
    this.routesService.routeChange$
      .pipe(
        take(1),
        mergeMap(() => this.navigateToLastKnownRoute()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private async navigateToLastKnownRoute(): Promise<void> {
    const lastRoute = await this.nativeAppUpdateNavigationService.retrieveCurrentRoute();
    if (lastRoute) {
      await this.router.navigateByUrl(lastRoute);
      await this.nativeAppUpdateNavigationService.clearCurrentRoute();
    }
  }

  private replaceHistoryForNativeBackButton(): void {
    this.router.events
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart && event.url === '/404') {
          this.location.replaceState('/');
        }
      });
  }

  private async registerBackSwipeListener(): Promise<void> {
    this.backButtonListener = await App.addListener(
      'backButton',
      (data: BackButtonListenerEvent) => {
        if (data.canGoBack) {
          this.location.back();
        } else {
          void App.exitApp();
        }
      },
    );
  }

  private isUpdateNeeded(currentVersion, updateVersion): boolean {
    const updateNeeded = updateVersion.localeCompare(
      currentVersion || '0',
      undefined,
      { numeric: true, sensitivity: 'base' },
    );
    if (updateNeeded === 1 || currentVersion === 'builtin') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * This function accepts file and directory and deletes the file / directory if its missplaced
   * Versions is the only folder where we save any data (actual place for app packages)
   * @param file FileInfo from Filesystem.readdir
   * @param directory Directory from Capacitor
   */
  private async cleanData(file: FileInfo, directory: Directory): Promise<void> {
    if (file.uri.includes('versions')) {
      return;
    }
    if (file.type === 'directory') {
      try {
        await Filesystem.rmdir({ path: file.name, directory, recursive: true });
      } catch {
        this.loggerService.logMessage(
          'Bundle Native Custom directory Delete Error',
          {
            tags: { context: 'native', result: 'failure' },
          },
        );
      }
    }
    if (file.type === 'file') {
      try {
        await Filesystem.deleteFile({ path: file.name, directory });
      } catch {
        this.loggerService.logMessage(
          'Bundle Native Custom file Error', {
            tags: { context: 'native', result: 'failure' },
          },
        );
      }
    }
  }

  private async cleanOldAppBundles(): Promise<void> {
    const currentBundleId = (await CapacitorUpdater.current()).bundle?.id;
    const listOfPackages = await CapacitorUpdater.list();

    const documents = await Filesystem.readdir({
      path: '/',
      directory: Directory.Documents,
    });
    const data = await Filesystem.readdir({
      path: '/',
      directory: Directory.Data,
    });

    documents.files.forEach(file => {
      void this.cleanData(file, Directory.Documents);
    });

    data.files.forEach(file => {
      void this.cleanData(file, Directory.Data);
    });

    if (listOfPackages?.bundles?.length < 2 && !currentBundleId) {
      return;
    }

    listOfPackages.bundles.forEach(bundle => {
      if (bundle.id !== currentBundleId) {
        try {
          void CapacitorUpdater.delete({ id: bundle.id });
        } catch {
          this.loggerService.logException(new Error('Bundle Native Delete Error'), {
            tags: { context: 'native', result: 'failure' },
          });
        }
      }
    });
  }

  public checkUpdate(): Promise<void> {
    if (this.isUpdateCheckInProgress.value || this.nativeAppUpdateInProgess) {
      return;
    }

    if (this.capacitorBundleUpdate) {
      void this.forceAppUpdate(this.capacitorBundleUpdate);
    }

    const translations = this.nativeAppTranslationService.getTranslations(this.domainService.lang);

    this.isUpdateAvailable()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((updateData: { url: string; version: string } | boolean) => {
        if (typeof updateData === 'object' && updateData.url && updateData.version) {
          void Dialog.alert({
            title: translations.APP_UPDATE_HEADER,
            message: translations.APP_UPDATE_DOWNLOAD,
            buttonTitle: translations.APP_UPDATE_CLOSE,
          });

          if (!this.nativeAppUpdateInProgess) {
            void this.downloadAppUpdate(updateData).then((bundleData: BundleInfo) => {
              this.applyUpdate(bundleData);
            });
          }
        } else {
          void Dialog.alert({
            title: translations.APP_UPDATE_CHECK,
            message: translations.APP_UPDATE_UNAVAILABLE,
            buttonTitle: translations.APP_UPDATE_CLOSE,
          });

          this.isUpdateCheckInProgress.next(false);
        }
      });
  }

  public updaterModalCheck(): void {
    if (this.isUpdateCheckInProgress.value || this.nativeAppUpdateInProgess) {
      return;
    }

    if (this.capacitorBundleUpdate) {
      void this.forceAppUpdate(this.capacitorBundleUpdate);
    }

    this.isUpdateCheckInProgress.next(true);

    this.isUpdateAvailable()
      .pipe(
        filter(updateData => {
          if (updateData === false) {
            this.isUpdateCheckInProgress.next(false);
          }
          return updateData !== false;
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((updateData: { url: string; version: string }) => {
        void this.downloadAppUpdate(updateData);
      });
  }

  private async downloadAppUpdate(updateData: {
    url: string;
    version: string;
    manual?: boolean;
  }): Promise<BundleInfo> {
    if (this.isUpdateDownloadInProgress.value) {
      return;
    }

    this.isUpdateDownloadInProgress.next(true);

    const status = await Network.getStatus();

    if (status.connectionType === 'cellular') {
      this.loggerService.logMessage(
        `Downloading app update via MobileData`,
        {
          extra: {
            updateData,
          },
        },
      );
    }

    //This will download package on background and then apply it after user confirms the modal
    const data = await CapacitorUpdater?.download({
      version: updateData.version,
      url: updateData.url,
    });

    void CapacitorUpdater?.next(data);

    return data;
  }

  private forceAppUpdate(data: BundleInfo): void {
    combineLatest([
      this.configuratorCacheService.getFeSystemParam<string>(
        'FORCE_APP_UPDATE_MINIMUM_PACKAGE_VERSION',
        'STRING',
      ),
      from(CapacitorUpdater.current()),
    ])
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([forceAppMinimumPackageVersion, curentBundle]: [string, CurrentBundleResult]) => {
        const updateNeeded = forceAppMinimumPackageVersion.localeCompare(
          curentBundle.bundle.version || '0',
          undefined,
          { numeric: true, sensitivity: 'base' },
        );
        if (updateNeeded === 1 || curentBundle.bundle.version === 'builtin') {
          this.applyUpdate(data);
        }
      });
  }

  private applyUpdate(data: BundleInfo): void {
    const translations = this.nativeAppTranslationService.getTranslations(this.domainService.lang);

    from(
      Dialog.alert({
        title: translations.APP_UPDATE_HEADER,
        message: translations.APP_UPDATE_DONE,
        buttonTitle: translations.APP_UPDATE_CONFIRM,
      }),
    )
      .pipe(
        take(1),
        finalize(() => {
          this.isUpdateCheckInProgress.next(false);
          this.isUpdateDownloadInProgress.next(false);
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        try {
          void this.nativeAppUpdateNavigationService.saveCurrentRoute(this.router.url);
          void CapacitorUpdater?.set(data);
        } catch (err) {
          console.log(err);
          captureException(err);
        }
      });
  }

  public isUpdateAvailable(): Observable<
    boolean | { url: string; version: string }
    > {
    return from(CapacitorUpdater.getLatest())
      .pipe(
        combineLatestWith(from(CapacitorUpdater.current())),
        mergeMap(
          ([updateData, curentBundle]: [
            LatestVersion,
            CurrentBundleResult,
          ]) => {
            if (
              this.isUpdateNeeded(
                curentBundle.bundle.version,
                updateData.version,
              )
            ) {
              return of({
                version: updateData.version,
                url: updateData.url,
              });
            } else {
              return of(false);
            }
          },
        ),
      );
  }

  private async registerDeepLinkListener(): Promise<void> {
    this.appUrlOpenListener = await App.addListener(
      'appUrlOpen',
      (event: URLOpenListenerEvent) => {
        this.ngZoneUtilService.runIn(() => {
          // On Android sometimes from Barion App opens firectly with our custom opener, we need to remove /open-android part
          const cleanUrl = event.url?.replace('/open-android', '');
          const url = new URL(cleanUrl);
          if (url?.pathname) {
            this.urlService
              .reverseTranslateUrl(
                url.pathname + url.search,
                this.domainService.getLangByHostname(url.hostname),
              )
              .pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe({
                next: (translatedRoute: string) => {
                  void this.router.navigateByUrl(translatedRoute);
                },
              });
          }
        });
      },
    );
  }

  private async registerUpdateListeners(): Promise<void> {
    this.appUrlOpenListener = await CapacitorUpdater.addListener(
      'download',
      (event: DownloadEvent) => {
        this.isUpdateDownloadInProgress.next(true);
        this.nativeAppUpdateInProgess = true;
      },
    );

    this.appUrlOpenListener = await CapacitorUpdater.addListener(
      'downloadComplete',
      (event: DownloadCompleteEvent) => {
        this.capacitorBundleUpdate = event.bundle;
        this.forceAppUpdate(event.bundle);
      },
    );

    this.appUrlOpenListener = await CapacitorUpdater.addListener(
      'downloadFailed',
      (event: DownloadFailedEvent) => {
        this.isUpdateDownloadInProgress.next(false);
        this.nativeAppUpdateInProgess = false;
      },
    );
  }

  /**
   * Saves the Aukro token to cookie manually if it is a native app.
   * (In native iOS apps, cookies from the Set-Cookie header are not readable in JS.
   * Therefore, we need to manually save the Aukro token to a cookie.)
   * @param aukroToken to be saved
   */
  public saveAukroTokenIfNativeApp(aukroToken: string): void {
    if (!PlatformCommonService.isNativeApp) {
      return;
    }

    this.cookieService.put(
      TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY,
      aukroToken,
      {
        expires: moment().add(365, 'days').toDate(),
      },
    );
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    void this.appUrlOpenListener?.remove();
    void this.backButtonListener?.remove();
  }

}
