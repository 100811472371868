import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { TranslationService } from '@api/generated/api/Translation';
import { Observable, of } from 'rxjs';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { TranslationValueDto } from '@api/generated/defs/TranslationValueDto';
import { StringUtils } from '@util/util/string.utils';
import { LoggerService } from '@common/logger/service/logger.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { TranslationKey } from '@common/translations/model/translation-key';

@Injectable({
  providedIn: 'root',
})
export class TranslationCacheService extends NgUnsubscribe implements CacheAware {

  public translationsLoaded: boolean = false;
  private TRANSLATIONS: Record<string, string> = {};

  constructor(
    public readonly cacheService: CacheService,
    private readonly translationService: TranslationService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(5),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(15),
    key: 'TranslationCacheService#getAll',
    localeDependent: true,
  })
  public getAll(): Observable<Record<string, string>> {
    return this.translationService.getAllTranslations()
      .pipe(
        catchError((err: unknown) => {
          const title: string = 'TranslationService: Cannot load translations from endpoint';
          this.loggerService.logException(
            new Error(title),
            {
              fingerprint: [title],
              extra: {
                HttpError: err,
              },
            },
          );
          return of([]);
        }),
        filter((translations: TranslationValueDto[]) => translations !== null),
        tap((data) => {
          if (data) {
            this.translationsLoaded = true;
          }
        }),
        map((data) => {
          if (data) {
            this.TRANSLATIONS = this.mapTranslations(data);
          }
          return this.TRANSLATIONS;
        }),
      );
  }

  /**
   * Check if translations file contains given key, its value is not important
   * @param key Translation key
   */
  public hasTranslation(key: string): boolean {
    return this.TRANSLATIONS[key] !== undefined && !StringUtils.isEmpty(this.TRANSLATIONS[key]);
  }

  /**
   * Check if translations file contains given key, its value is not important
   * @param key Translation key
   */
  public hasTranslation$(key: string): Observable<boolean> {
    return this.getAll()
      .pipe(
        map((translations: Record<string, string>) => translations[key] !== undefined && !StringUtils.isEmpty(translations[key])),
      );
  }

  /** Returns the count of actual translations – only for logging purposes */
  public translationsCount(): number {
    return Object.keys(this.TRANSLATIONS).length;
  }

  public translationsContent(): Record<string, string> {
    return this.TRANSLATIONS;
  }

  /**
   * @returns translation in format {KEY: translation, KEY2: translation2, ...}
   */
  private mapTranslations(translationData: TranslationValueDto[]): Record<TranslationKey, string> {
    return translationData.reduce((acc, { key, value }) => {
      acc[key] = value;

      return acc;
    }, {} as Record<TranslationKey, string>);
  }

}
