import { Inject, Injectable } from '@angular/core';
import { GaClientInformationDto } from '@api/aukro-api/model/ga-client-information-dto';
import { Nil } from '@util/helper-types/nil';
import { GoogleAnalyticsConstants } from '@shared/google-analytics/const/google-analytics.constats';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { DOCUMENT } from '@angular/common';
import { CookieService } from '@common/cookie/service/cookie.service';
import { VivnetworksService } from '@shared/services/vivnetworks/vivnetworks.service';
import { RoivenueService } from '@shared/roivenue/service/roivenue.service';
import { ParamMap } from '@angular/router';
import { UrlTrackingParamsEnum } from '@shared/google-analytics/model/url-tracking-params.enum';
import { StringUtils } from '@util/util/string.utils';
import { filter, take, takeUntil } from 'rxjs/operators';
import { RoivenueIdsModel } from '@shared/roivenue/model/roivenue-ids.model';
import { PlatformService } from '@shared/platform/service/platform.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { GoogleAnalyticsService } from '@shared/google-analytics/service/google-analytics.service';
import { ObjectUtils } from '@util/util/object.utils';

/**
 * Responsible for saving and getting the data related to the Google Analytics client
 */
@Injectable({
  providedIn: 'root',
})
export class GaClientInformationService extends NgUnsubscribe {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly cookieService: CookieService,
    private readonly vivnetworksService: VivnetworksService,
    private readonly roivenueService: RoivenueService,
  ) {
    super();
  }

  public setCampaignDataWhenGaClientIdAvailable(queryParamMap: ParamMap): void {
    const utm_source: string = queryParamMap.get(UrlTrackingParamsEnum.UTM_SOURCE);
    const utm_medium: string = queryParamMap.get(UrlTrackingParamsEnum.UTM_MEDIUM);
    const utm_campaign: string = queryParamMap.get(UrlTrackingParamsEnum.UTM_CAMPAIGN);
    const utm_content: string = queryParamMap.get(UrlTrackingParamsEnum.UTM_CONTENT);
    const utm_term: string = queryParamMap.get(UrlTrackingParamsEnum.UTM_TERM);
    const gclid: string = queryParamMap.get(UrlTrackingParamsEnum.GCLID);
    const dclid: string = queryParamMap.get(UrlTrackingParamsEnum.DCLID);
    const fbclid: string = queryParamMap.get(UrlTrackingParamsEnum.FBCLID);
    const cjevent: string = queryParamMap.get(UrlTrackingParamsEnum.CJEVENT);

    if (StringUtils.isEmpty(utm_source)
      && StringUtils.isEmpty(utm_medium)
      && StringUtils.isEmpty(utm_campaign)
      && StringUtils.isEmpty(utm_content)
      && StringUtils.isEmpty(utm_term)
      && StringUtils.isEmpty(gclid)
      && StringUtils.isEmpty(dclid)
      && StringUtils.isEmpty(fbclid)
      && StringUtils.isEmpty(cjevent)
    ) {
      return;
    }

    this.googleAnalyticsService.getGaClientId$()
      .pipe(
        take(1),
        filter(isNotNil),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((gaClientID: string) => {
        const roiVenueIds: RoivenueIdsModel = this.roivenueService.getRoivenueIds();
        const gaClientInformation: GaClientInformationDto = {
          campaignContent: utm_content,
          campaignMedium: utm_medium,
          campaignName: utm_campaign,
          campaignSearchedTerm: utm_term,
          campaignSource: utm_source,
          gaClientID,
          googleClickIdentifier: gclid,
          referrer: this.document.referrer,
          dclid,
          roiVenueId: roiVenueIds?.roiVenueId,
          roiVenueClientId: roiVenueIds?.roiVenueClientId,
          platformType: PlatformService.platformType,
          facebookClickIdentifier: fbclid,
        };
        if (!ObjectUtils.isNilOrEmptyOrHasAllNilValues(gaClientInformation)) {
          this.cookieService.putObject(GoogleAnalyticsConstants.GA_CLIENT_INFORMATION_SESSION_KEY, gaClientInformation);
          this.vivnetworksService.saveVivnetworksCje();
        }
      });
  }

  public getGaClientInformation(): GaClientInformationDto | Nil {
    let information: GaClientInformationDto | Nil = null;
    try {
      information = this.cookieService.getObject<GaClientInformationDto>(GoogleAnalyticsConstants.GA_CLIENT_INFORMATION_SESSION_KEY);

      const validVivnetworksCje: string = this.vivnetworksService.getValidVivnetworksCje();
      if (isNotNil(validVivnetworksCje)) {
        information = {
          ...information,
          vivNetworksId: validVivnetworksCje,
        };
      }
    } catch (error) {
      console.info('Cannot retrieve gaClientInformationDto', error.toString());
    }
    return information;
  }

}
