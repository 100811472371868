import { Injectable } from '@angular/core';
import { combineLatestWith, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { CurrencyService } from './currency.service';
import { CurrencyConfigElementEnumModel } from '../model/currency-config-element-enum.model';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UserCurrencyPreferenceDataService } from './user-currency-preference.data.service';
import { CurrencyCodeType } from '../model/currency-code.type';
import { isNotNil } from '@util/helper-functions/is-not-nil';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyPreferenceService extends NgUnsubscribe {

  constructor(
    private readonly currencyService: CurrencyService,
    private readonly userCurrencyPreferenceDataService: UserCurrencyPreferenceDataService,
  ) {
    super();
  }

  /**
   * Emits when preferred currency is changed. Does not emit first value on subscribe.
   */
  public get preferredCurrencyCodeChanged$(): Observable<void> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(
        distinctUntilChanged(),
        map(() => void 0),
        skip(1),
      );
  }

  public get preferredCurrencyCode$(): Observable<CurrencyCodeType> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(
        filter((currency) => isNotNil(currency)),
      );
  }

  public get preferredCurrencyCodeValue(): CurrencyCodeType {
    return this.userCurrencyPreferenceDataService.currentCurrencyCodeValue;
  }

  public get preferredCurrencyModel$(): Observable<CurrencyConfigElementEnumModel> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(
        combineLatestWith(this.currencyService.getCurrencies$()),
        map(([userCurrency, allCurrencies]) => this.findByCurrencyCodeOrDefault(allCurrencies, userCurrency)),
      );
  }

  public set preferredCurrencyCode(val: CurrencyCodeType) {
    this.userCurrencyPreferenceDataService.currentCurrencyCode = val;
  }

  private findByCurrencyCodeOrDefault(
    allCurrencies: CurrencyConfigElementEnumModel[],
    currencyCode: CurrencyCodeType,
  ): CurrencyConfigElementEnumModel {
    return allCurrencies.find((currency) => currency.code === currencyCode);
  }

}
