import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output, signal, WritableSignal,
} from '@angular/core';
import moment, { Duration, Moment } from 'moment-mini-ts';
import { Subscription } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { takeUntil } from 'rxjs/operators';
import { CountdownTimeModel } from './model/countdown-time.model';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { StringDate } from '@util/helper-types/string-date';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { CommonModule, I18nPluralPipe } from '@angular/common';
import { TimeUtil } from '@common/ui-kit/common/time/class/time.util';
import { MINUTES_THRESHOLD_TO_DISPLAY_SECONDS, TimeService } from '@common/time/service/time.service';
import { Translate2Module } from '@common/translations/translate2.module';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';

@Component({
  selector: 'auk-countdown-panel',
  templateUrl: './countdown-panel.component.html',
  styleUrls: ['./countdown-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,
    IconComponent,
  ],
})
export class CountdownPanelComponent extends ColorCombinationInputComponent implements OnChanges, OnDestroy {

  public static COUNTDOWN_STATE_LABEL_CLASS: string = 'countdown-state-label';

  private readonly timeRefreshInterval: number = 1_000;
  private readonly countdownThresholdSeconds: number = 30;

  /**
   * Tells if internal countdown timer should be used (INTERNAL mode),
   * or if coutdown timer logic is provided above and component only takes remaining time as a input (SHARED mode).
   */
  @Input() public countdownMode: 'SHARED' | 'INTERNAL' = 'INTERNAL';

  /**
   * Remaining time to end of countdown. Provide this value for SHARED countdown mode of component.
   */
  @Input() public remainingTime: Duration;

  /**
   * Ending time, when the countdown ends. Provide this value for INTERNAL countdown mode of componet.
   */
  @Input() public endingTime: StringDate | Moment;

  /**
   * Tells, if countdown should be visible
   */
  @Input() public isCountdownVisible: boolean = true;
  /**
   * Tells, if countdown text has background
   */
  @Input() public hasCountdownTextBg: boolean = false;

  /**
   * Tells, if countdown is used only as plain text for information
   */
  @Input() public isInfoCountdownText: boolean = false;
  @Input() public panelSize: 'DEFAULT' | 'COMPACT' = 'DEFAULT';

  @Output() public countdownEnd: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Notify parent components when there's less than one minute remaining
   */
  @Output() public lessThanOneMinute: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Notify parent components when animation was activated
   */
  @Output() public isCountdownAnimated: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected countdownTime: WritableSignal<CountdownTimeModel> = signal<CountdownTimeModel>(null);
  protected areSecondsDisplayed: boolean = false;
  protected isLessThan30Seconds: boolean = false;

  private countdownSub: Subscription;
  private isLessThanOneMinute: boolean = false;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly i18nPluralPipe: I18nPluralPipe,
    private readonly platformCommonService: PlatformCommonService,
    private readonly timeService: TimeService,
    private readonly domainService: DomainService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super(elementRef);
  }

  public override ngOnChanges(changes: AukSimpleChanges<CountdownPanelComponent>): void {
    super.ngOnChanges(changes);

    if (this.countdownMode === 'INTERNAL' && changes.endingTime) {
      const { currentValue, previousValue } = changes.endingTime;

      // initialize only if previous ending time is different than current ending time
      if (TimeUtil.areTimesDifferent(currentValue, previousValue)) {
        this.initializeCountdown(this.endingTime);
      }
    }

    if (this.countdownMode === 'SHARED' && changes.remainingTime) {
      this.updateCountdown(changes.remainingTime.currentValue);
    }
  }

  protected get getCountdownWidthPercent(): number {
    return this.isCountdownVisible ? this.countdownTime()?.remainingPercent : 100;
  }

  protected get containerClass(): string[] {
    const classList: string[] = [];

    if (this.areSecondsDisplayed) {
      classList.push('tw-bg-background-color', 'tw-py-0.5', 'tw-gap-x-1.5', 'tw-rounded-full', 'tw-z-10');

      if (this.panelSize === 'DEFAULT') {
        classList.push('tw-px-3');
      }
    }

    if (this.isLessThan30Seconds && this.panelSize === 'DEFAULT') {
      classList.push('tw--mr-5');
    }

    return classList;
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyCountdownSub();
  }

  private destroyCountdownSub(): void {
    this.countdownSub?.unsubscribe();
    this.countdownSub = null;
  }

  private initializeCountdown(endingTime: StringDate | Moment): void {
    this.destroyCountdownSub();

    if (isNil(endingTime)) {
      return;
    }

    const endingTimeParsed = moment(endingTime);
    const remainingTime = this.timeService.getRemainingTime(endingTimeParsed);
    const firstEmitTimerWaitTime = remainingTime.milliseconds();

    // update countdown timer at start
    this.updateInternalCountdown(endingTimeParsed);

    // don't init timer on SSR
    if (this.platformCommonService.isServer) {
      return;
    }

    // start countdown timer, it should occur exactly on every second
    this.countdownSub = this.ngZoneUtilService.timerOut$(firstEmitTimerWaitTime, this.timeRefreshInterval, false)
      .pipe(
        // take until countdown end emits
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.updateInternalCountdown(endingTimeParsed);
        this.changeDetectorRef.detectChanges();
      });
  }

  private updateInternalCountdown(endingTime: Moment): void {
    const remainingTime = this.timeService.getRemainingTime(endingTime);
    this.updateCountdown(remainingTime);
  }

  private updateCountdown(remainingTime: Duration): void {
    if (isNil(remainingTime)) {
      this.countdownTime.set(null);
      return;
    }

    const isLessThanOneMinute = remainingTime.asMinutes() < 1;
    const isLessThan30Seconds = remainingTime.asSeconds() < 30;

    const remainingTimeWithoutMilliseconds = TimeUtil.getTimeWithoutMilliseconds(remainingTime);

    // True if the remaining time is less than threshold to display seconds
    this.areSecondsDisplayed = remainingTime.asMinutes() < MINUTES_THRESHOLD_TO_DISPLAY_SECONDS;

    // Emit an event if the remaining time of 1 minute has been changed
    if (isLessThanOneMinute !== this.isLessThanOneMinute) {
      this.isLessThanOneMinute = isLessThanOneMinute;
      this.lessThanOneMinute.emit(isLessThanOneMinute);
    }

    // Emit an event if the remaining time of less than 30 seconds has been changed
    if (isLessThan30Seconds !== this.isLessThan30Seconds) {
      this.isLessThan30Seconds = isLessThan30Seconds;
      this.isCountdownAnimated.emit(isLessThan30Seconds);
    }

    // emit countdown end event, if remaining time equals 0
    if (remainingTime.asSeconds() <= 0) {
      this.onCountdownEnd();
    }

    this.countdownTime.set({
      timeText: TimeUtil.getHumanReadableRemainingTime(remainingTimeWithoutMilliseconds, this.i18nPluralPipe, this.domainService.lang),
      remainingPercent: this.calculateRemainingPercent(remainingTime),
    });
  }

  private onCountdownEnd(): void {
    this.countdownEnd.emit();
    this.destroyCountdownSub();

    // Reset countdown animation
    this.isLessThan30Seconds = false;
    this.isCountdownAnimated.emit(false);
  }

  private calculateRemainingPercent(remainingTime: Duration): number {
    // show 100 percent if remainingTime is NIL
    if (isNil(remainingTime)) {
      return 100;
    }

    const secondsTillEnd = remainingTime.asSeconds();

    // show 100 percent until countdown threshold
    if (secondsTillEnd > this.countdownThresholdSeconds) {
      return 100;
    }

    // calculate percents
    const percent = (secondsTillEnd / this.countdownThresholdSeconds) * 100;

    // if percent is negative, return 0 (it means countdown is finished)
    if (percent < 0) {
      return 0;
    }

    return percent;
  }

  protected get isInfoText(): boolean {
    return this.isInfoCountdownText && !this.areSecondsDisplayed && !this.isLessThan30Seconds;
  }

}
