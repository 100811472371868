/**
 * Unit maps for key from API to Slovenian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_SL = {
  PAIRS: {
    '=0': 'parov',
    '=1': 'par',
    '=2': 'pare',
    '=3': 'pare',
    '=4': 'pare',
    other: 'parov',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'pari',
    '=1': 'par',
    other: 'pari',
  },
  PIECES: {
    '=0': 'kosov',
    '=1': 'kos',
    '=2': 'kosi',
    '=3': 'kosi',
    '=4': 'kosi',
    other: 'kosov',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'kosi',
    '=1': 'kos',
    other: 'kosi',
  },
  PIECE_SHORT: {
    other: 'kos',
  },
  SET: {
    '=0': 'setov',
    '=1': 'set',
    '=2': 'seti',
    '=3': 'seti',
    '=4': 'seti',
    other: 'setov',
  },
  SET_ACCUSATIVE: {
    '=0': 'setov',
    '=1': 'set',
    other: 'setov',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'ponuja',
    '=2': 'ponujajo',
    '=3': 'ponujajo',
    '=4': 'ponujajo',
    other: 'ponuja',
  },
  BET_PAST: {
    '=1': 'ponujal',
    '=2': 'ponujali',
    '=3': 'ponujali',
    '=4': 'ponujali',
    other: 'ponujalo',
  },
  ITEM: {
    '=0': 'postavk',
    '=1': 'postavko',
    '=2': 'postavke',
    '=3': 'postavke',
    '=4': 'postavke',
    other: 'postavk',
  },
  DAY: {
    '=0': 'dni',
    '=1': 'dan',
    '=2': 'dnevi',
    '=3': 'dnevi',
    '=4': 'dnevi',
    other: 'dni',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'dnevom',
    other: 'dnevi',
  },
  HOUR: {
    '=0': 'ur',
    '=1': 'ura',
    '=2': 'ure',
    '=3': 'ure',
    '=4': 'ure',
    other: 'ur',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'uro',
    other: 'urami',
  },
  MINUTE: {
    '=0': 'minut',
    '=1': 'minuta',
    '=2': 'minute',
    '=3': 'minute',
    '=4': 'minute',
    other: 'minut',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minuto',
    other: 'minutami',
  },
  OTHER: {
    '=0': 'druge',
    '=1': 'druga',
    '=2': 'druge',
    '=3': 'druge',
    '=4': 'druge',
    other: 'druge',
  },
  FILE: {
    '=0': 'datotek',
    '=1': 'datoteka',
    '=2': 'datoteke',
    '=3': 'datoteke',
    '=4': 'datoteke',
    other: 'datotek',
  },
  IMAGE: {
    '=0': 'slik',
    '=1': 'slika',
    '=2': 'slike',
    '=3': 'slike',
    '=4': 'slike',
    other: 'slik',
  },
  OFFER: {
    '=0': 'ponud',
    '=1': 'ponuda',
    '=2': 'ponude',
    '=3': 'ponude',
    '=4': 'ponude',
    other: 'ponud',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'ponud',
    '=1': 'ponudo',
    '=2': 'ponude',
    '=3': 'ponude',
    '=4': 'ponude',
    other: 'ponud',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozitivnih komentarjev',
    '=1': 'pozitiven komentar',
    '=2': 'pozitivni komentarji',
    '=3': 'pozitivni komentarji',
    '=4': 'pozitivni komentarji',
    other: 'pozitivnih komentarjev',
  },
  USER_GENITIV: {
    '=0': 'uporabnikov',
    '=1': 'uporabnika',
    '=2': 'uporabnikov',
    '=3': 'uporabnikov',
    '=4': 'uporabnikov',
    other: 'uporabnikov',
  },
  USER_INSTRUMENTAL: {
    '=0': 'uporabnikom',
    '=1': 'uporabnikom',
    other: 'uporabniki',
  },
  ADDED_PASSIVE: {
    '=0': 'dodano',
    '=1': 'dodana',
    '=2': 'dodane',
    '=3': 'dodane',
    '=4': 'dodane',
    other: 'dodano',
  },
};
