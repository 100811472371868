import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ClickEventDto } from '../model/click-event-dto';
import { ClickEventResponseDto } from '../model/click-event-response-dto';
import { ContactFormEventDto } from '../model/contact-form-event-dto';
import { ShowEventDto } from '../model/show-event-dto';
import { ShowListingEventResponseDto } from '../model/show-listing-event-response-dto';
import { environment } from '@environment';

export interface SaveClickEventRequestParams {
    clickEventDto: ClickEventDto;
}

export interface SaveContactFormEventRequestParams {
    contactFormEventDto: ContactFormEventDto;
}

export interface SaveShowEventRequestParams {
    showEventDto: ShowEventDto;
}

@Injectable({
  providedIn: 'root',
})
export class MeasurementApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Saves click event
   */
  public saveClickEvent$(
    requestParameters: SaveClickEventRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ClickEventResponseDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.clickEventDto,
      responseType: this.configuration.getResponseType(false, 'ClickEventResponseDto'),
      headers,
    };
    return this.httpClient.request<ClickEventResponseDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/measurement/click`,
      options,
    );
  }

  /**
   * Create new contact form event
   */
  public saveContactFormEvent$(
    requestParameters: SaveContactFormEventRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.contactFormEventDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/measurement/contactFormEvent`,
      options,
    );
  }

  /**
   * Saves show event
   */
  public saveShowEvent$(
    requestParameters: SaveShowEventRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ShowListingEventResponseDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.showEventDto,
      responseType: this.configuration.getResponseType(false, 'ShowListingEventResponseDto'),
      headers,
    };
    return this.httpClient.request<ShowListingEventResponseDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/measurement/show`,
      options,
    );
  }

}
