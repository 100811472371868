import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import isNil from 'lodash-es/isNil';
import { BANK_ACCOUNT_PATTERN, CITY_PATTERN, EMAIL_PATTERN, NAME_PATTERN, PICKUP_POINT_STREET_PATTERN, REGISTRATION_LOGIN_PATTERN, STREET_PATTERN, WEBHOOK_URL_PATTERN } from './patterns';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';
import { getCountryByIdOrCode } from '@shared/util/method/country-by-id-or-code-util';
import { Nil } from '@util/helper-types/nil';
import { MoneyUtils } from '@shared/currency/util/money.utils';
import { ZipMaskModel } from '@shared/zip/model/zip-mask.model';

export function cityNameValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(CITY_PATTERN, 2, 150, 'city', abstractControl, false);
}

export function personNameValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(NAME_PATTERN, 2, 150, 'personName', abstractControl, false);
}

export function loginValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(REGISTRATION_LOGIN_PATTERN, 2, 16, 'login', abstractControl, false);
}

export function emailValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(EMAIL_PATTERN, 5, 254, 'email', abstractControl, false);
}

export function nullableEmailValidator(abstractControl: AbstractControl): Nil | ValidationErrors {
  if (!abstractControl.dirty || !abstractControl.value) {
    return null;
  }
  return emailValidator(abstractControl);
}

/**
 *
 * @param abstractControl
 */
export function webhookUrlValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(WEBHOOK_URL_PATTERN, 5, 511, 'webhookUrl', abstractControl, false);
}

// todo(radim.ehrlich): Move this functionality direct to personNameValidator and check consequences.
// If empty, field is valid. If value, return personNameValidator() output.
export function personNameAllowEmptyValidator(abstractControl: AbstractControl): ValidationErrors {
  if (!abstractControl.value) {
    return null;
  }
  return personNameValidator(abstractControl);
}

export function streetValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(STREET_PATTERN, 2, 150, 'street', abstractControl, false);
}

export function pickupPointStreetValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(PICKUP_POINT_STREET_PATTERN, 2, 150, 'street', abstractControl, false);
}

export function bankAccountNumberValidator(abstractControl: AbstractControl): ValidationErrors | null {
  return validatePattern(BANK_ACCOUNT_PATTERN, 2, 150, 'bankAccountNumber', abstractControl, false);
}

/**
 * Validates text-mask https://github.com/text-mask/text-mask
 */
export function textMaskValidator(mask: ZipMaskModel, validatorName: string):
  (control: AbstractControl) => (null | Record<string, unknown>) {
  const INVALID_ANSWER = {};
  INVALID_ANSWER[validatorName] = {
    valid: false,
  };
  return (control: AbstractControl) => {
    // do not validate empty value to allow optional controls or if mask is nil
    if (!control.value || isNil(mask)) {

      return null;
    }
    let maskInvalid = control.value.length !== mask.length;
    if (maskInvalid) {
      return INVALID_ANSWER;
    }
    mask.forEach((letterMask, index) => {
      if (typeof letterMask === 'string') {
        if (control.value[index] !== letterMask) {
          maskInvalid = true;
        }
      } else {
        if (!letterMask.test(control.value[index])) {
          maskInvalid = true;
        }
      }
    });
    return maskInvalid ? INVALID_ANSWER : null;
  };
}

export function maxValue(max: number, strict: boolean = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let input = control.value;

    if (MoneyUtils.isMoneyDto(input)) {
      input = input?.amount;
    }

    const isValid = strict ? (input > max) : (input >= max);
    if (isValid) {
      return {
        maxValue: { max },
      };
    } else {
      return null;
    }
  };
}

export function minValue(min: number, strict: boolean = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    let input = control.value;

    if (MoneyUtils.isMoneyDto(input)) {
      input = input?.amount;
    }

    const isValid = strict ? (input < min) : (input <= min);

    if (isValid) {
      return {
        minValue: { min },
      };
    } else {
      return null;
    }
  };
}

export function maxNumbersAfterComma(count: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const error = { maxNumbersAfterComma: { count } };
    let value = control.value;

    if (MoneyUtils.isMoneyDto(value)) {
      value = value?.amount;
    }

    value = parseFloat(value);
    if (isFinite(value) && !isNaN(value)) {
      const input = value.toString().split('.');
      if (input[1] && input[1].length > count) {
        return error;
      } else {
        return null;
      }
    }
    return error;
  };
}

const POSTAL_CODE_MIN_LENGTH = 1;
const POSTAL_CODE_MAX_LENGTH = 12;

export function zipCodeValidatorByPattern(countryControlPath: string, countries: RegistryCountryItemDto[]): ValidatorFn {
  return (abstractControl: AbstractControl) => {
    if (isNil(countries)) {
      return {};
    }
    const countryControl = abstractControl.root.get(countryControlPath);
    if (isNil(countryControl)) {
      return {};
    }
    const postcodeRegexp =
      getCountryByIdOrCode(
        isNil(countryControl?.value?.id)
          ? countryControl?.value
          : countryControl?.value?.id, countries,
      )?.postcodeRegexp;

    if (isNil(postcodeRegexp) || !countryControl?.value) {
      return {};
    }

    return validatePattern(
      new RegExp(
        postcodeRegexp,
      ),
      POSTAL_CODE_MIN_LENGTH,
      POSTAL_CODE_MAX_LENGTH,
      'zipCode',
      abstractControl,
      false,
    );
  };
}

export function multipleCheckboxesOneRequired(fieldName: string = 'checked'): ValidatorFn {
  return (formArray: FormArray): ValidationErrors => {
    const checked = formArray.controls.filter((formGroup: FormGroup) => formGroup.get(fieldName).value === true);
    if (checked.length === 0) {
      return {
        multipleCheckboxesOneRequired: false,
      };
    }

    return null;
  };
}

export function validatePattern(
  pattern: RegExp,
  minLength: number,
  maxLength: number,
  errorField: string,
  { value }: AbstractControl,
  lengthInNumbers: boolean,
): ValidationErrors | null {
  if (typeof value !== 'string' || !pattern.test(value)) {
    return { [errorField]: true };
  }
  return validateLength(value, minLength, maxLength, lengthInNumbers);
}

function validateLength(
  value: string,
  minLength: number,
  maxLength: number,
  lengthInNumbers: boolean,
): ValidationErrors | null {
  if (!value.length) {
    return null;
  }
  const length = lengthInNumbers ? value.replace(/[^0-9]/g, '').length : value.length;
  if (length < minLength) {
    return { minlength: true };
  }
  if (length > maxLength) {
    return { maxlength: true };
  }
  return null;
}

export function trimRequired(abstractControl: AbstractControl): ValidationErrors | null {
  const v = abstractControl.value;
  if (typeof v === 'string') {
    return v.trim() ? null : { required: true };
  }
  return null;
}
