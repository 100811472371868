import { Injectable } from '@angular/core';
import { BaseDomainConfig } from '@shared/domain/module/domain-config/domain/base-domain-config';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { CountryCodeEnum } from '@shared/domain/model/country-code.enum';
import { ZipUtil } from '@shared/zip/util/zip.util';

@Injectable({
  providedIn: 'root',
})
export class DomainConfigSkService extends BaseDomainConfig {

  public override getConfig(): DomainConfigModel {
    return {
      countryCode: CountryCodeEnum.SLOVAKIA,
      phonePrefix: '421',
      currencyConfig: {
        currencyCode: 'EUR',
        currencyNumberType: 'FLOAT',
        selectableCurrencyCodes: ['EUR', 'CZK'],
        currencySuffix: '€',
        minOnlinePayment: 1,
      },
      canSelectCountry: false,
      subbrandLogosMap: {
        BAZAAR: 'logo',
        ANTIQUE: 'logo-antique-sk',
        NOT_SPECIFIED: 'logo',
      },
      canFixZipCode: true,
      showDialogForAddressChange: true,
      isShippingTemplatesPageAllowed: false,
      showMpLink: false,
      billBalanceDomesticPaymentAllowed: false,
      vatConfig: {
        requiredTaxNumber: true,
        taxNumberPrefix: 'SK',
      },
      allowedToChangeCurrency: false,
      canShowVerifyForBuyAction: false,
      canShowVerifyBankAccountAction: true,
      showContinueButtonOnCartShippingAndPaymentPage: false,
      explicitLangInItemDetailDescription: true,
      isYuspAllowed: false,
      metaTagRobotIndexFollowAlways: false,
      differentiateBetweenCompanyNamesInCompanyForm: false,
      zipPattern: ZipUtil.ZIP_FOREIGN_PATTERN,
      zipMask: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/],
      exposeConfig: {
        fastExposeAllowed: false,
        simpleExposeAllowed: true,
        extendedExposeAllowed: false,
      },
      countryIconId: 'slovakia',
      countryCircleIconId: 'slovakia-circle',
      countryName: 'COUNTRY_NAME_SK',
      vatPattern: /^((\d{8,10}))$/,
      userAccountVerificationWay: 'BANK_TRANSFER',
      bankButtonsAllowed: false,
      urlParamCity: 'mesto',
    };
  }

}
