import { ChangeDetectorRef, Directive, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ImagePlaceholderSizeType } from '../model/image-placeholder-size.type';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { DummyImagePlaceholderModel } from '../model/dummy-image-placeholder.model';
import { DateUtils } from '@util/util/date.utils';
import { filter, take } from 'rxjs/operators';
import { takeUntil } from 'rxjs';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { LoggerService } from '@common/logger/service/logger.service';

const DUMMY_IMAGE_COM_URL: string = 'https://dummyimage.com/';

@Directive({
  selector: 'img[aukReplaceByPlaceholderOnError]',
  standalone: true,
})
export class ReplaceByPlaceholderOnErrorDirective extends BaseDestroy implements OnInit, OnChanges {

  /**
   * Image source (image url link), which will be set into src attribute
   */
  @Input() public aukReplaceByPlaceholderOnError: string;

  /** default placeholder */
  @Input() public placeholderSize: ImagePlaceholderSizeType = '400x300';

  /**
   * optional placeholder
   * if specified, has priority over default
   */
  @Input() public dummyImagePlaceholder: DummyImagePlaceholderModel;

  @Output() public imageLoadError: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('src') public srcAttr: string;

  private placeholderImgUrl: string;
  private dummyImagePlaceholderImgUrl: string;

  /**
   * Represents the current loading state of the image
   *
   * <ul>
   *   <li>`LOADING` - the image is currently loading</li>
   *   <li>`LOADED` - the original image has been successfully loaded</li>
   *   <li>`WAITING_FOR_RECOVERY` - an error occurred during loading, but recovery is being attempted</li>
   *   <li>`REPLACED_BY_PLACEHOLDER` - the loading failed, and a placeholder is being displayed instead of the original image</li>
   * </ul>
   */
  private loadingState: 'LOADING' | 'LOADED' | 'WAITING_FOR_RECOVERY' | 'REPLACED_BY_PLACEHOLDER' = 'LOADING';

  /**
   * Time for waiting to load the image after an error occurred. After this time placeholder will be displayed
   */
  private readonly ERROR_RECOVERY_DELAY_SEC: number = 1;

  constructor(
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.placeholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getPlaceholderImgUrl(this.placeholderSize);
  }

  public ngOnChanges(changes: AukSimpleChanges<ReplaceByPlaceholderOnErrorDirective>): void {
    if (changes.placeholderSize) {
      this.placeholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getPlaceholderImgUrl(this.placeholderSize);
    }
    if (changes.dummyImagePlaceholder) {
      this.dummyImagePlaceholderImgUrl = ReplaceByPlaceholderOnErrorDirective.getDummyImagePlaceholderImgUrl(this.dummyImagePlaceholder);
    }

    if (changes.aukReplaceByPlaceholderOnError) {
      this.srcAttr = this.aukReplaceByPlaceholderOnError ?? this.placeholderImgUrl;
    }
  }

  @HostListener('error')
  public onImageLoadError(): void {
    if (this.loadingState !== 'LOADING') {
      return;
    }

    this.loadingState = 'WAITING_FOR_RECOVERY';

    this.ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(this.ERROR_RECOVERY_DELAY_SEC))
      .pipe(
        take(1),
        filter(() => this.loadingState === 'WAITING_FOR_RECOVERY'),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.imageLoadError.emit();
        this.srcAttr = this.dummyImagePlaceholderImgUrl ?? this.placeholderImgUrl;
        this.changeDetectorRef.markForCheck();
        this.loadingState = 'REPLACED_BY_PLACEHOLDER';
      });
  }

  @HostListener('load')
  public onImageLoad(): void {
    if (this.loadingState === 'REPLACED_BY_PLACEHOLDER') {
      return;
    }

    if (this.loadingState === 'WAITING_FOR_RECOVERY') {
      this.logEventuallyLoadedAfterError();
    }

    this.loadingState = 'LOADED';
  }

  private logEventuallyLoadedAfterError(): void {
    this.loggerService.logMessage(
      'ReplaceByPlaceholderOnErrorDirective :: Image eventually loaded through error has occurred before',
      {
        tags: {
          imageSrc: this.srcAttr,
        },
        extra: {
          src: this.srcAttr,
        },
        fingerprint: ['IMAGE_EVENTUALLY_LOADED_AFTER_ERROR'],
        level: 'log',
      },
    );
  }

  private static getPlaceholderImgUrl(placeholderSize: ImagePlaceholderSizeType): string {
    // TODO: [PDEV-15679] - create utility function, to get base assets path
    return `/app/common/images/image-placeholder/${ placeholderSize }.webp`;
  }

  private static getDummyImagePlaceholderImgUrl(dummyImagePlaceholder: DummyImagePlaceholderModel): string {
    const config: DummyImagePlaceholderModel = {
      text: dummyImagePlaceholder.text,
      width: dummyImagePlaceholder.width ?? 400,
      height: dummyImagePlaceholder.height ?? 300,
      bgColor: dummyImagePlaceholder.bgColor ?? 'cccccc',
      textColor: dummyImagePlaceholder.textColor ?? 'ffffff',
    };
    return `${ DUMMY_IMAGE_COM_URL }${ config.width }x${ config.height }/${ config.bgColor }/${ config.textColor }&text=${ config.text }`;
  }

}
