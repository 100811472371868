import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { BannerPlacementDto } from '../model/banner-placement-dto';
import { RequestBannerDto } from '../model/request-banner-dto';
import { environment } from '@environment';

export interface GetBannersRequestParams {
    requestBannerDto: RequestBannerDto;
}

@Injectable({
  providedIn: 'root',
})
export class BannerApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   */
  public getBanners$(
    requestParameters: GetBannersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<BannerPlacementDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.requestBannerDto,
      responseType: this.configuration.getResponseType(false, 'Array<BannerPlacementDto>'),
      headers,
    };
    return this.httpClient.request<BannerPlacementDto[]>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/banner`,
      options,
    );
  }

}
