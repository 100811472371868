import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { CommonModule } from '@angular/common';
import { SpinnerSize } from '@common/ui-kit/component/spinner/model/spinner-size';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';

@Component({
  selector: 'auk-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
  ],
})
export class SpinnerComponent extends ColorCombinationInputComponent implements OnChanges {

  @Input() public size: SpinnerSize = 'MD';
  @Input() public v2: boolean = false;

  protected spinnerSizeClass: string | Nil;

  private static getSpinnerSizeClass(size: SpinnerSize): string {
    switch (size) {
      case 'XS': {
        return 'tw-w-4 tw-h-4';
      }
      case 'SM': {
        return 'tw-w-5 tw-h-5';
      }
      case 'MD': {
        return 'tw-w-6 tw-h-6';
      }
      case 'LG': {
        return 'tw-w-7 tw-h-7';
      }
      case 'XL': {
        return 'tw-w-8 tw-h-8';
      }
      case '2XL': {
        return 'tw-w-9 tw-h-9';
      }
      case '3XL': {
        return 'tw-w-10 tw-h-10';
      }
      case '4XL': {
        return 'tw-w-11 tw-h-11';
      }
      case '5XL': {
        return 'tw-w-12 tw-h-12';
      }
    }
  }

  public override ngOnChanges(changes: AukSimpleChanges<SpinnerComponent>): void {
    super.ngOnChanges(changes);

    if (changes.size) {
      this.spinnerSizeClass = SpinnerComponent.getSpinnerSizeClass(this.size ?? 'MD');
    }
  }

}

