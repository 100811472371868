import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { UserFavouritesPreviewDto } from '../model/user-favourites-preview-dto';
import { environment } from '@environment';

export interface GetFavouriteItemsPreviewRequestParams {
    itemsCount?: number;
}

@Injectable({
  providedIn: 'root',
})
export class UsersMeFavouritesApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Preview of favourite items, sellers and search terms
   */
  public getFavouriteItemsPreview$(
    requestParameters: GetFavouriteItemsPreviewRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserFavouritesPreviewDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      itemsCount: requestParameters.itemsCount,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'UserFavouritesPreviewDto'),
      headers,
    };
    return this.httpClient.request<UserFavouritesPreviewDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/users/me/favourites/preview`,
      options,
    );
  }

}
