import { Inject, Injectable } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { take, takeUntil } from 'rxjs/operators';
import { CardPaymentCreatedDto } from '@api/aukro-api/model/card-payment-created-dto';
import { captureException } from '@sentry/browser';
import { HttpError } from '@shared/rest/model/http-error';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AukWindow } from '@shared/model/auk-window.interface';
import { CardPaymentApiService } from '@api/aukro-api/api/card-payment-api.service';
import { ToastService } from '@common/toast/service/toast.service';
import { BrowserService } from '@shared/platform/browser.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

@Injectable({
  providedIn: 'root',
})
export class CardVerificationPaymentService extends BaseDestroy {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    private readonly cardPaymentApiService: CardPaymentApiService,
    private readonly toastService: ToastService,
    private readonly browserService: BrowserService,
  ) {
    super();
  }

  public initCardPaymentVerification(): void {
    this.cardPaymentApiService.createAccountVerificationPayment$({
      createVerificationCardPaymentDto: {
        paymentGateway: 'BARION',
        returnUrl: this.browserService.href,
        android: PlatformCommonService.isNativeAndroidApp,
      },
    })
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: CardPaymentCreatedDto) => {
          if (response?.redirectUrl) {
            if (PlatformCommonService.isNativeAndroidApp) {
              this.window.open(response.redirectUrl, '_blank');
            } else {
              this.window.location.href = response.redirectUrl;
            }
          } else {
            this.showStartErrorMessage();
            captureException(
              `UserAccountVerificationService :: Can not redirect to payment gateway because redirectUrl is missing`,
              { fingerprint: ['USER_ACCOUNT_VERIFICATION_MISSING_REDIRECT_URL'] },
            );
          }
        },
        error: (error: HttpError) => {
          this.showStartErrorMessage();
          throw error;
        },
      });
  }

  private showStartErrorMessage(): void {
    this.toastService.showDanger({ key: 'USER_ACCOUNT_VERIFICATION_START_ERROR' });
  }

}
