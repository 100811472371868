import { CurrencyConfigElementEnumModel } from '@shared/currency/model/currency-config-element-enum.model';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import { CurrencyConfigFeModel } from '@shared/currency/model/currency-config-fe.model';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';

export class CurrencyUtil {

  public static currenciesListToMap(
    currenciesList: CurrencyConfigElementEnumModel[],
    currenciesMap: Record<CurrencyCodeType, CurrencyConfigFeModel>,
  ): CurrencyModelMap {
    return currenciesList.reduce((acc, currencyConfig) => ({
      ...acc,
      [currencyConfig.code]: {
        ...currencyConfig,
        currenciesMap,
      },
    }), {} as CurrencyModelMap);
  }

}
