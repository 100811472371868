<div class="tw-flex tw-flex-col md:tw-flex-row tw-text-center tw-text-inherit
     tw-border tw-border-solid tw-bg-background-color tw-border-border-color tw-rounded-md tw-p-3 tw-gap-2
     tw-items-center tw-justify-between"
     [class.tw-justify-start]="textAlign === 'LEFT'"
     [class.tw-justify-center]="textAlign === 'CENTER'"
     [class.!tw-block]="hasBlockContent"
>

  <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-y-2 tw-gap-x-2">
    <div class="tw-flex tw-justify-center md:tw-justify-start"
         [class.!tw-block]="hasBlockContent">
      @if (visualType === 'ICON' && iconModel) {
        <auk-icon [source]="iconModel.source"
                  [colorCombination]="iconModel.colorCombination"
                  [iconType]="iconModel.type"
                  [size]="iconModel.size"
        ></auk-icon>
      }

      @if (visualType === 'STICKER' && sticker) {
        <auk-sticker class="tw-font-bold" [colorCombination]=" sticker.colorCombination">
          {{ sticker.label | translateSource }}
        </auk-sticker>
      }
    </div>

    <div class="tw-flex tw-items-center tw-justify-center">

      @if (text) {
        <div class="tw-text-sm tw-font-normal " [aukInnerHtml]="text" [allowStrictSanitize]="allowStrictSanitize"></div>
      }

      <ng-content></ng-content>

    </div>
  </div>

  @if (button) {
    @if (link) {
      <a [routerLink]="link.routerLink" [queryParams]="link.queryParams">
        <ng-container *ngTemplateOutlet="buttonTmpl"></ng-container>
      </a>
    } @else {
      <ng-container *ngTemplateOutlet="buttonTmpl"></ng-container>
    }
    <ng-template #buttonTmpl>
      <auk-button [colorCombination]="button.colorCombination" size="SM-NORMAL">
        <div class="tw-flex tw-items-center tw-gap-x-2">
          @if (button.icon) {
            <auk-icon
                [colorCombination]="button.icon.colorCombination"
                [source]="button.icon.source"
                [iconType]="button.icon.type"
                [size]="button.icon.size"
            ></auk-icon>
          }
          {{ button.label | translateSource }}
        </div>
      </auk-button>
    </ng-template>
  }

</div>
