import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SubbrandSessionDataService } from '@shared/subbrand/repository/subbrand-session-data.service';
import { StringUtils } from '@util/util/string.utils';
import isEmpty from 'lodash-es/isEmpty';
import { SwitchSubbrandDto } from '@shared/subbrand/model/switch-subbrand-dto';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * Intercepts backend response that contains header for switching session subbrand. Switches subbrands when needed.
 */
@Injectable()
export class SwitchSubbrandInterceptor implements HttpInterceptor {

  private static readonly SWITCH_SUBBRAND_HEADER_NAME: string = 'X-Switch-Subbrand';

  constructor(
    private readonly subbrandDataService: SubbrandSessionDataService,
    private readonly subbrandService: SubbrandService,
    private readonly loggerService: LoggerService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        tap((httpEvent: HttpEvent<unknown>) => {
          if (!(httpEvent instanceof HttpResponse)) {
            return;
          }

          const headerValue: string = httpEvent.headers.get(SwitchSubbrandInterceptor.SWITCH_SUBBRAND_HEADER_NAME);

          if (StringUtils.isBlank(headerValue)) {
            return;
          }

          this.processSwitchSubbrandResponseHeader(headerValue);
        }),
      );
  }

  /**
   * Tries to deserialize payload of switch subbrand header and sets new session subbrand if needed.
   * @param setSubbrandHeaderVal string header value
   */
  private processSwitchSubbrandResponseHeader(setSubbrandHeaderVal: string): void {

    let setSubbrandModel: SwitchSubbrandDto | Nil = null;

    try {
      setSubbrandModel = JSON.parse(setSubbrandHeaderVal) as SwitchSubbrandDto;
    } catch (e) {
      const errorMessage: string = `Error while parsing ${ SwitchSubbrandInterceptor.SWITCH_SUBBRAND_HEADER_NAME } response header.`;
      console.error(errorMessage, e);
      this.loggerService.logException(errorMessage, { extra: { error: e } });
    }

    if (isNil(setSubbrandModel) || isEmpty(setSubbrandModel?.subbrands)) {
      return;
    }

    const sessionSubbrand: SubbrandType = this.subbrandDataService.sessionSubbrand;

    // No need to change session subbrand if current session one is included in newly recieved
    if (setSubbrandModel?.subbrands.includes(sessionSubbrand)) {
      return;
    }

    // Switch to first subbrand in array
    const newSubbrand = setSubbrandModel?.subbrands[0];

    this.subbrandService.setSessionSubbrand(newSubbrand);
  }

}
