<h2>{{ 'UNPAID_ALERT_COMPONENT_HTML_1' | translate }}</h2>

<p class="m-t-2"
   [aukInnerHtml]="{ key: 'COMMON__UNPAID_ALERT_TEXT' }"
></p>

<div class="flex-center column-max-tablet m-t-3">
  <a (click)="close()" class="btn-secondary fluid-max-tablet">
    {{ 'UNPAID_ALERT_COMPONENT_HTML_6' | translate }}
  </a>
  <a [routerLink]="'/moje-aukro/muj-ucet/aktualni-zustatek'"
     class="btn-primary m-l-3-min-tablet m-t-2-max-tablet fluid-max-tablet">
    {{ 'UNPAID_ALERT_COMPONENT_HTML_7' | translate }}
  </a>
</div>
