import { Inject, Injectable, Injector, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LocaleUtil } from '@shared/locale/util/locale.util';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { ExpressRequest } from '@common/platform/model/request';
import { Lang } from '@shared/platform/lang.const';
import { IcuLocaleModel } from '@shared/locale/model/icu-locale.model';
import { Preferences } from '@capacitor/preferences';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { Device } from '@capacitor/device';
import { REQUEST } from '../../../server/const/request';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { BehaviorSubject, lastValueFrom, Observable, of, switchMap, take } from 'rxjs';
import { AukButtonDialogComponent } from '@common/ui-kit/component/form/auk-button-dialog/component/auk-button-dialog.component';
import { AukButtonDialogModel } from '@common/ui-kit/component/form/auk-button-dialog/model/auk-button-dialog.model';
import { TransparencyService } from '@shared/services/transparency/transparency.service';
import { MatDialogService } from '@common/dialog/service/mat-dialog.service';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';
import { CookieConsentNativeService } from '@shared/services/cookie-consent-native/cookie-consent-native.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { filter } from 'rxjs/operators';
import { environment } from '@environment';
import { DomainEnvironmentsValuesModel } from '@environments/model/domain-environments-values.model';
import { StringUtils } from '@util/util/string.utils';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { DomainConfigUtil } from '@shared/domain/module/domain-config/util/domain-config.util';
import { AukDialogButtonModel } from '@common/ui-kit/component/form/auk-button-dialog/model/auk-dialog-button.model';
import { LoggerService } from '@common/logger/service/logger.service';
import { CountryCodeEnum } from '@shared/domain/model/country-code.enum';
import { LangBeType } from '@shared/lang/model/lang-be.type';

/**
 * Key that is used in preference storage (in native app) for saving domain preference
 */
const MOBILE_APP_PREFERENCE_DOMAIN_KEY = 'appDomainPreference';

/**
 * Resolves on which domain app is running and saves it.
 * Does not work on SSR.
 */
@Injectable({
  providedIn: 'root',
})
export class DomainService {

  /**
   * Initialized only in client (not server).
   */
  private _currentLocale: IcuLocaleModel = LocaleUtil.getDefaultLocale();
  /**
   * Tells whether domain is initialized & resolved
   */
  private _domainInitialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Optional() @Inject(REQUEST) private readonly expressRequest: ExpressRequest,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly platformCommonService: PlatformCommonService,
    private readonly cookieConsentNativeService: CookieConsentNativeService,
    private readonly transparencyService: TransparencyService,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly matDialogService: MatDialogService<BaseDialogType>,
    private readonly injector: Injector,
    private readonly loggerService: LoggerService,
  ) {
    if (!this.platformCommonService.isServer) {
      this.initializeDomainNameCheck();
    }
  }

  /**
   * Returns domain string
   */
  public get domain(): DomainCode {
    return this.locale.country;
  }

  /**
   * Returns locale string
   */
  public get lang(): Lang {
    return this.locale.language;
  }

  public supportedLang(): LangBeType {
    return this.locale.language.toUpperCase() as LangBeType;
  }

  public get hrefLangLocale(): string {
    return this.locale.hrefLangLocale;
  }

  /**
   * @returns Current language and region code (such as "cs-CZ", "sk-SK", or "de-AT") following the BCP 47 standard for language tags
   */
  public get languageWithRegionCode(): string {
    return this.locale.languageWithRegionCode;
  }

  /**
   * Emits single time, when domain is initialized
   */
  public get domainInitialized$(): Observable<true> {
    return this._domainInitialized$
      .asObservable()
      .pipe(
        filter((isInitialized) => isInitialized),
        take(1),
      ) as Observable<true>;
  }

  /**
   * @returns current domain host with additional if for native app,
   *   which returns always default locales domain host
   */
  public get currentDomainHostWithNativeIf(): string {
    // in native app we always use default locale
    if (PlatformCommonService.isNativeApp) {
      return LocaleUtil.getDefaultLocale().domainUrl;
    }
    return this.getCurrentDomainEnvValue('HOST_FRONTEND');
  }

  /**
   * @returns domain host for given domain code
   */
  public getDomainHost(domainCode: DomainCode): string {
    return this.getDomainEnvValue('HOST_FRONTEND', domainCode);
  }

  /**
   * @returns domain host for current domain
   */
  public get currentDomainHost(): string {
    return this.getDomainHost(this.domain);
  }

  /**
   * @returns Domain url for given domain code including protocol prefix (http:// or https://)
   */
  public getDomainUrlWithProtocol(domainCode: DomainCode): string {
    return this.platformCommonService.getProtocol() + this.getDomainHost(domainCode);
  }

  /**
   * @returns domain host by given domain code with capital letter in upper case
   */
  public getDomainHostUpperCaseCapitalLetter(domainCode: DomainCode): string | null {
    const domainHost = this.getDomainHost(domainCode);

    if (isNil(domainHost)) {
      return null;
    }

    return domainHost[0].toUpperCase() + domainHost.slice(1);
  }

  public get locale(): IcuLocaleModel {
    if (!this.platformCommonService.isServer) {
      return this._currentLocale;
    }

    return this.resolveLocale(
      this.expressRequest.headers[LocaleUtil.NGINX_SSR_LANGUAGE_HEADER.toLowerCase()] as string,
    );
  }

  /**
   *
   * @returns Domain environment value for current domain
   */
  public getCurrentDomainEnvValue<KEY extends keyof DomainEnvironmentsValuesModel>(
    envKey: KEY,
  ): DomainEnvironmentsValuesModel[KEY] {
    return this.getDomainEnvValue(envKey, this.domain);
  }

  /**
   *
   * @returns Domain environment value for given domain code
   */
  public getDomainEnvValue<KEY extends keyof DomainEnvironmentsValuesModel>(
    envKey: KEY,
    domainCode: DomainCode,
  ): DomainEnvironmentsValuesModel[KEY] {
    // for this specific key & domain we use different value
    if (envKey === 'HOST_FRONTEND' && domainCode === 'CZ') {
      return environment.APP_HOST;
    }

    const value = environment[`${ envKey }_${ domainCode }`];

    if (StringUtils.isBlank(value)) {
      this.loggerService.logMessage(
        `DomainService#getDomainEnvValue :: empty environment value`,
        {
          level: 'error',
          fingerprint: ['DOMAIN_SERVICE_EMPTY_ENV_VALUE'],
          extra: {
            domain: domainCode,
            envKey,
          },
        },
      );
    }

    return value;
  }

  public getLangByHostname(hostname: string): Lang {
    const allDomainsLocales = LocaleUtil.getAllActiveDomainsLocales();

    return allDomainsLocales
      .find((domainLocale) => hostname.includes(this.getDomainHost(domainLocale.country)))?.language
      ?? LocaleUtil.getDefaultLocale().language;
  }

  public getFlagByDomain(domain: DomainCode): string {
    const allDomainsLocales: IcuLocaleModel[] = LocaleUtil.getAllDomainsLocales();

    const flag: string = allDomainsLocales.find((icuLocaleModel: IcuLocaleModel) => icuLocaleModel.country === domain)?.flag;

    return flag ?? LocaleUtil.getDefaultLocale().flag;
  }

  public resolveLocale(val: string): IcuLocaleModel {
    // as fallback return CZ locales
    if (StringUtils.isBlank(val)) {
      LocaleUtil.getDomainLocale('CZ', this.loggerService);
    }

    return LocaleUtil.getAllDomainsLocales()
      .find((locale) =>
        [this.getDomainHost(locale.country), this.getDomainEnvValue('HOST_INT_FRONTEND', locale.country)]
          .includes(val),
      ) ?? LocaleUtil.getDomainLocale('CZ', this.loggerService);
  }

  /**
   * @returns domain config for current domain
   */
  public get currentDomainConfig$(): Observable<DomainConfigModel> {
    return of(this.currentDomainConfig);
  }

  /**
   * @deprecated use {@link currentDomainConfig$} instead
   */
  public get currentDomainConfig(): DomainConfigModel {
    return this.getDomainConfig(this.domain);
  }

  public getDomainConfigByCountryId$(countryId: CountryCodeEnum): Observable<DomainConfigModel> {
    return of(DomainConfigUtil.getDomainConfigByCountryId(countryId, this.injector).getConfig());
  }

  /**
   * @deprecated use {@link getDomainConfig$} instead
   */
  public getDomainConfig(domainCode: DomainCode): DomainConfigModel {
    const domainConfigService = this.injector.get(DomainConfigUtil.getDomainConfig(domainCode));

    return domainConfigService.getConfig();
  }

  public getDomainConfig$(domainCode: DomainCode): Observable<DomainConfigModel> {
    const domainConfigService = this.injector.get(DomainConfigUtil.getDomainConfig(domainCode));

    return of(domainConfigService.getConfig());
  }

  public async readDomainPreference(): Promise<void> {
    if (!PlatformCommonService.isNativeApp) {
      this._domainInitialized$.next(true);
      return Promise.resolve();
    }

    const preferredDomain = (await Preferences.get({ key: MOBILE_APP_PREFERENCE_DOMAIN_KEY })).value as DomainCode;
    if (StringUtils.isBlank(preferredDomain)) {
      const domainCodeByLanguage = await this.getDomainCodeByLanguage();
      this._currentLocale = LocaleUtil.getDomainLocale(domainCodeByLanguage, this.loggerService);
      await this.openDomainSelector();
    } else {
      this._currentLocale = LocaleUtil.getDomainLocale(preferredDomain, this.loggerService);
      void this.initCookieConsentNative();
    }

    this._domainInitialized$.next(true);
    return Promise.resolve();
  }

  public async saveDomainPreference(domain: DomainCode, forceReload: boolean = false): Promise<void> {
    await Preferences.set({
      key: MOBILE_APP_PREFERENCE_DOMAIN_KEY,
      value: domain,
    });

    if (LocaleUtil.getDomainLocale(domain, this.loggerService).country !== this._currentLocale.country) {
      this.authenticationService.logout(true);

      // first navigate to HP, then force reload
      void this.router.navigate(['/'])
        .then(() => this.window.location.reload());
    } else {
      if (forceReload) {
        this.window.location.reload();
      }

      void this.initCookieConsentNative();
    }
  }

  /**
   * Browser locale check.
   */
  private initializeDomainNameCheck(): void {
    if (PlatformCommonService.isNativeApp) {
      return;
    }
    this._currentLocale = this.resolveLocale(this.document.location?.hostname);
  }

  private async openDomainSelector(): Promise<void> {
    const result: DomainCode = await lastValueFrom(
      this.matDialogService.openSimple$<
        AukButtonDialogComponent,
        AukButtonDialogModel,
        DomainCode
      >(
        AukButtonDialogComponent,
        {
          width: '100%',
          height: '100%',
          position: { top: '0px', left: '0px' },
          data: {
            headerIcon: { source: 'logo', type: 'SVG', colorCombination: 'PRIMARY_CONTRAST', size: '5XL' },
            showCloseButton: false,
            buttons: LocaleUtil.getAllActiveDomainsLocales().map((domainLocale) => ({
              text: { defaultValue: domainLocale.nonTranslatedLabel },
              action: domainLocale.country,
              testIdentification: domainLocale.testIdentification,
              accessibilityId: domainLocale.accessibilityId,
              icon: domainLocale.flag,
              colorCombination: 'SURFACE_3',
            } as AukDialogButtonModel)),
          },
        },
      ).pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        take(1),
      ),
    );

    await this.saveDomainPreference(result, false);
  }

  private async getDomainCodeByLanguage(): Promise<DomainCode> {
    const languageCodeDevice = (await Device.getLanguageCode()).value as Lang;

    return LocaleUtil.getDomainCodeByLangCode(languageCodeDevice, this.loggerService);
  }

  private async initCookieConsentNative(): Promise<void> {
    if (PlatformCommonService.isNativeIosApp) {
      const allowed = await this.transparencyService.transparencyIosRequest();
      this.cookieConsentNativeService.initCookieConsentNative(
        this.domain,
        // We need to initialize Didomi even if user has refused ATT
        !allowed,
      );
      return Promise.resolve();
    } else {
      this.cookieConsentNativeService.initCookieConsentNative(this.domain);
      return Promise.resolve();
    }
  }

}
