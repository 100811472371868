import { Injectable } from '@angular/core';
import { CookieService } from '@common/cookie/service/cookie.service';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { tokenCacheBuster$ } from '@shared/user/constant/cache-busters';
import { PlatformService } from '@shared/platform/service/platform.service';
import { map, share, take } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { GenerateTokenRequestParams, UsersApiService } from '@api/aukro-api/api/users-api.service';
import { TokenDto } from '@api/aukro-api/model/token-dto';
import { UserAgentService } from '@shared/platform/service/user-agent.service';
import { HttpContext } from '@angular/common/http';
import { TOKEN_GENERATION_DISABLED } from '@shared/rest/model/token-generation-disabled-http-context.token';
import { NativeAppService } from '@shared/native-app/service/native-app.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class TokenMonitoringService extends NgUnsubscribe implements CacheAware {

  public static readonly AUKRO_TOKEN_COOKIE_AND_LS_KEY: string = 'aukro-token';

  private generatingToken$: Observable<boolean>;

  constructor(
    public readonly cacheService: CacheService,
    private readonly localStorageService: LocalStorageService,
    private readonly cookieStorageService: CookieService,
    private readonly platformService: PlatformService,
    private readonly usersApiService: UsersApiService,
    private readonly userAgentService: UserAgentService,
    private readonly nativeAppService: NativeAppService,
  ) {
    super();
  }

  public checkToken(): boolean {
    const cookieValue: string = this.cookieStorageService.get(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY);
    const localStorageValue: string = this.localStorageService.getItem(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY);
    let tokenExists: boolean = false;
    if (!cookieValue && localStorageValue) {
      tokenExists = true;
      this.cookieStorageService.put(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY, localStorageValue);
    }
    if (!localStorageValue && cookieValue) {
      tokenExists = true;
      this.localStorageService.setItem(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY, cookieValue);
    }
    if (cookieValue && localStorageValue) {
      tokenExists = true;
    }
    return tokenExists;
  }

  /**
   * @returns Observable<boolean> whether the token was freshly generated
   */
  public checkAndGenerateToken(): Observable<boolean> {
    if (this.platformService.isBot || this.checkToken()) {
      return of(false);
    }

    if (isNil(this.generatingToken$)) {
      const params: GenerateTokenRequestParams = {
        platformType: PlatformService.platformType,
        userAgent: this.userAgentService.userAgent,
      };
      this.generatingToken$ = this.usersApiService.generateToken$(
        params,
        {},
        new HttpContext().set(TOKEN_GENERATION_DISABLED, true),
      )
        .pipe(
          take(1),
          map((token: TokenDto) => {
            this.nativeAppService.saveAukroTokenIfNativeApp(token.token);
            this.setToken();
            this.generatingToken$ = null;
            return true;
          }),
          share(),
        );
      return this.generatingToken$;
    }
    return of(false);
  }

  public setToken(): void {
    const cookieValue: string = this.cookieStorageService.get(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY);
    if (cookieValue) {
      this.localStorageService.setItem(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY, cookieValue);
    }
  }

  public findToken(ignoreCache: boolean = false): Observable<TokenDto | undefined> {
    if (ignoreCache) {
      tokenCacheBuster$.next();
    }
    return this.findTokenCached();
  }

  @Cacheable({
    cacheBuster$: tokenCacheBuster$,
    key: 'TokenMonitoringService#findToken',
  })
  private findTokenCached(): Observable<TokenDto> {
    return this.usersApiService.findToken$({});
  }

}
