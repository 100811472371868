import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { LoggerService } from '@common/logger/service/logger.service';
import { CurrencyService } from '@shared/currency/service/currency.service';

@Component({
  selector: 'auk-currency-approximation',
  templateUrl: './currency-approximation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyApproximationComponent extends NgUnsubscribe implements OnChanges, OnInit {

  @Input() public price: MoneyDto;

  private nonEmptyAmount: boolean = false;
  private preferredCurrencyCode: CurrencyCodeType;

  constructor(
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
    private readonly currencyService: CurrencyService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.userCurrencyPreferenceService.preferredCurrencyCode$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((preferredCurrency) => {
        this.preferredCurrencyCode = preferredCurrency;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnChanges(changes: AukSimpleChanges<CurrencyApproximationComponent>): void {
    if (changes.price) {
      if (typeof this.price === 'number') {
        this.logInvalidPriceDtoSentryError('ngOnChanges');
        this.nonEmptyAmount = !isNil(this.price) && this.price > 0;
      } else {
        this.nonEmptyAmount = !isNil(this.price) && this.price.amount > 0;
      }
    }
  }

  protected get shouldShowApproximation(): boolean {
    return this.nonEmptyAmount
      && this.currencyService.getCurrencyConfigFeByCode(this.preferredCurrencyCode).showApproximation
      && this.priceCurrencyDifferentThanUserCurrency(this.preferredCurrencyCode);
  }

  private priceCurrencyDifferentThanUserCurrency(userCurrencyCode: CurrencyCodeType): boolean {
    if (typeof this.price === 'number') {
      this.logInvalidPriceDtoSentryError('priceCurrencyDifferentThanUserCurrency');
      return true;
    }

    return !isNil(this.price) && this.price?.currency !== userCurrencyCode;
  }

  private logInvalidPriceDtoSentryError(method: string): void {
    this.loggerService.logMessage(
      `CurrencyApproximationComponent#${ method } :: price of type number has been passed`,
      {
        level: 'error',
        fingerprint: ['CURRENCY_APPROXIMATION_NUMBER_VALUE_PASSED'],
        extra: {
          price: this.price,
        },
      },
    );
  }

}
