import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { CardPaymentCreatedDto } from '../model/card-payment-created-dto';
import { CardPaymentRefundDto } from '../model/card-payment-refund-dto';
import { CardPaymentStatusDto } from '../model/card-payment-status-dto';
import { CreatePaymentForSaleCardPaymentDto } from '../model/create-payment-for-sale-card-payment-dto';
import { CreateVerificationCardPaymentDto } from '../model/create-verification-card-payment-dto';
import { environment } from '@environment';

export interface BarionCallBackRequestParams {
    paymentId: string;
    source?: string;
}

export interface CreateAccountVerificationPaymentRequestParams {
    createVerificationCardPaymentDto: CreateVerificationCardPaymentDto;
}

export interface CreatePaymentForSalePaymentRequestParams {
    createPaymentForSaleCardPaymentDto: CreatePaymentForSaleCardPaymentDto;
}

export interface GetStatus1RequestParams {
    orderId: string;
}

export interface RefundCardPaymentRequestParams {
    cardPaymentRefundDto: CardPaymentRefundDto;
}

export interface VerificationCallbackRequestParams {
    paymentId: string;
    source?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CardPaymentApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Endpoint for Barion callback
   */
  public barionCallBack$(
    requestParameters: BarionCallBackRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentId: requestParameters.paymentId,
      source: requestParameters.source,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment/barionCallback`,
      options,
    );
  }

  /**
   * Creates new card payment of VERIFICATION_PAYMENT type and returns URL to redirect
   */
  public createAccountVerificationPayment$(
    requestParameters: CreateAccountVerificationPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CardPaymentCreatedDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.createVerificationCardPaymentDto,
      responseType: this.configuration.getResponseType(false, 'CardPaymentCreatedDto'),
      headers,
    };
    return this.httpClient.request<CardPaymentCreatedDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment/account-verification`,
      options,
    );
  }

  /**
   * Creates new card payment of PAYMENT_FOR_SALE type and returns URL to redirect
   */
  public createPaymentForSalePayment$(
    requestParameters: CreatePaymentForSalePaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CardPaymentCreatedDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.createPaymentForSaleCardPaymentDto,
      responseType: this.configuration.getResponseType(false, 'CardPaymentCreatedDto'),
      headers,
    };
    return this.httpClient.request<CardPaymentCreatedDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment`,
      options,
    );
  }

  /**
   * Gets latest known payment status without querying a payment provider.
   */
  public getStatus1$(
    requestParameters: GetStatus1RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CardPaymentStatusDto> {
    const orderId: string = requestParameters.orderId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'CardPaymentStatusDto'),
      headers,
    };
    return this.httpClient.request<CardPaymentStatusDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment/status/${ this.configuration.encodeParam({ name: 'orderId', value: orderId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Just sets finished status of sending Payment via Aukro back to buyer.
   */
  public refundCardPayment$(
    requestParameters: RefundCardPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cardPaymentRefundDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment/refund`,
      options,
    );
  }

  /**
   * Barion callback for card verification payment
   */
  public verificationCallback$(
    requestParameters: VerificationCallbackRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentId: requestParameters.paymentId,
      source: requestParameters.source,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cardPayment/barion-verification-callback`,
      options,
    );
  }

}
