import isNil from 'lodash-es/isNil';
import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { NumberUtils } from '@util/util/number.utils';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import isObject from 'lodash-es/isObject';
import { Nil } from '@util/helper-types/nil';

export class MoneyUtils {

  public static moneyEqualsNullAllowed(o1: MoneyDto, o2: MoneyDto): boolean {
    if (isNil(o1) && isNil(o2)) {
      return true;
    }

    return MoneyUtils.moneyEquals(o1, o2);
  }

  public static moneyEquals(o1: MoneyDto, o2: MoneyDto): boolean {
    if (isNil(o1) || isNil(o2)) {
      return false;
    }

    return o1.currency === o2.currency && o1.amount === o2.amount;
  }

  public static czk(amount: number): MoneyDto | null {
    return MoneyUtils.of(amount, 'CZK');
  }

  public static eur(amount: number): MoneyDto | null {
    return MoneyUtils.of(amount, 'EUR');
  }

  public static of(amount: number, currency: CurrencyCodeType): MoneyDto | Nil {

    if (isNil(amount) || isNil(currency)) {
      return null;
    }

    return { amount, currency };
  }

  public static czkZero(): MoneyDto {
    return MoneyUtils.zero('CZK');
  }

  public static zero(currency: CurrencyCodeType): MoneyDto | null {
    return MoneyUtils.of(0, currency);
  }

  /**
   * Rounds money object with default decimal places for given currency
   */
  public static roundByCurrency(money: MoneyDto, currencies: CurrencyModelMap): MoneyDto {
    if (isNil(money)) {
      return null;
    }

    if (isNil(money.currency)) {
      return money;
    }

    if (isNil(currencies)) {
      return null;
    }

    money.amount = NumberUtils.roundUp(money.amount, currencies[money.currency]?.inputFractionDigits ?? 2);

    return money;
  }

  public static isMoneyDto(value: unknown): value is MoneyDto {
    if (isNil(value) || !isObject(value)) {
      return false;
    }

    return 'amount' in value && 'currency' in value;
  }

  public static isZeroOrEmpty(value: MoneyDto | Nil): boolean {
    if (isNil(value)) {
      return true;
    }

    return value.amount === 0;
  }

  public static isZero(value: MoneyDto | Nil): boolean {
    if (isNil(value)) {
      return false;
    }

    return value.amount === 0;
  }

}
