import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DateAdapter } from '@angular/material/core';
import { DomainService } from '@shared/domain/service/domain.service';
import { registerLocaleData } from '@angular/common';
import { Lang } from '@shared/platform/lang.const';

@Injectable({
  providedIn: 'root',
})
export class DateLocaleInitializer implements AppInitializer {

  private readonly localesMap: Record<Lang, Promise<{ default: unknown }>> = {
    cs: import('@angular/common/locales/cs'),
    sk: import('@angular/common/locales/sk'),
    de: import('@angular/common/locales/de'),
    hu: import('@angular/common/locales/hu'),
    pl: import('@angular/common/locales/pl'),
    ro: import('@angular/common/locales/ro'),
    it: import('@angular/common/locales/it'),
    fr: import('@angular/common/locales/fr'),
    el: import('@angular/common/locales/el'),
    nl: import('@angular/common/locales/nl'),
    hr: import('@angular/common/locales/hr'),
    sl: import('@angular/common/locales/sl'),
    bg: import('@angular/common/locales/bg'),
  };

  constructor(
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly domainService: DomainService,
  ) {
  }

  public init(): void {
    // asynchronously load only required locales based on domain language
    void this.localesMap[this.domainService.lang]
      .then((locale) => {
        registerLocaleData(locale.default);

        this.dateAdapter.setLocale(
          this.domainService.lang,
        );
      });
  }

}
