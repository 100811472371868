import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserTaskVerifiedBankAccountStepComponent } from './user-task-verified-bank-account-step.component';
import { finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { HttpError } from '@shared/rest/model/http-error';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { FileFormatUtil } from '@util/util/file-format/domain/file-format.util';
import { StringUtils } from '@util/util/string.utils';
import { ImagesApiService } from '@api/aukro-api/api/images-api.service';
import { UserInterestStatisticsDto, UserInterestStatisticsDtoAccountTypeEnumEnum } from '@api/aukro-api/model/user-interest-statistics-dto';
import { AccountDetailDto } from '@api/aukro-api/model/account-detail-dto';
import { VerificationPaymentDataForBankTransferDto } from '@api/aukro-api/model/verification-payment-data-for-bank-transfer-dto';
import { PaymentApiService } from '@api/aukro-api/api/payment-api.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { DomainService } from '@shared/domain/service/domain.service';
import isNil from 'lodash-es/isNil';
import { TranslationSource } from '@common/translations/model/translation-source';
import { MoneyDtoCurrencyEnum } from '@api/aukro-api/model/money-dto';
import { Observable, of } from 'rxjs';
import { LoggerService } from '@common/logger/service/logger.service';

@Component({
  selector: 'auk-user-task-verified-bank-account-step-no-account-verified',
  templateUrl: 'user-task-verified-bank-account-step-no-account-verified.component.html',
  styleUrls: ['./user-task-verified-bank-account-step-no-account-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent
  extends UserTaskVerifiedBankAccountStepComponent<'NO_ACCOUNT_VERIFIED'> implements OnInit {

  public actualStatistics: UserInterestStatisticsDto;
  public userDetail: AccountDetailDto;
  public bankTransferData: VerificationPaymentDataForBankTransferDto;
  public loading: boolean = false;
  public qrPaymentData: string;
  public isDesktop: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly paymentApiService: PaymentApiService,
    private readonly imagesApiService: ImagesApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService,
    private readonly domainService: DomainService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.loadData();
    this.initPlatformListener();
  }

  protected get showMaPaymentVerificationPrelinkInfo(): boolean {
    return !StringUtils.isEmpty(this.payload.maPaymentVerificationPrelinkInfo.defaultValue);
  }

  protected get isUserAccountType(): boolean {
    return this.accountType === 'USER' && !this.isUserChangingToCompany;
  }

  protected get isCompanyAccountType(): boolean {
    return this.accountType === 'COMPANY' || this.isUserChangingToCompany;
  }

  private get isUserChangingToCompany(): boolean {
    return this.userDetail?.stateDto?.changingToCompany;
  }

  private get accountType(): UserInterestStatisticsDtoAccountTypeEnumEnum {
    return this.actualStatistics?.accountTypeEnum;
  }

  private initPlatformListener(): void {
    this.responsivenessService.isMdLgCombination$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isMdAndLower, isLgAndLower]) => {
        this.isDesktop = !isMdAndLower && !isLgAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  private loadData(): void {
    this.loading = true;

    this.userService.getActualStatistics()
      .pipe(
        tap((actualStatistics: UserInterestStatisticsDto) => this.actualStatistics = actualStatistics),
        mergeMap(() => this.userService.detail(this.actualStatistics?.userId)),
        tap((userDetail: AccountDetailDto) => this.userDetail = userDetail),
        tap(() => this.loggerService.logException(
          'opening BAVD for user ' + String(this.actualStatistics.userId) +
          ' (roles: ' + String(this.actualStatistics.roles) + ') from ' + this.payload.source)),
        mergeMap(() => this.paymentApiService.newBankTransferVerificationPayment1$(
          {},
          new HttpContext().set(USER_ACTION_TYPE, 'VERIFY_THROUGH_PAYMENT'),
        )),
        mergeMap(() => this.paymentApiService.verificationPaymentDataForBankTransfer1$({})),
        tap((bankTransferData: VerificationPaymentDataForBankTransferDto) => this.bankTransferData = bankTransferData),
        mergeMap((bankTransferData: VerificationPaymentDataForBankTransferDto) => this.generateQrCode(bankTransferData)),
        take(1),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (qrCodeData: string) => {
          this.qrPaymentData = FileFormatUtil.withPngBase64Prefix(qrCodeData);
        },
        error: (error: HttpError) => {
          this.loggerService.logException(
            new Error(
              'Error ' + error?.code?.toString() + ' - loading data for bank account verification failed',
            ),
            {
              extra: {
                error,
              },
            },
          );
        },
      });
  }

  public get currencySwitchButtonText(): TranslationSource {
    if (isNil(this.payload?.currencySwitchButton)) {
      return null;
    }
    const currency = this.currencyToSwitch();
    return {
      defaultValue: this.payload.currencySwitchButton.defaultValue,
      params: {
        currency,
      },
    };
  }

  public switchToCurrencyAction(): void {
    // used only in .CZ to obtain EUR verification bank account + minimal amount to pay in EUR
    const country = this.currencyToSwitch() === 'CZK' ? 'CZ' : 'SK';
    this.paymentApiService.verificationPaymentDataForBankTransfer1$({ country })
      .pipe(
        tap((bankTransferData: VerificationPaymentDataForBankTransferDto) => this.bankTransferData = bankTransferData),
        // QR code is generated only for CZ verifications
        mergeMap((data) => country === 'CZ' ? this.generateQrCode(data) : of(null)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      ).subscribe((qrCodeData: string) => {
        this.qrPaymentData = FileFormatUtil.withPngBase64Prefix(qrCodeData);
        this.changeDetectorRef.markForCheck();
      });
  }

  private currencyToSwitch(): MoneyDtoCurrencyEnum {
    return this.bankTransferData?.amount?.currency === 'EUR' ? 'CZK' : 'EUR';
  }

  private generateQrCode(data: VerificationPaymentDataForBankTransferDto): Observable<string> {
    return this.imagesApiService.generateQRCode$({
      generateQRCodeDto: {
        amount: data.amount,
        bankAccountNumber: data.bankAccount,
        variableSymbol: data.variableSymbol,
        dimension: 200,
        transactionDomain: this.domainService.domain,
      },
    });
  }

}
