import { Injectable } from '@angular/core';

import { AuthenticationService } from '@shared/authentication/service/authentication.service';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable({
  providedIn: 'root',
})
export class AppUserGuardInitializer implements AppInitializer {

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly authenticationService: AuthenticationService) {
  }

  public init(): void {
    if (this.platformCommonService.isBrowser && !this.authenticationService.isLoggedIn()) {
      this.authenticationService.logout();
    }
  }

}
