import { APP_INITIALIZER, ApplicationConfig, TransferState } from '@angular/core';
import { SentryBrowserInitializer } from '@shared/sentry/initializer/sentry-browser.initializer';
import { APP_STATE_SERVICE_INITIALIZER } from '@common/app-state/const/app-initializer';
import { VERSION_SERVICE_INITIALIZER } from '@shared/version/const/version-service-initializer';
import { AppSetCorrectCssValueInitializer } from '@shared/initializers/app-set-correct-css-value.initializer';
import { PROCESS_SCOPED_MEMORY_CACHE } from '@common/cache/di/process-scoped-memory-cache.injection-token';
import { ProcessScopedMemoryCacheBrowserFactory } from '@common/cache/di/process-scoped-memory-cache-browser.factory';
import { VivnetworksInitializer } from '@shared/initializers/vivnetworks.initializer';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { OldNativeAppUpdateRequiredInitializer } from '@shared/native-app/initializer/old-native-app-update-required.initializer';
import { Observable } from 'rxjs';

export const APP_BROWSER_CONFIG: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    {
      provide: APP_INITIALIZER,
      useFactory: (initializer: OldNativeAppUpdateRequiredInitializer) => (): Observable<void> => initializer.init(),
      deps: [OldNativeAppUpdateRequiredInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (sentryService: SentryBrowserInitializer) => (): void => sentryService.init(),
      deps: [SentryBrowserInitializer],
      multi: true,
    },
    APP_STATE_SERVICE_INITIALIZER,
    VERSION_SERVICE_INITIALIZER,
    {
      provide: PROCESS_SCOPED_MEMORY_CACHE,
      useFactory: ProcessScopedMemoryCacheBrowserFactory,
      deps: [TransferState],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initializer: AppSetCorrectCssValueInitializer) => (): void => initializer.init(),
      deps: [AppSetCorrectCssValueInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (initializer: VivnetworksInitializer) => (): void => initializer.init(),
      deps: [VivnetworksInitializer],
      multi: true,
    },
  ],
};
