<!-- #ssr-done is used for ssr error detection -->
<div [ngClass]="{
       'blur': disclaimerModalOpened,
       'aukro-main-wrapper': !hideHeaderFooter,
       'aukro-main-wrapper-portal': hideHeaderFooter,
       'has-fixed-full-page-height': hasFixedFullPageHeight,
       'tw-bg-tertiary-100' : hasBackgroundColor,
       '!tw-bg-transparent' : showHeaderV2
     }"
     id="ssr-done"
>

  <!-- HEADER -->
  <!-- Do not show any header until type of header is evaluated (prevents double creating and destroying component) -->
  <ng-container *ngIf="isShowHeaderV2Evaluated">
    <auk-app-header-v2 *ngIf="showHeaderV2; else legacyHeaderTmpl"/>
  </ng-container>

  <ng-template #legacyHeaderTmpl>
    <ng-container *aukIfIsActiveBreakpoint="{ min: 'LG' }">
      <auk-app-header-sticky-panel/>
      <auk-top-panel/>
    </ng-container>

    <auk-app-header-menu
      [class.hidden]="hideHeaderFooter"
      *ngIf="isMdAndLower"
      [dummySearchInput]="dummySearchInput"
      class="tw-sticky tw-z-[100] tw-top-0 tw-block tw-translate-x-0"
    />

    <auk-app-header
      [class.hidden]="hideHeaderFooter"
      [showGlobalGoBackButton]="showGlobalGoBackButton"
      [notHideGlobalGoBackButton]="notHideGlobalGoBackButton"
      (dummySearchChange)="setDummySearchRef($event)"
      (openSuggestionsDropdown)="openSuggestionsDropdown()"
    />
  </ng-template>
  <!-- ./HEADER -->

  <!-- MAIN APP -->
  <main class="main">
    <ng-container *aukIfIsActiveBreakpoint="{ max: 'LG' }">
      <auk-top-panel *ngIf="!hasFixedFullPageHeight" [class.hidden]="hideHeaderFooter"/>
    </ng-container>

    <auk-router-outlet-with-loader/>

  </main>

  <auk-app-back-to-top-button
    *ngIf="showBackToTopBtn"
    [class.tw-hidden]="routesService.isSimpleExposePage$ | async"
    [isHiddenOnTablet]="isProductPage"
  />
  <!-- ./MAIN APP -->

</div>

<!-- FOOTER -->
<ng-container *ngIf="showFooter">
  <auk-app-footer *aukAsyncRender
                  [ngClass]="{
                  'blur': disclaimerModalOpened,
                  'hidden': hideHeaderFooter || isMyMessages
                }"
  />
</ng-container>
<!-- ./FOOTER -->

<!-- JSON LD -->
<auk-json-ld/>
<!-- ./JSON LD -->
