import { Injectable } from '@angular/core';
import { DomainService } from '@shared/domain/service/domain.service';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainInitializer implements AppInitializer {

  constructor(
    private readonly domainService: DomainService,
  ) {
  }

  public async init(): Promise<void> {
    await this.domainService.readDomainPreference();
    await lastValueFrom(this.domainService.domainInitialized$);
  }

}
