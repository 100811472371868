import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { RegistrationSharedData } from '../model/registration-shared-data';
import { UserLoginDto } from '@api/aukro-api/model/user-login-dto';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';

export const REGISTRATION_ITEM_RETURN_URL_KEY = 'registration_back_to_item_url';

@Injectable({
  providedIn: 'root',
})
export class RegistrationSharedService {

  private registerUser = new BehaviorSubject<RegistrationSharedData>(null);

  constructor(
    private readonly router: Router,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
  }

  public setRegisterUser(resp: UserLoginDto, email: string): void {
    const user: RegistrationSharedData = { resp, email } as RegistrationSharedData;
    this.registerUser.next(user);
  }

  public getRegisterUser(): Observable<RegistrationSharedData> {
    return this.registerUser.asObservable();
  }

  public checkAccountStatus(actualStatistics: UserInterestStatisticsDto, redirectToRegistrationSteps: boolean = true): boolean {
    if (isNil(actualStatistics)) {
      return false;
    }

    if (!actualStatistics.hasPersonalDataFilled && !actualStatistics.emailVerified) {
      if (actualStatistics.accountTypeEnum === 'COMPANY') {
        void this.router.navigate(['/moje-aukro/muj-ucet/moje-udaje'], {
          queryParams: {
            companyEmailRequired: true,
          },
        });
        return false;
      }
      if (actualStatistics.accountBlockForSaleTypeEnum) {
        if (actualStatistics.accountBlockForSaleTypeEnum === 'BLOCK_SELL') {
          void this.router.navigate(['/moje-aukro/muj-ucet/aktualni-zustatek']);
        } else {
          void this.router.navigate(['/moje-aukro/muj-ucet/moje-udaje']);
        }
        return false;
      } else {
        // ability to disable redirect due to popup flow
        // because we don't want to do redirect (instead we show appreciate dialogs to fill the required data on the same page)
        if (redirectToRegistrationSteps) {
          // redirecting to non-existing step after steps reducing
          if ((actualStatistics.registrationStepDone + 1) >= 4) {
            actualStatistics.registrationStepDone = null;
          }
          void this.router.navigate(['/registrace/krok-' + (actualStatistics.registrationStepDone + 1).toString()]);
        }
        return false;
      }
    }
    return true;
  }

  public getCompanyRegistrationEnabled$(): Observable<boolean> {
    return this.configuratorCacheService.getFeSystemParam<boolean>(
      'COMPANY_REGISTRATION_ALLOWED',
      'BOOLEAN',
    );
  }

}
