import { HttpError } from './model/http-error';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

export abstract class BaseErrorInterceptor extends BaseDestroy {

  protected abstract readonly matcher: RegExp;

  protected shouldIntercept(err: HttpError): boolean {
    return this.matcher.test(String(err.code));
  }

}
