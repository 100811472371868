import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isNil from 'lodash-es/isNil';
import { Observable, catchError, from, map, mergeMap, of, throwError } from 'rxjs';

const DIRTY_LIST_OF_CONTENT_EXEPTIONS_ENDPOINTS = ['/offers-v2/import', '/unsubscribe/{', '/images', 'conversation/contactForm'];
const DIRTY_LIST_OF_NATIVE_EXEPTIONS_ENDPOINTS = ['wss://', 'assets', '/translation'];
const DIRTY_LIST_OF_ANDROID_FILE_MUTATE_ENPOINTS_ENDS = ['/images'];
const DIRTY_LIST_OF_ANDROID_FILE_MUTATE_ENPOINTS = ['conversation/message/attachment'];

/**
 * This interceptor will check for exceptions for native API calls and use CapacitorWebFetch (Web Fetch - non Native)
 * to fix some features that are not availible in HTTPNative Plugin (relative paths, web sockets, etc.)
 * Also it fixes problem with correct content type for HTTPNative Plugin.
 */
@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {

  constructor(@Inject(WINDOW_OBJECT) private readonly window: AukWindow) {}

  public intercept<T extends BodyInit>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.useWebFetchForNative(request)) {
      return this.handleNativeWebRequest(request);
    }

    if (this.appFileUpload(request)) {
      return of(request).pipe(
        mergeMap(() =>
          next.handle(
            request.clone({
              setHeaders: this.getFileHeaders(request.headers),
              body: this.getFileBody(request.body),
            }),
          ),
        ),
      );
    }

    if (this.replaceContentType(request)) {
      return of(request).pipe(mergeMap(() => next.handle(request.clone({ setHeaders: this.getAuthHeaders(request.headers) }))));
    }

    return next.handle(request);
  }

  private getAuthHeaders(headers: HttpHeaders): Record<string, string> {
    const headersModified: { [key: string]: string } = {};
    headers.keys().forEach((key) => {
      headersModified[key] = headers.get(key);
    });

    headersModified['Content-Type'] = 'application/json';

    return headersModified;
  }

  private getFileBody(body: BodyInit): BodyInit {
    if (!(body instanceof FormData)) {
      return body;
    }

    const modifiedFormData = new FormData();

    // Iterate over the file list and add non-empty ones to new modified fromData
    for (const file of body.entries()) {
      if (!isNil(file[1])) {
        modifiedFormData.append(file[0], file[1]);
      }
    }

    return modifiedFormData;
  }

  private getFileHeaders(headers: HttpHeaders): Record<string, string> {
    // native bridge have problem with assigning correct ContentType, this is only actual workaround
    const headersModified: { [key: string]: string } = {};
    headers.keys().forEach((key) => {
      headersModified[key] = headers.get(key);
    });
    headersModified['Content-Type'] = 'multipart/form-data; boundary=----XXXXXX';

    return headersModified;
  }

  private appFileUpload(request: HttpRequest<unknown>): boolean {
    return (
      PlatformCommonService.isNativeApp &&
      (DIRTY_LIST_OF_ANDROID_FILE_MUTATE_ENPOINTS_ENDS.some((singleUrl) => request.url.endsWith(singleUrl)) ||
        DIRTY_LIST_OF_ANDROID_FILE_MUTATE_ENPOINTS.some((singleUrl) => request.url.includes(singleUrl))) &&
      request.method === 'POST'
    );
  }

  private replaceContentType(request: HttpRequest<unknown>): boolean {
    const isContentExeption = DIRTY_LIST_OF_CONTENT_EXEPTIONS_ENDPOINTS.some((singleUrl) => request.url.includes(singleUrl));
    if (PlatformCommonService.isNativeApp && request.method === 'POST' && !isContentExeption) {
      return true;
    }
  }

  private useWebFetchForNative(request: HttpRequest<unknown>): boolean {
    const isNativeFetchException = DIRTY_LIST_OF_NATIVE_EXEPTIONS_ENDPOINTS.some((singleUrl) => request.url.includes(singleUrl));
    if (PlatformCommonService.isNativeApp && isNativeFetchException) {
      return true;
    }
  }

  private handleNativeWebRequest<T extends BodyInit>(request: HttpRequest<T>): Observable<HttpEvent<unknown>> {
    const headersObject = request.headers.keys().reduce((headers, key) => {
      headers[key] = request.headers.get(key);
      return headers;
    }, {});

    return from(
      this.window.CapacitorWebFetch(request.url, {
        method: request.method,
        headers: headersObject,
        body: request.body,
      }),
    ).pipe(
      mergeMap((response: Response) => {
        const contentType: string = response.headers.get('content-type');
        const promise: Promise<unknown> = contentType?.includes('application/json') ? response.json() : response.text();

        return from(promise).pipe(
          map(
            (data) =>
              new HttpResponse({
                body: data,
                status: response.status,
                statusText: response.statusText,
              }),
          ),
          catchError((error) =>
            throwError(
              () =>
                new HttpErrorResponse({
                  error,
                  status: error.status || 500,
                  statusText: error.statusText || 'Internal Server Error',
                }),
            ),
          ),
        );
      }),
      catchError((error) =>
        throwError(
          () =>
            new HttpErrorResponse({
              error,
              status: error.status || 500,
              statusText: error.statusText || 'Internal Server Error',
            }),
        ),
      ),
    );
  }

}
