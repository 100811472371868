import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SocialIconButtonId } from './model/social-icon-button-id';
import { TranslationSource } from '@common/translations/model/translation-source';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-social-icon-button',
  templateUrl: './social-icon-button.component.html',
  styleUrls: ['./social-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    Translate2Module,
    LinkFromHrefDirective,
  ],
})
export class SocialIconButtonComponent extends ColorCombinationInputComponent {

  @Input() public source: SocialIconButtonId;
  @Input() public hrefUrl: TranslationSource;
  @Input() public text: TranslationSource;
  @Input() public changeColorOnHover: boolean = false;

}
