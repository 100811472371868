import { Injectable, Predicate } from '@angular/core';
import find from 'lodash-es/find';
import sortBy from 'lodash-es/sortBy';
import { AsyncSubject, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { RegistryUtil } from './registry.util';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { DateUtils } from '@util/util/date.utils';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { ShippingOptionDtoExtended } from '@shared/model/shipping-option-dto-extended';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { ShippingServiceDto } from '@api/aukro-api/model/shipping-service-dto';
import { RegistryItemDto } from '@api/aukro-api/model/registry-item-dto';
import { RegistryCountryItemDto } from '@api/aukro-api/model/registry-country-item-dto';
import { ShippingOptionDto } from '@api/aukro-api/model/shipping-option-dto';
import { RegistryApiService } from '@api/aukro-api/api/registry-api.service';
import { RegistryDetailDto } from '@api/aukro-api/model/registry-detail-dto';
import { ItemShippingMethodDto } from '@api/aukro-api/model/item-shipping-method-dto';

interface ShippingMethodFilter {
  aukroShippingDisabled: boolean;
  isMobileExposeForm?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RegistryService extends NgUnsubscribe implements CacheAware {

  private accountingEntryTypes = new AsyncSubject<RegistryItemDto[]>();
  private listItemShipmentPayerEnum = new AsyncSubject<RegistryItemDto[]>();

  constructor(
    public readonly cacheService: CacheService,
    private readonly registryApiService: RegistryApiService,
    private readonly userService: UserService,
  ) {
    super();
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'RegistryService#getCountries',
    localeDependent: true,
  })
  public getCountries(): Observable<RegistryCountryItemDto[]> {
    const orderEnum = 'POPULAR';
    return this.registryApiService.getCountries$({ orderEnum });
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'RegistryService#getEUCountries',
    localeDependent: true,
  })
  public getEUCountries(): Observable<RegistryCountryItemDto[]> {
    const orderEnum = 'POPULAR';
    return this.registryApiService.getEUCountries$({ orderEnum });
  }

  public getListItemDurationEnum(itemType: 'BUYNOW' | 'BIDDING'): Observable<RegistryItemDto[]> {
    return this.registryApiService.listItemDurationValues$({ itemType });
  }

  public getListItemQuantityEnum(): Observable<RegistryItemDto[]> {
    return this.getRegistryDetail().pipe(map(data => data.itemQuantity));
  }

  public getListItemShipmentPayerEnum(): Observable<RegistryItemDto[]> {
    if (!this.listItemShipmentPayerEnum.isStopped &&
      this.listItemShipmentPayerEnum.observers.length === 0) {
      this.registryApiService.listItemShipmentPayerValues$()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((data: RegistryItemDto[]) => {
          this.listItemShipmentPayerEnum.next(data);
          this.listItemShipmentPayerEnum.complete();
        });
    }
    return this.listItemShipmentPayerEnum.asObservable();
  }

  public getAccountingEntryTypes(): Observable<RegistryItemDto[]> {
    if (!this.accountingEntryTypes.isStopped &&
      this.accountingEntryTypes.observers.length === 0) {
      this.registryApiService.getAccountingEntryTypes$()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((data: RegistryItemDto[]) => {
          this.accountingEntryTypes.next(data);
          this.accountingEntryTypes.complete();
        });
    }
    return this.accountingEntryTypes.asObservable();
  }

  /**
   * Returns all or filtered (payment on delivery) shipping methods, based on ShippingMethodFilter
   * @param filter
   */
  public getShippingMethodPaymentOnDeliveryOptions(filter?: ShippingMethodFilter): Observable<ItemShippingMethodDto[]> {
    if (filter && filter.aukroShippingDisabled) {
      return this.userService.getActualStatistics()
        .pipe(
          switchMap(userStatistics => this.getRegistryDetailExpose()
            .pipe(
              map(data => data.paymentOnDelivery),
              map(
                shippingMethods => shippingMethods.filter(this.getShippingOptionPredicate(userStatistics, filter)),
              ),
            ),
          ),
        );
    } else {
      return this.getRegistryDetailExpose().pipe(map(data => data.paymentOnDelivery));
    }
  }

  /**
   * Returns all or filtered (payment before) shipping methods, based on ShippingMethodFilter
   * @param filter
   * @param useSimpleExposeEP
   */
  public getShippingMethodPaymentBeforeOptions(
    filter?: ShippingMethodFilter,
    useSimpleExposeEP: boolean = false,
  ): Observable<ItemShippingMethodDto[]> {
    const correctEP = useSimpleExposeEP ? this.getRegistryDetailSimpleExpose() : this.getRegistryDetailExpose();

    if (filter && filter.aukroShippingDisabled) {
      return this.userService.getActualStatistics()
        .pipe(
          switchMap(userStatistics => correctEP
            .pipe(
              map(data => data.paymentBefore),
              map(
                shippingMethods => shippingMethods.filter(this.getShippingOptionPredicate(userStatistics, filter)),
              ),
            ),
          ),
        );
    } else {
      return correctEP.pipe(map(data => data.paymentBefore));
    }
  }

  public getShippingServices(useSimpleExposeEP: boolean = false): Observable<ShippingServiceDto[]> {
    const correctEP = useSimpleExposeEP ? this.getRegistryDetailSimpleExpose() : this.getRegistryDetail();
    return correctEP.pipe(map(data => data.shippingServices));
  }

  private getShippingOptionPredicate(
    userStatistics: UserInterestStatisticsDto,
    filter: ShippingMethodFilter): Predicate<ItemShippingMethodDto> {
    return opt => !this.isShippingOptionExcluded(
      opt,
      userStatistics.aukroShippingDisabled,
      userStatistics.exclusiveZbaDisabled,
      filter.isMobileExposeForm);
  }

  /**
   * Returns a flag whether given shipping option should be excluded or not.
   * <p>
   * Excluding the Aukro shipping options depends just on the <code>aukroShippingDisabled</code> flag.<br>
   * Excluding the "Zasilkovna na vydejni misto CR" shipping option depends on more complex rules:
   * <ul>
   * <li>on the mobile expose form it's excluded only if the Aukro shipping options
   * are included and the other way around (they're complementary)
   * <li>everywhere else then on the mobile expose form it's excluded if the Aukro
   * shipping options are included and the flag <code>exclusiveZbaDisabled</code> is set to true
   * @param shippingOption
   * @param isAukroShippingDisabled Flag whether given user has aukroShipping disabled
   * @param isExclusiveZbaDisabled
   * @param isMobileExposeForm      Flag whether this method is called for the expose form
   */
  public isShippingOptionExcluded(
    shippingOption: ItemShippingMethodDto,
    isAukroShippingDisabled: boolean,
    isExclusiveZbaDisabled: boolean,
    isMobileExposeForm: boolean): boolean {
    const isAukroShippingOptionExcluded: boolean = isAukroShippingDisabled && shippingOption.aukroShipping;

    let isZbAShippingOptionExcluded: boolean = false;
    if (RegistryUtil.isZbAShipping(shippingOption.id)) {
      if (isMobileExposeForm) {
        isZbAShippingOptionExcluded = !isAukroShippingDisabled;
      } else {
        isZbAShippingOptionExcluded = !isAukroShippingDisabled && isExclusiveZbaDisabled;
      }
    }

    return isAukroShippingOptionExcluded || isZbAShippingOptionExcluded;
  }

  /**
   * Combines:
   * - getShippingMethodPaymentOnDeliveryOptions
   * - getShippingMethodPaymentBeforeOptions
   * into a single Object
   * @param filter
   * @param useSimpleExposeEP
   */
  public getShippingMethodPaymentOptions(filter?: ShippingMethodFilter, useSimpleExposeEP: boolean = false): Observable<{
    shippingMethodPaymentOnDelivery: ItemShippingMethodDto[];
    shippingMethodPaymentBefore: ItemShippingMethodDto[];
  }> {
    return observableCombineLatest(
      this.getShippingMethodPaymentOnDeliveryOptions(filter),
      this.getShippingMethodPaymentBeforeOptions(filter, useSimpleExposeEP),
      (shippingMethodPaymentOnDelivery, shippingMethodPaymentBefore) =>
        ({ shippingMethodPaymentOnDelivery, shippingMethodPaymentBefore }),
    );
  }

  public filterShippingOptions(
    selectedShippings: ShippingOptionDto[],
    possibleMethods: ItemShippingMethodDto[]): ShippingOptionDtoExtended[] {
    const shippingOptions: ShippingOptionDtoExtended[] = [];
    selectedShippings?.forEach((shippingOption: ShippingOptionDto) => {
      const shippingMethods: ItemShippingMethodDto = find(possibleMethods,
        (method: ItemShippingMethodDto) => method.id === shippingOption.shippingMethodId);
      if (shippingMethods) {
        shippingOptions.push({
          ...shippingOption,
          packageQuantityHidden: shippingMethods.packageQuantityHidden,
        });
      }
    });
    return shippingOptions;
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'RegistryService#getRegistryDetail',
    localeDependent: true,
  })
  public getRegistryDetail(): Observable<RegistryDetailDto> {
    return this.registryApiService.getDetailRegistry$()
      .pipe(
        map(data => {
          data.paymentBefore = sortBy(data.paymentBefore, (o) => o.position);
          data.paymentOnDelivery = sortBy(data.paymentOnDelivery, (o) => o.position);
          return data;
        }),
      );
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'getRegistryDetailExpose',
    localeDependent: true,
  })
  public getRegistryDetailExpose(): Observable<RegistryDetailDto> {
    return this.registryApiService.getExposeRegistry$()
      .pipe(
        map(data => {
          data.paymentBefore = sortBy(data.paymentBefore, (o) => o.position);
          data.paymentOnDelivery = sortBy(data.paymentOnDelivery, (o) => o.position);
          data.shippingServices = sortBy(data.shippingServices, (o) => o.position);
          return data;
        }),
      );
  }

  @Cacheable({
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(30),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(30),
    key: 'getRegistryDetailSimpleExpose',
    localeDependent: true,
  })
  public getRegistryDetailSimpleExpose(): Observable<RegistryDetailDto> {
    return this.registryApiService.getExposeRegistrySe$()
      .pipe(
        map(data => {
          data.paymentBefore = sortBy(data.paymentBefore, (o) => o.position);
          data.paymentOnDelivery = sortBy(data.paymentOnDelivery, (o) => o.position);
          data.shippingServices = sortBy(data.shippingServices, (o) => o.position);
          return data;
        }),
      );
  }

}
