
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { RegistryCountryItemDto } from '@api/aukro-api/model/registry-country-item-dto';
import { ArrayUtils } from '@util/util/array.utils';

export function getCountryByIdOrCode(
  idOrCode: number | string,
  countries: RegistryCountryItemDto[],
): RegistryCountryItemDto | Nil {
  if (isNil(idOrCode) || ArrayUtils.isEmpty(countries)) {
    return null;
  }

  return countries.find((country) => [country.code, country.id].includes(idOrCode));
}
