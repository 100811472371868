import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyCodeType } from '../model/currency-code.type';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyPreferenceDataService extends NgUnsubscribe {

  private _currentCurrencyCode$: BehaviorSubject<CurrencyCodeType> = new BehaviorSubject<CurrencyCodeType>(null);

  constructor() {
    super();
  }

  public set currentCurrencyCode(val: CurrencyCodeType) {
    if (this.currentCurrencyCodeValue !== val) {
      this._currentCurrencyCode$.next(val);
    }
  }

  public get currentCurrencyCode$(): Observable<CurrencyCodeType> {
    return this._currentCurrencyCode$.asObservable();
  }

  public get currentCurrencyCodeValue(): CurrencyCodeType {
    return this._currentCurrencyCode$.value;
  }

}
