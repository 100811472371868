import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CacheService } from '@common/cache/service/cache.service';
import { CacheAware } from '@common/cache/model/cache-aware';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';
import { CurrencyApiService } from '@api/aukro-api/api/currency-api.service';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { ExchangeRatesDto } from '@api/aukro-api/model/exchange-rates-dto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesService extends NgUnsubscribe implements CacheAware {

  constructor(
    public readonly cacheService: CacheService,
    private readonly currencyApiService: CurrencyApiService,
  ) {
    super();
  }

  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(10),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(10),
    key: 'ExchangeRatesService#exchangeRates',
  })
  public exchangeRates(currency: CurrencyCodeType): Observable<ExchangeRatesDto> {
    return this.currencyApiService.exchangeRates$({ currency });
  }

}
