import { Inject, Injectable, Optional } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import { MessageFormatConfigModel } from './model/message-format-config.model';
import { defaultConfig, MESSAGE_FORMAT_CONFIG } from './aukro-translate.constants';
import MessageFormat, { MessageFunction } from '@messageformat/core';
import isEmpty from 'lodash-es/isEmpty';
import { LoggerService } from '@common/logger/service/logger.service';

/**
 * This compiler expects ICU syntax and compiles the expressions with messageformat.js
 *
 * Extension of https://github.com/lephyrus/ngx-translate-messageformat-compiler
 *
 */
@Injectable()
export class TranslateMessageFormatCompilerService extends TranslateCompiler {

  private messageFormat: MessageFormat;

  constructor(
    private readonly loggerService: LoggerService,
    @Optional()
    @Inject(MESSAGE_FORMAT_CONFIG)
    overrideConfig?: Partial<MessageFormatConfigModel>,
  ) {
    super();

    const config = {
      ...defaultConfig,
      ...overrideConfig,
    };

    this.messageFormat = new MessageFormat(config.locales, config);
  }

  public compile(val: string, lang: string): MessageFunction<'string'> {
    return this.messageFormat.compile(val);
  }

  /**
   * Compiles translations.
   * @param source
   * @param lang
   */
  public compileTranslations(source: Record<string, string>, lang: string): Record<string, MessageFunction<'string'>> {

    const compiled: Record<string, MessageFunction<'string'>> = {};

    const invalidKeys = [];
    for (const [key, value] of Object.entries(source)) {
      try {
        compiled[key] = this.messageFormat.compile(value);
      } catch (e) {
        invalidKeys.push(key);
      }
    }

    if (!isEmpty(invalidKeys)) {
      this.logError(invalidKeys);
    }

    return compiled;
  }

  private logError(invalidKeys: string[]): void {
    const message = `Translations compilation failed for keys: "${ invalidKeys }"`;
    console.error(message);
    this.loggerService.logException(
      new Error(message),
      {
        fingerprint: ['TRANSLATE_MESSAGE_FORMAT_COMPILER_ERROR'],
        extra: {
          invalidKeys,
        },
      },
    );
  }

}
