<auk-icon
  class="tw-cursor-pointer"
  [colorCombination]="iconColorCombination"
  [tooltipModel]="{
    type: 'INFORMATIVE',
    text: hintTooltip,
    size: 'SM',
  }"
  [delayOnClose]="0"
  [placement]="tooltipPosition"
  [isTooltipDisabled]="isTooltipDisabled"
  aukToolTip2
  iconType="SVG"
  size="MD"
  source="info_outline"
/>
