import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, mergeMap, isObservable, of } from 'rxjs';
import { Result } from './base-guard.model';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

/**
 *
 * The `BaseGuardService` is an abstract class that provides a base implementation for route guards.
 *
 * This service is designed to be extended by guard services that implement the `canActivateImp` method.
 *
 * If the parent guard returns `false` and the previous route is empty, the `BaseGuardService` will
 * reset the route to the homepage ('/')
 *
 * TODO: [PDEV-20803] - use functional approach - so we can use it in functional guards
 */
export abstract class BaseGuardService extends BaseDestroy {

  constructor(
    protected readonly router: Router,
  ) {
    super();
  }

  /**
   * Checks if the route can be activated based on the implementation of `canActivateImpl`
   * @returns boolean, an observable of boolean, or a UrlTree.
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Result {
    const result: Result =  this.canActivateImpl(route, state);

    const result$ = isObservable(result) ? result : of(result);

    return result$
      .pipe(
        mergeMap((r: boolean) => {
          if (r === false) {
            return this.handleEmptySnapshotUrl();
          }
          return of(r);
        }),
      );
  }

  private handleEmptySnapshotUrl(): Observable<boolean | UrlTree> {
    if (this.router.routerState.snapshot.url === '') {
      return of(this.router.createUrlTree(['/']));
    } else {
      return of(false);
    }
  }

  /**
   * Method `canActivateImpl` should be implemented by the extending class to provide the actual guard logic
   */
  protected abstract canActivateImpl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Result;

}
