import { Routes, UrlSegment } from '@angular/router';
import { MarketingMailSettingsPageModeEnum } from '../typings/original/internal';
import { ListingType } from '@shared/listing/model/listing-type.enum';
import { allowedToEditOfferGuard } from '@shared/services/routing/allowed-to-edit-offer.guard';
import { allowedToSaleGuard } from '@shared/services/routing/allowed-to-sale.guard';
import { authorizationGuard } from '@shared/authentication/guard/authorization.guard';
import { registrationGuard } from '@shared/services/routing/registration.guard';
import { searchParamsGuard } from '@shared/services/routing/search-params.guard';
import { ProductUtil } from '@shared/util/product/product.util';
import { currentOfferInCollectionGuard } from '@shared/services/routing/current-offer-in-collection.guard';
import { allowedToContactGuard } from '@shared/services/routing/allowed-to-contact.guard';
import { ListingConstants } from '@shared/listing/const/listing.constants';
import { formSwitcherGuard } from '@section/offer-expose/guard/form-switcher.guard';
import { RouteDataConstants } from '@common/routing/const/route-data.constants';
import { openAndroidGuard } from '@shared/services/routing/open-android.guard';
import { getLeafCanActivateGuards } from '@common/routing/domain/get-leaf-can-activate-guard.fn';

// Every new or changed route needs to be updated in INGRESS as well because of SSR
export const ROUTES: Routes = [
  {
    path: '',
    ...getLeafCanActivateGuards(),
    loadComponent: () => import('@section/homepage/page/homepage/homepage.page.component').then((m) => m.HomepagePageComponent),
    data: {
      pagetype: 'Homepage',
      doNotShowGoBackButton: true,
      [RouteDataConstants.APP_STABLE_MONITORING_ENABLED]: true,
    },
  },
  {
    path: 'novinky',
    children: [
      {
        path: '',
        pathMatch: 'full',
        ...getLeafCanActivateGuards(),
        loadComponent: () =>
          import('@section/news/page/news/news.page.component').then((m) => m.NewsPageComponent),
        data: {
          seo: {
            title: 'TITLES.NOVINKY',
          },
        },
      },
      {
        path: ':newSeoUrl',
        ...getLeafCanActivateGuards(),
        loadComponent: () =>
          import('@section/news/page/new-detail/new-detail.page.component').then((m) => m.NewDetailPageComponent),
        data: {
          seo: {
            title: 'TITLES.NOVINKA',
          },
        },
      },
    ],
    data: {
      [RouteDataConstants.APP_STABLE_MONITORING_ENABLED]: true,
    },
  },
  {
    path: 'uzivatel',
    loadChildren: () => import('@section/user-profile/user-profile.module').then((m) => m.UserProfileModule),
    data: {
      [RouteDataConstants.APP_STABLE_MONITORING_ENABLED]: true,
    },
  },
  {
    path: 'zebricky',
    loadChildren: () => import('@section/sellers-rankings/sellers-rankings.module').then((m) => m.SellersRankingsModule),
  },
  {
    path: 'registrace',
    canActivate: [registrationGuard],
    loadChildren: () => import('@section/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    path: 'prihlaseni',
    loadChildren: () => import('@section/login/login.module').then((m) => m.LoginModule),
    data: { pagetype: 'Login' },
  },
  {
    path: 'odstranit',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/remove-products/remove-products.module').then((m) => m.RemoveProductsModule),
  },
  {
    path: 'odstranit-sledovane',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/remove-watching/remove-watching.module').then((m) => m.RemoveWatchingModule),
  },
  {
    path: 'odstranit-komentare',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/remove-feedbacks/remove-feedbacks.module').then((m) => m.RemoveFeedbacksModule),
  },
  {
    path: 'odstranit-stazene',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () =>
      import('@section/remove-cancel-requests/remove-cancel-requests.module').then((m) => m.RemoveCancelRequestsModule),
  },
  {
    path: 'odstranit-oblibene-prodejce',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/remove-favourite-sellers/remove-favourite-sellers.module')
      .then((m) => m.RemoveFavouriteSellersModule),
  },
  {
    path: 'odstranit-oblibene-vyhledavani',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/remove-favourite-searching/remove-favourite-searching.module')
      .then((m) => m.RemoveFavouriteSearchingModule),
  },
  {
    path: 'odstranit-sdeleni',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () =>
      import('@section/remove-announcements/remove-announcements.module').then((m) => m.RemoveAnnouncementsModule),
  },
  {
    path: 'cerna-listina/odstranit',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () =>
      import('@section/remove-blacklist-buyers/remove-blacklist-buyers.module').then((m) => m.RemoveBlacklistBuyersModule),
  },
  {
    path: 'zhave-aukce',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.HOT_AUCTIONS,
      pagetype: 'Hot Auctions',
    },
  },
  {
    path: 'specialni-koncici',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.SPECIAL_ENDING,
      pagetype: 'Ending Items',
    },
  },
  {
    path: 'doporucene-nabidky', // todo: probably unused, let Product department to check
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.INTERESTING_OFFERS,
      pagetype: 'Category',
    },
  },
  {
    path: 'specialni-od-1kc',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.SPECIAL_FROM_1,
      pagetype: 'From 1 CZK',
    },
  },
  {
    path: 'do-300-kc',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.SPECIAL_300_CZK,
      pagetype: 'To 300 CZK',
    },
  },
  {
    path: 'nove-nabidky',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.NEW_OFFERS,
      pagetype: 'New offers',
    },
  },
  {
    path: 'popularni-nabidky',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.POPULAR_OFFERS,
      pagetype: 'Popular offers',
    },
  },
  {
    path: 'black-friday',
    loadChildren: () => import('@section/listing/listing.module').then(m => m.ListingModule),
    data: {
      listingType: ListingType.BLACK_FRIDAY,
      pagetype: 'Black Friday',
    },
  },
  {
    path: 'cenove-hity',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.PRICE_HITS,
      pagetype: 'Cenové hity',
    },
  },
  {
    path: 'premiove-nabidky',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.PREMIUM_OFFERS,
      pagetype: 'Prémiové nabídky',
    },
  },
  {
    path: 'charitativni-aukce',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.CHARITY_OFFERS,
      pagetype: 'Charity offers',
    },
  },
  {
    path: 'blaznive-ceny-interni',
    loadChildren: () => import('@section/listing/listing.module').then(m => m.ListingModule),
    data: {
      listingType: ListingType.CRAZY_PRICES_INTERNAL,
      pagetype: 'Category',
    },
  },
  {
    path: 'mali-novi-prodejci',
    loadChildren: () => import('@section/listing/listing.module').then(m => m.ListingModule),
    data: {
      listingType: ListingType.SMALL_NEW_SELLERS_INTERNAL,
      pagetype: 'Category',
    },
  },
  {
    path: 'nabidky-s-apv',
    loadChildren: () => import('@section/listing/listing.module').then(m => m.ListingModule),
    data: {
      listingType: ListingType.APV_INTERNAL,
      pagetype: 'Category',
    },
  },
  {
    path: 'vysledky-vyhledavani',
    canActivate: [searchParamsGuard],
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.SEARCH,
      pagetype: 'Search',
    },
  },
  {
    path: 'vv/:indexedSearchUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.INDEXED_SEARCH,
      pagetype: 'Search',
    },
  },
  { // temporary solution: keep until all external links will be changed
    path: 'vystavit-predmet',
    redirectTo: '/vystavit',
  },
  {
    path: 'jednoduche-vystaveni',
    canActivate: [allowedToSaleGuard, formSwitcherGuard],
    canActivateChild: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/simple-expose/simple-expose.module')
      .then((m) => m.SimpleExposeModule),
  },
  {
    path: 'vystavit',
    canActivate: [allowedToSaleGuard],
    canActivateChild: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/offer-expose/offer-expose.module').then((m) => m.OfferExposeModule),
  },
  {
    path: 'rychle-vystavit',
    canActivate: [authorizationGuard, allowedToSaleGuard],
    canActivateChild: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/expose-quickly/expose-quickly.module').then((m) => m.ExposeQuicklyModule),
  },
  {
    path: 'polozit-dotaz-prodejci',
    redirectTo: 'zacit-konverzaci/nabidka',
  },
  {
    path: 'polozit-dotaz-kupujicimu',
    redirectTo: 'zacit-konverzaci/zakoupena-nabidka',
  },
  {
    path: 'zacit-konverzaci',
    canActivate: [allowedToContactGuard],
    loadChildren: () =>
      import('@section/start-conversation/page/start-conversation.module').then((m) => m.StartConversationModule),
  },
  {
    path: 'nahlasit-poruseni-pravidel/:itemId',
    loadChildren: () =>
      import('@section/report-policy-violation/report-policy-violation.module').then((m) => m.ReportPolicyViolationModule),
  },
  {
    path: 'odpoved-uzivateli',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/ikem-answer/ikem-answer.module').then((m) => m.IkemAnswerModule),
  },
  {
    path: 'kosik',
    loadChildren: () => import('@section/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'pays',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/pays/pays.module').then((m) => m.PaysModule),
  },
  {
    path: 'card-payment',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/card-payment/card-payment.module').then((m) => m.CardPaymentModule),
  },
  {
    path: 'kontaktujte-nas',
    loadChildren: () => import('@section/contact-form-new-ticket-system/contact-form-new-ticket-system.module')
      .then((m) => m.ContactFormNewTicketSystemModule),
  },
  {
    path: 'moje-aukro',
    canActivateChild: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/my-aukro/my-aukro.module').then((m) => m.MyAukroModule),
  },
  {
    path: 'mapa-kategorii',
    loadChildren: () => import('@section/category-list/category-list.module').then((m) => m.CategoryListModule),
  },
  {
    path: '404',
    loadChildren: () => import('@section/not-found/not-found.module').then((m) => m.NotFoundModule),
    data: { pagetype: 'Error 404' },
  },
  {
    path: '500',
    loadChildren: () => import('@section/internal-error/internal-error.module').then((m) => m.InternalErrorModule),
    data: { pagetype: 'Error 500' },
  },
  {
    path: 'zavreno',
    loadChildren: () => import('@section/closed/closed.module').then((m) => m.ClosedModule),
  },
  {
    path: 'zavreno-native',
    loadChildren: () => import('@section/closed-native/closed-native.module').then((m) => m.ClosedNativeModule),
  },
  {
    path: 'payu-change-settings',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/payu-activate/payu-activate.module').then((m) => m.PayuActivateModule),
  },
  {
    path: 'podat-odvolani',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/recall/recall.module').then((m) => m.RecallModule),
  },
  {
    path: 'zapomenute-heslo',
    loadChildren: () => import('@section/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: 'reset-hesla/:token',
    loadChildren: () => import('@section/set-new-password/set-new-password.module').then((m) => m.SetNewPasswordModule),
  },
  {
    path: 'overeni-uzivatele',
    loadChildren: () => import('@section/user-authentication/user-authentication.module').then((m) => m.UserAuthenticationModule),
  },
  {
    path: 'kontakt',
    loadChildren: () => import('@section/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'ukoncit-prodej',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/close-offer/close-offer.module').then((m) => m.CloseOfferModule),
  },
  {
    path: 'zaplatit',
    canActivate: [authorizationGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/auction-cart/auction-cart.module').then((m) => m.AuctionCartModule),
  },
  {
    path: 'zadost-o-zruseni-prihozu',
    loadChildren: () => import('@section/remove-bids/remove-bids.module').then((m) => m.RemoveBidsModule),
  },
  {
    path: 'prodlouzit-nabidku',
    canActivate: [authorizationGuard, allowedToSaleGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () => import('@section/automatic-reexpose/automatic-reexpose.module').then((m) => m.AutomaticReexposeModule),
  },
  {
    path: 'zrusit',
    canActivate: [authorizationGuard, allowedToEditOfferGuard],
    data: {
      loggedInRequired: true,
    },
    loadChildren: () =>
      import('@section/change-offer/change-offer.module').then((m) => m.ChangeOfferModule),
  },
  {
    path: 'potvrdit-email-zhave-aukce',
    loadChildren: () =>
      import('@section/confirm-email-collector/confirm-email-collector.module').then((m) => m.ConfirmEmailCollectorModule),
  },
  {
    path: 'confirm-email/:token',
    loadChildren: () => import('@section/confirm-email/confirm-email.module').then((m) => m.ConfirmEmailModule),
  },
  {
    path: 'oblibene-vyhledavani/:hash',
    loadChildren: () => import('@section/favourite-search/favourite-search.module').then((m) => m.FavouriteSearchModule),
    data: {
      [ListingConstants.QUERY_PARAM_NAME_SEARCH_REDIRECT_DISABLED]: true,
      [ListingConstants.QUERY_PARAM_NAME_FAVOURITE_SEARCH]: true,
    },
  },
  {
    path: 'overeni-uctu',
    canActivateChild: [authorizationGuard],
    loadChildren: () => import('@section/account-verification/account-verification.module').then((m) => m.AccountVerificationModule),
  },
  {
    path: 'aktivace-uctu',
    loadChildren: () => import('@section/account-activation/account-activation.module').then((m) => m.AccountActivationModule),
    data: { pagetype: 'Activation' },
  },
  {
    path: 'open-android',
    canActivate: [openAndroidGuard],
    children: [],
  },
  {
    path: 'open-android/:url',
    canActivate: [openAndroidGuard],
    children: [],
  },
  {
    path: 'rozhodnuti-vraceni-provize/:token/:confirm',
    loadChildren: () => import('@section/comission-return/commission-return.module').then((m) => m.CommissionReturnModule),
  },
  {
    path: 'odhlaseni-z-odberu-upozornovani',
    loadChildren: () =>
      import('@section/marketing-mail-settings/marketing-mail-settings.module').then((m) => m.MarketingMailSettingsModule),
    data: { pageMode: MarketingMailSettingsPageModeEnum.NOT_AUTHENTICATED },
  },
  {
    path: 'odhlaseni-z-odberu',
    loadChildren: () => import('@section/email-unsubscribe/email-unsubscribe.module').then((m) => m.EmailUnsubscribeModule),
  },
  {
    path: 'dokumenty',
    loadChildren: () => import('@section/google-document-page/google-document-page.module').then((m) => m.GoogleDocumentPageModule),
    data: {
      [RouteDataConstants.APP_STABLE_MONITORING_ENABLED]: true,
    },
  },
  {
    path: 'stranka/:siteUrlPart',
    ...getLeafCanActivateGuards(),
    loadComponent: () => import('@section/standalone-page/page/standalone.page.component').then((m) => m.StandalonePageComponent),
  },
  {
    matcher: productDetailMatcher,
    loadChildren: () => import('@section/item-detail-page/item-detail.page.module').then((m) => m.ItemDetailPageModule),
    data: {
      pagetype: 'Product',
      notHideGlobalGoBackButton: true,
      [RouteDataConstants.APP_STABLE_MONITORING_ENABLED]: true,
    },
  },
  {
    path: 'lp/:seoUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.LANDING_PAGE,
      pagetype: 'Landing page',
    },
  },
  {
    path: 'lp/:seoUrl/:attributeSeoUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.LANDING_PAGE,
      pagetype: 'Landing page',
    },
  },
  {
    path: 'lp', // if user go to /lp without :seoUrl redirect into Homepage
    redirectTo: '/',
  },
  {
    path: 'aktivace-nabidky/:itemId',
    canActivate: [authorizationGuard],
    loadChildren: () => import('@section/confirm-item/confirm-item.module').then((m) => m.ConfirmItemModule),
  },
  {
    path: 'kolekce/:collectionId/aktualni-nabidka',
    canActivate: [currentOfferInCollectionGuard], // Always redirects to current offer in collection
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
  },
  {
    path: 'kolekce/:collectionSeoUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.ITEMS_COLLECTION,
      pagetype: 'Items collection',
    },
  },
  {
    path: ':seoUrl/:attributeSeoUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.CATEGORY_OFFERS,
      pagetype: 'Category',
    },
  },
  {
    path: ':seoUrl',
    loadChildren: () => import('@section/listing/listing.module').then((m) => m.ListingModule),
    data: {
      listingType: ListingType.CATEGORY_OFFERS,
      pagetype: 'Category',
    },
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

function productDetailMatcher(url: UrlSegment[]): { consumed: UrlSegment[] } {
  const isDepositUrl = url.length === 2 && url[1].path === 'kauce';

  if ((url.length === 1 || isDepositUrl) && url[0].path) {
    const offerUrlObject = ProductUtil.getOfferUrlObject(url[0].path);
    if (offerUrlObject) {
      if (offerUrlObject.id && (!offerUrlObject.hasIChar || (offerUrlObject.hasIChar && offerUrlObject.hasHtmlExtension))) {
        return {
          consumed: [url[0]],
        };
      }
    }
  }
  return null;
}
