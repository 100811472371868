import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { GenerateQRCodeDto } from '../model/generate-qr-code-dto';
import { ImageInfoLocationDto } from '../model/image-info-location-dto';
import { ImageLocationWithUploadContextDto } from '../model/image-location-with-upload-context-dto';
import { UploadNonItemImageRequest } from '../model/upload-non-item-image-request';
import { environment } from '@environment';

export interface ConvertFilenameRequestParams {
    /** Batch size */
    batchSize: number;
    /** Total size */
    totalSize: number;
}

export interface GenerateQRCodeRequestParams {
    generateQRCodeDto: GenerateQRCodeDto;
}

export interface ImagesLocationInfoRequestParams {
    imageIds: number[];
}

export interface ImagesWithUploadContextRequestParams {
    imageIds: number[];
}

export interface PreviewThumbnailImagesWithUploadContextRequestParams {
    imageIds: number[];
}

export interface RenameToNewFormatRequestParams {
    /** Batch size */
    batchSize: number;
    /** Total size */
    totalSize: number;
}

export interface RotateImageRequestParams {
    imageId: number;
    rotationSteps: number;
}

export interface UploadAvatarRequestParams {
    /** file1 */
    file1?: Blob;
    /** Total % of height from source image we want to use */
    height: number;
    /** Horizontal starting position of image in percent of source width */
    offsetX: number;
    /** Vertical starting position of image in percent of source height */
    offsetY: number;
    /** Total % of width from source image we want to use */
    width: number;
}

export interface UploadImagesRequestParams {
    /** context */
    context?: Blob;
    /** file1 */
    file1?: Blob;
    /** file2 */
    file2?: Blob;
    /** file3 */
    file3?: Blob;
    /** file4 */
    file4?: Blob;
    /** file5 */
    file5?: Blob;
    /** file6 */
    file6?: Blob;
    /** file7 */
    file7?: Blob;
    /** file8 */
    file8?: Blob;
}

export interface UploadNonItemImageRequestParams {
    imageType: 'LANDING_PAGE' | 'CATEGORY';
    uploadNonItemImageRequest?: UploadNonItemImageRequest;
}

@Injectable({
  providedIn: 'root',
})
export class ImagesApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   */
  public convertFilename$(
    requestParameters: ConvertFilenameRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<number> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      totalSize: requestParameters.totalSize,
      batchSize: requestParameters.batchSize,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'number'),
      headers,
    };
    return this.httpClient.request<number>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/convertFilename`,
      options,
    );
  }

  /**
   * Delete avatar
   */
  public deleteAvatar$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'delete',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/avatar`,
      options,
    );
  }

  /**
   * Generates QR code for given payment information
   */
  public generateQRCode$(
    requestParameters: GenerateQRCodeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.generateQRCodeDto,
      responseType: this.configuration.getResponseType(false, 'string'),
      headers,
    };
    return this.httpClient.request<string>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/QRcode/generate`,
      options,
    );
  }

  /**
   * Get images location info by IDs
   */
  public imagesLocationInfo$(
    requestParameters: ImagesLocationInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: ImageInfoLocationDto }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      imageIds: requestParameters.imageIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, '{ [key: string]: ImageInfoLocationDto; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: ImageInfoLocationDto }>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images`,
      options,
    );
  }

  /**
   * Get images location info by IDs with upload context
   */
  public imagesWithUploadContext$(
    requestParameters: ImagesWithUploadContextRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: ImageLocationWithUploadContextDto }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      imageIds: requestParameters.imageIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, '{ [key: string]: ImageLocationWithUploadContextDto; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: ImageLocationWithUploadContextDto }>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/with-upload-context`,
      options,
    );
  }

  /**
   * Get preview thumbnails images location info by IDs with upload context
   */
  public previewThumbnailImagesWithUploadContext$(
    requestParameters: PreviewThumbnailImagesWithUploadContextRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: ImageLocationWithUploadContextDto }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      imageIds: requestParameters.imageIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, '{ [key: string]: ImageLocationWithUploadContextDto; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: ImageLocationWithUploadContextDto }>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/preview/with-upload-context`,
      options,
    );
  }

  /**
   */
  public renameToNewFormat$(
    requestParameters: RenameToNewFormatRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<number> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      totalSize: requestParameters.totalSize,
      batchSize: requestParameters.batchSize,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'number'),
      headers,
    };
    return this.httpClient.request<number>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/renameToNewFormat`,
      options,
    );
  }

  /**
   * Rotates existing image
   */
  public rotateImage$(
    requestParameters: RotateImageRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ImageInfoLocationDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      rotationSteps: requestParameters.rotationSteps,
      imageId: requestParameters.imageId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'ImageInfoLocationDto'),
      headers,
    };
    return this.httpClient.request<ImageInfoLocationDto>(
      'put',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/rotate`,
      options,
    );
  }

  /**
   * Saves avatar
   */
  public uploadAvatar$(
    requestParameters: UploadAvatarRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      width: requestParameters.width,
      height: requestParameters.height,
      offsetX: requestParameters.offsetX,
      offsetY: requestParameters.offsetY,
    };
    const formParams = {
      file1: requestParameters.file1,
    };
    const options: object = {
      context: httpContext,
      body: this.configuration.toFormData(formParams),
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/avatar`,
      options,
    );
  }

  /**
   * Upload images
   */
  public uploadImages$(
    requestParameters: UploadImagesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: ImageInfoLocationDto }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const formParams = {
      file1: requestParameters.file1,
      file2: requestParameters.file2,
      file3: requestParameters.file3,
      file4: requestParameters.file4,
      file5: requestParameters.file5,
      file6: requestParameters.file6,
      file7: requestParameters.file7,
      file8: requestParameters.file8,
      context: requestParameters.context,
    };
    const options: object = {
      context: httpContext,
      body: this.configuration.toFormData(formParams),
      responseType: this.configuration.getResponseType(false, '{ [key: string]: ImageInfoLocationDto; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: ImageInfoLocationDto }>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images`,
      options,
    );
  }

  /**
   * Upload admin tool images
   */
  public uploadNonItemImage$(
    requestParameters: UploadNonItemImageRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: ImageInfoLocationDto }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      imageType: requestParameters.imageType,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.uploadNonItemImageRequest,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, '{ [key: string]: ImageInfoLocationDto; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: ImageInfoLocationDto }>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/images/admin-tool-image`,
      options,
    );
  }

}
