/**
 * Unit maps for key from API to Bulgarian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_BG = {
  PAIRS: {
    '=0': 'пари',
    '=1': 'пара',
    '=2': 'пари',
    '=3': 'пари',
    '=4': 'пари',
    other: 'пари',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'пари',
    '=1': 'пара',
    other: 'пари',
  },
  PIECES: {
    '=0': 'бройки',
    '=1': 'брой',
    '=2': 'броя',
    '=3': 'броя',
    '=4': 'броя',
    other: 'бройки',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'бройки',
    '=1': 'брой',
    other: 'бройки',
  },
  PIECE_SHORT: {
    other: 'бр.',
  },
  SET: {
    '=0': 'комплекта',
    '=1': 'комплект',
    '=2': 'комплекта',
    '=3': 'комплекта',
    '=4': 'комплекта',
    other: 'комплекта',
  },
  SET_ACCUSATIVE: {
    '=0': 'комплекта',
    '=1': 'комплект',
    other: 'комплекта',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'залага',
    '=2': 'залагат',
    '=3': 'залагат',
    '=4': 'залагат',
    other: 'залага',
  },
  BET_PAST: {
    '=1': 'заложил',
    '=2': 'заложили',
    '=3': 'заложили',
    '=4': 'заложили',
    other: 'заложило',
  },
  ITEM: {
    '=0': 'артикула',
    '=1': 'артикул',
    '=2': 'артикула',
    '=3': 'артикула',
    '=4': 'артикула',
    other: 'артикула',
  },
  DAY: {
    '=0': 'дни',
    '=1': 'ден',
    '=2': 'дни',
    '=3': 'дни',
    '=4': 'дни',
    other: 'дни',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'ден',
    other: 'дни',
  },
  HOUR: {
    '=0': 'часа',
    '=1': 'час',
    '=2': 'часа',
    '=3': 'часа',
    '=4': 'часа',
    other: 'часа',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'час',
    other: 'часа',
  },
  MINUTE: {
    '=0': 'минути',
    '=1': 'минута',
    '=2': 'минути',
    '=3': 'минути',
    '=4': 'минути',
    other: 'минути',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'минута',
    other: 'минути',
  },
  OTHER: {
    '=0': 'други',
    '=1': 'друг',
    '=2': 'други',
    '=3': 'други',
    '=4': 'други',
    other: 'други',
  },
  FILE: {
    '=0': 'файлове',
    '=1': 'файл',
    '=2': 'файла',
    '=3': 'файлове',
    '=4': 'файлове',
    other: 'файлове',
  },
  IMAGE: {
    '=0': 'изображения',
    '=1': 'изображение',
    '=2': 'изображения',
    '=3': 'изображения',
    '=4': 'изображения',
    other: 'изображения',
  },
  OFFER: {
    '=0': 'оферти',
    '=1': 'оферта',
    '=2': 'оферти',
    '=3': 'оферти',
    '=4': 'оферти',
    other: 'оферти',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'оферти',
    '=1': 'оферта',
    '=2': 'оферти',
    '=3': 'оферти',
    '=4': 'оферти',
    other: 'оферти',
  },
  POSITIVE_COMMENT: {
    '=0': 'позитивни коментари',
    '=1': 'позитивен коментар',
    '=2': 'позитивни коментари',
    '=3': 'позитивни коментари',
    '=4': 'позитивни коментари',
    other: 'позитивни коментари',
  },
  USER_GENITIV: {
    '=0': 'потребители',
    '=1': 'потребител',
    '=2': 'потребители',
    '=3': 'потребители',
    '=4': 'потребители',
    other: 'потребители',
  },
  USER_INSTRUMENTAL: {
    '=0': 'потребители',
    '=1': 'потребител',
    other: 'потребители',
  },
  ADDED_PASSIVE: {
    '=0': 'добавени',
    '=1': 'добавен',
    '=2': 'добавени',
    '=3': 'добавени',
    '=4': 'добавени',
    other: 'добавени',
  },
};
