<div class="tw-flex">
  @if (userRating) {

    @if (!anonymous) {
      <a class="username" [routerLink]="['/uzivatel', userRating.userName, 'nabidky']">{{ userRating.userName }}</a>

      (<a class="num"
          [routerLink]="['/uzivatel', userRating.userName]"
      >{{ userRating.rating || 0 }}</a>)
    }
    @if (anonymous) {
      <span class="username anonymous">
      {{ userRating.userName }}
    </span>
    }

    @if (userRating.domain) {
      <auk-icon-with-text
          [iconModel]="iconModel(userRating.domain)"
          class="tw-ml-2 tw-mr-2"
      ></auk-icon-with-text>
    }

    <a class="icon" aukLinkFromHref="https://napoveda.aukro.cz/node/39" [openInNewTab]="true">
      <auk-star-icon-legacy [starType]="userRating.starType"></auk-star-icon-legacy>
    </a>
    @if (userRating.aukroPlus && !hideAukroPlus) {
      <a class="icon">
    <span class="icn-aukro-topSeller aukro-plus" aukPopover>
      <auk-svg-icon-legacy id="aukro-topSeller" class="small" aukPopoverToggle></auk-svg-icon-legacy>
      <div class="dropdown-container-a" aukPopoverContainer>
        <i class="material-icons text-gray text-bold remove" aukPopoverCloseIcon>
          close
        </i>
        <div class="dropdown-content">
          <p>
            <span [aukInnerHtml]="{key: 'USER_RATING_COMPONENT_HTML_1'}"></span>
            {{ 'USER_RATING_COMPONENT_HTML_2' | translate }}
          </p>
        </div>
      </div>
    </span>
      </a>
    }
    @if (userRating.companyAccount) {
      <a class="icon" aukLinkFromHref="https://napoveda.aukro.cz/node/100" [openInNewTab]="true">
        <i class="material-icons text-blue vertical-sub">
          business_center
        </i>
      </a>
    }
  }
  @if (!userRating) {
    <span class="no-reg">{{ 'USER_RATING_COMPONENT_HTML_3' | translate }}</span>
  }
  @if (showBuyer) {
    <span class="buyer">{{ 'USER_RATING_COMPONENT_HTML_4' | translate }}</span>
  }
  @if (showSeller) {
    <span class="seller">{{ 'USER_RATING_COMPONENT_HTML_5' | translate }}</span>
  }
  @if (userRating?.userBlocked) {
    <span class="blocked">{{ 'USER_RATING_COMPONENT_HTML_6' | translate }}</span>
  }

</div>
