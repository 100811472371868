import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { DomainService } from '@shared/domain/service/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DynamicScriptLoaderService } from '@shared/services/script/dynamic-script-loader.service';
import { CookieConsentService } from '@shared/cookie-consent/service/cookie-consent.service';
import { Observable, takeUntil } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { APP_CONSTANTS } from '@app-constants';
import { LoggerService } from '@common/logger/service/logger.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerInitializer extends BaseDestroy implements AppInitializer {

  constructor(
    private readonly domainService: DomainService,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly cookieConsentService: CookieConsentService,
    private readonly httpClient: HttpClient,
    private readonly platformCommonService: PlatformCommonService,
    private readonly loggerService: LoggerService,
  ) {
    super();
  }

  public init(): void {
    // init gtm only in browser
    if (!this.platformCommonService.isBrowser) {
      return;
    }

    const gtmId: string = this.domainService.getCurrentDomainEnvValue('GTM_ID');
    const auth: string = this.domainService.getCurrentDomainEnvValue('GTM_AUTH');
    const preview: string = this.domainService.getCurrentDomainEnvValue('GTM_PREVIEW');
    const cookies: string = APP_CONSTANTS.GTM_COOKIES_WIN;

    const gtmScript: string = `(function(w,d,s,l,i){
      w[l]=w[l]||[];
      w[l].push({'gtm.start': window.appInitTime,event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=${ auth }&gtm_preview=${ preview }&gtm_cookies_win=${ cookies }';
      f.parentNode.insertBefore(j,f);})
      (window,document,'script','dataLayer','${ gtmId }');`;

    // Insert gtag and GTM scripts when consent is initialized
    this.cookieConsentService.pushCallbackToDidomiOnReady(() => {
      this.insertGtagModifiedForIosApp();
      this.dynamicScriptLoaderService.insertJsScript(gtmScript);
    });
  }

  private insertGtagModifiedForIosApp(): void {
    if (!PlatformCommonService.isNativeIosApp) {
      return;
    }

    this.fetchGtag()
      .pipe(
        map((scriptText: string) => this.modifyGtagScriptForIosApp(scriptText)),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (modifiedScriptText: string) => this.dynamicScriptLoaderService.insertJsScript(modifiedScriptText),
        error: (error: Error) => {
          const exceptionText = 'GTM :: Failed to fetch, process or insert the gtag script in iOS Capacitor app: ' + JSON.stringify(error);
          console.error(exceptionText);
          this.loggerService.logException(exceptionText, { fingerprint: ['GTAG_PROCESSING_FAILED'] });
        },
      });
  }

  private fetchGtag(): Observable<string> {
    const options: object = {
      responseType: 'text',
    };
    const ga4Id: string = this.domainService.getCurrentDomainEnvValue('GA4_ID');

    return this.httpClient.request<string>(
      'get',
      `https://www.googletagmanager.com/gtag/js?id=${ ga4Id }&l=dataLayer&cx=c`,
      options,
    );
  }

  private modifyGtagScriptForIosApp(scriptText: string): string {
    // Change all occurrences of "https:" to "capacitor:", because in iOS Capacitor app, it is not possible to change protocol,
    // and the original gtag script strictly requires https: protocol
    const modifiedText = scriptText.replace(/"https:"/g, `"capacitor:"`);

    if (scriptText === modifiedText) {
      const exceptionText = `GTM :: The string "https:" was not found and replaced in the gtag script, likely because the script has been
      modified by Google. As a result, Google Analytics measurement may not function correctly in the iOS Capacitor app.`;
      console.error(exceptionText);
      this.loggerService.logException(exceptionText, { fingerprint: ['GTAG_SCRIPT_CHANGED_BY_GOOGLE'] });
    }

    return modifiedText;
  }

}
