import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { SocialLoginService } from '@shared/services/social-login/social-login.service';
import { finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { SocialUser } from '@abacritt/angularx-social-login';
import { AuthenticateWrapperComponent } from '@shared/legacy/component/authenticate-wrapper/component/authenticate-wrapper.component';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { tokenCacheBuster$ } from '@shared/user/constant/cache-busters';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { DialogAlert } from '@common/dialog-wrapper/dialog-alert';
import { HttpError } from '@shared/rest/model/http-error';
import { AUTO_STANCES } from '@shared/registration/const/auto-stances';
import { DomainService } from '@shared/domain/service/domain.service';
import { DomainUtil } from '@shared/domain/util/domain.util';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import { FacebookNativeSDKService } from '@shared/social-login/service/facebook-native-sdk.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { UserErrorUtil } from '@shared/user/util/user-error.util';
import { RegistrationApiService } from '@api/aukro-api/api/registration-api.service';
import { UserAccountStateDto } from '@api/aukro-api/model/user-account-state-dto';
import { LoginVM } from '@api/aukro-api/model/login-vm';
import { PersonDto } from '@api/aukro-api/model/person-dto';

@Component({
  selector: 'auk-user-task-login-step-facebook-load',
  templateUrl: 'user-task-login-step-facebook-load.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ErrorsService,
  ],
})
export class UserTaskLoginStepFacebookLoadComponent extends UserTaskLoginStepComponent<'FB_LOAD'> implements OnInit {

  public isSubmitting: boolean = false;
  public dialogAlerts: DialogAlert[] = [];

  @ViewChild(forwardRef(() => AuthenticateWrapperComponent), { static: false })
  public authenticateWrapperComponent: AuthenticateWrapperComponent;

  constructor(
    private readonly socialLoginService: SocialLoginService,
    private readonly errorService: ErrorsService,
    private readonly registrationApiService: RegistrationApiService,
    private readonly tokenMonitoringService: TokenMonitoringService,
    private readonly authenticationService: AuthenticationService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainService: DomainService,
    private readonly facebookNativeSDKService: FacebookNativeSDKService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.signInWithFb();
  }

  private async loginViaFBNative(): Promise<void> {
    const fbData = await this.facebookNativeSDKService.loginViaFBNative();

    if (!fbData) {
      this.isSubmitting = false;
      this.resolve.emit({
        type: 'RESOLUTION_FACEBOOK_AUTHORIZE_FAILED',
        payload: {},
      });
      return Promise.resolve();
    }

    this.authenticateWrapperComponent.doAuthenticate(
      fbData.loginVM, { registrationPersonDto: fbData.registrationPersonDto, onSuccessNavigateUrl: '/' })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.resolve.emit({
            type: 'RESOLUTION_SUCCESS',
            payload: {},
          });
        },
        error: (httpError: HttpError) => this.fbLoginError(httpError, fbData.loginVM, fbData.registrationPersonDto),
      });
  }

  public signInWithFb(): void {
    this.isSubmitting = true;
    this.dialogAlerts = [];
    let loginVM: LoginVM;
    let registrationPersonDto: PersonDto;

    if (PlatformCommonService.isNativeApp) {
      void this.loginViaFBNative();
      return;
    }

    this.socialLoginService.signInWithFb()
      .pipe(
        mergeMap((socialUser: SocialUser) => {
          loginVM = {
            username: socialUser.email,
            extUserId: socialUser.id as any, // needed retype to any because of facebook user id could be bigger than Number.MAX_VALUE
            registrationChannelEnum: 'FACEBOOK',
            authToken: socialUser.authToken,
          };
          registrationPersonDto = {
            name: socialUser.firstName,
            surname: socialUser.lastName,
          };
          return this.authenticateWrapperComponent.doAuthenticateViaFbWithoutCatchError(loginVM, { registrationPersonDto });
        }),
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.resolve.emit({
            type: 'RESOLUTION_SUCCESS',
            payload: {},
          });
        },
        error: (httpError: HttpError) => this.fbLoginError(httpError, loginVM, registrationPersonDto),
      });
  }

  private fbLoginError(httpError: HttpError, loginVM: LoginVM, registrationPersonDto: PersonDto): void {
    if (this.errorService.facebookAuthorizeFailed(httpError)) {
      this.resolve.emit({
        type: 'RESOLUTION_FACEBOOK_AUTHORIZE_FAILED',
        payload: {},
      });

      return;
    }

    if (this.errorService.userNotRegisteredViaFB(httpError)) {
      this.handleNotRegisteredViaFb(loginVM);
      return;
    }

    if (this.errorService.userDoesntExists(httpError)) {
      this.registrationApiService.createUser$({
        userRegistrationDto: {
          thirdPartyCredentialsDto: { extUserId: loginVM.extUserId, authToken: loginVM.authToken },
          email: loginVM.username,
          personDto: registrationPersonDto,
          registrationChannelEnum: 'FACEBOOK',
          consentStances: AUTO_STANCES,
          termsConfirmed: true,
          sourceAction: this.payload.sourceAction,
        },
      })
        .pipe(
          mergeMap(() => {
            this.tokenMonitoringService.checkToken();
            return this.authenticationService.authenticate(loginVM);
          }),
          tap(() => {
            tokenCacheBuster$.next();
            this.tokenMonitoringService.setToken();
          }),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: () => {
            this.googleAnalyticsTrackingService.trackSimpleEvent('registerConsumerFacebook');
            this.resolve.emit({
              type: 'RESOLUTION_SUCCESS',
              payload: {},
            });
          },
          error: (registrationHttpError: HttpError) => {
            let registrationFailed: boolean = false;
            if (UserErrorUtil.firstNameNotValid(registrationHttpError)) {
              this.dialogAlerts = [{ type: 'DANGER', text: { key: 'FIRSTNAME_NOT_ALLOWED_HTML' } }];
              registrationFailed = true;
            } else if (UserErrorUtil.lastNameNotValid(registrationHttpError)) {
              this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LASTNAME_NOT_ALLOWED_HTML' } }];
              registrationFailed = true;
            }

            if (registrationFailed) {
              this.resolve.emit({
                type: 'RESOLUTION_FACEBOOK_AUTHORIZE_FAILED',
                payload: {
                  dialogAlerts: this.dialogAlerts,
                },
              });
              return;
            }

            console.error(registrationHttpError);
            this.dialogAlerts = [{ type: 'DANGER', text: { key: 'REGISTRATION_STEP_1_OVERALL_ERROR' } }];
          },
        });

      return;
    }

    if (this.errorService.isCrossDomainNotAllowed(httpError)) {
      const regDomainCode: DomainCode = DomainUtil.resolveDomainCodeFromError(httpError);
      this.dialogAlerts = [{
        type: 'WARNING', text: {
          key: 'CROSS_DOMAIN_SING_IN_NOT_ALLOWED_OLD_LOGIN_PAGE',
          params: {
            regDomain: this.domainService.getDomainHostUpperCaseCapitalLetter(regDomainCode),
            regDomainUrl: this.domainService.getDomainUrlWithProtocol(regDomainCode),
            actualDomain: this.domainService.getDomainHostUpperCaseCapitalLetter(this.domainService.domain),
          },
        },
      }];
      this.isSubmitting = false;

      this.resolve.emit({
        type: 'RESOLUTION_FACEBOOK_AUTHORIZE_FAILED',
        payload: {
          regDomainCode,
          dialogAlerts: this.dialogAlerts,
        },
      });

      return;
    }

    this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LOGIN_FAILED' } }];
    console.error(httpError);
  }

  private handleNotRegisteredViaFb(loginVM: LoginVM): void {
    this.registrationApiService.validateUserAccount$({ registrationValidationDto: { loginOrEmail: loginVM.username } })
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (userAccountState: UserAccountStateDto) => {
          this.resolve.emit({
            type: userAccountState.passwordSet ?
              'RESOLUTION_PASSWORD_NON_REGISTERED_VIA_FB' : 'RESOLUTION_NON_REGISTERED_VIA_FACEBOOK_NO_PASSWORD',
            payload: {
              email: loginVM.username,
            },
          });
        },
        error: () => {
          this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LOGIN_FAILED' } }];
        },
      });
  }

}
