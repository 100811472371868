import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class NativeAppUpdateNavigationService {

  private readonly NAVIGATION_KEY = 'last_known_route';

  public async saveCurrentRoute(route: string): Promise<void> {
    await Preferences.set({ key: this.NAVIGATION_KEY, value: route });
  }

  public async retrieveCurrentRoute(): Promise<string | null> {
    const { value } = await Preferences.get({ key: this.NAVIGATION_KEY });
    return value;
  }

  public async clearCurrentRoute(): Promise<void> {
    await Preferences.remove({ key: this.NAVIGATION_KEY });
  }

}
