/**
 * Unit maps for key from API to Greek strings including plurals
 */
export const UNIT_CODE_MAPPINGS_EL = {
  PAIRS: {
    '=0': 'ζεύγους',
    '=1': 'ζεύγος',
    '=2': 'ζεύγη',
    '=3': 'ζεύγη',
    '=4': 'ζεύγη',
    other: 'ζεύγους',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'ζευγάρια',
    '=1': 'ζευγάρι',
    other: 'ζευγάρια',
  },
  PIECES: {
    '=0': 'τεμαχίων',
    '=1': 'τεμάχιο',
    '=2': 'τεμάχια',
    '=3': 'τεμάχια',
    '=4': 'τεμάχια',
    other: 'τεμαχίων',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'τεμαχίων',
    '=1': 'τεμάχιο',
    other: 'τεμαχίων',
  },
  PIECE_SHORT: {
    other: 'τμχ',
  },
  SET: {
    '=0': 'σετ',
    '=1': 'σετ',
    '=2': 'σετ',
    '=3': 'σετ',
    '=4': 'σετ',
    other: 'σετ',
  },
  SET_ACCUSATIVE: {
    '=0': 'σετ',
    '=1': 'σετ',
    other: 'σετ',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'προσφέρει',
    '=2': 'προσφέρουν',
    '=3': 'προσφέρουν',
    '=4': 'προσφέρουν',
    other: 'προσφέρει',
  },
  BET_PAST: {
    '=1': 'πρόσφερε',
    '=2': 'πρόσφεραν',
    '=3': 'πρόσφεραν',
    '=4': 'πρόσφεραν',
    other: 'πρόσφερε',
  },
  ITEM: {
    '=0': 'αντικειμένων',
    '=1': 'αντικείμενο',
    '=2': 'αντικείμενα',
    '=3': 'αντικείμενα',
    '=4': 'αντικείμενα',
    other: 'αντικειμένων',
  },
  DAY: {
    '=0': 'ημερών',
    '=1': 'ημέρα',
    '=2': 'ημέρες',
    '=3': 'ημέρες',
    '=4': 'ημέρες',
    other: 'ημερών',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'ημέρα',
    other: 'ημέρες',
  },
  HOUR: {
    '=0': 'ωρών',
    '=1': 'ώρα',
    '=2': 'ώρες',
    '=3': 'ώρες',
    '=4': 'ώρες',
    other: 'ωρών',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'ώρα',
    other: 'ώρες',
  },
  MINUTE: {
    '=0': 'λεπτών',
    '=1': 'λεπτό',
    '=2': 'λεπτά',
    '=3': 'λεπτά',
    '=4': 'λεπτά',
    other: 'λεπτών',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'λεπτό',
    other: 'λεπτά',
  },
  OTHER: {
    '=0': 'άλλο',
    '=1': 'άλλο',
    '=2': 'άλλο',
    '=3': 'άλλο',
    '=4': 'άλλο',
    other: 'άλλα',
  },
  FILE: {
    '=0': 'αρχείων',
    '=1': 'αρχείο',
    '=2': 'αρχεία',
    '=3': 'αρχεία',
    '=4': 'αρχεία',
    other: 'αρχείων',
  },
  IMAGE: {
    '=0': 'εικόνων',
    '=1': 'εικόνα',
    '=2': 'εικόνες',
    '=3': 'εικόνες',
    '=4': 'εικόνες',
    other: 'εικόνων',
  },
  OFFER: {
    '=0': 'προσφορών',
    '=1': 'προσφορά',
    '=2': 'προσφορές',
    '=3': 'προσφορές',
    '=4': 'προσφορές',
    other: 'προσφορών',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'προσφορών',
    '=1': 'προσφορά',
    '=2': 'προσφορές',
    '=3': 'προσφορές',
    '=4': 'προσφορές',
    other: 'προσφορών',
  },
  POSITIVE_COMMENT: {
    '=0': 'θετικών αξιολογήσεων',
    '=1': 'θετική αξιολόγηση',
    '=2': 'θετική αξιολόγηση',
    '=3': 'θετική αξιολόγηση',
    '=4': 'θετική αξιολόγηση',
    other: 'θετική αξιολόγηση',
  },
  USER_GENITIV: {
    '=0': 'χρηστών',
    '=1': 'χρήστη',
    '=2': 'χρηστών',
    '=3': 'χρηστών',
    '=4': 'χρηστών',
    other: 'χρηστών',
  },
  USER_INSTRUMENTAL: {
    '=0': 'χρήστη',
    '=1': 'χρήστη',
    other: 'χρήστες',
  },
  ADDED_PASSIVE: {
    '=0': 'προστέθηκαν',
    '=1': 'προστέθηκε',
    '=2': 'προστέθηκαν',
    '=3': 'προστέθηκαν',
    '=4': 'προστέθηκαν',
    other: 'προστέθηκαν',
  },
};
