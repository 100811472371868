/**
 * Unit maps for key from API to Dutch strings including plurals
 */
export const UNIT_CODE_MAPPINGS_NL = {
  PAIRS: {
    '=0': 'paren',
    '=1': 'paar',
    '=2': 'paren',
    '=3': 'paren',
    '=4': 'paren',
    other: 'paren',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'paren',
    '=1': 'paar',
    other: 'paren',
  },
  PIECES: {
    '=0': 'stukken',
    '=1': 'stuk',
    '=2': 'stukken',
    '=3': 'stukken',
    '=4': 'stukken',
    other: 'stukken',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'stuks',
    '=1': 'stuk',
    other: 'stuks',
  },
  PIECE_SHORT: {
    other: 'st.',
  },
  SET: {
    '=0': 'sets',
    '=1': 'set',
    '=2': 'sets',
    '=3': 'sets',
    '=4': 'sets',
    other: 'sets',
  },
  SET_ACCUSATIVE: {
    '=0': 'sets',
    '=1': 'set',
    other: 'sets',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'biedt',
    '=2': 'bieden',
    '=3': 'bieden',
    '=4': 'bieden',
    other: 'biedt',
  },
  BET_PAST: {
    '=1': 'bod',
    '=2': 'bieden',
    '=3': 'bieden',
    '=4': 'bieden',
    other: 'bod',
  },
  ITEM: {
    '=0': 'items',
    '=1': 'item',
    '=2': 'items',
    '=3': 'items',
    '=4': 'items',
    other: 'items',
  },
  DAY: {
    '=0': 'dagen',
    '=1': 'dag',
    '=2': 'dagen',
    '=3': 'dagen',
    '=4': 'dagen',
    other: 'dagen',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'dag',
    other: 'dagen',
  },
  HOUR: {
    '=0': 'uren',
    '=1': 'uur',
    '=2': 'uren',
    '=3': 'uren',
    '=4': 'uren',
    other: 'uren',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'uur',
    other: 'uren',
  },
  MINUTE: {
    '=0': 'minuten',
    '=1': 'minuut',
    '=2': 'minuten',
    '=3': 'minuten',
    '=4': 'minuten',
    other: 'minuten',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minuut',
    other: 'minuten',
  },
  OTHER: {
    '=0': 'andere',
    '=1': 'ander',
    '=2': 'andere',
    '=3': 'andere',
    '=4': 'andere',
    other: 'andere',
  },
  FILE: {
    '=0': 'bestanden',
    '=1': 'bestand',
    '=2': 'bestanden',
    '=3': 'bestanden',
    '=4': 'bestanden',
    other: 'bestanden',
  },
  IMAGE: {
    '=0': 'afbeeldingen',
    '=1': 'afbeelding',
    '=2': 'afbeeldingen',
    '=3': 'afbeeldingen',
    '=4': 'afbeeldingen',
    other: 'afbeeldingen',
  },
  OFFER: {
    '=0': 'aanbiedingen',
    '=1': 'aanbieding',
    '=2': 'aanbiedingen',
    '=3': 'aanbiedingen',
    '=4': 'aanbiedingen',
    other: 'aanbiedingen',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'aanbiedingen',
    '=1': 'aanbieding',
    '=2': 'aanbiedingen',
    '=3': 'aanbiedingen',
    '=4': 'aanbiedingen',
    other: 'aanbiedingen',
  },
  POSITIVE_COMMENT: {
    '=0': 'positieve opmerkingen',
    '=1': 'positieve opmerking',
    '=2': 'positieve opmerkingen',
    '=3': 'positieve opmerkingen',
    '=4': 'positieve opmerkingen',
    other: 'positieve opmerkingen',
  },
  USER_GENITIV: {
    '=0': 'gebruikers',
    '=1': 'gebruiker',
    '=2': 'gebruikers',
    '=3': 'gebruikers',
    '=4': 'gebruikers',
    other: 'gebruikers',
  },
  USER_INSTRUMENTAL: {
    '=0': 'gebruiker',
    '=1': 'gebruiker',
    other: 'gebruikers',
  },
  ADDED_PASSIVE: {
    '=0': 'toegevoegd',
    '=1': 'toegevoegd',
    '=2': 'toegevoegd',
    '=3': 'toegevoegd',
    '=4': 'toegevoegd',
    other: 'toegevoegd',
  },
};
