@if (favouriteItems?.countOfAllFavouriteItems > 0) {
  <div>
    @for (favouriteItem of favouriteItems.favouriteItemsPreview; track favouriteItem.itemId) {
      <a
          class="!tw-no-underline !tw-text-matter-neutral-primary tw-bg-surface-neutral-1"
          [routerLink]="getRouterLink(favouriteItem)"
          (click)="onItemClick($event, favouriteItem)">
        <div class="tw-flex tw-flex-col">
          <div class="content">
            <!-- INFO SECTION -->
            <div class="tw-flex tw-flex-row tw-gap-2.5 tw-flex-grow tw-min-w-0">

              <!-- IMAGE -->
              <div class="tw-flex tw-shrink-0">
                <img class="tw-rounded tw-w-[56px] tw-h-[42px] tw-object-cover"
                     [aukReplaceByPlaceholderOnError]="favouriteItem.imageUrl"
                     placeholderSize="74x73"
                     [alt]="favouriteItem.itemName"
                />
              </div>
              <!--./IMAGE -->

              <!-- DESCRIPTION -->
              <div class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-pr-2 tw-items-start tw-min-w-0">
                <!-- ITEM NAME -->
                <span aukTestIdentification="favourite-items-name" class="tw-text-sm tw-font-medium tw-truncate tw-w-full">
                  {{ favouriteItem.itemName }}
                </span>
                <!--./ITEM NAME -->

                <!-- ADDITIONAL INFO -->
                <div class="tw-flex tw-flex-row tw-items-center">

                  <div>
                    @if (favouriteItem.endTime) {
                      <auk-countdown-panel
                          [endingTime]="favouriteItem.endTime"
                          class="tw-whitespace-nowrap"
                      />
                    }
                  </div>

                  <!-- SEPARATOR -->
                  <div class="tw-text-matter-neutral-tertiary tw-mx-3">
                    •
                  </div>
                  <!-- ./SEPARATOR -->

                  <!-- ITEM STATE -->
                  <div class="tw-uppercase">
                    <!-- AUCTION -->
                    @if (favouriteItem.stateInfo; as stateInfo) {
                      <span [ngClass]="stateInfo.classes">
                        {{ stateInfo.label | translateSource }}
                      </span>
                    }
                  </div>
                  <!-- ./ITEM STATE -->

                </div>
                <!--./ADDITIONAL INFO -->

              </div>
              <!--./DESCRIPTION -->

            </div>
            <!--./INFO SECTION -->

            <!-- PRICE SECTION -->
            <div class="tw-flex tw-flex-col tw-items-end tw-gap-1 tw-content-center">
              <span class="tw-text-sm tw-font-bold">
                {{ favouriteItem.price | currency }}
              </span>
              @if (favouriteItem.itemTypeEnum === 'BIDDING') {
                <a [routerLink]="getRouterLink(favouriteItem)" (click)="onBidButtonClick($event, favouriteItem)">
                  <auk-button
                      size="XS"
                      colorCombination="SILVERSTEIN_100_PRIMARY"
                  >
                    {{ { key: 'FAVOURITES_POPOVER_ITEMS_ACTION_BID' } | translateSource }}
                  </auk-button>
                </a>
              } @else {
                <auk-button
                    size="XS"
                    colorCombination="SILVERSTEIN_100_PRIMARY"
                    (buttonClick)="onBuyButtonClick($event, favouriteItem)"
                >
                  {{ { key: 'FAVOURITES_POPOVER_ITEMS_ACTION_BUY' } | translateSource }}
                </auk-button>
              }
            </div>
            <!--./PRICE SECTION -->

          </div>
        </div>
      </a>
    }
  </div>
} @else {
  <auk-favourite-popover-empty-tab-content
      [emptyPopoverContent]="emptyPopoverContent"
  />
}

