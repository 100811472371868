import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { CompanyRegistrationDto } from '../model/company-registration-dto';
import { EmailDto } from '../model/email-dto';
import { RegistrationValidationDto } from '../model/registration-validation-dto';
import { UserAccountStateDto } from '../model/user-account-state-dto';
import { UserLoginDto } from '../model/user-login-dto';
import { UserRegistrationDto } from '../model/user-registration-dto';
import { environment } from '@environment';

export interface CreateCompanyUserRequestParams {
    companyRegistrationDto: CompanyRegistrationDto;
}

export interface CreateUserRequestParams {
    userRegistrationDto: UserRegistrationDto;
}

export interface GetSimilarUserNamesRequestParams {
    login: string;
}

export interface IsUserNamePresentRequestParams {
    /** Login */
    login: string;
}

export interface ValidateEmailRequestParams {
    emailDto: EmailDto;
}

export interface ValidateUserAccountRequestParams {
    registrationValidationDto: RegistrationValidationDto;
}

@Injectable({
  providedIn: 'root',
})
export class RegistrationApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * [UCU010.2] Register company account
   */
  public createCompanyUser$(
    requestParameters: CreateCompanyUserRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserLoginDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.companyRegistrationDto,
      responseType: this.configuration.getResponseType(false, 'UserLoginDto'),
      headers,
    };
    return this.httpClient.request<UserLoginDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/registerCompany`,
      options,
    );
  }

  /**
   * [UCU010.1] Register personal account
   */
  public createUser$(
    requestParameters: CreateUserRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserLoginDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.userRegistrationDto,
      responseType: this.configuration.getResponseType(false, 'UserLoginDto'),
      headers,
    };
    return this.httpClient.request<UserLoginDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/registerUser`,
      options,
    );
  }

  /**
   * [UCU010.1] Get similar available logins for selected login
   */
  public getSimilarUserNames$(
    requestParameters: GetSimilarUserNamesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string[]> {
    const login: string = requestParameters.login;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<string>'),
      headers,
    };
    return this.httpClient.request<string[]>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/similarLogins/${ this.configuration.encodeParam({ name: 'login', value: login, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * [UCU010.1] Validate login for registration
   */
  public isUserNamePresent$(
    requestParameters: IsUserNamePresentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const login: string = requestParameters.login;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/validateLogin/${ this.configuration.encodeParam({ name: 'login', value: login, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * [UCU010.1] Validate email for registration
   */
  public validateEmail$(
    requestParameters: ValidateEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.emailDto,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/validateEmail`,
      options,
    );
  }

  /**
   * Check if user\&#39;s login or email exist.
   */
  public validateUserAccount$(
    requestParameters: ValidateUserAccountRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserAccountStateDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.registrationValidationDto,
      responseType: this.configuration.getResponseType(false, 'UserAccountStateDto'),
      headers,
    };
    return this.httpClient.request<UserAccountStateDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/registration/accountState`,
      options,
    );
  }

}
