import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CurrencyCodeType } from '../model/currency-code.type';
import { Observable, of } from 'rxjs';
import { UserCurrencyPreferenceService } from './user-currency-preference.service';
import isNil from 'lodash-es/isNil';
import { DomainCurrencyService } from '@shared/currency/service/domain-currency.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyResolverService extends NgUnsubscribe {

  constructor(
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
    private readonly domainCurrencyService: DomainCurrencyService,
  ) {
    super();
  }

  public resolvePreferredCurrency(preferredCurrency?: CurrencyCodeType): Observable<CurrencyCodeType> {
    if (isNil(preferredCurrency)) {
      preferredCurrency = this.domainCurrencyService.currentDomainCurrency;
    }

    this.userCurrencyPreferenceService.preferredCurrencyCode = preferredCurrency;

    return of(this.userCurrencyPreferenceService.preferredCurrencyCode);
  }

}

