import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import {
  actualStatisticsCacheBuster$,
  actualUserProfileStatisticsCacheBuster$,
  userEmailInfoCacheBuster$,
  userProfileCacheBuster$,
} from '../constant/cache-busters';
import isNil from 'lodash-es/isNil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UserActualStatisticsService } from './user-actual-statistics.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { Nil } from '@util/helper-types/nil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';
import { DomainService } from '@shared/domain/service/domain.service';
import { DomainConfigModel } from '@shared/domain/module/domain-config/model/domain-config.model';
import { InitialBasicDataInsertRequestParams, UsersApiService } from '@api/aukro-api/api/users-api.service';
import { UserProfileStatisticsDto } from '@api/aukro-api/model/user-profile-statistics-dto';
import { NewUserProfileBaseDto } from '@api/aukro-api/model/new-user-profile-base-dto';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { NewUserProfileAllDto } from '@api/aukro-api/model/new-user-profile-all-dto';
import { ChangeEmailDto } from '@api/aukro-api/model/change-email-dto';
import { GetUserAccountDetail200Response } from '@api/aukro-api/model/get-user-account-detail200-response';
import { SellerInfoDto } from '@api/aukro-api/model/seller-info-dto';
import { AccountDetailDto } from '@api/aukro-api/model/account-detail-dto';
import {
  AccountStateDtoActivationChannelEnumEnum,
  AccountStateDtoActivationProcessStatusEnumEnum,
} from '@api/aukro-api/model/account-state-dto';
import { AddressDto } from '@api/aukro-api/model/address-dto';
import { UserShowNameDto } from '@api/aukro-api/model/user-show-name-dto';
import { ChangeLoginDto } from '@api/aukro-api/model/change-login-dto';
import { JWTInfo } from '@api/aukro-api/model/jwt-info';
import { UpdatePhoneRequestParams, UsersMeApiService } from '@api/aukro-api/api/users-me-api.service';
import { UpdateCompanyAccountDetailDto } from '@api/aukro-api/model/update-company-account-detail-dto';
import { UserAccountDetailDto } from '@api/aukro-api/model/user-account-detail-dto';
import { UserProfileDto } from '@api/aukro-api/model/user-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class UserService extends NgUnsubscribe implements CacheAware {

  private readonly userProfileDataRefreshIntervalMs: number = DateUtils.convertMinutesToMilliseconds(5);

  constructor(
    public readonly cacheService: CacheService,
    private readonly authenticationService: AuthenticationService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly usersApiService: UsersApiService,
    private readonly usersMeApiService: UsersMeApiService,
    private readonly userActualStatisticsService: UserActualStatisticsService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
  ) {
    super();

    if (this.platformCommonService.isServer) {
      return;
    }

    // refresh user profile data
    // TODO: PDEV-12794
    this.ngZoneUtilService.intervalOut$(this.userProfileDataRefreshIntervalMs)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        actualUserProfileStatisticsCacheBuster$.next();
      });
  }

  public get userRegDomainConfig$(): Observable<DomainConfigModel> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((actualStatistics) =>
          this.domainService.getDomainConfig$(actualStatistics.registrationDomainCode),
        ),
      );
  }

  public getActualUserProfileStatistics(ignoreCache: boolean = false): Observable<UserProfileStatisticsDto | Nil> {
    if (!this.authenticationService.isLoggedIn()) {
      return of(undefined);
    }

    if (ignoreCache) {
      return this.usersApiService.getUserProfileStatistics$();
    } else {
      return this.loadUserProfileStatistics();
    }
  }

  public getUserProfileMinimalById(userId: number): Observable<NewUserProfileBaseDto> {

    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getUserProfileByIdMinimal$({ id: userId });
  }

  /**
   * Returns user info if is logged in, otherwise returns null
   * @param ignoreCache - whether cache should be ignored and API call should be made
   */
  public getActualStatistics(ignoreCache: boolean = false): Observable<UserInterestStatisticsDto | Nil> {
    if (this.authenticationService.isLoggedIn()) {
      if (ignoreCache) {
        return this.userActualStatisticsService.loadActualStatisticsIgnoringCache();
      } else {
        return this.userActualStatisticsService.loadActualStatistics();
      }
    }

    return of(null);
  }

  @Cacheable({
    cacheBuster$: userProfileCacheBuster$,
    key: 'UserService#getCurrentUserProfileMinimal',
  })
  public getCurrentUserProfileMinimal(): Observable<NewUserProfileBaseDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) => stats
          ? this.getUserProfileMinimalById(stats.userId)
          : of(null)));
  }

  @Cacheable({
    cacheBuster$: userProfileCacheBuster$,
    key: 'UserService#getCurrentUserProfileFull',
  })
  public getCurrentUserProfileFull(): Observable<UserProfileDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) =>
          stats
            ? this.usersApiService.getUserProfile$({ id: stats.userId })
            : of(null)));
  }

  @Cacheable({
    cacheBuster$: userEmailInfoCacheBuster$,
    key: 'UserService#getCurrentUserEmailInfo',
  })
  public getCurrentUserEmailInfo(): Observable<ChangeEmailDto> {
    return this.getActualStatistics()
      .pipe(
        mergeMap((stats: UserInterestStatisticsDto) =>
          stats
            ? this.usersApiService.getUserEmailInfo$({ id: stats.userId })
            : of(null)));
  }

  public detail(userId: number): Observable<GetUserAccountDetail200Response> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getUserAccountDetail$({ id: userId });
  }

  public resetCurrentUser(): void {
    this.authenticationService.resetCurrentUser();
  }

  public isCurrentlyLoggedUser(userId: number): Observable<boolean> {
    return this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        map(() => this.authenticationService.currentLoggedUserId),
        distinctUntilChanged(),
        map((currentLoggedUserId) => userId === currentLoggedUserId),
      );
  }

  public isSellerInfoFilled(sellerInfo: SellerInfoDto): boolean {
    if (!sellerInfo) {
      return false;
    }
    let isFilled = false;
    Object.keys(sellerInfo).forEach((key) => {
      if (sellerInfo[key] && typeof sellerInfo[key] === 'string' && sellerInfo[key] !== '') {
        isFilled = true;
      }
    });
    if (sellerInfo.hallmarkVisible || sellerInfo.companyIdentifiers) {
      isFilled = true;
    }
    return isFilled;
  }

  /**
   * Users who have verified account by MAIL or CARD cant verify account by CARD during change to company process
   * @param accountDetail
   */
  public hasChangingUserToCompanyEnabledCardVerification(accountDetail: AccountDetailDto): boolean {
    if (accountDetail.stateDto.changingToCompany &&
      this.isUserVerifiedBySpecificMethods(['CARD', 'MAIL'], accountDetail) &&
      (['STARTED', 'NO_ACTIVATION'] as AccountStateDtoActivationProcessStatusEnumEnum[])
        .includes(accountDetail.stateDto.activationProcessStatusEnum)) {
      return false;
    }
    return true;
  }

  /**
   * Check if user has verified by provided ActivationChannelEnumAccountStateDto list
   * @param methods
   * @param accountDetail
   * @returns boolean user is verified by specific methods
   */
  public isUserVerifiedBySpecificMethods(methods: AccountStateDtoActivationChannelEnumEnum[], accountDetail: AccountDetailDto): boolean {
    return methods.includes(accountDetail.stateDto.activationChannelEnum);
  }

  /**
   * Updates current user's phone
   * ActualStatistics cache is invalidated on success because field phoneVerificationRequired of actualStatistics could change
   * @param params - update phone params
   * @param headers - HTTP headers
   * @returns - API call source
   */
  public updatePhone(params: UpdatePhoneRequestParams, headers: { [key: string]: string } = {}): Observable<void> {
    return this.usersMeApiService.updatePhone$(params, headers)
      .pipe(
        tap(() => actualStatisticsCacheBuster$.next()),
      );
  }

  public resendActivationEmail(userId: number): Observable<number> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.resendActivationEmail$({ id: userId });
  }

  public address(userId: number): Observable<AddressDto | Nil> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getUserAddress$({ id: userId });
  }

  public showName(suggestText: string): Observable<UserShowNameDto[]> {
    if (isNil(suggestText)) {
      return of(null);
    }

    return this.usersApiService.suggestShowName$({ text: suggestText });
  }

  public getLoginInfo(userId: number): Observable<ChangeLoginDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getUserLoginInfo$({ id: userId });
  }

  public initBasicData(params: InitialBasicDataInsertRequestParams): Observable<JWTInfo> {
    if (isNil(params)) {
      return of(null);
    }

    return this.usersApiService.initialBasicDataInsert$(params);
  }

  public getSellerInfo(userId: number): Observable<SellerInfoDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getSellerInfo$({ userId });
  }

  public getEmailInfo(userId: number): Observable<ChangeEmailDto> {
    if (isNil(userId)) {
      return of(null);
    }

    return this.usersApiService.getUserEmailInfo$({ id: userId });
  }

  public updateLoginInfo(userId: number, changeLoginDto: ChangeLoginDto): Observable<ChangeLoginDto> {
    if (isNil(userId) || isNil(changeLoginDto)) {
      return of(null);
    }

    return this.usersApiService.updateUserLoginInfo$({ id: userId, changeLoginDto });
  }

  public updateUserDetail(userId: number, userAccountDetailDto: UserAccountDetailDto): Observable<void> {
    if (isNil(userId) || isNil(userAccountDetailDto)) {
      return of(null);
    }

    return this.usersApiService.updateUserAccountDetail$({ id: userId, userAccountDetailDto });
  }

  public updateCompanyDetail(userId: number, updateCompanyAccountDetailDto: UpdateCompanyAccountDetailDto): Observable<void> {
    if (isNil(userId) || isNil(updateCompanyAccountDetailDto)) {
      return of(null);
    }

    return this.usersApiService.updateCompanyAccountDetail$({ id: userId, updateCompanyAccountDetailDto });
  }

  public updateSellerInfo(sellerInfoDto: SellerInfoDto): Observable<SellerInfoDto> {
    if (isNil(sellerInfoDto)) {
      return of(null);
    }

    return this.usersApiService.updateSellerInfo$({ sellerInfoDto });
  }

  public getUserProfileByUsernameAll(username: string): Observable<NewUserProfileAllDto> {
    if (isNil(username)) {
      return of(null);
    }

    return this.usersApiService.getUserProfileByUsernameAll$({ username });
  }

  @Cacheable({
    cacheBuster$: actualUserProfileStatisticsCacheBuster$,
    key: 'UserService#loadUserProfileStatistics',
  })
  private loadUserProfileStatistics(): Observable<UserProfileStatisticsDto> {
    return this.usersApiService.getUserProfileStatistics$();
  }

}

