/**
 * Unit maps for key from API to Polish strings including plurals
 */
export const UNIT_CODE_MAPPINGS_PL = {
  PAIRS: {
    '=0': 'par',
    '=1': 'para',
    '=2': 'pary',
    '=3': 'pary',
    '=4': 'pary',
    other: 'par',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'pary',
    '=1': 'parę',
    other: 'pary',
  },
  PIECES: {
    '=0': 'sztuk',
    '=1': 'sztuka',
    '=2': 'sztuki',
    '=3': 'sztuki',
    '=4': 'sztuki',
    other: 'sztuk',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'sztuk',
    '=1': 'sztuka',
    other: 'sztuk',
  },
  PIECE_SHORT: {
    other: 'szt.',
  },
  SET: {
    '=0': 'zestawów',
    '=1': 'zestaw',
    '=2': 'zestawy',
    '=3': 'zestawy',
    '=4': 'zestawy',
    other: 'zestawów',
  },
  SET_ACCUSATIVE: {
    '=0': 'zestawy',
    '=1': 'zestaw',
    other: 'zestawy',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'stawia',
    '=2': 'stawiają',
    '=3': 'stawiają',
    '=4': 'stawiają',
    other: 'stawia',
  },
  BET_PAST: {
    '=1': 'stawiał',
    '=2': 'stawiali',
    '=3': 'stawiali',
    '=4': 'stawiali',
    other: 'stawiało',
  },
  ITEM: {
    '=0': 'przedmiotów',
    '=1': 'przedmiot',
    '=2': 'przedmioty',
    '=3': 'przedmioty',
    '=4': 'przedmioty',
    other: 'przedmiotów',
  },
  DAY: {
    '=0': 'dni',
    '=1': 'dzień',
    '=2': 'dni',
    '=3': 'dni',
    '=4': 'dni',
    other: 'dni',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'dniem',
    other: 'dniami',
  },
  HOUR: {
    '=0': 'godzin',
    '=1': 'godzina',
    '=2': 'godziny',
    '=3': 'godziny',
    '=4': 'godziny',
    other: 'godzin',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'godziną',
    other: 'godzinami',
  },
  MINUTE: {
    '=0': 'minut',
    '=1': 'minuta',
    '=2': 'minuty',
    '=3': 'minuty',
    '=4': 'minuty',
    other: 'minut',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minutą',
    other: 'minutami',
  },
  OTHER: {
    '=0': 'innych',
    '=1': 'inny',
    '=2': 'inne',
    '=3': 'inne',
    '=4': 'inne',
    other: 'innych',
  },
  FILE: {
    '=0': 'plików',
    '=1': 'plik',
    '=2': 'pliki',
    '=3': 'pliki',
    '=4': 'pliki',
    other: 'plików',
  },
  IMAGE: {
    '=0': 'obrazów',
    '=1': 'obraz',
    '=2': 'obrazy',
    '=3': 'obrazy',
    '=4': 'obrazy',
    other: 'obrazów',
  },
  OFFER: {
    '=0': 'ofert',
    '=1': 'oferta',
    '=2': 'oferty',
    '=3': 'oferty',
    '=4': 'oferty',
    other: 'ofert',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'ofert',
    '=1': 'ofertę',
    '=2': 'oferty',
    '=3': 'oferty',
    '=4': 'oferty',
    other: 'ofert',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozytywnych opinii',
    '=1': 'pozytywna opinia',
    '=2': 'pozytywne opinie',
    '=3': 'pozytywne opinie',
    '=4': 'pozytywna opinia',
    other: 'pozytywnych opinii',
  },
  USER_GENITIV: {
    '=0': 'użytkowników',
    '=1': 'użytkownika',
    '=2': 'użytkowników',
    '=3': 'użytkowników',
    '=4': 'użytkowników',
    other: 'użytkowników',
  },
  USER_INSTRUMENTAL: {
    '=0': 'użytkownikiem',
    '=1': 'użytkownikiem',
    other: 'użytkownikami',
  },
  ADDED_PASSIVE: {
    '=0': 'dodane',
    '=1': 'dodana',
    '=2': 'dodane',
    '=3': 'dodane',
    '=4': 'dodane',
    other: 'dodane',
  },
};
