import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { AuctionCartContentDto } from '../model/auction-cart-content-dto';
import { CartCheckoutDto } from '../model/cart-checkout-dto';
import { CartCheckoutFormDto } from '../model/cart-checkout-form-dto';
import { CartDetailDto } from '../model/cart-detail-dto';
import { CartThankYouDto } from '../model/cart-thank-you-dto';
import { environment } from '@environment';

export interface CartDetailRequestParams {
    auctionCartContentDto: AuctionCartContentDto;
}

export interface CheckoutCartRequestParams {
    cartCheckoutFormDto: CartCheckoutFormDto;
    /** Cart id */
    cartId: number;
}

export interface ShowCartOptionsChoice1RequestParams {
    /** Cart id */
    cartId: number;
    /** Cart item ids */
    cartItemIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class CartAuctionApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Show content of cart
   */
  public cartDetail$(
    requestParameters: CartDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.auctionCartContentDto,
      responseType: this.configuration.getResponseType(false, 'CartDetailDto'),
      headers,
    };
    return this.httpClient.request<CartDetailDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cartAuction/detail`,
      options,
    );
  }

  /**
   * Checkout cart.
   */
  public checkoutCart$(
    requestParameters: CheckoutCartRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartThankYouDto> {
    const cartId: number = requestParameters.cartId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cartCheckoutFormDto,
      responseType: this.configuration.getResponseType(false, 'CartThankYouDto'),
      headers,
    };
    return this.httpClient.request<CartThankYouDto>(
      'post',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cartAuction/${ this.configuration.encodeParam({ name: 'cartId', value: cartId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/checkoutCart`,
      options,
    );
  }

  /**
   * Show form with shipping options and payment options.
   */
  public showCartOptionsChoice1$(
    requestParameters: ShowCartOptionsChoice1RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartCheckoutDto> {
    const cartId: number = requestParameters.cartId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      cartItemIds: requestParameters.cartItemIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'CartCheckoutDto'),
      headers,
    };
    return this.httpClient.request<CartCheckoutDto>(
      'get',
      `${ environment.API_DEFINITIONS['BACKEND_WEB'].url }/cartAuction/${ this.configuration.encodeParam({ name: 'cartId', value: cartId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/cartOptionsChoice`,
      options,
    );
  }

}
