@if (!basicRegistrationForm.enabled) {
  <div class="tw-relative">
    <auk-spinner-legacy [isFullPage]="true" class="tw-absolute"></auk-spinner-legacy>
  </div>
}

@if (basicRegistrationForm) {
  <form
    [formGroup]="basicRegistrationForm"
    (ngSubmit)="onSubmit()"
    aukTestIdentification="registration-form"
    aukValidationMessages
    #vme="validationMessagesExport"
    >
    <div class="tw-flex tw-flex-col sm:tw-flex-row tw-mx-[-0.25rem]">
      <!-- First name -->
      <mat-form-field class="tw-grow tw-mx-1" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_FIRST_NAME_LABEL'}"
        ></mat-label>
        <input formControlName="firstName"
          aukTestIdentification="registration-form-first-name"
          [placeholder]="{key: 'REGISTRATION_BASIC_DATA_FIRST_NAME_PLACEHOLDER'} | translateSource"
          matInput
          >
        <div class="tw-absolute tw-top-[2.7rem]">
          @if (basicRegistrationForm.get('firstName').value) {
            <mat-error>
              @if (vme.hasError('firstName', 'minlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_FIRST_NAME_MIN_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('firstName', 'maxlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_FIRST_NAME_MAX_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('firstName', 'personName')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_FIRST_NAME_VALIDATION_MESSAGE'}"></span>
              }
            </mat-error>
          }
        </div>
      </mat-form-field>
      <!-- Last name -->
      <mat-form-field class="tw-grow tw-mx-1" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_LAST_NAME_LABEL'}"
        ></mat-label>
        <input formControlName="lastName"
          aukTestIdentification="registration-form-last-name"
          [placeholder]="{key: 'REGISTRATION_BASIC_DATA_LAST_NAME_PLACEHOLDER'} | translateSource"
          autocomplete="off"
          matInput
          >
        <div class="tw-absolute tw-top-[2.7rem]">
          @if (basicRegistrationForm.get('lastName').value) {
            <mat-error>
              @if (vme.hasError('lastName', 'minlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_LAST_NAME_MIN_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('lastName', 'maxlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_LAST_NAME_MAX_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('lastName', 'personName')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_LAST_NAME_VALIDATION_MESSAGE'}"></span>
              }
            </mat-error>
          }
        </div>
      </mat-form-field>
    </div>
    <div class="tw-flex tw-flex-col sm:tw-flex-row tw-mx-[-0.25rem]">
      <!-- Country -->
      <mat-form-field class="tw-grow sm:tw-grow-0 tw-mx-1" formGroupName="address" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_COUNTRY_LABEL'}"
        ></mat-label>
        <mat-select aukTestIdentification="registration-form-country-select" formControlName="country">
          @for (country of countries; track country) {
            <mat-option [value]="country.code">
              {{ country.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- Address -->
      <mat-form-field class="tw-grow tw-mx-1" formGroupName="address" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ADDRESS_LABEL'}"
        ></mat-label>
        <input formControlName="address"
          aukTestIdentification="registration-form-adress"
          [placeholder]="{key: 'REGISTRATION_BASIC_DATA_ADDRESS_PLACEHOLDER'} | translateSource"
          matInput
          >
        <div class="tw-absolute tw-top-[2.7rem]">
          @if (basicRegistrationForm.get('address').get('address').value) {
            <mat-error>
              @if (vme.hasError('address.address', 'minlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ADDRESS_MIN_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('address.address', 'maxlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ADDRESS_MAX_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('address.address', 'street')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ADDRESS_VALIDATION_MESSAGE'}"></span>
              }
            </mat-error>
          }
        </div>
      </mat-form-field>
    </div>
    <div class="tw-flex tw-flex-col sm:tw-flex-row tw-mx-[-0.25rem]">
      <!-- City -->
      <mat-form-field class="tw-grow tw-mx-1" formGroupName="address" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_CITY_LABEL'}"
        ></mat-label>
        <input formControlName="city"
          aukTestIdentification="registration-form-city"
          [placeholder]="{key: 'REGISTRATION_BASIC_DATA_CITY_PLACEHOLDER'} | translateSource"
          matInput
          >
        <div class="tw-absolute tw-top-[2.7rem]">
          @if (basicRegistrationForm.get('address').get('city').value) {
            <mat-error>
              @if (vme.hasError('address.city', 'minlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_CITY_MIN_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('address.city', 'maxlength')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_CITY_MAX_LENGTH_VALIDATION_MESSAGE'}"></span>
              }
              @if (vme.hasError('address.city', 'city')) {
                <span
                [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_CITY_VALIDATION_MESSAGE'}"></span>
              }
            </mat-error>
          }
        </div>
      </mat-form-field>
      <!-- ZIP code -->
      @if (isZipCodeInputVisible) {
        <mat-form-field class="tw-grow sm:tw-grow-0 tw-mx-1" formGroupName="address"
          appearance="outline">
          <mat-label
            [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ZIP_LABEL'}"
          ></mat-label>
          <input formControlName="zipCode"
            aukTestIdentification="registration-form-zip-code"
            [aukInputTextMask]="{mask: zipCodeTextMask}"
            [placeholder]="{key: 'REGISTRATION_BASIC_DATA_ZIP_PLACEHOLDER'} | translateSource"
            matInput
            >
          <div class="tw-absolute tw-top-[2.7rem]">
            @if (basicRegistrationForm.get('address.zipCode').value && basicRegistrationForm.get('address.zipCode').hasError('zipCode')) {
              <mat-error>
                <span [aukInnerHtml]="{ key: 'REGISTRATION_BASIC_DATA_ZIP_CODE_VALIDATION_MESSAGE'}"></span>
              </mat-error>
            }
          </div>
        </mat-form-field>
      }
    </div>
    <div class="tw-flex">
      <!-- Phone -->
      <div class="tw-grow">
        <auk-phone-field
          aukTestIdentification="registration-form-phone"
          aukOnlyNumberInput
          [aukLimitMaxLength]="15"
          [control]="phoneControl"
          [isLabelShown]="true"
        ></auk-phone-field>
        @if (phoneControl.value || vme.hasError('phone')) {
          <mat-error
            class="tw-pl-3">
            @if (vme.hasError('phone','required')) {
              <span>{{ { key: 'BASIC_REGISTRATION_FORM_ERROR_REQUIRED' } | translateSource }}</span>
            }
            @if (vme.hasError('phone','shortInput')) {
              <span>{{getValidationMsgForPhone('shortInput') | translateSource}}</span>
            }
            @if (vme.hasError('phone','parseError')) {
              <span>{{getValidationMsgForPhone('parseError') | translateSource}}</span>
            }
            @if (vme.hasError('phone','phoneNumberNotValid')) {
              <span>{{getValidationMsgForPhone('phoneNumberNotValid') | translateSource}}</span>
            }
            @if (vme.hasError('phone','phoneNumberNotExists')) {
              <span>{{getValidationMsgForPhone('phoneNumberNotExists') | translateSource}}</span>
            }
          </mat-error>
        }
      </div>
    </div>
    <div class="tw-flex tw-mx-[-0.25rem] tw-mt-4">
      <mat-form-field
        class="tw-grow tw-mx-1"
        appearance="outline"
        (click)="birthDatePicker.open()">
        <mat-label
          [aukInnerHtml]="{key: 'REGISTRATION_BASIC_BIRTH_DATE_LABEL'}"
        ></mat-label>
        <input matInput
          readonly
          aukTestIdentification="registration-form-birthdate"
          formControlName="birthDate"
          type="text"
          [matDatepicker]="birthDatePicker"
          [min]="minBirthDate"
          [max]="maxBirthDate">
        <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #birthDatePicker
          color="warn"
          startView="multi-year"
          [touchUi]="responsivenessService.isMdAndLower"
          />
      </mat-form-field>
    </div>
    <auk-business-registration
      [basicRegistrationForm]="basicRegistrationForm">
    </auk-business-registration>
    @if (showAdditionalInfo) {
      <p class="tw-text-md tw-font-[500] tw-text-center tw-my-4"
        [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_ADDITIONAL_INFO'}"
      ></p>
    }
    <auk-button type="submit"
      colorCombination="PRIMARY"
      [chin]="true"
      aukTestIdentification="registration-form-submit"
      class="tw-w-full tw-mt-4"
      [isDisabled]="isSubmitting || basicRegistrationForm.invalid || basicRegistrationForm.pending || basicRegistrationForm.disabled"
      [isLoading]="isSubmitting"
      >
      <span [aukInnerHtml]="{key: 'REGISTRATION_BASIC_DATA_NEXT'}"></span>
    </auk-button>
  </form>
}
