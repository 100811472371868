<!-- Toggle dropdown on touch devices only if user is signed in. -->
<auk-avatar
  [class.toggle-on-touch]="user"
  class="is-small"
  [url]="user?.avatarUrlMedium"
></auk-avatar>
<span [class.toggle-on-touch]="user"
  class="info m-l-2"
  [attr.data-title]="'ACCOUNT_CONTROL_COMPONENT_HTML_14' | translate"
  [attr.data-content]="userSubtitle | translateSource"
></span>

@if (user) {
  <div class="dropdown-submenu center"
    (click)="onPopoverClick($event)"
    aukViewportDetector
    [checkOncePerPage]="false"
    (inViewportDetectorChange)="onPopoverInViewport($event)"
    >
    <div class="popover-header is-account">
      <p class="black-header">{{user.username}}</p>
      <a [routerLink]="userProfileUrl" class="text-blue" (click)="onPopoverLinkClick(userProfileUrl)">{{ 'ACCOUNT_CONTROL_COMPONENT_HTML_1' | translate }}</a>
    </div>
    <div class="tracking">
      @for (link of aukroLinks; track trackByIndexFn($index, link)) {
        <a
          [routerLink]="link.url"
          (click)="onPopoverLinkClick(link.url)">
          {{ link.name | translate }}
        </a>
      }
    </div>
    <ng-container *aukHideIfMobileApp>
      <a class="btn-primary tracking-box"
         *ngIf="isSellButtonEnabled$ | async"
         [routerLink]="exposeUrl"
         (click)="onAddProductClick($event)"
         data-gtm="new-item-in-my-popup"
      >
        <i class="material-icons big text-white text-bold">add</i>
        {{ 'ACCOUNT_CONTROL_COMPONENT_HTML_12' | translate }}
      </a>
    </ng-container>
    <a (click)="logout($event)" class="log-out">
      <i class="material-icons text-blue m-r-1">
        power_settings_new
      </i>
      {{ 'ACCOUNT_CONTROL_COMPONENT_HTML_13' | translate }}
    </a>
  </div>
}
