/**
 * Unit maps for key from API to Slovak strings including plurals
 */
export const UNIT_CODE_MAPPINGS_SK = {
  PAIRS: {
    '=0': 'párov',
    '=1': 'pár',
    '=2': 'páry',
    '=3': 'páry',
    '=4': 'páry',
    other: 'párov',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'páry',
    '=1': 'pár',
    other: 'páry',
  },
  PIECES: {
    '=0': 'kusov',
    '=1': 'kus',
    '=2': 'kusy',
    '=3': 'kusy',
    '=4': 'kusy',
    other: 'kusov',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'kusy',
    '=1': 'kus',
    other: 'kusy',
  },
  PIECE_SHORT: {
    other: 'ks',
  },
  SET: {
    '=0': 'sád',
    '=1': 'sada',
    '=2': 'sady',
    '=3': 'sady',
    '=4': 'sady',
    other: 'sád',
  },
  SET_ACCUSATIVE: {
    '=0': 'sady',
    '=1': 'sadu',
    other: 'sady',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'prihadzuje',
    '=2': 'prihadzujú',
    '=3': 'prihadzujú',
    '=4': 'prihadzujú',
    other: 'prihadzuje',
  },
  BET_PAST: {
    '=1': 'prihadzoval',
    '=2': 'prihadzovali',
    '=3': 'prihadzovali',
    '=4': 'prihadzovali',
    other: 'prihadzovalo',
  },
  ITEM: {
    '=0': 'položiek',
    '=1': 'položku',
    '=2': 'položky',
    '=3': 'položky',
    '=4': 'položky',
    other: 'položiek',
  },
  DAY: {
    '=0': 'dní',
    '=1': 'deň',
    '=2': 'dni',
    '=3': 'dni',
    '=4': 'dni',
    other: 'dní',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'dňom',
    other: 'dňami',
  },
  HOUR: {
    '=0': 'hodín',
    '=1': 'hodina',
    '=2': 'hodiny',
    '=3': 'hodiny',
    '=4': 'hodiny',
    other: 'hodín',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'hodinou',
    other: 'hodinami',
  },
  MINUTE: {
    '=0': 'minút',
    '=1': 'minúta',
    '=2': 'minúty',
    '=3': 'minúty',
    '=4': 'minúty',
    other: 'minút',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'minútou',
    other: 'minútami',
  },
  OTHER: {
    '=0': 'ďalší',
    '=1': 'ďalší',
    '=2': 'ďalší',
    '=3': 'ďalší',
    '=4': 'ďalší',
    other: 'ďalších',
  },
  FILE: {
    '=0': 'súborov',
    '=1': 'súbor',
    '=2': 'súbory',
    '=3': 'súbory',
    '=4': 'súbory',
    other: 'súborov',
  },
  IMAGE: {
    '=0': 'obrázkov',
    '=1': 'obrázok',
    '=2': 'obrázky',
    '=3': 'obrázky',
    '=4': 'obrázky',
    other: 'obrázkov',
  },
  OFFER: {
    '=0': 'ponúk',
    '=1': 'ponuka',
    '=2': 'ponuky',
    '=3': 'ponuky',
    '=4': 'ponuky',
    other: 'ponúk',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'ponúk',
    '=1': 'ponuku',
    '=2': 'ponuky',
    '=3': 'ponuky',
    '=4': 'ponuky',
    other: 'ponúk',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozitívnych hodnotení',
    '=1': 'pozitívne hodnotenie',
    '=2': 'pozitívne hodnotenie',
    '=3': 'pozitívne hodnotenie',
    '=4': 'pozitívne hodnotenie',
    other: 'pozitívne hodnotenie',
  },
  USER_GENITIV: {
    '=0': 'užívateľov',
    '=1': 'užívateľa',
    '=2': 'užívateľov',
    '=3': 'užívateľov',
    '=4': 'užívateľov',
    other: 'užívateľov',
  },
  USER_INSTRUMENTAL: {
    '=0': 'užívateľom',
    '=1': 'užívateľom',
    other: 'užívatelia',
  },
  ADDED_PASSIVE: {
    '=0': 'pridané',
    '=1': 'pridaná',
    '=2': 'pridané',
    '=3': 'pridané',
    '=4': 'pridané',
    other: 'pridané',
  },
};
