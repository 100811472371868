import { ArrayUtils } from '@util/util/array.utils';
import { CurrencyCodeType } from '@shared/currency/model/currency-code.type';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { DomainCode } from '@shared/domain/model/domain-code.type';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { MoneyWithDomain } from '@api/aukro-api/model/money-with-domain';

export class WebsocketPricesUtil {

  public static findProperPriceInCurrencyOrDefault(
    moneyDtos: MoneyWithDomain[],
    currentCurrency: CurrencyCodeType,
    currentDomain: DomainCode,
  ): MoneyDto | Nil {
    if (ArrayUtils.isEmpty(moneyDtos) || isNil(currentCurrency)) {
      return null;
    }

    const valueInTargetCurrency =
      moneyDtos.find(moneyDto => currentDomain === moneyDto.domain && moneyDto.currency === currentCurrency);

    return valueInTargetCurrency;
  }

}
