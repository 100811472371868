export const APP_CONSTANTS = {
  BASE_URL: '/',
  ANDROID_APP_PACKAGE_NAME: 'cz.aukro.twa',
  IS_IOS_NATIVE_COOKIE_NAME: 'is-ios-native',
  IS_ANDROID_MOBILE_APP_PWA_STORAGE_KEY: 'is-android-mobile-app-pwa',
  CATEGORY_RECOMMENDER_COOKIE_NAME: 'category-recommender',
  SEARCH_COOKIE_NAME: 'search-group',
  LISTING_COOKIE_NAME: 'listing-group',
  SEARCH_PERSONALIZATION_COOKIE_NAME: 'search-personalization-group',
  SUGGEST_COOKIE_NAME: 'suggest-group',
  SUGGEST_MEASUREMENT_ENABLED_COOKIE_NAME: 'suggest-measurement-enabled',
  WSRV1_COOKIE_NAME: 'wsrv-group-1',
  WSRV2_COOKIE_NAME: 'wsrv-group-2',
  WSRV3_COOKIE_NAME: 'wsrv-group-3',
  WSRV4_COOKIE_NAME: 'wsrv-group-4',
  WSRV5_COOKIE_NAME: 'wsrv-group-5',
  WSRV6_COOKIE_NAME: 'wsrv-group-6',
  WSRV7_COOKIE_NAME: 'wsrv-group-7',
  WSRV8_COOKIE_NAME: 'wsrv-group-8',
  WSRV9_COOKIE_NAME: 'wsrv-group-9',
  WSRV10_COOKIE_NAME: 'wsrv-group-10',
  WSRV11_COOKIE_NAME: 'wsrv-group-11',
  WSRV12_COOKIE_NAME: 'wsrv-group-12',
  CAROUSELS_COOKIE_NAME: 'carousels-group',
  ROIVENUE_IDS_COOKIE_NAME: 'roivenue-ids',
  AUKRO_BANNERS_CHANCE_LOCALSTORAGE_NAME: 'banners-chance',
  RECO1_COOKIE_SPLIT_GROUP_NAME: 'reco1',
  // TODO: PDEV-25500 - could be removed (GTM as well)
  ENDED_ITEM_COOKIE_SPLIT_GROUP_NAME: 'ended-item',
  GTM_COOKIES_WIN: 'x',
} as const;
