import { I18nPluralPipe } from '@angular/common';
import { UnitCodeMappings } from '../model/unit-code-mappings';
// eslint-disable-next-line import/no-restricted-paths
import { Lang } from '@shared/platform/lang.const';
import { UNIT_CODE_MAPPINGS_CS } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-cs';
import { UNIT_CODE_MAPPINGS_SK } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-sk';
import { UNIT_CODE_MAPPINGS_DE } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-de';
import { UNIT_CODE_MAPPINGS_HU } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-hu';
import { UNIT_CODE_MAPPINGS_PL } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-pl';
import { UNIT_CODE_MAPPINGS_RO } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-ro';
import { UNIT_CODE_MAPPINGS_IT } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-it';
import { UNIT_CODE_MAPPINGS_FR } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-fr';
import { UNIT_CODE_MAPPINGS_EL } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-el';
import { UNIT_CODE_MAPPINGS_NL } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-nl';
import { UNIT_CODE_MAPPINGS_HR } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-hr';
import { UNIT_CODE_MAPPINGS_SL } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-sl';
import { UNIT_CODE_MAPPINGS_BG } from '@common/ui-kit/common/unit-pluralize/constant/unit-code-mappings-bg';

const UNIT_CODE_MAP_IMPORTS: Record<Lang, UnitCodeMappings> = {
  cs: UNIT_CODE_MAPPINGS_CS,
  sk: UNIT_CODE_MAPPINGS_SK,
  de: UNIT_CODE_MAPPINGS_DE,
  hu: UNIT_CODE_MAPPINGS_HU,
  pl: UNIT_CODE_MAPPINGS_PL,
  ro: UNIT_CODE_MAPPINGS_RO,
  it: UNIT_CODE_MAPPINGS_IT,
  fr: UNIT_CODE_MAPPINGS_FR,
  el: UNIT_CODE_MAPPINGS_EL,
  nl: UNIT_CODE_MAPPINGS_NL,
  hr: UNIT_CODE_MAPPINGS_HR,
  sl: UNIT_CODE_MAPPINGS_SL,
  bg: UNIT_CODE_MAPPINGS_BG,
};

export class UnitUtil {

  public static unitPluralize(
    value: string,
    count: number,
    i18nPluralPipe: I18nPluralPipe,
    lang: Lang = 'cs',
  ): string {
    const UNIT_CODE_MAP = UNIT_CODE_MAP_IMPORTS[lang];
    if (value !== null && value !== '') {
      let map = UNIT_CODE_MAP[value];
      if (typeof map === 'string') {
        map = UNIT_CODE_MAP[map];
      }
      if (typeof map === 'object') {
        return i18nPluralPipe.transform(count, map);
      } else {
        throw new Error(`Invalid Data in unit-code-mapping: ${ typeof map }`);
      }
    }

    return value;
  }

}
