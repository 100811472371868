import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { takeUntil } from 'rxjs/operators';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { setUser } from '@sentry/core';

interface SentryUserModel {
  username: string;
  id: string;
}

/**
 * Service which initializes Sentry in browser (Sentry on server is initialized in sentry-user-initializer.ts)
 */
@Injectable({
  providedIn: 'root',
})
export class SentryUserInitializer extends BaseDestroy implements AppInitializer {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly authCacheService: AuthCacheService,
  ) {
    super();
  }

  public init(): void {
    this.initUserChangeCheck();
  }

  private initUserChangeCheck(): void {
    this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        setUser(this.getCurrentUser());
      });
  }

  private getCurrentUser(): SentryUserModel {
    return {
      username: this.authenticationService.isLoggedIn() ? this.authCacheService.decodedToken?.sub : null,
      id: this.authCacheService.aukroToken,
    };
  }

}
