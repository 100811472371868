import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DynamicScriptLoaderService {

  private readonly renderer2: Renderer2;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Append the JS tag to the Document Body.
   * @param src The path to the script
   * @returns the script element
   */
  public loadJsScript(src: string, id?: string): HTMLScriptElement {
    const script: HTMLScriptElement = this.renderer2.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    if (id) {
      script.id = id;
    }
    this.renderer2.appendChild(this.document.body, script);
    return script;
  }

  /**
   * Append the JS tag to with the given script the document body.
   * @param script Text of the script
   */
  public insertJsScript(script: string): void {
    const scriptElement: HTMLScriptElement = this.renderer2.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = script;
    this.renderer2.appendChild(this.document.body, scriptElement);
  }

}
