import { captureException, captureMessage, startSpan } from '@sentry/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  public logMessage(
    ...[message, captureContext]: Parameters<typeof captureMessage>
  ): void {
    captureMessage(message, captureContext);
  }

  public logException(
    ...[message, captureContext]: Parameters<typeof captureException>
  ): void {
    captureException(message, captureContext);
  }

  public startSpan(
    ...[options, callback]: Parameters<typeof startSpan>
  ): void {
    startSpan(options, callback);
  }

}
