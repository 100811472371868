/**
 * Unit maps for key from API to Croatian strings including plurals
 */
export const UNIT_CODE_MAPPINGS_HR = {
  PAIRS: {
    '=0': 'parova',
    '=1': 'par',
    '=2': 'para',
    '=3': 'para',
    '=4': 'para',
    other: 'parova',
  },
  PAIRS_ACCUSATIVE: {
    '=0': 'pari',
    '=1': 'par',
    other: 'pari',
  },
  PIECES: {
    '=0': 'komadi',
    '=1': 'komad',
    '=2': 'komada',
    '=3': 'komada',
    '=4': 'komada',
    other: 'komadi',
  },
  PIECES_ACCUSATIVE: {
    '=0': 'komadi',
    '=1': 'komad',
    other: 'komadi',
  },
  PIECE_SHORT: {
    other: 'kom',
  },
  SET: {
    '=0': 'setova',
    '=1': 'set',
    '=2': 'seta',
    '=3': 'seta',
    '=4': 'seta',
    other: 'setova',
  },
  SET_ACCUSATIVE: {
    '=0': 'setova',
    '=1': 'set',
    other: 'setova',
  },
  SETS: 'SET',
  SETS_ACCUSATIVE: 'SET_ACCUSATIVE',
  BET: {
    '=1': 'ponudio',
    '=2': 'ponudili',
    '=3': 'ponudili',
    '=4': 'ponudili',
    other: 'ponudio',
  },
  BET_PAST: {
    '=1': 'ponudio',
    '=2': 'ponudili',
    '=3': 'ponudili',
    '=4': 'ponudili',
    other: 'ponudio',
  },
  ITEM: {
    '=0': 'predmeta',
    '=1': 'predmet',
    '=2': 'predmeta',
    '=3': 'predmeta',
    '=4': 'predmeta',
    other: 'predmeta',
  },
  DAY: {
    '=0': 'dana',
    '=1': 'dan',
    '=2': 'dana',
    '=3': 'dana',
    '=4': 'dana',
    other: 'dana',
  },
  DAY_INSTRUMENTAL: {
    '=1': 'nappal',
    other: 'napokkal',
  },
  HOUR: {
    '=0': 'sati',
    '=1': 'sat',
    '=2': 'sata',
    '=3': 'sata',
    '=4': 'sata',
    other: 'sati',
  },
  HOUR_INSTRUMENTAL: {
    '=1': 'órával',
    other: 'órákkal',
  },
  MINUTE: {
    '=0': 'minuta',
    '=1': 'minuta',
    '=2': 'minute',
    '=3': 'minute',
    '=4': 'minute',
    other: 'minuta',
  },
  MINUTE_INSTRUMENTAL: {
    '=1': 'perccel',
    other: 'percekkel',
  },
  OTHER: {
    '=0': 'drugih',
    '=1': 'drugi',
    '=2': 'drugi',
    '=3': 'drugi',
    '=4': 'drugi',
    other: 'drugih',
  },
  FILE: {
    '=0': 'datoteka',
    '=1': 'datoteka',
    '=2': 'datoteke',
    '=3': 'datoteke',
    '=4': 'datoteke',
    other: 'datoteka',
  },
  IMAGE: {
    '=0': 'slika',
    '=1': 'slika',
    '=2': 'slike',
    '=3': 'slike',
    '=4': 'slike',
    other: 'slika',
  },
  OFFER: {
    '=0': 'ponuda',
    '=1': 'ponuda',
    '=2': 'ponude',
    '=3': 'ponude',
    '=4': 'ponude',
    other: 'ponuda',
  },
  OFFER_ACCUSATIVE: {
    '=0': 'ponuda',
    '=1': 'ponudu',
    '=2': 'ponude',
    '=3': 'ponude',
    '=4': 'ponude',
    other: 'ponuda',
  },
  POSITIVE_COMMENT: {
    '=0': 'pozitivnih komentara',
    '=1': 'pozitivan komentar',
    '=2': 'pozitivan komentar',
    '=3': 'pozitivan komentar',
    '=4': 'pozitivan komentar',
    other: 'pozitivnih komentara',
  },
  USER_GENITIV: {
    '=0': 'korisnika',
    '=1': 'korisnik',
    '=2': 'korisnika',
    '=3': 'korisnika',
    '=4': 'korisnika',
    other: 'korisnika',
  },
  USER_INSTRUMENTAL: {
    '=0': 'korisnikom',
    '=1': 'korisnikom',
    other: 'korisnicima',
  },
  ADDED_PASSIVE: {
    '=0': 'dodano',
    '=1': 'dodana',
    '=2': 'dodane',
    '=3': 'dodane',
    '=4': 'dodane',
    other: 'dodano',
  },
};
