import { I18nPluralPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UnitUtil } from '../class/unit.util';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/domain/service/domain.service';

@Pipe({ name: 'aukUnitPluralize' })
export class UnitPluralizePipe implements PipeTransform {

  constructor(
    private readonly domainService: DomainService,
    private readonly i18nPluralPipe: I18nPluralPipe,
  ) {
  }

  public transform(value: string, count: number): string {
    return UnitUtil.unitPluralize(value, count, this.i18nPluralPipe, this.domainService.lang);
  }

}
