import { ErrorHandler, Inject, Injectable } from '@angular/core';
import moment from 'moment-mini-ts';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AukError } from '@common/error/domain/auk-error';
import { ErrorUtil } from '@common/error/util/error-util';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';

@Injectable()
export class AukroErrorHandlerService extends BaseDestroy implements ErrorHandler {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
    super();
  }

  public handleError(error: AukError): void {
    if (!ErrorUtil.canLogError(error, PlatformCommonService.isDevMode)) {
      return;
    }

    console.error(error);

    if (PlatformCommonService.isDevMode) {
      return;
    }

    // if error is chunk load error, try manually force refresh page
    if (
      error?.message
      && typeof error.message === 'string'
      && error.message.includes('ChunkLoadError')
    ) {
      const reloadedAt = this.window.sessionStorage.getItem('reloadedAt');

      if (!reloadedAt || moment(reloadedAt).add('3', 'minutes').toDate() < new Date()) {
        this.window.sessionStorage.setItem('reloadedAt', moment().toDate().toString());
        this.window.location.reload();
        return;
      }
    }
  }

}
