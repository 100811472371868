import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { WatchingItemPreviewDto, WatchingItemPreviewDtoItemTypeEnumEnum, WatchingItemPreviewDtoStateEnum, WatchingItemPreviewDtoStateOfBiddingEnum } from '@api/aukro-api/model/watching-item-preview-dto';
import { AsyncDataUpdateService } from '@shared/async-data-update/async-data-update.service';
import { WsBackupPoolingConfig, WsPollingConfig } from '@shared/async-data-update/model/async-update-config.model';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { CookieService } from '@common/cookie/service/cookie.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Observable, of } from 'rxjs';
import { WebsocketPricesUtil } from '@shared/item-detail/service/async/util/websocket-prices-util';
import { FavouritePopoverWsService } from '@shared/favourite/service/favourite-popover-ws.service';
import { OffersApiService } from '@api/aukro-api/api/offers-api.service';
import { FavouritePopoverAsyncUpdateHelperService } from '@shared/favourite/service/favourite-popover-async-update-helper.service';
import { OfferDetailShort } from '@api/aukro-api/model/offer-detail-short';
import { Nil } from '@util/helper-types/nil';
import { WsConnectionState } from '@shared/websocket/model/ws-connection-state.model';
import { FavouritePopoverDataService } from '@shared/favourite/service/favourite-popover-data.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { ProductStateUtils } from '@shared/item-detail/service/mapper/base-item/utils/product-state.utils';
import { WebSocketItemDetailDto } from '@api/aukro-api/model/web-socket-item-detail-dto';
import moment from 'moment-mini-ts';
import first from 'lodash-es/first';
import { TimeService } from '@common/time/service/time.service';
import { PollingBracketsSeconds, PollingUtils } from '@shared/async-data-update/polling.utils';
import { FavouritePopoverWsAdditionalDataModel } from '@shared/favourite/model/favourite-popover-ws-additional-data.model';
import { WsDestinationModel } from '@shared/websocket/model/ws-destination.model';
import isNil from 'lodash-es/isNil';
import { DateUtils } from '@util/util/date.utils';
import { WsTopicDataModel } from '@shared/websocket/model/ws-topic-data.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DomainService } from '@shared/domain/service/domain.service';
import { LoggerService } from '@common/logger/service/logger.service';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverDataAsyncUpdateService
  extends AsyncDataUpdateService<WatchingItemPreviewDto[], FavouritePopoverWsAdditionalDataModel> {

  private readonly timeLeftToPollingTimeoutSecondsMap: PollingBracketsSeconds = new Map([
    [300, 120],
    [120, 60],
    [60, 30],
    [30, 10],
  ]);

  private readonly wsPollingBackupConfig: WsBackupPoolingConfig = {
    websocket: {
      connectTimeout: 8_000,
    },
    polling: {
      rateResolver: () => this.getWsPollingBackupRateResolver$(5),
    },
    mode: 'WS_POLLING_BACKUP',
  };

  private readonly wsPollingConfig: WsPollingConfig = {
    websocket: {
      connectTimeout: 8_000,
    },
    polling: {
      rateResolver: (currentIteration) => this.getWsPollingRateResolver(currentIteration),
    },
    mode: 'WS_POLLING',
    performPollOnInit: false,
  };

  constructor(
    configuratorCacheService: ConfiguratorCacheService,
    cookieService: CookieService,
    platformCommonService: PlatformCommonService,
    ngZoneUtilService: NgZoneUtilService,
    loggerService: LoggerService,
    private readonly favouritePopoverWsService: FavouritePopoverWsService,
    private readonly offersApiService: OffersApiService,
    private readonly favouritePopoverAsyncUpdateHelperService: FavouritePopoverAsyncUpdateHelperService,
    private readonly favouritePopoverDataService: FavouritePopoverDataService,
    private readonly authenticationService: AuthenticationService,
    private readonly timeService: TimeService,
    private readonly domainService: DomainService,
  ) {
    super(
      configuratorCacheService,
      cookieService,
      platformCommonService,
      ngZoneUtilService,
      favouritePopoverWsService,
      loggerService,
    );
  }

  protected override get defaultConfig(): Observable<WsBackupPoolingConfig | WsPollingConfig> {
    return this.favouritePopoverDataService.hasFirstToEndPopoverUrgentItemEndingTimePassed$()
      .pipe(
        distinctUntilChanged(),
        map((hasEndingTimePassed) =>
          hasEndingTimePassed
            ? this.wsPollingConfig
            : this.wsPollingBackupConfig,
        ),
      );
  }

  protected get wsState$(): Observable<WsConnectionState> {
    return this.favouritePopoverWsService.wsConnectionState$;
  }

  protected override websocketDataProvider$(): Observable<WatchingItemPreviewDto[] | Nil> {
    return this.favouritePopoverWsService.wsMessage$()
      .pipe(
        map((items) => items?.map((item) => this.mapWsItemToPreviewItem(item))),
      );
  }

  protected override pollingDataProvider$(
    destinations?: WsDestinationModel<FavouritePopoverWsAdditionalDataModel>[] | Nil,
  ): Observable<WatchingItemPreviewDto[]> {
    const obs$ = isNil(destinations)
      ? this.favouritePopoverAsyncUpdateHelperService.itemsIdsReadyForAsyncUpdate$
      : of(destinations.map(({ additionalData }) => additionalData.itemId));

    return obs$
      .pipe(
        filter((ids) => !!ids?.length),
        switchMap((itemIds) => this.offersApiService.bulkShowShortOfferDetailShort$({ itemIds })),
        map((items) =>
          items.map((item) => this.mapShortItemToPreviewItem(item))),
      );
  }

  private mapWsItemToPreviewItem(
    wsTopicData: WsTopicDataModel<WebSocketItemDetailDto, FavouritePopoverWsAdditionalDataModel>,
  ): WatchingItemPreviewDto {
    const existingPreviewItem = this.getExistingPreviewItem(wsTopicData.additionalData.itemId);
    const wsItem = wsTopicData.data;

    return {
      ...existingPreviewItem,
      endTime: wsItem.endingTime ?? existingPreviewItem.endTime,
      itemId: wsItem.itemId ?? existingPreviewItem.itemId,
      state: wsItem.state ?? existingPreviewItem.state,
      price:
        WebsocketPricesUtil.findProperPriceInCurrencyOrDefault(
          wsItem.prices,
          existingPreviewItem.price.currency,
          this.domainService.domain,
        )
        ?? existingPreviewItem.price,
      stateOfBidding:
        this.calculateStateOfBidding(
          wsItem.state,
          existingPreviewItem.stateOfBidding,
          existingPreviewItem.itemTypeEnum,
          wsItem.highestBidderId,
          wsItem.currentBidBidderId,
        )
        ?? existingPreviewItem.stateOfBidding,
    };
  }

  private mapShortItemToPreviewItem(shortItem: OfferDetailShort): Partial<WatchingItemPreviewDto> {
    const existingPreviewItem = this.getExistingPreviewItem(shortItem.itemId);

    return {
      ...existingPreviewItem,
      endTime: shortItem.endingTime,
      itemId: shortItem.itemId,
      price: shortItem.price,
      state: shortItem.state,
      buyNowActive: shortItem.buyNowActive,
      stateOfBidding: this.calculateStateOfBidding(
        shortItem.state,
        existingPreviewItem.stateOfBidding,
        existingPreviewItem.itemTypeEnum,
        shortItem.highestBidderId,
        null,
      ),
    };
  }

  private getExistingPreviewItem(itemId: number): WatchingItemPreviewDto | Nil {
    return this.favouritePopoverDataService.popoverData.favouriteItemsPreviewDto.favouriteItemsPreview
      .find((item) => item.itemId === itemId);
  }

  private calculateStateOfBidding(
    state: WatchingItemPreviewDtoStateEnum,
    stateOfBidding: WatchingItemPreviewDtoStateOfBiddingEnum | Nil,
    itemType: WatchingItemPreviewDtoItemTypeEnumEnum,
    highestBidderId: number | Nil,
    currentBidBidderId: number | Nil,
  ): WatchingItemPreviewDtoStateOfBiddingEnum {
    const currentUserId = this.authenticationService.currentLoggedUserId;

    // check if it is winning
    if (
      ProductStateUtils.isNotEndedAuctionAndWinning(
        { state, itemType, highestBidderId, currentBidBidderId },
        { stateOfBidding },
        currentUserId,
      )
    ) {
      return 'WINNING';
    }

    // check if it is losing
    if (
      ProductStateUtils.isNotEndedAuctionAndLosing(
        { state, itemType, highestBidderId, currentBidBidderId },
        { stateOfBidding },
        currentUserId)
    ) {
      return 'LOOSING';
    }
    return null;
  }

  /**
   * @returns duration after the next poll should be called
   */
  private getWsPollingBackupRateResolver$(
    defaultPollingTimeoutSeconds: number,
  ): Observable<number> {
    return this.favouritePopoverAsyncUpdateHelperService.itemsReadyForAsyncUpdate$
      .pipe(
        filter((items) => !!items?.length),
        map((items) => {
          // calculate first to end item
          const firstToEndItem = items.reduce(
            (acc, item) =>
              moment(acc.endTime).isBefore(item.endTime)
                ? acc
                : item,
            first(items),
          );

          // calculate remaining time
          const timeLeftSeconds =
            this.timeService.getRemainingTime(moment(firstToEndItem.endTime)).asSeconds();

          return PollingUtils.resolveTimeout(
            timeLeftSeconds,
            this.timeLeftToPollingTimeoutSecondsMap,
            defaultPollingTimeoutSeconds,
          ) * 1000;
        }),
        take(1),
      );
  }

  private getWsPollingRateResolver(
    currentIteration: number,
  ): number {
    let delayInSeconds: number | Nil;
    switch (currentIteration) {
      case 1:
        delayInSeconds = 2;
        break;
      case 2:
        delayInSeconds = 3;
        break;
      case 3:
        delayInSeconds = 5;
        break;
      default:
        delayInSeconds = 10;
    }

    return DateUtils.convertSecondsToMilliseconds(delayInSeconds);
  }

}
