import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { SuggestAllResponse } from '../model';
import { PopularSearchesDto } from '@api/generated/defs/PopularSearchesDto';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CartParams {
  /**
   * itemId
   * format: int64
   */
  itemId: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SimilarOfferNamesParams {
  /** text */
  text: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SuggestAllParams {
  params: model.SuggestAllRequest;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class SuggestionService extends RestHttpClientService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public cart(params: CartParams): Observable<model.ItemViewDto[]> {
    let pathParams = {
      itemId: params.itemId,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/suggest/cart/${ pathParams.itemId }`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public similarOfferNames(params: SimilarOfferNamesParams): Observable<string[]> {
    let queryParams = {
      text: params.text,
    };
    return this.getGenerated(`/suggest/similarOfferNames`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public popularSearches(): Observable<PopularSearchesDto> {
    return this.getGenerated(`/suggest/popular-searches`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public suggestAll(params: SuggestAllParams, headers: { [key: string]: string } = {}): Observable<SuggestAllResponse> {
    let bodyParams = params.params;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/suggest/all`, bodyParams, queryParams, formDataParams, headers);
  }
}
